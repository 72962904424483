import types from './Types';
import DelegateAction from '../ActionDelegator';

const PrivateCourseActions = {
  setShowCourseDatailModal: DelegateAction(types.SET_SHOW_COURSE_MODAL),
  getCoursesList : DelegateAction(types.GET_PRIVATE_COURSES_LIST),
  addCourse : DelegateAction(types.ADD_COURSE),
  deleteCourse : DelegateAction(types.DELETE_COURSE),
  updateCourse : DelegateAction(types.UPDATE_COURSE),
  getCourseById : DelegateAction(types.GET_COURSE_BY_ID),
  getMyCoursesList : DelegateAction(types.GET_MY_COURSES_LIST),
  getCalendarList : DelegateAction(types.GET_CALENDAR_LIST)
};

export default PrivateCourseActions;
