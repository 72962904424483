
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.639 9.23397C16.1711 9.23397 15.7991 8.86201 15.7991 8.39406V6.85821C15.7991 5.68233 15.2952 4.55445 14.4313 3.76253C13.5433 2.95861 12.4035 2.58665 11.2036 2.69464C9.18779 2.88662 7.42397 4.93841 7.42397 7.07419V8.15408C7.42397 8.62204 7.05201 8.994 6.58406 8.994C6.1161 8.994 5.74414 8.62204 5.74414 8.15408V7.07419C5.74414 4.0745 8.16789 1.30278 11.0356 1.02681C12.7034 0.870823 14.3113 1.39877 15.5471 2.52665C16.771 3.63054 17.4669 5.21438 17.4669 6.85821V8.39406C17.4669 8.86201 17.095 9.23397 16.639 9.23397Z" fill={pathFill}/>
<path d="M21.1621 9.3531C20.1542 8.23721 18.4984 7.69727 16.0747 7.69727H7.14757C4.72382 7.69727 3.06798 8.23721 2.06009 9.3531C0.896205 10.649 0.932202 12.3768 1.06419 13.5767L1.9041 20.26C2.15608 22.5998 3.10398 24.9995 8.26346 24.9995H14.9588C20.1183 24.9995 21.0662 22.5998 21.3181 20.272L22.158 13.5647C22.29 12.3768 22.314 10.649 21.1621 9.3531ZM7.31555 14.3806H7.30355C6.64362 14.3806 6.10368 13.8406 6.10368 13.1807C6.10368 12.5208 6.64362 11.9808 7.30355 11.9808C7.97549 11.9808 8.51543 12.5208 8.51543 13.1807C8.51543 13.8406 7.97549 14.3806 7.31555 14.3806ZM15.7147 14.3806H15.7027C15.0428 14.3806 14.5028 13.8406 14.5028 13.1807C14.5028 12.5208 15.0428 11.9808 15.7027 11.9808C16.3746 11.9808 16.9146 12.5208 16.9146 13.1807C16.9146 13.8406 16.3746 14.3806 15.7147 14.3806Z" fill={pathFill}/>
</svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;