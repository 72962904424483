export default {
  SET_SHOW_COMMENT_MODAL: 'SET_SHOW_COMMENT_MODAL',

  GET_COMMENTS: 'GET_COMMENTS',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FAIL: 'GET_COMMENTS_FAIL',
  GET_COMMENTS_EXCEPTION: 'GET_COMMENTS_EXCEPTION',

  ADD_COMMENT: 'ADD_COMMENT',
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
  ADD_COMMENT_FAIL: 'ADD_COMMENT_FAIL',
  ADD_COMMENT_EXCEPTION: 'ADD_COMMENT_EXCEPTION',

  DELETE_COMMENT: 'DELETE_COMMENT',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAIL: 'DELETE_COMMENT_FAIL',
  DELETE_COMMENT_EXCEPTION: 'DELETE_COMMENT_EXCEPTION',
 
};
