import React, { useEffect, useState } from "react";
import { Modal, Box, Grid, Select, MenuItem } from "@mui/material"
import { withTranslation } from "react-i18next";
import { ModalConatiner } from "../../../../views/globalstyles";
import CloseIcon from "../../../../assets/icons/DeleteIcon";
import { Formik, Field } from "formik";
import { FormInput } from "../../../../kit/Form";
import { StyledTextArea } from "../../../../kit/Form";
import FormController from "../../../../controllers/FormController";
import VirtualizedSelect from 'react-virtualized-select';
import { hoursListOptions, daysListOptions, genderListOptions } from "./helpers";
import { toast } from 'react-toastify';
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";
import { VerifyValidationMaster } from "../../../../validation/Schemas/VerifyValidationMaster";

const { REACT_APP_API_URL } = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const MasterDetailModal = ({
    open,
    onClose,
    onConfirm,
    title,
    fields,
    setFields,
    t,
    mode
}) => {
    const [file , setFile]= useState(null);
    const defaultSchedule ={
        sat: [],
        sun: [],
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: []
    }
    const [schedule, setSchedule] = useState(defaultSchedule);
    useEffect(() => {
        if(fields.image !== null){
            setFile(URL.createObjectURL(fields.image)); 
        }
    }, [fields]);
   
    useEffect(() => {
        if(mode === "edit" && fields.photo && fields.image === null){
            setFile(REACT_APP_API_URL + "/" +fields.photo);
        }
    }, [fields]);
    const addTime = (formik) => {
        let day = formik.values.day;
        let time = formik.values.times;
        time = time.split(",");
        // alert(time)
        setSchedule({
            ...schedule,
            [day]: time
        });
        toast.success("به لیست زمان بندی اضافه شد", {
            position: toast.POSITION.BOTTOM_RIGHT
        });

        formik.setFieldValue("times", "");
        formik.setFieldValue("day", "");

    }
    const assignTime = (selected ,formik) =>{
        const time = schedule[selected.value];
        if(time.length === 0){
            formik.setFieldValue("times", "");
        }
        else{
          formik.setFieldValue("times", time.toString());
        }
    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...fields,
            
            }}
            onSubmit={(values, actions) => {
                onConfirm(values, actions, schedule);
            }}
           validationSchema={VerifyValidationMaster}
        >
            {(formik) => (
                <Modal
                    open={open}
                    onClose={() => {setFile(null);onClose(formik);setSchedule(setSchedule)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <ModalConatiner lang={"fa"}>
                            <Grid container>
                                <Grid item container xs={12} id="modal_title">

                                    <Grid item xs={11} display="flex" justifyContent="start">
                                        {title}
                                    </Grid>
                                    <Grid item xs={1} display="flex" justifyContent="end"
                                        onClick={() => {
                                            setFile(null);
                                            onClose(formik);
                                            setSchedule(setSchedule)
                                        }} className="clickable">
                                        <CloseIcon />
                                    </Grid>
                                </Grid>

                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">نام فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_first_name && formik.touched.fa_first_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_first_name}
                                                    id="fa_first_name"
                                                    name="fa_first_name"  
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_first_name:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام فارسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">نام خانوادگی فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_last_name && formik.touched.fa_last_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_last_name}
                                                    id="fa_last_name"
                                                    name="fa_last_name"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_last_name:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام خانوادگی فارسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">نام انگلیسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_first_name && formik.touched.en_first_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_first_name}
                                                    id="en_first_name"
                                                    name="en_first_name"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,en_first_name:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام انگلیسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">نام خانوادگی انگلیسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_last_name && formik.touched.en_last_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_last_name}
                                                    id="en_last_name"
                                                    name="en_last_name"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,en_last_name:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام خانوادگی انگلیسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">ایمیل</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                                    }
                                                    value={formik.values.email}
                                                    id="email"
                                                    name="email"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,email:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"ایمیل"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">شماره موبایل</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.phone && formik.touched.phone ? " warning-border" : "")
                                                    }
                                                    value={formik.values.phone}
                                                    id="phone"
                                                    name="phone"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,phone:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"شماره موبایل"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box mb={1} className="lable-form">جنسیت</Box>
                                            <Box dispaly="flex">
                                                <VirtualizedSelect
                                                    value={formik.values.gender}
                                                    name="gender"
                                                    id="gender"
                                                    placeholder="انتخاب کنید"
                                                    onChange={(selected) => {
                                                        FormController.onSelect(selected, "gender", formik, null, true);
                                                        setFields({...fields,gender: selected.value})
                                                    }}
                                                    maxHeight={150}
                                                    options={genderListOptions}
                                                    multi={false}
                                                    className={
                                                        "custom_input" + (formik.errors.gender && formik.touched.gender ? " warning-bottom-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">ناریخ تولد</Box>
                                                <DatePicker
                                                    value={formik.values.birthday}
                                                    calendar={persian}
                                                    locale={persian_fa}
                                                    className={
                                                        "input_file" + (formik.errors.birthday && formik.touched.birthday ? " warning-border" : "")
                                                    }
                                                    name="birthday"
                                                    onChange={(e) => {FormController.onChangeDate(e, formik, "birthday", null); setFields({...fields,birthday:  e.year + "/" + e.month.number + "/" + e.day})}}
                                                />
                                                {formik.errors.birthday && formik.touched.birthday ? (
                                                    <div className="text-alert">{formik.errors.birthday}</div>
                                                ) : <div className="text-alert"></div>}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                            <Box mb={1} className="lable-form">بیوگرافی فارسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_biography && formik.touched.fa_biography ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_biography}
                                                    id="fa_biography"
                                                    name="fa_biography"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_biography:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"بیوگرافی فارسی"}
                                                />
                                                {formik.errors.fa_biography && formik.touched.fa_biography ? (
                                                    <div className="fa_biography-danger">{formik.errors.fa_biography}</div>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                            <Box mb={1} className="lable-form">بیگرافی انگلیسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_biography && formik.touched.en_biography ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_biography}
                                                    id="en_biography"
                                                    name="en_biography"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,en_biography:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"بیوگرافی انگلیسی"}
                                                />
                                                {formik.errors.en_biography && formik.touched.en_biography ? (
                                                    <div className="en_biography-danger">{formik.errors.en_biography}</div>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                        <Box mb={1} className="lable-form">تصویر</Box>
                                            <Box display="flex">
                                                <FormInput
                                                    placeholder="انتخاب فایل"
                                                    className={
                                                        "login-form-control" + (formik.errors.photo && formik.touched.photo ? " warning-border" : "")
                                                    }
                                                    type="file"
                                                    name='photo'
                                                    accept={".png, .jpg"}
                                                    onChange={(e) => {FormController.onChangeFile(e, formik, null);
                                                        setFields({...fields,image:e.target.files[0]})
                                                    }}
                                                />
                                                {/* <Field type="file" id="photo" name="photo" accept="image/*"  onChange={(e) => FormController.onChangeFile1(e, formik, null)}/> */}
                                                <img
                                                    style={{ width: "45px", paddingRight: "10px" }}
                                                    src={file}
                                                    alt="view" />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={9} p={1}>

                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                        <Box mb={1} className="lable-form">انتخاب روز</Box>
                                            <Box dispaly="flex" >
                                                <VirtualizedSelect
                                                    value={formik.values.day}
                                                    name="day"
                                                    id="day"
                                                    placeholder="انتخاب کنید"
                                                    onChange={(selected) => {

                                                        FormController.onSelect(selected, "day", formik, null, true);
                                                        assignTime(selected ,formik);
                                                    }}
                                                    maxHeight={150}
                                                    options={daysListOptions}
                                                    multi={false}
                                                    className={
                                                        "custom_input" + (formik.errors.day && formik.touched.day ? " warning-bottom-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={8} p={1}>
                                        <Box mb={1} className="lable-form">انتخاب ساعات</Box>
                                            <Box dispaly="flex" >
                                                <VirtualizedSelect
                                                    value={formik.values.times}
                                                    backspaceToRemoveMessage=""
                                                    name="times"
                                                    id="times"
                                                    placeholder="انتخاب کنید"
                                                    onChange={(selected) => {

                                                        FormController.handleChangeMultiSelect(selected, "times", formik, null, true);
                                                    }}
                                                    maxHeight={150}
                                                    options={hoursListOptions}
                                                    multi={true}
                                                    className={
                                                        "custom_input" + (formik.errors.times && formik.touched.times ? " warning-bottom-border" : "")
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={1} p={1}>
                                          <Box mb={1} className="lable-form" style={{height:"18px"}}></Box>
                                            <Box dispaly="flex" onClick={() => { addTime(formik) }} id="add-time-btn">
                                                +
                                            </Box>
                                        </Grid>
                                        <Grid items xs={12} p={1}>
                                            <Box dispaly="flex" >
                                                <button
                                                    color="primary"
                                                    size="lg"
                                                    onClick={formik.handleSubmit}
                                                    className="ml-1"
                                                >
                                                    <span className="color-white">{t("send")}</span>
                                                </button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>


                            </Grid>
                        </ModalConatiner>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default withTranslation()(MasterDetailModal);