import React, { useEffect } from "react";
import { Modal, Box, Grid } from "@mui/material"
import { withTranslation } from "react-i18next";
import { ModalConatiner } from "../../../../views/globalstyles";
import CloseIcon from "../../../../assets/icons/DeleteIcon";
import { Formik } from "formik";
import { FormInput } from "../../../../kit/Form";
import { StyledTextArea } from "../../../../kit/Form";
import FormController from "../../../../controllers/FormController";
import VirtualizedSelect from 'react-virtualized-select';
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};
const genderListOptions = [
    { value: "male", label: "مرد" },
    { value: "female", label: "زن" },
    { value: "other", label: "سایر" },
]

const MemberDetailModal = ({
    open,
    onClose,
    onConfirm,
    title,
    fields,
    setFields,
    t
}) => {
    useEffect(() => {

    }, [fields]);
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...fields,
            }}
            onSubmit={(values, actions) => {
                onConfirm(values, actions);
            }}
        // validationSchema={}
        >
            {(formik) => (
                <Modal
                    open={open}
                    onClose={() => onClose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <ModalConatiner lang={"fa"}>
                            <Grid container>
                                <Grid item container xs={12} id="modal_title">

                                    <Grid item xs={11} display="flex" justifyContent="start">
                                        {title}
                                    </Grid>
                                    <Grid item xs={1} display="flex" justifyContent="end"
                                        onClick={() => onClose(formik)} className="clickable">
                                        <CloseIcon />
                                    </Grid>
                                </Grid>

                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نام فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_first_name && formik.touched.fa_first_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_first_name}
                                                    id="fa_first_name"
                                                    name="fa_first_name"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام فارسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نم خانوادگی فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_last_name && formik.touched.fa_last_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_last_name}
                                                    id="fa_last_name"
                                                    name="fa_last_name"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام خانوادگی فارسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نام انگلیسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_first_name && formik.touched.en_first_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_first_name}
                                                    id="en_first_name"
                                                    name="en_first_name"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام انگلیسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نام خانوادگی انگلیسی</Box>

                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_last_name && formik.touched.en_last_name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_last_name}
                                                    id="en_last_name"
                                                    name="en_last_name"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام خانوادگی انگلیسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">ایمیل</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                                    }
                                                    value={formik.values.email}
                                                    id="email"
                                                    name="email"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"ایمیل"}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">جنسیت</Box>
                                                <VirtualizedSelect
                                                    value={formik.values.gender}
                                                    name="gender"
                                                    id="gender"
                                                    placeholder="انتخاب کنید"
                                                    onChange={(selected) => {

                                                        FormController.onSelect(selected, "gender", formik, null, true);
                                                    }}
                                                    maxHeight={150}
                                                    options={genderListOptions}
                                                    multi={false}
                                                    className={
                                                        "custom_input" + (formik.errors.gender && formik.touched.gender ? " warning-bottom-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">تاریخ تولد</Box>
                                                <DatePicker
                                                    value={formik.values.birthday}
                                                    calendar={persian}
                                                    locale={persian_fa}
                                                    className={
                                                        "input_file" + (formik.errors.birthday && formik.touched.birthday ? " warning-border" : "")
                                                    }
                                                    name="birthday"
                                                    id="birthday"
                                                    onChange={(e) => FormController.onChangeDate(e, formik, "birthday", null)}
                                                />
                                                {formik.errors.birthday && formik.touched.birthday ? (
                                                    <div className="text-alert">{formik.errors.birthday}</div>
                                                ) : <div className="text-alert"></div>}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">شماره موبایل</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.phone && formik.touched.phone ? " warning-border" : "")
                                                    }
                                                    value={formik.values.phone}
                                                    id="phone"
                                                    name="phone"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"شماره موبایل"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">بیوگرافی فارسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_biography && formik.touched.fa_biography ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_biography}
                                                    id="fa_biography"
                                                    name="fa_biography"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"بیوگرافی فارسی"}
                                                />
                                                {formik.errors.fa_biography && formik.touched.fa_biography ? (
                                                    <div className="fa_biography-danger">{formik.errors.fa_biography}</div>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">بیوگرافی انگلیسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_biography && formik.touched.en_biography ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_biography}
                                                    id="en_biography"
                                                    name="en_biography"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"بیوگرافی انگلیسی"}
                                                />
                                                {formik.errors.en_biography && formik.touched.en_biography ? (
                                                    <div className="en_biography-danger">{formik.errors.en_biography}</div>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">رمز عبور</Box>
                                                <FormInput
                                                    type="password"
                                                    className={
                                                        "login-form-control" + (formik.errors.ramz && formik.touched.ramz ? " warning-border" : "")
                                                    }
                                                    value={formik.values.ramz}
                                                    id="ramz"
                                                    name="ramz"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"رمز عبور"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={12} p={1}>
                                            <Box dispaly="flex" >
                                                <button
                                                    color="primary"
                                                    size="lg"
                                                    onClick={formik.handleSubmit}
                                                    className="ml-1"
                                                >
                                                    <span className="color-white">{t("send")}</span>
                                                </button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </ModalConatiner>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default withTranslation()(MemberDetailModal);