import styled from 'styled-components';
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";

export const CalendarContainer = styled(Row)`
    width:100%;
   >div , .MuiPaper-root{
    width:100%;
   }

`;