import React from "react";
import { Formik } from "formik";
import FormController from "../../controllers/FormController";
import { ForgetPasswordFormWrapper,
     TitleContainer, FormContainer,
      BtnContainer, TextContainer ,
      LogoContainer ,
      ForgetPasswordWrapper
    } from "./styles";
import { FormInput } from "../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "../../redux/Login/Actions";
import { useTranslation } from "react-i18next";
import { VerifyValidationForgetPassword } from "../../validation/Schemas/VerifyValidationForgetPassword";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

import { Grid } from "@mui/material"
const ForgetPass = ({ setMode }) => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    const onForgetPassword = (values, action) => {
        dispatch(LoginActions.forgetPassword({ values, action }))
    }
    return (
        <ForgetPasswordWrapper>
           <ForgetPasswordFormWrapper lang={lang}>
            <Link to='/'><LogoContainer src={Logo} /></Link>
            <TitleContainer>
                {t("forget password")}
            </TitleContainer>
            <TextContainer>
                {t("Please enter your email address")}
            </TextContainer>
            <FormContainer>
                <Formik
                    initialValues={{
                        email: "",
                    }}
                    onSubmit={(values, actions) => {
                        onForgetPassword(values, actions);
                    }}
                    validationSchema={VerifyValidationForgetPassword}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid items xs={12} py={1}>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                        }
                                        value={formik.values.email}
                                        id="email"
                                        name="email"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("email")}
                                    />
                                </Grid>
                            </Grid>
                            <BtnContainer>
                                <button
                                    color="primary"
                                    size="lg"
                                    onClick={formik.handleSubmit}
                                    type="submit"
                                >
                                    <span className="color-white"> {t("Password recovery")}</span>
                                </button>
                            </BtnContainer>
                        </form>
                    )}
                </Formik>
            </FormContainer>
        </ForgetPasswordFormWrapper>
        </ForgetPasswordWrapper>
    )
}
export default ForgetPass