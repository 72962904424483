import types from './Types';
import RecordController from "../../controllers/RecordController";

const initState = {
  showCourseDetailModal: false,
  privateCoursesList: [],
  coursesLoading: true,
  take: 12,
  skip: 0,
  hasMore: false,
  count: 0,
  mode: "add",
  myCoursesList: [],
  myCoursesListLoading: true,
  courseDetail: [],
  calendarListFa: [],
  calendarListEn:[],
  calendarListLoading: true,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    case types.SET_SHOW_COURSE_MODAL:
      return {
        ...state,
        showCourseDetailModal: action.data.visible,
        mode: action.data.mode,

      };

    case types.GET_PRIVATE_COURSES_LIST:
      return {
        ...state,
        coursesLoading: true,
      };
    case types.GET_MY_COURSES_LIST_SUCCESS:
      return {
        ...state,
        myCoursesList: action.data.data,
        myCoursesListLoading: false,
      };
    case types.GET_PRIVATE_COURSES_LIST_SUCCESS:
      let newCourseList = [...state.privateCoursesList];
      newCourseList = [...action.data.data];
      return {
        ...state,
        coursesLoading: false,
        privateCoursesList: newCourseList,
        skip: state.skip + state.take,
        count: action.data.count,
        hasMore: state.skip + state.take < state.count ? true : false,
      };

    case types.UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        privateCoursesList: RecordController.update(
          state.privateCoursesList,
          action.data
        ),
        showCourseDetailModal: false,
        mode: "add"
      };
    case types.ADD_COURSE_SUCCESS:
      return {
        ...state,
        privateCoursesList: RecordController.add(
          state.privateCoursesList,
          action.data
        ),
        showCourseDetailModal: false,
        mode: "add"
      };
    case types.DELETE_COURSE_SUCCESS:
      let result = { id: action.data };
      return {
        ...state,
        privateCoursesList: RecordController.delete(
          state.privateCoursesList,
          result
        )
      };
    case types.GET_COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        courseDetail: action.data,
      };
    case types.GET_CALENDAR_LIST_SUCCESS:
      let calendarList = action.data;
      let list_fa = [];
      let list_en = [];

      calendarList?.map((each, index) => {
        return (
          list_fa.push({
            title: each.event_name_fa,
            start: new Date(each.date_date),
            end: new Date(each.date_date),
            event_id: index,
           
          })
        )
      });
      calendarList?.map((each, index) => {
        return (
          list_en.push({
            title: each.event_name_en,
            start: new Date(each.date_date),
            end: new Date(each.date_date),
            event_id: index
          })
        )
      });
      return {
        ...state,
        calendarListFa: list_fa,
        calendarListEn: list_en,
        calendarListLoading: false,
      };
    default:
      return state;
  }
}
