import styled from 'styled-components';
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";


export const AnnouncemnetContainer = styled(Row)`
    width:100%;
    border-radius : 10px;
    box-sizing:border-box;
    padding: 10px;
    border :1px solid #E9E9E9;
    margin:5px ;
    .title_container{
        font-family: ${(props) => (props.theme.fontBold)};
        color: ${(props) => props.theme.secondaryFontColor};
    }
   
;`;
