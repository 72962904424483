import React from 'react';
import { useSelector } from 'react-redux';

function LoacationIcon() {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28 25.666C29.933 25.666 31.5 24.099 31.5 22.166C31.5 20.233 29.933 18.666 28 18.666C26.067 18.666 24.5 20.233 24.5 22.166C24.5 24.099 26.067 25.666 28 25.666Z" fill="white"/>
        <path d="M27.9997 4.66602C23.0811 4.66577 18.3613 6.60679 14.8659 10.0672C11.3705 13.5276 9.38219 18.2277 9.33301 23.146C9.33301 35.9327 25.783 50.166 26.483 50.7727C26.9057 51.1342 27.4435 51.3328 27.9997 51.3328C28.5558 51.3328 29.0937 51.1342 29.5163 50.7727C30.333 50.166 46.6663 35.9327 46.6663 23.146C46.6172 18.2277 44.6288 13.5276 41.1334 10.0672C37.6381 6.60679 32.9182 4.66577 27.9997 4.66602ZM27.9997 30.3327C26.3845 30.3327 24.8055 29.8537 23.4625 28.9564C22.1195 28.059 21.0728 26.7835 20.4547 25.2913C19.8365 23.799 19.6748 22.157 19.9899 20.5728C20.305 18.9886 21.0828 17.5334 22.225 16.3913C23.3671 15.2492 24.8223 14.4714 26.4064 14.1563C27.9906 13.8412 29.6327 14.0029 31.1249 14.621C32.6172 15.2391 33.8926 16.2859 34.79 17.6289C35.6874 18.9719 36.1663 20.5508 36.1663 22.166C36.1663 24.332 35.3059 26.4092 33.7744 27.9407C32.2428 29.4723 30.1656 30.3327 27.9997 30.3327Z" fill="white"/>
        </svg>
        
    );
}

export default LoacationIcon;
