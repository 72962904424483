import React from 'react';
import { InstructorCoursesWrapper } from "../styles";
import Content from '../../../shared/content';
import {Grid , Box} from "@mui/material";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const InstructorCourses = ({t}) => {
    const lang = useSelector((state) => state.App.lang);
    const stateCourse = useSelector((state) => state.Course);
    const {masterCourseList} = stateCourse;

    return (
            <InstructorCoursesWrapper  lang={lang}>
            <Box  id="title_container" display="flex" justifyContent="start">{t("Master's courses")}</Box>
            <Grid container>
                {masterCourseList.map((each,index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Content data={each} key={index}/>
                        </Grid>)
                })}
            </Grid>
            </InstructorCoursesWrapper>
    )
}
export default withTranslation()(InstructorCourses);