import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { ImageContainer } from "../../../globalStyle";
import CameraIcon from "../../../../../assets/icons/CameraIcon";
const { REACT_APP_API_URL } = process.env;

const FileInput = ({handleUpdateProfile ,userInfo}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(REACT_APP_API_URL + "/" + userInfo?.photo);

    useEffect(() => {
            setImageUrl(REACT_APP_API_URL + "/" + userInfo?.photo);
    }, [userInfo]);
    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);
    return (
        <Grid width="100%">
            <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: "none" }}
                onChange={(e) => {setSelectedImage(e.target.files[0]); handleUpdateProfile(e.target.files[0])}} />
            {(<Box mt={2} textAlign="center">
                <div></div>
                <ImageContainer src={imageUrl} height="100px">
                    <label htmlFor="select-image">
                        <Box
                            className="clickable"
                            variant="contained"
                            component="span">
                            <CameraIcon />
                        </Box>
                    </label>
                </ImageContainer>
            </Box>)}
        </Grid>);
}; export default FileInput;