import {
    all,
    takeEvery,
    put,
    fork,
  } from "redux-saga/effects";
  import types from "./Types";
  import Endpoints from "./Endpoints";
  import Api from "../../services/Api";
  
  
  export function* GetComments() {
    yield takeEvery(types.GET_COMMENTS, function* ({ data }) {
      try {
        const response = yield Api.Get(Endpoints.GetComments+`/${data.course_id}?&offset=${data.offset}&limit=${data.limit}`, false);
        if (response) {
          yield put({
            type: types.GET_COMMENTS_SUCCESS,
            data: {data:response.data , count :response.total },

          });
        } else
          yield put({
            type: types.GET_COMMENTS_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.GET_COMMENTS_FAIL,
        });
      }
    });
  }
  
  export function* AddComment() {
    yield takeEvery(types.ADD_COMMENT, function* ({ data }) {
      
      let body ={
          "title": data.title,
          "rate": data.rate,
          "course_id": data.course_id
      }
      try {
        const response = yield Api.Post(Endpoints.AddComment ,body,true);
        if (response) {
          yield put({
            type: types.ADD_COMMENT_SUCCESS,
            data:response

          });
        } else
          yield put({
            type: types.ADD_COMMENT_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.ADD_COMMENT_FAIL,
        });
      }
    });
  }

  export function* DeleteComment() {
    yield takeEvery(types.DELETE_COMMENT, function* ({ data }) {
      try {
        const response = yield Api.Delete(Endpoints.DeleteComment+`/${data.comment_id}`, true);
        if (response) {
          yield put({
            type: types.DELETE_COMMENT_SUCCESS,
            data: data.comment_id

          });
        } else
          yield put({
            type: types.DELETE_COMMENT_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.DELETE_COMMENT_FAIL,
        });
      }
    });
  }

  export default function* CommentsSagas() {
    yield all(
      [
        fork(GetComments),
        fork(AddComment),
        fork(DeleteComment)
      ]);
  }
  