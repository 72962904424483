import React, {useEffect, useState} from "react";
import { PaymentWrapper } from "../styles"
import { Button, Box, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { useSelector ,useDispatch } from "react-redux";
import { Formik } from "formik";
import FormController from "../../../controllers/FormController";
import { FormInput } from "../../../kit/Form";
import PaymentActions from "../../../redux/Payment/Actions";
import CheckoutForm from "./CheckoutForm";
import { toast } from 'react-toastify';
import i18n from "../../../helpers/i18n";

const Payment = ({ t, data }) => {
    const lang = useSelector((state) => state.App.lang);
    // const bookCourse = useSelector((state) => state.Payment.bookCourse);
    const discountInfo = useSelector((state) => state.Payment.discountInfo);
    const dispatch = useDispatch();
    // console.log(discountInfo)
    useEffect(()=>{

    },[])
    return (
        <PaymentWrapper>
            <Grid container>
                <Grid items py={1} xs={12} md={6} pr={lang === "fa" ? 1 : 0} pl={lang === "en" ? 1 : 0}>
                    <Box className="payment-box" style={{"height":"18rem"}} alignItems="start">
                        <Box className="title-container">{t("discount code")}</Box>
                        <Formik
                            initialValues={{discountCode: ""}}
                            onSubmit={(values, actions) => {
                                dispatch(PaymentActions.validateDiscountCode({course_id:data.id,code : values.discountCode}))
                            }}>
                            {(formik) => (
                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={11}>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.discountCode && formik.touched.discountCode ? " warning-border" : "")
                                                }
                                                value={formik.values.discountCode}
                                                id="discountCode"
                                                name="discountCode"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                placeholder={t("enter your discount code")}
                                            />
                                        </Grid>
                                        <Grid items xs={1} display="flex" justifyContent="end" >
                                            <button   style={{width:"100%",color:"#ffff", background:"#3d54a8",cursor:"pointer"}}
                                                    size="lg" onClick={formik.handleSubmit} className="ml-1">
                                                <span>+</span>
                                            </button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Grid>
                <Grid items py={1} xs={12} md={6} pl={lang === "fa" ? 1 : 0} pr={lang === "en" ? 1 : 0}>
                    <Box className="payment-box"  style={{"height":"25rem"}}  >
                        <Box style={{"fontSize":"1rem"}} >{t("payable amount")}</Box>
                        <Box className={discountInfo?.data?.status === "ok" ? "title-container-dashed" : "title-container"}
                             style={{"fontSize":"3rem"}} justifyContent="center">{data.price + " " + data.price_unit } </Box>
                        <Box style={{"fontSize":"1rem"}} >{t("payable amount after discount")}</Box>
                        <Box className="title-container" style={{"fontSize":"3rem"}} justifyContent="center">{
                            discountInfo?.data?.status === "ok" ? (discountInfo?.data?.new_price + " " + data.price_unit) : "-" } </Box>

                    </Box>
                </Grid>
                {/* <Grid xs={12} display="flex" justifyContent="flex-end" py={3}>
                  <CheckoutForm productId={data.id}/>
                </Grid> */}
            </Grid>
        </PaymentWrapper >
    )
}
export default withTranslation()(Payment);