import React, { useEffect } from "react";
import Filter from "./filter";
import { CourseContainer } from "../../styles";
import Content from '../../../../shared/content';
import { Grid, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CourseActions from '../../../../redux/Course/Actions';
import { withTranslation } from "react-i18next";

const Programming = ({  t,id, search, setSearch, sort, setSort, xxx }) => {
    const lang = useSelector((state) => state.App.lang);
    const CourseState = useSelector((state) => state.Course);
    const { hasMoreCourse, courseList, courseLoading, skip, take } = CourseState;
    const dispatch = useDispatch();
    console.log(xxx)
    useEffect(() => {
        // alert("programming")
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: id,
            offset: skip,
            limit: take,
            query: search,
            sort:"",
            oriented:""
        }))
    }, []);
    const showMore = () => {
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: id,
            offset: skip + take,
            limit: take,
            mode: "loadMore",
            query: search,
            sort:"",
            oriented:""
        }))
    }
    return (
        <CourseContainer lang={lang}>
            <Filter courseType="programming"
                            category_id={id}
                            search={search}
                            setSearch={setSearch}
                            sort={sort}
                            setSort={setSort}
                        />
            {courseLoading !== true ?
                courseList.length > 0 ?
                        <Grid container>
                            {courseList?.map((each, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Content data={each} key={index} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                     : <>{t("no data to dispaly")}</>
                : <div className="loader"></div>
            }
            {hasMoreCourse === true &&
                <Button id="more_button" onClick={() => showMore()}>{t("more")}</Button>
            }
        </CourseContainer >
    )
}
export default withTranslation()(Programming);