import { React } from "react";
import {
    BannerWrapper,
} from './styles';
import backgroundVector from "../../../../assets/images/banner1.png";
import {withTranslation} from "react-i18next";

const Banner = ({t}) => {

    return (

        <BannerWrapper cover={backgroundVector} onClick={(e)=>window.location.href="/search?q="} style={{"cursor":"pointer"}}>
            
        </BannerWrapper>
    )
}

export default withTranslation()(Banner);
