
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 12C14.8141 12 17.5 9.31359 17.5 6C17.5 2.68641 14.8141 0 11.5 0C8.18594 0 5.5 2.68641 5.5 6C5.5 9.31359 8.18594 12 11.5 12ZM13.8766 14.25H9.12344C4.63797 14.25 1 17.8875 1 22.3734C1 23.2706 1.7275 23.9986 2.62469 23.9986H20.3762C21.2734 24 22 23.2734 22 22.3734C22 17.8875 18.3625 14.25 13.8766 14.25Z" fill={pathFill}/>
</svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;