import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"

const  Law= ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {lawList} = stateCourse;
  return (
    lawList.length>0 &&
    <ContentWrapprr>
      <Title title={t("law")} hasMoreView={true} tabValue={lawList[0]?.category_id} data={lawList}/>
      <Carousel data={lawList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Law);
