import { ActionIconsContainer } from "../../../../shared/GeneralStyles";
import Row, {SmallRow} from "../../../../kit/Row";
import TableActions from "../../../../shared/TableActions";

const DiscountsTableConfig = (UpdateDiscount, DeleteDiscount ) => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <SmallRow >{props.index + 1}</SmallRow>;
        },
      },
    
      {
        headerTitle: "کد تخفیف",
        displayTitle: "code",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <SmallRow>{props.row.code !=="" ? props.row.code : "-"}</SmallRow>
          );
        },
      },
      {
        headerTitle: "تعداد استفاده",
        displayTitle: "max_usage",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <SmallRow>
              {props.row.max_usage !=="" ? props.row.max_usage :"-"}
            </SmallRow>
          );
        },
      },
      {
        headerTitle: "درصد تخفیف",
        displayTitle: "percent",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <SmallRow>
              {props.row.percent !=="" ? props.row.percent + " %" :"-"}
            </SmallRow>
          );
        },
      },
      {
        headerTitle: "مقدار تخفیف",
        displayTitle: "value",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <SmallRow>
              {props.row.value !=="" ? props.row.value + " $ CAD" :"-"}
            </SmallRow>
          );
        },
      },
      {
        headerTitle: "دسته بندی",
        displayTitle: "category",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <SmallRow>
              {props.row.category !== undefined && props.row.category !== null ?
                  props.row.category.fa_name :"-"}
            </SmallRow>
          );
        },
      },
      
      {
        headerTitle: "عملیات",
        displayTitle: "id",
        size: 1,
        responsiveShow: false,
        element: (props) => {
          return (
            <ActionIconsContainer>
              <TableActions
                type="edit"
                onClick={() => {
                  UpdateDiscount(props.row);
                }}
              />
              <TableActions
                type="delete"
                onClick={() => {
                  DeleteDiscount(props.row);
                }}
              />
            </ActionIconsContainer>
          );
        },
      },
    ],
  };
};
export default DiscountsTableConfig;
