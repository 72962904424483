
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0.457031C5.37188 0.457031 0.0421877 4.82203 0.0421877 10.207C0.0421877 12.533 1.04297 14.6592 2.71266 16.3336C2.12672 18.6947 0.16875 20.7994 0.145313 20.8228C0.0398439 20.9283 0.0105471 21.0916 0.0750002 21.2322C0.133547 21.3728 0.263485 21.4556 0.415828 21.4556C3.5213 21.4556 5.81114 19.9669 7.00645 19.0467C8.53598 19.6209 10.2418 19.9556 12.0408 19.9556C18.669 19.9556 23.9986 15.5902 23.9986 10.2478C23.9986 4.90547 18.6281 0.457031 12 0.457031ZM5.95781 11.707C5.12578 11.707 4.45781 11.0391 4.45781 10.2492C4.45781 9.45937 5.12578 8.74922 5.95781 8.74922C6.78984 8.74922 7.45781 9.41719 7.45781 10.2492C7.45781 11.0812 6.82969 11.707 5.95781 11.707ZM12 11.707C11.168 11.707 10.5422 11.0391 10.5422 10.2492C10.5422 9.45937 11.2102 8.74922 12 8.74922C12.7898 8.74922 13.4578 9.41719 13.4578 10.2492C13.4578 11.0812 12.8344 11.707 12 11.707ZM17.9578 11.707C17.1258 11.707 16.4578 11.0391 16.4578 10.2492C16.4578 9.45937 17.1258 8.74922 17.9578 8.74922C18.7898 8.74922 19.4578 9.41719 19.4578 10.2492C19.4578 11.0812 18.8297 11.707 17.9578 11.707Z" fill={pathFill}/>
      </svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;