import React ,{useEffect} from "react";
import Content from "../../../../shared/content";
import { CourseContainer } from "./styles";
import { Grid, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import CourseActions from '../../../../redux/Course/Actions';
import { withTranslation } from "react-i18next";

const FavoriteCourse = ({id,t}) => {
    const lang = useSelector((state) => state.App.lang);
    const CourseState = useSelector((state) => state.Course);
    const { hasMoreCourse, courseList, courseLoading, skip, take } = CourseState;
    const dispatch = useDispatch();

    useEffect(() => {
        
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: id,
            offset: skip,
            limit: take,
            query: "",
            sort:"",
            oriented:""
        }))
    }, []);
    const showMore = () => {
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: id,
            offset: skip + take,
            limit: take,
            mode: "loadMore",
            query: "",
            sort:"",
            oriented:""
        }))
    }
    return (
        <CourseContainer lang={lang}>
            {courseLoading !== true ?
                courseList.length > 0 ?
                        <Grid container>
                            {courseList?.map((each, index) => {
                                return (
                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                        <Content data={each} key={index} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                     : <>{t("no data to dispaly")}</>
                : <div className="loader"></div>
            }
            {hasMoreCourse === true &&
                <Button id="more_button" onClick={() => showMore()}>{t("more")}</Button>
            }
        </CourseContainer >
    )
}

export default withTranslation()(FavoriteCourse)