import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const ToggleWrapprr = styled(Row)`
    width:100%;
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
    #toggle-container{
        box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
        width:100%;  
        border-radius:10px;
        padding:10px 0;
        margin :15px 0;
    }
    .title-container {
      color :  ${(props) => props.theme.secondaryFontColor};
      font-family: ${(props) => (props.theme.fontBold)};
    }
    svg path {
      color :  ${(props) => props.theme.secondaryFontColor};

    }
`;