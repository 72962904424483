import React from 'react';
import { useSelector } from 'react-redux';

function LinkedinIcon() {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="none"
    viewBox="0 0 60 60"
  >
    <path
      fill="#3D54A8"
      d="M55.238 0H4.762C2.132 0 0 2.239 0 5v50c0 2.761 2.132 5 4.762 5h50.476C57.868 60 60 57.761 60 55V5c0-2.761-2.132-5-4.762-5z"
    ></path>
    <path
      fill="#FCFCFC"
      d="M30 14a16 16 0 100 32 16 16 0 000-32zm-4.65 24.188h-3.896V26.464h3.897v11.724zm-1.948-13.325h-.025a2.032 2.032 0 11.051-4.05 2.032 2.032 0 11-.026 4.05zm16 13.325h-3.897v-6.272c0-1.576-.565-2.651-1.974-2.651a2.133 2.133 0 00-1.995 1.424c-.1.307-.144.63-.129.952v6.547h-3.9s.052-10.624 0-11.724h3.897v1.66a3.869 3.869 0 013.512-1.935c2.563 0 4.486 1.675 4.486 5.276v6.723z"
    ></path>
  </svg>
  );
}

export default LinkedinIcon;
