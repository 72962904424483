
import { Grid, Box } from "@mui/material";
import React from "react";
import {
    ContentWrapprr,
    ContentContainer,
    ImageBanner,
    TitleContainer,
    SectionContainer,
} from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { withTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
const { REACT_APP_API_URL } = process.env;

const Content = ({ data, t }) => {
    const lang = useSelector((state) => state.App.lang);
    const isLoggedin = useSelector((state) => state.Login.isLoggedin);


    return (
        <ContentWrapprr lang={lang}>
            <ContentContainer style={{"cursor":"pointer"}} onClick={(e)=>window.location.href =  '/instructor?id=' + data?.id}>
                <ImageBanner src={REACT_APP_API_URL + "/" + data?.photo} />
                <TitleContainer >
                    {lang === "fa" ? data?.fa_first_name+" "+data?.fa_last_name : data?.en_first_name+" "+data?.en_last_name}
                </TitleContainer>
                <TitleContainer >
                    {data?.active_course_count  }&nbsp;{t("active_course")}
                </TitleContainer>
                    {/*{lang === "fa" ? data?.fa_first_name+" "+data?.fa_last_name : data?.en_first_name+" "+data?.en_last_name}*/}
                {/*<TitleContainer >*/}
                {/*    {lang === "fa" ? data?.fa_biography : data?.en_biography}*/}
                {/*</TitleContainer>*/}
                {/*<TitleContainer >*/}
                {/*    {lang === "fa" ? data?.fa_category : data?.en_category}*/}
                {/*</TitleContainer>*/}
                <SectionContainer>
                    <Grid container>
                        <Grid item xs={12}><Rating name="half-rating" defaultValue={data.rate} readOnly /></Grid>
                    </Grid>
                </SectionContainer>

            </ContentContainer>
        </ContentWrapprr>
    )
}
export default withTranslation()(Content);