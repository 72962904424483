import types from './Types';
import { theme } from '../../utils/theme';
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";

const initState = {
  lang: Storage.get(Keys.i18nextLng) === "en" ? "en" : "fa",
  langtext: Storage.get(Keys.i18nextLng) === "en" ? "English" : "فارسی",
  theme,
  sideBarIsShow: false,
  moreIconIsShow: false,
  showResponsiveMenu: false,
  userMenu: false,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    case types.DARK_THEME:
      return {
        ...state,
        theme: {},
      };
    case types.LIGHT_THEME:
      return {
        ...state,
        theme: {},
      };
    case types.SIDEBAR_ISSHOW:
      return {
        ...state,
        sideBarIsShow: !state.sideBarIsShow,
        showResponsiveMenu:
          state.sideBarIsShow === false
            ? (state.showResponsiveMenu = false)
            : state.showResponsiveMenu,
      };
    case types.MORE_iCON_IS_SHOW:
      return {
        ...state,
        moreIconIsShow: !state.moreIconIsShow,
      };
    case types.RESPONSIVE_SETTING_MENU_SHOW:
      return {
        ...state,
        showResponsiveMenu: !state.showResponsiveMenu,
      };

    case types.USER_MENU:
      return {
        ...state,
        userMenu: action.data,
      };
    case types.CHANGE_LANGUAGE:
      Storage.set(Keys.language, action.data.lang);
      return {
        ...state,
        lang: action.data.lang,
        langtext : action.data.text
      };


    default:
      return state;
  }
}
