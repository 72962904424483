import styled from "styled-components";

const Row = styled.div`
    display  : flex ;
    direction : rtl;
    font-size: 14px;
    justify-content : center;
    align-items : center;
`;

export const SmallRow = styled.div`
    display  : flex ;
    direction : rtl;
    font-size: 14px;
    justify-content : center;
    align-items : center;
  height: 11px;
`;

export default Row;
