import styled from 'styled-components';
import Col from "../../kit/Column"
import Row from "../../kit/Row"


export const HeaderWrapprr = styled(Col)`
*{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

    width:90%;
    //margin-top: 5px;
  
    position : absolute;
    top:5px;
    header{
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "transparent"} !important;
    box-shadow :none;
    }
    #change-lang{
       cursor :pointer;
       display: flex;
    }
.MuiDrawer-paper span {
    font-family: ${(props) => (props.theme.fontDefault)} !important;
    color: ${(props) => props.theme.secondaryFontColor}  !important;

}
 .MuiListItem-root span{
   color: ${(props) => props.theme.secondaryFontColor};
  background: #4cd964;
 }
 .MuiButton-text a , .MuiButton-text{
    color: ${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeMd};
    font-weight : bold;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
 #loginRegister-btn{
   position: relative;
    margin-top :10px;
    background-color: ${(props) => props.theme.secondaryBtnColor};
    border-radius: ${(props) => props.theme.borderRadius};
    border: none;
    width: 131px;
    padding: 10px;
    color : ${(props) => props.theme.white};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontBold)};

   box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
    a{
    color : ${(props) => props.theme.white};
    }
   @media screen and (max-width: 768px) {
        display: none;
      
     }

 }
 .menu-btn{
    padding: 5px 20px;
    svg{
      width: 15px;
      height: 15px;
      transform: rotate(90deg);
      padding: 0 4px;
    }
 }
 #desktop-menu{
   display : flex;
}
#mobile-menu{
  display : none;
}
.min-ali {
  min-width: fit-content !important;
  width: auto !important;
  background: #3b3b3b!important;
}
.text-container{
   color : ${(props) => props.color ? props.color : props.theme.white};
   font-family: ${(props) => (props.theme.fontDefault)};
   padding-right :${(props) => props.lang === "fa" ? "10px" : "0"};
   padding-left :${(props) => props.lang === "en" ? "10px" : "0"};

}


 @media screen and (max-width:900px) {
   #desktop-menu{
      display : none;
   }
   #mobile-menu{
     display : block;
     width: 400px;
  }  
  .svg-container{
   svg g path{fill :${(props) => props.theme.secondaryFontColor};}
  }
  .text-container{
   color :${(props) => props.theme.secondaryFontColor};}

}
}


`;
export const LogoContainer = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: contain;
    width: 77px;
    height: 60px;
    background-position: center;
`;
export const ActionContainer = styled(Row)`
   width: 100%;
   display: flex;
   justify-content: center;
  svg{
    position: absolute;
    left:10px
  }
  
`;
