import React from "react";

function Icon({pathFill}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="28" height="25" >
      <path fill={pathFill}  d="M14 2c-3.3 0-6 2.7-6 6v4c-1.7 0-3 1.3-3 3v6c0 2.4.9 4.7 2.6 6.4 1.7 1.7 4 2.6 6.4 2.6 4.3 0 7.9-3 8.8-7h.2c1.1 0 2.1-.4 2.8-1.2C26.6 21 27 20 27 19s-.4-2.1-1.2-2.8S24.1 15 23 15c0-1.7-1.3-3-3-3V8c0-3.3-2.7-6-6-6zm10.4 15.6c.4.4.6.9.6 1.4s-.2 1-.6 1.4c-.5.5-1.3.7-2.1.5-.8-.3-1.3-1-1.3-1.9 0-.5.2-1 .6-1.4.2-.2.5-.4.8-.5.2-.1.4-.1.6-.1.5 0 1 .2 1.4.6zM21 15v.6c-.3.2-.6.4-.8.6-.5.5-.9 1.1-1 1.8H10c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1v-1h2v1c0 .6.4 1 1 1s1-.4 1-1v-1h4.1c.3 1 .9 1.8 1.7 2.4-.6 3.2-3.4 5.6-6.8 5.6-1.9 0-3.6-.7-5-2-1.3-1.3-2-3.1-2-5v-6c0-.6.4-1 1-1h12c.6 0 1 .4 1 1zm-11-3V8c0-2.2 1.8-4 4-4s4 1.8 4 4v4h-8z"></path>
    </svg>
  );
}

Icon.defaultProps = {
    pathFill: "#476582",
  };
 export default Icon;