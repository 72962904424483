import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const CourseWrapprr = styled(Col)`
    width:100%;
    margin-top:75px;
    background-color : #f7f7f7;
    border-top :1px solid #ccc;
    box-shadow:0px 1px 3px 1px #b9b9b9;
`;
export const ContentWrapprr = styled(Col)`
    background-color:  ${props => props.backgroundColor ? props.backgroundColor : props.theme.baseBackGroundColor};
    width:90%;
    padding:30px 0;
        @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const TitleContainer = styled(Row)`
    width:90%;
    justify-content:start;
    margin: 20px 0;
    font-size: ${(props) => props.theme.fontSizeLg};
    font-family: ${(props) => (props.theme.fontBold)};
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
////////////////////////////////////////////////
export const CourseDescriptionWrapper = styled(Row)`
    width:90%;
    background-color: #ebe9f4;
    border-radius : 10px;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    margin:60px 0;
    box-shadow: 0px 1px 3px 0px #b9b9b9;
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }

`;
export const CourseDescriptionContainer = styled(Row)`
    width:96%;
    padding:16px 0;
    #title-container{
        font-weight : bold;
        color: ${(props) => props.theme.secondaryFontColor};
        font-size: ${(props) => props.theme.fontSizeXl};

    }
    #text-container{
        padding-right :  ${(props) => props.lang === "fa"  ? "20px" :"0"};
        padding-left :  ${(props) => props.lang === "en"  ? "20px" : "0"};

    }
    #rating_container{
        background-color: ${(props) => props.theme.secondaryFontColor};
        height: 19px;
        border-radius: 15px;
        padding: 8px;
    }
    .MuiRating-icon {
        color :#ffc107;
    }
    @media screen and (max-width: 600px) {
        #text-container{
            padding :10px;
        }
     }
`;
export const CourseTextContainer = styled(Row)`
    text-align: justify;
    width: 100%;
    justify-content: end;
    font-size: ${(props) => props.theme.fontSizeMd};
    font-family: ${(props) => (props.theme.fontDefault)};
`;
export const CourseImageContainer = styled(Row)`
    width:100%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    height :250px;
    border-radius : 10px;
    @media screen and (max-width: 600px) {
       margin-bottom :20px; 
    }

`;
//////////////////////////////////////////about

export const InstructorAboutWrapprr = styled(Row)`
    margin :20px 0px;
    width: 90%;
    .about-container{  
     background-color: ${(props) => props.theme.white};
    padding: 20px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow:0px 0px 3px 0px #b9b9b9;

    } 
    #instructor-container{
        font-family: ${(props) => (props.theme.fontBold)};
    }
    .title-container{
        font-weight : bold;
        padding-bottom :15px;
        color: ${(props) => props.theme.secondaryFontColor};
        font-size: ${(props) => props.theme.fontSizeLg};

    }
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const CourseDetailContainer = styled(Row)`
    margin-bottom :20px;
    box-shadow:0px 0px 3px 0px #b9b9b9;
    width: 90%;
    height :250px;
    padding:0 2%;
    border-radius : 10px;
    background-color: ${(props) => (props.theme.white)};
    div{font-family: ${(props) => (props.theme.fontBold)};} 
    button{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: none;
        width: 100%;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
     }
     .btn:hover {
        color : ${(props) => props.theme.btnColor};
        background-color: ${(props) => props.theme.white};
        border : 1px solid ${(props) => props.theme.btnColor};;
      }
    #price-container{
        border-top :1px solid #ccc;
      }
      @media screen and (max-width: 600px) {
        width: 96%;  
    }
    a{width : 100%}
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const ImageProfileConianer = styled(Row)`
  width :70px;
  background-image : url(${props => props.src});
  background-repeat:no-repeat;
  background-position: top;
  background-size: contain;
  height:70px;
  border-radius :50%;
  border:1px solid #ccc;
`;

export const CommentWrapper = styled(Col)`
  width: 100%;
  #commnet-container{
    width: 90%;  
  }
  button{
    margin-top :10px;
    background-color: ${(props) => props.theme.btnColor};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.btnColor};
    width: 200px;
    padding: 10px;
    color : ${(props) => props.theme.white};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
 .btn:hover {
    color : ${(props) => props.theme.btnColor};
    background-color: ${(props) => props.theme.white};
    border : 1px solid ${(props) => props.theme.btnColor};;
  }
  #add-comment {
    background-color:  ${(props) => props.theme.btnColor};
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    color : ${(props) => props.theme.white};

  }
  @media screen and (min-width: 1700px) and (max-width :2000px) {
    width : 70%;
   }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;


