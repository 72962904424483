import React, {  useEffect } from 'react';
import CoursesView from "../../views/courses";


const Courses = () => {
 
    useEffect(() => {
       
    }, [])
   
    return (  <CoursesView />  )
}
export default Courses;