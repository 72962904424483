import React from "react";
import { Formik } from "formik";
import FormController from "../../../../../controllers/FormController";
import { FormWrapper, FormContainer, BtnContainer, ImageContainer } from "../styles";
import { FormInput } from "../../../../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import ProfileActions from "../../../../../redux/Profile/Actions";
import { VerifyValidationChangePassword } from "../../../../../validation/Schemas/VerifyValidationChangePassword";
import profile from "../../../../../assets/images/profile.png";
import { TitleContainer } from "../../../globalStyle";
import { Grid, Box, Button ,TextField ,MenuItem , Select} from "@mui/material";
import UplaodImage from "./uplaodImage";
import { withTranslation } from "react-i18next";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

const PersonalInfo = ({t ,userInfo ,timeZoneList}) => {
    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    const handleUpdateUser = (values, action) => {
        
         dispatch(ProfileActions.updateUserInfo({ values, action ,lang }))
    }
      const handleUpdateProfile = (file) => {
         dispatch(ProfileActions.updateUserProfile({ file }))
    }
    return (
        <Grid container>
            <Grid items xs={12}  md={6}>
                <FormWrapper lang={lang}>
                    <TitleContainer>
                       {t("User account information" )}
                    </TitleContainer>
                    <UplaodImage handleUpdateProfile={handleUpdateProfile} userInfo={userInfo}/>
                    <FormContainer>
                        <Formik
                            initialValues={{
                                firstName: lang === "fa" ? userInfo.fa_first_name : userInfo.en_first_name ,
                                lastName: lang === "fa" ? userInfo.fa_last_name : userInfo.en_last_name  ,
                                gender: userInfo.gender,
                               
                                birthday: userInfo.birthday,
                                phone: userInfo.phone,
                                email: userInfo.email,
                                zone: userInfo.time_zone
                            }}
                            onSubmit={(values, actions) => {
                                
                                handleUpdateUser(values, actions);
                            }}
                            enableReinitialize={true}
                           
                        >
                            {(formik) => (
                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("firstName")}</Box>
                                            {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.firstName && formik.touched.firstName ? " warning-border" : "")
                                                }
                                                value={formik.values.firstName}
                                                id="firstName"
                                                name="firstName"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                            />
                                        </Grid>
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("lastName")}</Box>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.lastName && formik.touched.lastName ? " warning-border" : "")
                                                }
                                                value={formik.values.lastName}
                                                id="lastName"
                                                name="lastName"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                            />

                                        </Grid>
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("email")}</Box>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                                }
                                                value={formik.values.email}
                                                id="email"
                                                name="email"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                            />
                                        </Grid>
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("gender")}</Box>
                                            <Select
                                                    value={formik.values.gender}
                                                    id="gender"
                                                    name="gender"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                >
                                                    <MenuItem value={"male"}>مرد</MenuItem>
                                                    <MenuItem value={"female"}>زن</MenuItem>
                                                    <MenuItem value={"other"}>سایر</MenuItem>
                                            </Select>
                                        </Grid>
                                      
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("birthday date")}</Box>
                                            <DatePicker
                                                value={formik.values.birthday}
                                                calendar={lang === "fa" && persian}
                                                locale={lang === "fa" && persian_fa}
                                                className={
                                                    "input_file" + (formik.errors.birthday && formik.touched.birthday ? " warning-border" : "")
                                                }
                                                name="birthday"
                                                onChange={(e) => FormController.onChangeDate(e, formik,"birthday", null)}
                                            />
                                                  {formik.errors.birthday && formik.touched.birthday ? (
                                                <div className="text-alert">{formik.errors.birthday}</div>
                                            ) : <div className="text-alert"></div>}
                                          
                                        </Grid>
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("phone number")}</Box>
                                            <FormInput
                                                type="text"
                                                className={
                                                    "login-form-control" + (formik.errors.phone && formik.touched.phone ? " warning-border" : "")
                                                }
                                                value={formik.values.phone}
                                                id="phone"
                                                name="phone"
                                                onChange={(e) => FormController.onChange(e, formik)}
                                                onBlur={(e) => FormController.onBlur(e, formik)}
                                                // placeholder={"شماره موبایل"}
                                            />
                                        </Grid>
                                     
                                        <Grid items xs={6} p={1} >
                                            <Box mb={1}>{t("location")}</Box>
                                            <Select
                                                     id="zone"
                                                     name="zone"
                                                     onChange={(e) => FormController.onChange(e, formik)}
                                                     value={formik.values.zone}
                                                >
                                                    {timeZoneList.map((each,index)=> {
                                                        return(
                                                            <MenuItem value={each.value} key={index}>{each.value}</MenuItem>  
                                                        )
                                                    })}
                                                    
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <BtnContainer>
                                        <Button
                                            onClick={formik.handleSubmit}
                                            type="submit"
                                            className="btn"
                                        >
                                            <span className="color-white">{t("send")}</span>
                                        </Button>
                                    </BtnContainer>
                                </form>
                            )}
                        </Formik>
                    </FormContainer>
                </FormWrapper>
            </Grid>
            <Grid items md={6} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                     {/* <TitleContainer>
                        اطلاعات حساب کاربری
                    </TitleContainer> */}
                <ImageContainer src={profile} height="110px"/>
            </Grid>
        </Grid>

    )
}
export default withTranslation()(PersonalInfo)