class FormController {
    onChange = (e, formik, callBack) => {
      let value = e.target.value;
      let name = e.target.name;
      formik.setFieldValue(e.target.name, e.target.value);
      callBack && callBack(e, name, value);
    };
  
    onBlur = (e, formik) => {
      let name = e.target.name;
      formik.setFieldTouched(name);
    }
    onPress = (name, formik) => {
      formik.setFieldTouched(name);
    }
    // onSelect = (selected, name, formik, callBack) => {
    //   let value = selected;
    //   formik.setFieldValue(name.name, value);
    //   callBack && callBack(selected, name.name);
    // };

    onSelect = (selected, name, formik, callBack) => {

      let value = selected.value
      formik.setFieldValue(name, value);
      callBack && callBack(selected, name);
    };
    handleChangeMultiSelect = (selected, name, formik, callBack, multi = false) => {
      let value = null;
      if (multi === true) {
        value = selected.map(each => each.value).join()
      }
      formik.setFieldValue(name, value);
      callBack && callBack(selected, name);
  
    };
  onChangeFile = (e, formik, callBack) => {
    let value = e.target.files
    let name = e.target.name;
    formik.setFieldValue(e.target.name, e.target.files[0]);
    callBack && callBack(e, name, value);
  };
 
  onChangeDate = (e, formik, name, callBack) => {
      
    let value = e.year + "/" + e.month.number + "/" + e.day;
    formik.setFieldValue(name, value);
    callBack && callBack(e, name, value);
  };
  
  }
    export default new FormController();
    