import React from 'react';
import CloseIcon from "../../../../assets/icons/DeleteIcon"
import AvatarIcon from "../../../../assets/icons/AccountIcon";
import {
    ToggleWrapper,
    ImageContainer,
    InfoConatiner,
    InfoSection
} from "../../globalStyle";
import { Box, Grid } from "@mui/material";
const { REACT_APP_API_URL } = process.env;

const CourseDetailToggle = (props) => {
    const {isOpen, onToggle, selected} = props;

    return (
        <ToggleWrapper>
            <div className={`sidebar-menu${isOpen === true ? ' open' : ''}`}>
                <Box onClick={onToggle} px={2}><CloseIcon /></Box>
                <Grid display="flex" justifyContent="center" my={2} width="100%">
                    <ImageContainer src={REACT_APP_API_URL + "/" + selected?.image} height="100px" />
                </Grid>
                <InfoConatiner>
                    <InfoSection>
                        <Box className='title'>نام  :</Box>
                        <Box>{selected?.fa_title}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'> نام به انگلیسی :</Box>
                        <Box>{selected?.en_title}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  تاریخ شروع :</Box>
                        <Box>{selected?.start_date_fa !== "" ? selected?.start_date_fa : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  تاریخ پایان :</Box>
                        <Box>{selected?.end_date_fa !== "" ? selected?.end_date_fa : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'> ظرفیت دانشجو :</Box>
                        <Box>{selected?.student_capacity !== "" ? selected?.student_capacity : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  ظرفیت باقیمانده :</Box>
                        <Box>{selected?.remaining_capacity !== "" ? selected?.remaining_capacity : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  استاد :</Box>
                        <Box>{selected?.master?.fa_last_name !== "" ? selected?.master?.fa_last_name : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  امتیاز :</Box>
                        <Box>{selected?.rate !== "" ? selected?.rate : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>   وضعیت :</Box>
                        <Box>{selected?.status !== "" ? selected?.status : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  لینک جلسه :</Box>
                        <Box>{selected?.meeting_link !== "" ? selected?.meeting_link : "-"}</Box>
                    </InfoSection>
                    
                </InfoConatiner>
            </div>
        </ToggleWrapper>
    )
}

export default CourseDetailToggle;