import styled from 'styled-components';
import  Col from "../../../kit/Column";

export const LoginRegisterIntroWrapper = styled(Col)`
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.baseBackGroundColor};
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const LoginRegisterIntroImage = styled(Col)`
  height: 100%;
  width: 100%;
  
  background-image: url(${(props) => props.source});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  
`;

