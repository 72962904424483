import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
:root {
  overflow-x:hidden;
  font-size : ${(props) => (props.theme.fontSizeSm)};
  font-family: ${(props) => (props.theme.fontDefault)} !important;
};
*{
  font-family: ${(props) => (props.theme.fontDefault)};
  }
body {
  padding:0 !important;
  overflow-x : hidden;
  margin :0;
  .clickable{
    cursor :pointer;
  }
  .justify-content-first{
    justify-content:flex-start;
  }
  .justify-content-end{
    justify-content:flex-end;
  }
  .MuiMenu-list li a , .MuiMenu-list li {
    color: ${(props) => props.theme.secondaryFontColor} !important;
    font-size: ${(props) => props.theme.fontSizeMd};
    font-family: ${(props) => (props.theme.fontBold)} !important;
 }
 .MuiMenu-list li{
  justify-content: center !important;
  width: fit-content;
 }
 .MuiMenu-paper{
  top: 56px;
    left: 909px;
 }
 .MuiFormLabel-root , .MuiTypography-root {
  font-family: ${(props) => (props.theme.fontDefault)};
 }
 .MuiButtonBase-root{
  font-family: ${(props) => (props.theme.fontBold)} !important;
  font-size: ${(props) => props.theme.fontSizeMd};
}
}
#add-time-btn{
  background: ${(props) => props.theme.secondaryFontColor};
  width: 100%;
  color: ${(props) => props.theme.white};
  border-radius: 5px;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
#arrow-down svg{

  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  padding: 0 4px;

}
#arrow-up svg{
  width: 15px;
  height: 15px;
  transform: rotate(270deg);
  padding: 0 4px;
}
.MuiStepLabel-label , .MuiListItemText-root span{
  color: ${(props) => props.theme.secondaryFontColor} !important;
  font-size: ${(props) => props.theme.fontSizeMd};
  font-family: ${(props) => (props.theme.fontBold)} !important;
}
.MuiPaper-root {
  text-align-last: right;
  width:110px;
}
.warning-border{
  border:1px solid red;
}
.Schedule-wrapper{
  width :100%
}
.MuiModal-root .rmdp-input{
  height:44px;
}
.lable-form{
  color: ${(props) => props.theme.secondaryFontColor} !important;
  font-size: ${(props) => props.theme.fontSizeSm};
  font-weight : bold;
}
#generate-discount{
  background-color: ${(props) => props.theme.secondaryFontColor};
  color : ${(props) => props.theme.white};
}
.MuiPopover-root .MuiMenu-list , .MuiPopover-root .MuiMenu-list li{
  width:100% !important;
}
.MuiPaper-root{
  min-width:140px !important;
}
/////////////////////////////date picker
.rmdp-arrow-container{
  width :unset !important;
  padding :0 3px!important;
  color: white;
}
.rmdp-container {
  width:100%;
}
.rmdp-input{
  padding:0 10px;
  box-sizing :border-box;
  width:100%;
}
////////////////////////////////////////scroll bar
/* width */
::-webkit-scrollbar {
  width: 7px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 15px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${(props) => (props.theme.lightGary)};
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${(props) => (props.theme.lightGary)};
}
///////////////////////////////////////////loader

.loader {
  color: ${(props) => props.theme.secondaryFontColor};
  font-size: 35px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



`;