import React from "react";
import { withTranslation } from "react-i18next";
import CommentSection from "../../../shared/comment";
import { CommentWrapper, TitleContainer } from "../styles";
import { useSelector, useDispatch } from "react-redux";
import { Button ,Grid ,Box } from "@mui/material";
import CommentsActions from "../../../redux/Comment/Actions"
import CommentModal from "../../../shared/commentModal/index.js";

const Comment = ({ t, data }) => {
  const lang = useSelector((state) => state.App.lang);
  const commentState = useSelector((state) => state.Comment);
  const { commentsList, hasMore, skip, take, showCommentModal, commentsLoading } = commentState;
  const dispatch = useDispatch();

  const laodMore = () => {
    dispatch(CommentsActions.getComments({ course_id: data.id, offset: skip, limit: take }))
  }
  const setShowCommentModalHandler = (visible) => {
    dispatch(CommentsActions.setShowCommentModal({ visible }));
  }
  const registerComment = (item) => {
    dispatch(CommentsActions.addComment({ title: item.title, rate: item.rate, course_id: data.id }));
    dispatch(CommentsActions.setShowCommentModal({ visible: false }));

  }
  return (
    <CommentWrapper>
      <Grid container id="commnet-container">
        <Grid item xs={6}>
          <TitleContainer lang={lang}>
            {t("comments")}
          </TitleContainer>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="end" alignItems="center">
          <Box id="add-comment" onClick={() => { setShowCommentModalHandler(true) }}>{t("register a comment")}</Box>
        </Grid>
      </Grid>

      {

        commentsLoading !== true ? commentsList.length > 0 ?
          commentsList.map((item, index) => {
            return (<CommentSection key={index} data={item} />)
          }
          )

          : <>{t("no data to dispaly")}</>
          : <div className="loader"></div>}
      {hasMore === true &&
        <Button className="btn" onClick={() => laodMore()}>{t("more")}</Button>
      }
      <CommentModal
        open={showCommentModal}
        handleClose={setShowCommentModalHandler}
        onConfirm={registerComment} />
    </CommentWrapper>
  );
};
export default withTranslation()(Comment);
