import types from './Types';
import DelegateAction from '../ActionDelegator';

const LoginActions = {
  login: DelegateAction(types.LOGIN),
  logOut: DelegateAction(types.LOGOUT),
  register: DelegateAction(types.REGISTER),
  forgetPassword: DelegateAction(types.FORGET_PASSWORD),
  resetPassword: DelegateAction(types.RESET_PASSWORD),
  activateAnAccount: DelegateAction(types.ACTIVATE_AN_ACCOUNT),

};

export default LoginActions;
