import React, { useEffect } from "react";
import { ContentWrapper, BtnContainer } from "./styles";
import { withTranslation } from "react-i18next";
import { Box } from "@mui/material"
import { useLocation, Link } from "react-router-dom";
import LoginActions from "../../../redux/Login/Actions";
import { useDispatch, useSelector } from "react-redux";

const LoginRegisterContent = ({ t }) => {
    let location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email').replace(" ", "+");
    const activationCode = queryParams.get('activationCode');

    const LoginState = useSelector((state) => state.Login);
    const { activateLoading, activateStatus } = LoginState;

    useEffect(() => {
         dispatch(LoginActions.activateAnAccount({ email: email, activation: activationCode }))
    })
    return (
        <ContentWrapper >
            {activateLoading !== true ?
                activateStatus === true ?
                    <>
                        <Box>
                            {t("your account has been activated")}
                        </Box>
                        <Box>
                            <Link to="/Login">
                                <BtnContainer>
                                    <button
                                        color="primary"
                                        size="lg"
                                        type="submit"
                                    >
                                        <span className="color-white"> {t("login")}</span>
                                    </button>
                                </BtnContainer>
                            </Link>
                        </Box>
                    </> :
                    <>
                        {t("your account has not been activated")}
                    </> : <div className="loader"></div>
            }

        </ContentWrapper>
    )
}
export default withTranslation()(LoginRegisterContent);