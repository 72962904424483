
import React from "react";
import {HeaderWrapprr} from "./styles";
import {Grid, Typography} from "@mui/material";
import MyMenu from "./components/menu";
import Language from "./components/language";
import {useSelector} from "react-redux";

const Header = ({backgroundColor , color}) => {
  const lang = useSelector((state) => state.App.lang);

    return(
        <HeaderWrapprr backgroundColor={backgroundColor} color={color} lang={lang}>
            <Grid container>
                <Grid item xs={2} sm={5} md={7} lg={8}>
                   <MyMenu />
                </Grid>
                <Grid item xs={10}  sm={7} md={5} lg={4}  display="flex" justifyContent="center">

                    <Language color={color}/>
                </Grid>
            </Grid>
        </HeaderWrapprr>
    )
}
export default Header;