import React from 'react';
import {
  LoginRegisterIntroWrapper,
  LoginRegisterIntroImage,
} from './styles';
import LoginImg from '../../../assets/images/login.png';

const LoginRegisterIntro = () => {
  return (
    <LoginRegisterIntroWrapper >
      <LoginRegisterIntroImage  source={LoginImg}>
      </LoginRegisterIntroImage>
    </LoginRegisterIntroWrapper>
  );
};
export default LoginRegisterIntro;
