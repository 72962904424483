import React ,{useEffect} from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"
import PrivateCourseActions from "../../../../redux/PrivateCourse/Actions";
import { TableWrapper, CardContainer } from "../../globalStyle";
import Table from "../../../../shared/Table";
import OrdersTableConfig from "./OrdersTableConfig";
import { useDispatch, useSelector } from "react-redux";

const Orders = () => {
    const courseState = useSelector((state) => state.PrivateCourse);
    const { myCoursesListLoading, myCoursesList, skip, take, count } = courseState;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(PrivateCourseActions.getMyCoursesList({ offset: 0, limit: take }));
    }, [])
 //////////////////////////////////////
 const pageHandler = (res) => {
    dispatch(
        PrivateCourseActions.getMyCoursesList({
        offset: res,
        limit: take,
      })
    );
  }
    return (
        <MainLayout>
            <CardContainer>
                <TableWrapper height="calc(100% - 60px)">
                    <Table
                        tblConfig={OrdersTableConfig()}
                        tblData={myCoursesList}
                        showPaging={true}
                        pageHandler={(res) => {pageHandler(res);}}
                        skip={skip}
                        take={take}
                        count={count}
                        loading={myCoursesListLoading}
                    />
                </TableWrapper>
            </CardContainer>
        </MainLayout>
    )
}

export default Orders