import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"

const Programming = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {programmingList} = stateCourse;
  return (
    programmingList.length>0 &&
    <ContentWrapprr>
      <Title title={t("programming")} hasMoreView={true} tabValue={programmingList[0]?.category_id} data={programmingList}/>
      <Carousel data={programmingList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Programming);
