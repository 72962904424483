import React from "react";
import {CompleteInfoWrapprr, PriceContainer} from "../styles";
import { TableWrapper } from "../../globalstyles";
import {
    Grid,
    Box,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cover from "../../../assets/images/content.png";
import Row from "../../../kit/Row";
import { FormInput } from "../../../kit/Form";
import FormController from "../../../controllers/FormController";
import { Formik } from "formik";
import {toast} from "react-toastify";
const { REACT_APP_API_URL } = process.env;

const CompleteInfo = ({ t ,data }) => {
    const lang = useSelector((state) => state.App.lang);
    const [value, setValue] = React.useState('0');
    const meetingList = useSelector((state) => state.Payment.meetingList);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <CompleteInfoWrapprr src={REACT_APP_API_URL + "/" + data?.image} lang={lang}>
            <Grid container >
                <Grid container item xs={12} >
                    <Box className="completeinfo-container" p={3}>
                        <Grid container >
                            <Grid item xs={12} md={4}>
                                <Box id="image-container"></Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <a href={"/course/" +  data?.id}  target={"_blank"} >
                                    <Grid container className="course-info">
                                        <Grid item xs={6}>{t("course name")}:</Grid>
                                        <Grid item xs={6} className="blue-font">
                                        {lang === "fa" ? data?.fa_title : data?.en_title}
                                        </Grid>
                                    </Grid>
                                </a>
                                <a href={"/instructor?id=" +  data?.master?.id}  target={"_blank"} >
                                    <Grid container className="course-info">
                                        <Grid item xs={6}>{t("instructor name")}:</Grid>
                                        <Grid item xs={6} className="blue-font">
                                        {lang === "fa" ?
                                            (data?.master?.fa_first_name + " " + data?.master?.fa_last_name) :
                                            (data?.master?.en_last_name + " " + data?.master?.en_last_name)
                                        }
                                        </Grid>
                                    </Grid>
                                </a>
                                <Grid container className="course-info">
                                    <Grid item xs={6}>{t("course start")}:</Grid>
                                    <Grid item xs={6} className="blue-font">
                                    {lang === "fa" ? data?.start_date_fa : data?.start_date_en}
                                    </Grid>
                                </Grid>
                                <Grid container className="course-info">
                                    <Grid item xs={6}>{t("course end")}:</Grid>
                                    <Grid item xs={6} className="blue-font">
                                    {lang === "fa" ? data?.start_date_fa : data?.start_date_en}
                                    </Grid>
                                </Grid>
                                <Grid container className="course-info">
                                    <Grid item xs={6}>{t("Duration of each course")}:</Grid>
                                    <Grid item xs={6} className="blue-font">
                                    { data?.duration + " " + t("minutes")}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box id="price-container">
                                    <Box style={{"marginBottom":"40px"}}>{t("price")}</Box>
                                    <Box><PriceContainer>{data.price}</PriceContainer><span>{data.price_unit}</span> </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container item xs={12} >
                    <Box className="completeinfo-container" p={3}>
                        <Box>جلسات رزرو شده</Box>
                        <TableWrapper lang={lang}>
                            <TableContainer >
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">{t("order")}</TableCell>
                                            <TableCell align="center">{t("day")}</TableCell>
                                            <TableCell align="center">{t("date")}</TableCell>
                                            <TableCell align="center">{t("hour")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {meetingList?.map((each, ind) => (
                                            <TableRow key={ind}>
                                                <TableCell align="center">{ind+1}</TableCell>
                                                <TableCell align="center">{each.week_day_name}</TableCell>
                                                <TableCell align="center">{each.date}</TableCell>
                                                <TableCell align="center">{each.start_time}-{each.end_time}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableWrapper>
                    </Box>
                </Grid>
                <Grid container item xs={12} >
                    <Box className="completeinfo-container" p={2}>

                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">{t("participant information")}</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="0" control={<Radio />} label={t("I am my own participant")} />
                                <FormControlLabel value="1" control={<Radio />} label={t("The participant is someone else")} />
                            </RadioGroup>
                        </FormControl>
                        {value === "1" &&
                            <Box id="contact-info">
                                <Formik
                                    initialValues={{
                                        phoneNumber: "",
                                        name: ""
                                    }}
                                    onSubmit={(values, actions) => {
                                        localStorage.setItem("information", JSON.stringify(values))
                                        // toast.success("")
                                    }}
                                >
                                    {(formik) => (
                                        <form className="" onSubmit={formik.handleSubmit}>
                                            <Row className="field-container">
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.phoneNumber && formik.touched.phoneNumber ? " warning-border" : "")
                                                    }
                                                    value={formik.values.phoneNumber}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={t("phone number")}
                                                />
                                                {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                                                    <div className="text-danger">{formik.errors.phoneNumber}</div>
                                                ) : null}
                                            </Row>
                                            <Row className="field-container">
                                                <FormInput
                                                    type="name"
                                                    className={
                                                        "login-form-control" + (formik.errors.name && formik.touched.name ? " warning-border" : "")
                                                    }
                                                    value={formik.values.name}
                                                    id="name"
                                                    name="name"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={t("name")}
                                                />
                                                {formik.errors.name && formik.touched.name ? (
                                                    <div className="text-danger">{formik.errors.name}</div>
                                                ) : null}
                                            </Row>

                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </CompleteInfoWrapprr>
    )
}
export default withTranslation()(CompleteInfo);