
import {Grid, Box, Button} from "@mui/material";
import React from "react";
import {
    ContentWrapprr,
    ContentContainer,
    ImageBanner,
    TitleContainer,
    DescriptionConatiner,
    SectionContainer,
    BtnConatiner
} from "./styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Redirect } from 'react-router-dom';

const { REACT_APP_API_URL } = process.env;


const Content = ({ data, t }) => {
    const lang = useSelector((state) => state.App.lang);
    const isLoggedin = useSelector((state) => state.Login.isLoggedin);

    const showMessage  = () =>{
        let message= t("Please log in to your account");
       if(!isLoggedin){
            toast.warning(message, {
            position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
           });
           <Redirect to='/login' />
       }
    }
    return (
        <ContentWrapprr lang={lang}>
            <ContentContainer>
                <ImageBanner src={REACT_APP_API_URL + "/" + data?.image} />
                {
                    data.is_submitted_by_me === true && isLoggedin === true ?
                        <Link to={{ pathname: '/learning/' + data.id, state: { id: data.id, data: data } }} >
                            <TitleContainer>
                                {lang === "fa" ? data?.fa_title : data?.en_title}
                            </TitleContainer>
                        </Link>
                        :
                        <Link to={{ pathname: '/course/' + data.id, state: { id: data.id, data: data } }}>
                            <TitleContainer>
                                {lang === "fa" ? data?.fa_title : data?.en_title}
                            </TitleContainer>
                        </Link>
                }
                <DescriptionConatiner>
                    {lang === "fa" ? data?.fa_subtitle : data?.en_subtitle}
                </DescriptionConatiner>
                <SectionContainer>
                    <span>{t("instructor")} : </span>
                    {/* <Link to={'/instructor/' + data?.master?.id} state={{ id: data?.master?.id, master: data.master }}> */}
                    <a href={ '/instructor?id=' + data?.master?.id}>
                        <Box color="#3D54A8" className="font-bold">
                            {lang === "fa" ?
                                (data?.master?.fa_first_name + " "  + data?.master?.fa_last_name ) :
                                (data?.master?.en_first_name  + " "  + data?.master?.en_last_name)}
                        </Box>
                    </a>
                </SectionContainer>
                <SectionContainer>
                    <Grid container>
                        <Grid item xs={6}><Rating name="half-rating" defaultValue={data.rate} readOnly /></Grid>
                        <Grid item xs={6} display="flex" justifyContent="end" >({data.comments} {t("comments")})</Grid>
                    </Grid>
                </SectionContainer>
                <SectionContainer>
                    <Grid container>
                        <Grid item xs={6}>{t("price")} :</Grid>
                        <Grid
                            item xs={6}
                            display="flex"
                            justifyContent="end"

                        >
                            <Box>{data.price_unit}</Box>
                            <Box className="font-bold" color="#3D54A8" sx={{ pr: 1 }}>{data.price}</Box>
                        </Grid>
                    </Grid>
                </SectionContainer>


                  <a href={isLoggedin === true ?'/courseRegister?id='  + data?.id : '/login?ref=/courseRegister?id='  + data?.id }>
                      {/*<Button className="btn" onClick={() => {showMessage()}}>{t("register")}</Button>*/}
                      <BtnConatiner onClick={() => {showMessage()}}>
                        <button id="btn">{t("register")}</button>
                    </BtnConatiner>
                  </a>
                {/*<Link to={{pathname :isLoggedin === true ?'/courseRegister/' + data.id : '/login' , state:{ id: data.id , data:data }}}>*/}
                {/*    */}
                {/*</Link>*/}

            </ContentContainer>
        </ContentWrapprr>
    )
}
export default withTranslation()(Content);