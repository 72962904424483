import React from "react";

const EditIcon =(props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.112 2.19H2.175A1.175 1.175 0 001 3.363v6.461A1.175 1.175 0 002.175 11h6.461a1.175 1.175 0 001.175-1.175V6.888m-.83-5.53a1.175 1.175 0 111.66 1.662L5.598 8.063H3.937V6.402L8.98 1.359z"
      ></path>
    </svg>
  );
}
EditIcon.defaultProps = {
  stroke : "#3D54A8"
};
export default EditIcon;
