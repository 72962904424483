
export const ActiveListOptions = [
    {value : "active" , label : "فعال"},
    {value : "deactivate" , label : "غیر فعال"},
]

export const durationListOptions = [
    {value : 15 , label : "15 دقیقه"},
    {value : 30 , label : "30 دقیقه"},
    {value : 60 , label : "60 دقیقه"},
    {value : 90 , label : "90 دقیقه"},
    {value : 120 , label : "120 دقیقه"}
]

export const CourseTypeOptions = [
    {value : "peer_to_peer" , label : "خصوصی"},
    // {value : "peer_to_peer" , label : "چند نفره"},
]