export default {
  DARK_THEME: 'DARK_THEME',
  LIGHT_THEME: 'LIGHT_THEME',
  SIDEBAR_ISSHOW: 'SIDEBAR_ISSHOW',
  MORE_iCON_IS_SHOW: ' MORE_iCON_IS_SHOW',
  RESPONSIVE_SETTING_MENU_SHOW: 'RESPONSIVE_SETTING_MENU_SHOW',
  USER_MENU: 'USER_MENU',

  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  NEXT_PAYMENT_STEP :"NEXT_PAYMENT_STEP",

};
