import React from 'react';
import {
  LoginRegisterIntroWrapper,
  LoginRegisterIntroImage,
} from './styles';

import RegisterImg from '../../../assets/images/login.svg';
import LoginImg from '../../../assets/images/login.svg';

const LoginRegisterIntro = ({mode}) => {
  return (
    <LoginRegisterIntroWrapper >
      <LoginRegisterIntroImage  mode={mode} source={mode === "login" ? LoginImg : RegisterImg}>
      </LoginRegisterIntroImage>
    </LoginRegisterIntroWrapper>
  );
};
export default LoginRegisterIntro;
