import React from 'react';
import { AboutUsWrapper, AboutImageContainer } from "./styles"
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { Grid, Box } from "@mui/material";
import about from "../../assets/images/aboutUs.png"
import { withTranslation } from "react-i18next";
import {  useSelector } from "react-redux";

const Contact = ({ t }) => {
    const theme = useSelector((state) => state.App.theme);

    return (
        <AboutUsWrapper>
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor}/>
            <AboutImageContainer src={about} />
            <Grid container id="about-container">
                <Grid items xs={12} id="title-container">
                    {t("about")}
                </Grid>
                <Grid items xs={12} id="body-container">
                    <Box>
                        ایران میتاپ با ایجاد یک پلتفرم امن و کارآمد، تلاش میکنه که خدماتی که توسط ایرانیان داخل کشور ارائه میشه رو در اختیار تمام فارسی زبانان دنیا قرار بده و هر کس از هر گوشه ی دنیا بتونه به راحتی، خدمت مورد نیازش رو از ایران میتاپ دریافت کنه. تمام خدماتی که در ایران میتاپ ارائه میشن (آموزش یا مشاوره) به صورت جلسات خصوصی هست. یعنی شما زمانی رو که از استاد یا مشاور رزرو میکنید فقط و فقط در اختیار شماست و طی یک تماس ویدیویی در بستر ایران میتاپ، خدمات مورد نیازتون رو دریافت میکنید.
                        ما در ایران میتاپ با کمک اساتید و مشاورین بسیار حرفه ای، مطمئن میشیم که شما بهترین خدمات رو با قیمت بسیار مناسب، دریافت میکنید.
                    </Box>
                </Grid>
            </Grid>

            <Footer />
        </AboutUsWrapper>
    )
}
export default withTranslation()(Contact);