import React, {useEffect, useState} from 'react';
import { CourseContainer, FilterConatiner } from "../../styles";
import { Grid, Box, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { withTranslation } from "react-i18next";
import { FormInput } from "../../../../kit/Form";
import { useSelector, useDispatch } from "react-redux";
import CourseActions from '../../../../redux/Course/Actions';
import SerachInput from "../../../../shared/inputSearch"
const Courses = ({ t, category_id, search, setSearch, sort, setSort }) => {
    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    useEffect(()=>{
        setSort(2);
    },[])
    const handleChange = (e) => {
        let result = e.target.value;
        setSearch(result);
        if (result.length > 3 || result.length === 0) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: false,
                query: result,
                category_id: category_id,
                limit: 12,
                sort: "",
                oriented: "",
                offset: 0
            }))
        }
    }
    const handleChangeSelect = (e) => {
        setSort(e.target.value);

        if (e.target.value === 6) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: true,
                has_discount: false,
                category_id: category_id,
                limit: 12,
                sort: "",
                oriented: "",
                query: "",
                offset: 0
            }))
        }
        else if (e.target.value === 3) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: false,
                category_id: category_id,
                limit: 12,
                sort: "price",
                oriented: "asc",
                query: "",
                offset: 0
            }))
        }
        else if (e.target.value === 2) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: false,
                category_id: category_id,
                limit: 12,
                sort: "price",
                query: "",
                oriented: "desc",
                offset: 0
            }))
        }
        else if (e.target.value === 4) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: true,
                category_id: category_id,
                limit: 12,
                sort: "",
                query: "",
                oriented: "",
                offset: 0
            }))
        }
        else if (e.target.value === 5) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: false,
                category_id: category_id,
                limit: 12,
                sort: "id",
                query: "",
                oriented: "desc",
                offset: 0
            }))
        }
    }
    return (

        <FilterConatiner>
            <Grid container>
                <Grid item xs={12} sm={6} py={1}>
                    {/* <FormInput
                        placeholder={t("search")}
                        id="search-input"
                        value={search}

                        onChange={(e) => handleChange(e)} /> */}
                         <SerachInput id="search-input" onChange={handleChange} value={search}  placeholder={t("search")}/>
                </Grid>
                <Grid container xs={12} sm={6} py={1} dispaly="flex" justifyContent="end">

                    <Box className="filter_container" dispaly="flex" justifyContent="end">
                        <FormControl
                            variant="outlined"
                            margin={"1"}
                            style={{ width: "100%", marginBottom: 32, }} //  "border": "2px solid #3D54A8", "borderRadius":"8px"
                        >
                            <InputLabel id="demo-simple-select-label">{t("order by")}</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={sort} label={t("order by")} onChange={handleChangeSelect}>
                                <MenuItem value={2}>{t("cheapest")} </MenuItem>
                                <MenuItem value={3}>{t("most expensive")} </MenuItem>
                                <MenuItem value={4}>{t("discounts")} </MenuItem>
                                <MenuItem value={5}>{t("oldest")} </MenuItem>
                                <MenuItem value={6}>{t("newest")} </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </Grid>
            </Grid>
        </FilterConatiner >

    )
}
export default withTranslation()(Courses);