import React from "react";
import { Formik } from "formik";
import FormController from "../../../../../controllers/FormController";
import { FormWrapper, FormContainer, BtnContainer ,ImageContainer } from "../styles";
import { FormInput } from "../../../../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import ProfileActions from "../../../../../redux/Profile/Actions";
import { VerifyValidationChangePassword } from "../../../../../validation/Schemas/VerifyValidationChangePassword";
import Lock from "../../../../../assets/images/lock.png";
import {TitleContainer} from "../../../globalStyle";
import { Grid,Box,Button } from "@mui/material";
import { withTranslation } from "react-i18next";
import { toast } from 'react-toastify';

const ChangePasswordForm = ({t}) => {

    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    const handleChangePassword = (values, action) => {
        if(values.newPassword === values.repeatePassword){
            dispatch(ProfileActions.changeUserPassword({ values, action }));
        }
        else{
            let message= t("Repeating a password is not the same as a new password");
            toast.warning(message, {
                position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
               });
        }
    }
    return (
        <Grid container>
            <Grid items md={6}>
            <FormWrapper lang={lang}>
            <TitleContainer>
               {t("change Password")}
            </TitleContainer>
            <FormContainer>
                <Formik
                    initialValues={{
                        currentPassword: "",
                        newPassword: "",
                        repeatePassword: ""
                    }}
                    onSubmit={(values, actions) => {
                        handleChangePassword(values, actions);
                    }}
                    validationSchema={VerifyValidationChangePassword}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                            <Grid items xs={12} py={1} >
                                   <Box mb={1}>{t("Current password")}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.currentPassword && formik.touched.currentPassword ? " warning-border" : "")
                                        }
                                        value={formik.values.currentPassword}
                                        id="currentPassword"
                                        name="currentPassword"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        // placeholder={t("Current password")}
                                    />
                                  
                                </Grid>
                                <Grid items xs={12} py={1} >
                                   <Box mb={1}>{t("New password")}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.newPassword && formik.touched.newPassword ? " warning-border" : "")
                                        }
                                        value={formik.values.newPassword}
                                        id="newPassword"
                                        name="newPassword"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        // placeholder={t("New password")}
                                    />
                                  
                                </Grid>
                                <Grid items xs={12} py={1} >
                                   <Box mb={1}>{t("repeat the password")}</Box>
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.repeatePassword && formik.touched.repeatePassword ? " warning-border" : "")
                                        }
                                        value={formik.values.repeatePassword}
                                        id="repeatePassword"
                                        name="repeatePassword"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        // placeholder={t("repeat the password")}
                                    />
                                  
                                </Grid>
                            </Grid>
                            
                            <BtnContainer>
                                <Button
                                    onClick={formik.handleSubmit}
                                    type="submit"
                                    className="btn"
                                >
                                    <span className="color-white">{t("send")}</span>
                                </Button>
                            </BtnContainer>
                        </form>
                    )}
                </Formik>
            </FormContainer>
        </FormWrapper>
            </Grid>
            <Grid items md={6} display="flex" justifyContent="center" alignItems="center">
                <ImageContainer src={Lock} />
            </Grid>
        </Grid>
        
    )
}
export default withTranslation()(ChangePasswordForm)