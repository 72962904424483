import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { ToggleWrapprr } from "./styles";
import { useSelector} from "react-redux";

const ToggleableBox = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(true);
  const lang = useSelector((state) => state.App.lang);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <ToggleWrapprr lang={lang}>
      <Box id="toggle-container">
      <Grid container >
        <Grid item xs={6} display="flex" alignItems="center" px={1}>
          <Box className="title-container">
            {title}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={handleToggle}>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {isOpen && (
        <Box padding="10px">
          {children}
        </Box>
      )}
      </Box>
    </ToggleWrapprr >
  );
};

export default ToggleableBox;