import styled from 'styled-components';
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";


export const CourseWrapper = styled(Col)`
width:90%;
padding :20px 0;
.course-info{
    padding:7px 15px 7px 15px;
  font-weight: bold;
}
.course-container{
    box-shadow:0px 0px 3px 0px #b9b9b9;
    border-radius : 10px;
    width:100%;
    margin: 10px 0;
}
.blue-font{
    color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
    font-family: ${(props) => (props.theme.fontBold)};

}
.btn-container {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
}
 button{
    margin-top :5px;
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};
    width: 120px;
    padding: 5px;
    color : ${(props) => props.theme.secondaryFontColor};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }

`;


export const ImageContainer = styled(Row)`
    height : 120px;
    width : 100%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: ${(props) => props.lang === "fa" ? "right" : "left"};
    background-size: cover;
    border-radius : 5px;
`;