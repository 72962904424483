import { combineReducers } from 'redux';
import App from './App/Reducer';
import Login from './Login/Reducer';
import Comment from './Comment/Reducer';
import Course from './Course/Reducer';
import Payment from './Payment/Reducer';
import Profile from './Profile/Reducer';
import Member from './Member/Reducer';
import Master from './Master/Reducer';
import PrivateCourse from './PrivateCourse/Reducer';
import Discount from './Discount/Reducer';


export default combineReducers({
  App,
  Login,
  Comment,
  Course,
  Payment,
  Profile,
  Member,
  Master,
  PrivateCourse,
  Discount
});
