import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const AboutUsWrapper = styled(Col)`
    width:100%;
    margin-top:75px;
    background-color : #f7f7f7;
    border-top :1px solid #ccc;
    box-shadow:0px 1px 3px 1px #b9b9b9;
    #about-container {
        display: flex;
        justify-content: center;
        width: 80%;
    }
    #title-container{
        display: flex;
        justify-content: center;
        padding:30px 0;
        color :${(props) => props.theme.secondaryFontColor};
        font-family: ${(props) => (props.theme.fontBold)};
        font-size: ${(props) => props.theme.fontSizeLg};

    }
    #body-container{
        display: flex;
        justify-content: center;
        line-height: 35px;
        text-align: justify;
        padding-bottom:30px;
        font-size: ${(props) => props.theme.fontSizeMd};

    }
`;

export const AboutImageContainer = styled(Row)`
    height: calc((100vw) * 0.53);
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
`;