import React from 'react';
import { useSelector } from 'react-redux';

function Icon({ fill }) {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_9_1396)">
        <path d="M12.0005 21C18.6286 21 24.0005 16.6359 24.0005 11.25C24.0005 5.86406 18.6286 1.5 12.0005 1.5C5.37236 1.5 0.000482208 5.86406 0.000482208 11.25C0.000482208 13.3641 0.83017 15.3187 2.23642 16.9172C2.14736 18.0656 1.70204 19.0875 1.23329 19.8656C0.975482 20.2969 0.712982 20.6437 0.520795 20.8781C0.422357 20.9953 0.347357 21.0844 0.291107 21.1453C0.262982 21.1734 0.244232 21.1969 0.23017 21.2109L0.216107 21.225C0.000482209 21.4406 -0.0604553 21.7594 0.0567322 22.0406C0.17392 22.3219 0.445795 22.5047 0.750482 22.5047C2.09579 22.5047 3.45048 22.0875 4.57548 21.6C5.64892 21.1312 6.56298 20.5734 7.12079 20.1656C8.61142 20.7047 10.2614 21.0047 12.0005 21.0047V21ZM6.00048 12.75C5.17079 12.75 4.50048 12.0797 4.50048 11.25C4.50048 10.4203 5.17079 9.75 6.00048 9.75C6.83017 9.75 7.50048 10.4203 7.50048 11.25C7.50048 12.0797 6.83017 12.75 6.00048 12.75ZM12.0005 12.75C11.1708 12.75 10.5005 12.0797 10.5005 11.25C10.5005 10.4203 11.1708 9.75 12.0005 9.75C12.8302 9.75 13.5005 10.4203 13.5005 11.25C13.5005 12.0797 12.8302 12.75 12.0005 12.75ZM19.5005 11.25C19.5005 12.0797 18.8302 12.75 18.0005 12.75C17.1708 12.75 16.5005 12.0797 16.5005 11.25C16.5005 10.4203 17.1708 9.75 18.0005 9.75C18.8302 9.75 19.5005 10.4203 19.5005 11.25Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_9_1396">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    );
}
Icon.defaultProps = {
    fill: "#fff",
};
export default Icon;
