import styled from 'styled-components';
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";


export const DashboardWrapper = styled(Col)`
 width:100%;
 .card-container{
    width:100%;
    box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
    box-sizing:border-box;
    padding: 10px;
    border-radius : 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height :330px;
 }
 button{
    margin-top :5px;
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};
    width: 120px;
    padding: 5px;
    color : ${(props) => props.theme.secondaryFontColor};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }

`;

export const TitleContainer = styled(Row)`
    width:100%;
    justify-content:start;
    margin: 8px 0;
    font-size: ${(props) => props.theme.fontSizeMd};
    font-family: ${(props) => (props.theme.fontBold)};
    color: ${(props) => props.theme.secondaryFontColor};
`;
export const AnnouncemnetContainer = styled(Row)`
    width:100%;
    border-radius : 10px;
    box-sizing:border-box;
    padding: 10px;
    border :1px solid #E9E9E9;
    margin:5px ;
    .title_container{
        font-family: ${(props) => (props.theme.fontBold)};
        color: ${(props) => props.theme.secondaryFontColor};
    }
;`;
