import React, { useState, useEffect } from "react";
import loading from "../../../assets/images/spinner.gif";
import success from "../../../assets/images/sucess-payment.png";
import error from "../../../assets/images/error-payment.png";
import { PurchaseWrapper, PurchaseImageContainer, ActionsContainer,TextContainer } from "../styles"
import { Button, Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import PaymentActions from "../../../redux/Payment/Actions";
// import Row from "../../../kit/Row";

import { useSelector ,useDispatch } from "react-redux";
const Purchase = ({ t , data }) => {
    const [paymentSuccess, setPaymentSuccess] = useState(1);

    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentStatusImage, setPaymentStatusImage] = useState(loading);
    const dispatch = useDispatch();
    const meetingList = useSelector((state) => state.Payment.meetingList);
    // console.log(meetingList)
    const params = new URLSearchParams(window.location.search);

    useEffect(()=>{
        if(meetingList && meetingList.length > 0){
            dispatch(PaymentActions.bookCourse({times: meetingList , course_id :params.get("id") , name :"" ,email:""  }));
        }
        setTimeout((e)=>{
            setPaymentStatus("success");
        },1000)
    },[setPaymentStatusImage, setPaymentStatus, meetingList]);
    const returnHome = (e) =>{
        window.location.href = "/"
    }

    const tryAgain = (e) =>{
        window.location.href = "/masters"
    }

    return (
        <PurchaseWrapper>
            <Box id="purchase-container">
                <PurchaseImageContainer src={paymentStatus.length === 0 ? loading : (paymentStatus === "success" ?  success : error)} />
                <TextContainer color={paymentStatus === "success" ? "green" : "red"}
                               style={{"display": paymentStatus.length > 0 ? "flex" : "none", fontSize:"2rem"}}>
                    {
                        paymentSuccess === 1 ?
                            t("Your order has been successfully completed") :
                            t("Your payment was not successful")
                    }
                </TextContainer>

                <ActionsContainer  justify="center" style={{"display": paymentStatus.length > 0 ? "flex" : "none"}}>
                    <Button className="btn " style={{height:"4rem", width:"14rem"}}  onClick={tryAgain}>{paymentSuccess === 1 ? t("dashboard") : t("try again")}</Button>
                    <Button className="btn " style={{height:"4rem", width:"14rem"}}  onClick={returnHome} id="return">{paymentSuccess === 1 ? t("return to home") : t("return")}</Button>
                </ActionsContainer>
            </Box>
        </PurchaseWrapper>
    )
}
export default withTranslation()(Purchase);