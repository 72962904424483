import styled from "styled-components";
import Col from "../../kit/Column";
import Row from "../../kit/Row";
export const ForgetPasswordWrapper = styled(Col)`
  height: 100%;
  background-color: ${(props) => props.theme.baseBackGroundColor};
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    width: 100%;
  } ;
`;
export const ForgetPasswordFormWrapper = styled(Col)`
width :100%;
padding-right :100px;
input : focus {
   background-color: inherit !important;
}
button{
   margin-top :10px;
   background-color: ${(props) => props.theme.btnColor};
   border-radius: ${(props) => props.theme.borderRadius};
   border: none;
   width: 100%;
   padding: 10px;
   color : ${(props) => props.theme.white};
   cursor :pointer;
   font-family: ${(props) => (props.theme.fontDefault)};

}
font-family: ${(props) => (props.theme.fontDefault)};
@media screen and (max-width: 900px) {
    width :80%;
    padding-right :0px
    }
*{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

`;
export const TitleContainer = styled(Row)`
    margin: 10px 0;
    font-size: ${(props) => props.theme.fontSizeXl};
    font-weight : bold;
    justify-content : flex-start;
    width: 100%;
    color :  ${(props) => props.theme.text};
`;
export const FormContainer = styled(Row)`
    width: 100%;
    form{
        width :100%;
        padding-top:20px;
    }
`;
export const BtnContainer = styled(Row)`
    margin :10px 0;
`;
export const TextContainer = styled(Row)`
    color :  ${(props) => props.theme.text};
    font-size : ${(props) => props.theme.fontSizeSm};
    justify-content : flex-start;
    cursor : pointer;
    font-family: ${(props) => (props.theme.fontBold)};
    width: 100%;
`;

export const LogoContainer = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: contain;
    width: 80px;
    height: 80px;
    justify-content : flex-start;
`;