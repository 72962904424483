import styled from "styled-components";

import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";


export const IntroductionWrapper = styled(Col)`
    min-height: 320px;
    width: 90%;
    padding:50px 0;
    background-color:  ${props => props.theme.baseBackGroundColor};
    *:focus {
    outline: 0;
    outline: none;
  }
  @media screen and (min-width: 1700px) and (max-width :2000px) {
    width : 70%;
   }
  @media screen and (min-width: 2000px)  {
      width : 65%;
  }
`;

export const IntroductionItem = styled(Col)`
    padding:6px;
    .ItemContainer{
      box-shadow: 0px 0px 3px 0px #888888;
        text-align:center;
        height: 223px;
        width:100%;
        background-color : #F8F8F8;
        border-radius: ${(props) => props.theme.borderRadius};
        cursor: pointer;
    } 
`;
export const ImgContainer=styled(Col) `
       background-image: url(${props => props.src});
       min-height:150px;
       width:78px;
       background-size:contain;
       background-repeat:no-repeat;
       background-position:center;
       margin: 0 auto;
       border-radius:15px;
`;
export const BoxTitle = styled(Col)`
  font-size: ${props => props.theme.fontSizeLg};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.secondaryFontColor};
  padding: 15px;
  font-family: ${(props) => (props.theme.fontDefault)};
`;

