import types from "./Types";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
// let localUserInfo = Storage.get(Keys.userInfo)
//   ? Storage.get(Keys.userInfo)
//   : {};
const initState = {
  activeMode: "login",
  isLoggedin: Storage.get(Keys.isLoggedin) ? true : false,
  role: Storage.get(Keys.role) ? Storage.get(Keys.role) : "",
  exception: null,
  loading: false,
  // userInfo: {
  //   ...localUserInfo,
  // },
  activateLoading: false,
  activateStatus: false,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.LOGIN_SUCCESS:
      
      // Storage.set(Keys.userInfo, action.data);
      Storage.set(Keys.token, action.data.token);
      Storage.set(Keys.role, action.data.permission);
      Storage.set(Keys.isLoggedin, true);
      return {
        ...state,
        isLoggedin: true,
        // userInfo: action.data,
        role : action.data.permission
      };
      case types.REGISTER_SUCCESS:
        
        return {
          ...state,
        };

    case types.LOGIN_FAIL:
      return {
        ...state,
        exception: action.data.exception,
      };

    case types.LOGOUT:
       Storage.logOut();
      return {
       ...state,
        isLoggedin: false,
      };
    case types.ACTIVATE_AN_ACCOUNT_SUCCESS :
      return{
        ...state,
        activateLoading: false,
        activateStatus: true,
      }
    // case types.ACTIVATE_AN_ACCOUNT_FAIL :
    //   debugger
    //   return {
    //     ...state,
    //     activateLoading: false,
    //     activateStatus: false,
    //   }
    default:
      return state;
  }
}
