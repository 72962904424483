import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const CoursesRegisterWrapprr = styled(Col)`
    width:100%;
    margin-top:75px;
    border-top :1px solid #ccc;
    box-shadow:0px 1px 3px 1px #b9b9b9;
    //  *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    
  .MuiStepper-root , .MuiFormControlLabel-root {
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
  }
  .MuiButtonBase-root {
    font-family: ${(props) => (props.theme.fontBold)};
  }
  #subscription_btn{
    width :200px;
  }
  #subscription_form{
    display : flex;
    justify-content: flex-end;
  }
`;
export const TabContainer = styled(Col)`
    width:90%;
    margin:5px 0;
    box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
    border-radius :10px;
    padding:15px 10px;
    margin:20px 0;
    box-sizing:border-box;
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const TabBodyContainer = styled(Col)`
    width:90%;
    padding:15px 0px;
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;


export const CalendarWrapprr = styled(Col)`
    width:100%;
    padding :20px 0;
    #calendar{
        width:100%;  
    }
    .meeting-container{
        border : 1px solid ${(props) => props.theme.secondaryFontColor};
        border-radius : 10px;
        width:100%;
        padding:5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .MuiFormControl-root {
      width:100%;
    }
    .choose-time{
        color :${(props) => props.theme.secondaryFontColor};
        font-family: ${(props) => (props.theme.fontBold)};

    }
    #choosen-time {
        color :${(props) => props.theme.thirdFontColor};
        font-family: ${(props) => (props.theme.fontBold)};
        padding :15px 0;

    }
`;
export const CalendarConatiner = styled(Col)`
    width:100%;
    border : 1px solid #ccc;
    border-radius : 10px;

    #date-container {
        border-bottom : 1px solid #ccc;
        padding :10px 0;
        color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
        font-family: ${(props) => (props.theme.fontBold)};
    }
    .time-container{
        border : 1px solid #ccc;
        border-radius : 5px;
        display: flex;
        justify-content : center;
    }
  
`;
///////////////////////////////////////Complete info
export const CompleteInfoWrapprr = styled(Row)`
    width:100%;
    padding :20px 0;
    .course-info{
        padding:7px 15px 7px 15px;
    }
    .completeinfo-container{
        box-shadow:0px 0px 3px 0px #b9b9b9;
        border-radius : 10px;
        width:100%;
        margin: 10px 0;
    }
    .blue-font{
        color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
    }
    #image-container{
        height : 180px;
        width : 100%;
        background-image : url(${props => props.src});
        background-repeat:no-repeat;
        background-position: ${(props) => props.lang === "fa" ? "right" : "left"};
        background-size: cover;
        border-radius : 5px;
    }
    #price-container{
        border-radius : 5px;  
        height : 180px;
        background-color :#e0e6ed;
        display:flex;
        justify-content:center;
        flex-direction: column;
        align-items: center;
    }
    #contact-info{
        border: 1px solid  ${(props) => props.theme.secondaryFontColor};
        padding: 10px;
        border-radius : 5px;  
        background-color :#e0e6ed;
        margin : 10px;
    }
    .field-container{
        justify-content : flex-start;
        padding:10px 0;
    }
    input {
        width:250px;
    }
  
`;
/////////////////////////////////////////payment

export const PurchaseWrapper = styled(Col)`
    width:100%;
    padding :20px 0;
    #purchase-container{
        box-shadow:0px 0px 3px 0px #b9b9b9;
        border-radius : 10px;
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }
   
`;
export const PurchaseImageContainer = styled(Row)`
    height : 300px;
    width : 50%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    border-radius : 5px;
  
`;
export const ActionsContainer = styled(Row)`
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
    width:100%;
    justify-content : ${(props) => props.justify ? props.justify : "center"};
    .btn{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: 1px solid ${(props) => props.theme.btnColor};
        width: 160px;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
        margin: 5px;
     }
     .btn:hover {
        color : ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.btnColor};
      }
     #return{
        background-color: ${(props) => props.theme.white};
        color : ${(props) => props.theme.btnColor};
     }
 
  
`;
export const TextContainer = styled(Row)`
  color : ${(props) => props.color};
  padding:30px 0;
  

`;
export const PriceContainer = styled(Row)`
    //text-decoration: underline;
  display: inline;
  margin-right: 20px;
  font-size: 40px;
  font-weight: bold;
  color:${(props) => props.theme.secondaryFontColor};
`;

//////////////////////////////////////////////////

export const PaymentWrapper = styled(Row)`
width:100%;
  .payment-box{
       width:100%;
        box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
        border-radius :10px;
        padding: 15px 20px;
        box-sizing: border-box;
        display :flex;
        flex-direction:column;
        align-items :center;
        min-height:150px;
        justify-content:center;
  }
  form{
      width :100%;
  }
  button{
    height: 100%;
    border: 1px solid #CFD8DE;
    background-color: #fff;
    border-radius: 5px;
    color:${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeXl};
    width:40px;
  }
  .title-container{
    color:${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeLg};
    padding:20px;
    font-family: ${(props) => (props.theme.fontBold)};
    display: flex;
    width: 100%;
  }
  .title-container-dashed{
    color:${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeLg};
    padding:20px;
    font-family: ${(props) => (props.theme.fontBold)};
    display: flex;
    width: 100%;
    text-decoration: line-through;
  }
`;