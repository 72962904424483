import React, { useState, useEffect } from 'react';
import { HomeWrapprr } from "./styles";
import HomwView from "../../views/home";
import CourseActions from "../../redux/Course/Actions";
import { useDispatch } from "react-redux";

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(CourseActions.getCategories({}));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: true, has_discount: false, courseType: "recent" ,category_id:"",query:"",master_id:"",course_id:"" }));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: true, courseType: "discount" ,category_id:"",query:"",master_id:"",course_id:""}));

        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "programming", category_id: 1,query:"",master_id:"",course_id:"" }));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "English", category_id: 2 ,query:"",master_id:"",course_id:""}));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "law", category_id: 3 ,query:"",master_id:"",course_id:""}));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "sport", category_id: 4,query:"",master_id:"",course_id:"" }));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "music", category_id: 5 ,query:"",master_id:"",course_id:""}));
        dispatch(CourseActions.getCourses({offset:0,limit:8, related: false, recent: false, has_discount: false, courseType: "psychology", category_id: 6 ,query:"",master_id:"",course_id:""}));


    }, [])


    return (
        <HomeWrapprr >
            <HomwView />
        </HomeWrapprr>
    )
}
export default Home;