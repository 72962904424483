import React from "react";
import Row from "../../kit/Row";
import { Formik } from "formik";
import { FormInput } from "../../kit/Form";
import FormController from "../../controllers/FormController";
import { FormRow } from "./globalStyle";
import SerachInput from "../../shared/inputSearch"

const FilterNewsCategory = ({ fields, onConfirm }) => {
    return (
        <Formik
            initialValues={{ ...fields }}
            isInitialValid={false}
            enableReinitialize={true}
            onSubmit={(values, action) => {
                onConfirm(values, action);
            }}

        >
            {(formik) => (

                <form className="py-5" onSubmit={formik.handleSubmit}>
                    <Row>
                        <FormRow md={4}>
                            {/* <FormInput
                                value={formik.values.query}
                                name="query"
                                id="query"
                                placeholder="جستجو"
                                onChange={(e) => FormController.onChange(e, formik, null)}
                                className={
                                    "custom_input" + (formik.errors.query && formik.touched.query ? " warning-bottom-border" : "")
                                }
                            /> */}
                            <SerachInput 
                                value={formik.values.query}
                                name="query"
                                id="query"
                                placeholder="جستجو"
                                onChange={(e) => FormController.onChange(e, formik, null)}
                                className={
                                    "custom_input" + (formik.errors.query && formik.touched.query ? " warning-bottom-border" : "")
                                }/>
                            {formik.errors.query && formik.touched.query ? (
                                <div className="text-danger text-small text-alert">{formik.errors.query}</div>
                            ) : null}

                        </FormRow>
                        <FormRow md={4}>
                            <button
                                color="primary"
                                size="lg"
                                onClick={formik.handleSubmit}
                                className="btn-filter"
                            >
                                <span className="color-white">جستجو</span>
                            </button>
                        </FormRow>
                    </Row>
                </form>

            )}
        </Formik>
    )
}

export default FilterNewsCategory;
