import React from 'react';
import { useSelector } from 'react-redux';

function MenuIcon({fill}) {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 7L4 7" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
            <path d="M20 12L4 12" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
            <path d="M20 17L4 17" stroke={fill} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
}

MenuIcon.defaultProps = {
    fill: "#1C274C",
  };
  export default MenuIcon;
