import {
  all,
  takeEvery,
  put,
  fork,
  takeLatest,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Storage from "../../storage";
import keys from "../../storage/NameSpace";
import Api from "../../services/Api";


export function* GetCategories() {
  yield takeEvery(types.GET_CATEGORIES, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetCategories, false);
      if (response) {
        yield put({
          type: types.GET_CATEGORIES_SUCCESS,
          data: response,
        });
      } else
        yield put({
          type: types.GET_CATEGORIES_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_CATEGORIES_FAIL,
      });
    }
  });
}

export function* GetCourses() {
  yield takeEvery(types.GET_COURSES, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetCourses+`?related=${data.related}&recent=${data.recent}&has_discount=${data.has_discount}&course_id=${data.course_id}&master_id=${data.master_id}&offset=${data.offset}&limit=${data.limit}&query=${data.query}&category_id=${data.category_id}`, false);
      if (response) {
        yield put({
          type: types.GET_COURSES_SUCCESS,
          data: {data:response.data , courseType :data.courseType},
        });
      } else
        yield put({
          type: types.GET_COURSES_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_COURSES_FAIL,
      });
    }
  });
}

export function* GetTabCourses() {
  yield takeEvery(types.GET_TAB_COURSES, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetTabCourses+`?related=${data.related}&recent=${data.recent}&has_discount=${data.has_discount}&offset=${data.offset}&limit=${data.limit}&q=${data.query}&category_id=${data.category_id}&oriented=${data.oriented}&sort=${data.sort}`, false);
      if (response) {
        yield put({
          type: types.GET_TAB_COURSES_SUCCESS,
          data: {data:response.data , count :response.total , category_id: data.category_id , mode : data.mode},
        });
      } else
        yield put({
          type: types.GET_TAB_COURSES_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_TAB_COURSES_FAIL,
      });
    }
  });
}

export function* GetCourseItem() {
  yield takeEvery(types.GET_COURSES_ITEM, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetCourses +`/${data.course_id}`, true);
      if (response) {
        yield put({
          type: types.GET_COURSES_ITEM_SUCCESS,
          data: response,
        });
      } else
        yield put({
          type: types.GET_COURSES_ITEM_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_COURSES_ITEM_FAIL,
      });
    }
  });
}
export default function* CourseSagas() {
  yield all(
    [
      fork(GetCategories),
      fork(GetCourses),
      fork(GetTabCourses),
      fork(GetCourseItem)
    ]);
}
