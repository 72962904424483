import {
  all,
  takeEvery,
  put,
  fork,
  select
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";

export function* GetMasters() {
  yield takeEvery(types.GET_MASTERS_LIST, function* ({ data }) {
    // alert(data.q)
    try {
      let response =[];
      if(data.type === "filter"){
         response = yield Api.Get(Endpoints.GetMasters + `?offset=${data.offset}&limit=${data.limit}&q=${data.values.query}`, true);
      }
      else{
         response = yield Api.Get(Endpoints.GetMasters + `?offset=${data.offset}&limit=${data.limit}`, true);
      }
      if (response) {
        yield put({
          type: types.GET_MASTERS_LIST_SUCCESS,
          data: { data: response.data, count: response.total },

        });
      } else
        yield put({
          type: types.GET_MASTERS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_MASTERS_LIST_FAIL,
      });
    }
  });
}

export function* AddMaster() {
  yield takeEvery(types.ADD_MASTER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

  
    const Data = new FormData();
    Data.append('email', data.fields.email);
    Data.append('phone', data.fields.phone);
    Data.append('en_biography', data.fields.en_biography);
    Data.append('en_first_name', data.fields.en_first_name);
    Data.append('en_last_name', data.fields.en_last_name);
    Data.append('fa_biography', data.fields.fa_biography);
    Data.append('photo', data.fields.image);
    Data.append('fa_first_name', data.fields.fa_first_name);
    Data.append('fa_last_name', data.fields.fa_last_name);
    Data.append('gender', data.fields.gender);
    Data.append('birthday', data.fields.birthday);
    Data.append('times', JSON.stringify(data.schedule));


    try {
      const response = yield Api.Post(Endpoints.AddMaster ,Data,true, "form");
      if (response) {
        yield put({
          type: types.ADD_MASTER_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.ADD_MASTER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.ADD_MASTER_FAIL,
      });
    }
  });
}

export function* DeleteMaster() {
  yield takeEvery(types.DELETE_MASTER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Delete(Endpoints.DeleteMaster + `/${data.master.id}`, true);
      if (response) {
        yield put({
          type: types.DELETE_MASTER_SUCCESS,
          data: data.master.id

        });
        data?.callBack();
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.DELETE_MASTER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.DELETE_MASTER_FAIL,
      });
    }
  });
}

export function* UpdateMaster() {
  yield takeEvery(types.UPDATE_MASTER, function* ({ data }) {
    
    const Data = new FormData();
    Data.append('email', data.fields.email);
    Data.append('phone', data.fields.phone);
    Data.append('en_biography', data.fields.en_biography);
    Data.append('en_first_name', data.fields.en_first_name);
    Data.append('en_last_name', data.fields.en_last_name);
    Data.append('fa_biography', data.fields.fa_biography);
    Data.append('photo', data.fields.image);
    Data.append('fa_first_name', data.fields.fa_first_name);
    Data.append('fa_last_name', data.fields.fa_last_name);
    Data.append('gender', data.fields.gender);
    Data.append('birthday', data.fields.birthday);
    Data.append('id', data.fields.id);
    Data.append('times', JSON.stringify(data.schedule));

    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Put(Endpoints.UpdateMaster + `/${data.fields.id}`,Data,true, "form");
      if (response) {
        yield put({
          type: types.UPDATE_MASTER_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.UPDATE_MASTER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.UPDATE_MASTER_FAIL,
      });
    }
  });
}

export function* GetMasterById() {
  yield takeEvery(types.GET_MASTER_BY_ID, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetMasterById + `/${data.id}`, false);
      if (response) {
        yield put({
          type: types.GET_MASTER_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_MASTER_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_MASTER_BY_ID_FAIL,
      });
    }
  });
}

export default function* MastersSagas() {
  yield all(
    [
      fork(GetMasters),
      fork(AddMaster),
      fork(DeleteMaster),
      fork(UpdateMaster),
      fork(GetMasterById)
    ]);
}
