export default {
  NEXT_PAYMENT_STEP :"NEXT_PAYMENT_STEP",
  PREVIOUS_PAYMENT_STEP :"PREVIOUS_PAYMENT_STEP",
  CHANGE_PAYMENT_STEP :"CHANGE_PAYMENT_STEP",

  ADD_TO_MEETING_LIST :"ADD_TO_MEETING_LIST",
  REMOVE_FROM_MEETING_LIST :"REMOVE_FROM_MEETING_LIST",

  VALIDATE_DISCOUNT_CODE: 'VALIDATE_DISCOUNT_CODE',
  VALIDATE_DISCOUNT_CODE_SUCCESS: 'VALIDATE_DISCOUNT_CODE_SUCCESS',
  VALIDATE_DISCOUNT_CODE_FAIL: 'VALIDATE_DISCOUNT_CODE_FAIL',
  VALIDATE_DISCOUNT_CODE_EXCEPTION: 'VALIDATE_DISCOUNT_CODE_EXCEPTION',

  GET_MASTER_AVAILABLE_TIME: 'GET_MASTER_AVAILABLE_TIME',
  GET_MASTER_AVAILABLE_TIME_SUCCESS: 'GET_MASTER_AVAILABLE_TIME_SUCCESS',
  GET_MASTER_AVAILABLE_TIME_FAIL: 'GET_MASTER_AVAILABLE_TIME_FAIL',
  GET_MASTER_AVAILABLE_TIME_EXCEPTION: 'GET_MASTER_AVAILABLE_TIME_EXCEPTION',

  BOOK_COURSE: 'BOOK_COURSE',
  BOOK_COURSE_SUCCESS: 'BOOK_COURSE_SUCCESS',
  BOOK_COURSE_FAIL: 'BOOK_COURSE_FAIL',
  BOOK_COURSE_EXCEPTION: 'BOOK_COURSE_EXCEPTION',

  PROCESS_PAYMENT: 'PROCESS_PAYMENT',
  PROCESS_PAYMENT_SUCCESS: 'PROCESS_PAYMENT_SUCCESS',
  PROCESS_PAYMENT_FAIL: 'PROCESS_PAYMENT_FAIL',
  PROCESS_PAYMENT_EXCEPTION: 'PROCESS_PAYMENT_EXCEPTION',
 
};
