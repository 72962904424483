import React, { useEffect , useState} from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  ListItemIcon
} from '@mui/material';
import { LogoContainer } from "../styles";
import logo from "../../../assets/images/logo.png";
import Arrow from "../../../assets/icons/arrowIcon";
import MenuIcon from "../../../assets/icons/MenuIcon";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Home,
  RoundaboutLeft,
  Javascript,
  Php,
  ContactMail,
  VerifiedUser,
  Language,
  Psychology
} from "@mui/icons-material";

const MyMenu = ({ t }) => {
  const lang = useSelector((state) => state.App.lang);
  const stateCourse = useSelector((state) => state.Course);
  const { categoriesListOptions } = stateCourse;
  const [mobileDrowpDownList, setMobileDrowpDownList] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);


  useEffect(() => {
    let list = [];
    categoriesListOptions.map((each ,index) => {
      list.push({
        fa_name : each.fa_name,
        en_name : each.en_name,
        value : each.value,
        onClick : handleMenuClose,
      })
    })
    setMobileDrowpDownList(list);
  }, [])

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmenuClick = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSubmenuOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerItems = [
    { label: t("home"), onClick: handleMenuClose, submenu: false, to: '/', icon:<Home /> },
    { label: t("programming"), onClick: handleMenuClose, submenu: false, to: '/courses?id=1', icon:<Home /> },
    { label: t("psychology"), onClick: handleMenuClose, submenu: false, to: '/courses?id=2' , icon:<Psychology />},
    { label: t("english"), onClick: handleMenuClose, submenu: false, to: '/courses?id=3', icon:<Language /> },
    { label: t("about"), onClick: handleMenuClose, submenu: false, to: '/about-us', icon:<VerifiedUser /> },
    { label: t("contact us"), onClick: handleMenuClose, submenu: false, to: '/contact-us' , icon:<ContactMail />},
  ];
  return (
    <AppBar position="static">
      <Toolbar>
        <div id="desktop-menu">
          <LogoContainer src={logo} style={{"cursor":"pointer", "margin-left":"2rem"}} onClick={(e)=>window.location.href="/"} />
          <Button color="inherit" className='menu-btn' onClick={(e)=>window.location.href="/"}>{t("home")}</Button>
          <Button color="inherit" className='menu-btn' onClick={(e)=>window.location.href="/search?q="}>{t("search")}</Button>
          {/*<Link to='/'>{t("home")}</Link>*/}
          <Button color="inherit" className='menu-btn' sx={{"minWidth":"10rem"}} onClick={handleMenuClick}>
            {t("courses")}
            <Arrow id="arrow-down" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{sx: {width: '12rem', direction:"rtl"}}}
            MenuListProps={{sx: {width: 'fit-content'}}}
            // className={"min-ali"}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
          >
            {categoriesListOptions?.map((item, index) => {
              return (
                <MenuItem sx={{"minWidth":"10rem","min-width":"fit-content"}} onClick={(e)=>window.location.href='/courses?id=' + item?.value}  key={index}>
                  {lang === "fa" ? item?.fa_name : item?.en_name}
                </MenuItem>
              )
            })}

          </Menu>
          <Button color="inherit" className='menu-btn'  onClick={(e)=>window.location.href="/contact-us"}>{t("contact us")}</Button>
          <Button color="inherit" className='menu-btn'  onClick={(e)=>window.location.href="/about-us"}>{t("about")}</Button>

        </div>

        <div id="mobile-menu">

          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <Drawer PaperProps={{ style: { width: '50%', "direction":"rtl" } }}  anchor={lang === "fa" ? "right" : "left"} open={mobileOpen} onClose={handleDrawerToggle}>
            <List>
              {drawerItems.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem  button onClick={item.onClick}>

                    {item.submenuItems && item.submenuItems.length > 0 && (
                      item.submenuOpen ? <Box id="arrow-up"><Arrow /></Box>
                        : <Box id="arrow-down"><Arrow /></Box>
                    )}
                    <Link to={item.to}>
                      {/*<ListItemIcon title={item.label} content={"exit"} primary={item.label} />*/}
                      <ListItemIcon> {item.icon} </ListItemIcon>
                      {/*<ListItemText  primary="CSS" />*/}
                      <ListItemText primary={item.label} />
                    </Link>
                  </ListItem>
                  <hr/>
                  {item.submenuItems && item.submenuItems.length > 0 && (
                    <Collapse in={item.submenuOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.submenuItems.map((submenuItem, subIndex) => (
                          <ListItem button key={subIndex} onClick={(e)=>window.location.href='/courses?id=' + submenuItem?.value}>
                            {/*<Link to={{ pathname:   }} >*/}
                              <ListItemText primary={lang === "fa" ? submenuItem.fa_name : submenuItem.en_name } />
                            {/*</Link>*/}
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Drawer>
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default withTranslation()(MyMenu);