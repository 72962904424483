import Keys from "../../storage/NameSpace";
import storage from "../../storage";

export const apiConfig = (type = "", needAuth = true) => {
  let config = {};
  if (needAuth === true) {
    if (type === "xlsx" || type === "pdf") {
      config = {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${storage.get(Keys.token)}`,
        },
      };
    }else if (type === "form") {
      config = {
        headers: {
          "Content-Type": "multipart/formdata",
          Authorization: `Bearer ${storage.get(Keys.token)}`,
        },
      };
      } else {
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage.get(Keys.token)}`,
        },
      };
    }
  } else {
    if (type === "login") {
      config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
    } else {
      config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    }
  }

  return config;
};
