import React, { useEffect, useState } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import { Scheduler } from "@aldabil/react-scheduler";
import { CalendarContainer } from "./styles";
import PrivateCourseActions from '../../../../redux/PrivateCourse/Actions';
import { useDispatch, useSelector } from 'react-redux';
const Calendar = () => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.App.lang);
    const calendarListFa = useSelector((state) => state.PrivateCourse.calendarListFa);
    const calendarListEn = useSelector((state) => state.PrivateCourse.calendarListEn);
    const calendarListLoading = useSelector((state) => state.PrivateCourse.calendarListLoading);

    useEffect(() => {
        dispatch(PrivateCourseActions.getCalendarList({}));
    }, []);

    return (
        <MainLayout >
            {calendarListLoading === false ?
                <CalendarContainer>
                    <Scheduler
                        className="Schedule-wrapper"
                        view="month"
                        events={lang === "fa" ? calendarListFa : calendarListEn}
                        onClick = {(e) => e.preventDefault()}
                    /> 
                </CalendarContainer> : <div className='loader'></div>
            }
        </MainLayout>
    )
}
export default Calendar;