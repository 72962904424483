import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const CoursesWrapprr = styled(Col)`
    width:100%;
    margin-top:75px;
    background-color : #f7f7f7;
    input{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    .more_button{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: 1px solid ${(props) => props.theme.btnColor};
        width: 200px;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
     }
`;
export const CourseContainer = styled(Col)`
    width:100%;
    min-height:330px;
    justify-content: flex-start;
    padding: 20px 0;

`;
export const SectionContainer = styled(Col)`
    padding: 20px 0;
    width:80%;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

     @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;

export const FilterConatiner = styled(Row)`
    width:79%;
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    margin: 30px 0;
    padding: 20px 0;
    box-shadow:0px 0px 3px 0px #b9b9b9;
    .filter_container{
        height:45px;
        width:100%;
        border-radius: 5px;
        justify-content :flex-end;
        display:flex;
    }
    #search-input{
        height:45px;
        border-radius: 8px;
        border: 2px solid #3D54A8;
        background: #FFF;

    }

    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
  
`;