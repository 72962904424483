import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const CommentWrapper = styled(Col)`
  width :90%;
  border:2px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
  *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
  #title_container {
    height: 25px;
    border-left: ${(props) => props.lang === "fa" ? "2px solid #ccc" : "none"};
    border-right: ${(props) => props.lang === "en" ? "2px solid #ccc" : "none"};

    padding-left: ${(props) => props.lang === "fa" ? "10px" : "0"};
    padding-right: ${(props) => props.lang === "en" ? "10px" : "0"};
  }
  #rate_container , #clock_container {
    padding-right: ${(props) => props.lang === "fa" ? "10px" : "0"};
    padding-left: ${(props) => props.lang === "en" ? "10px" : "0"};
  }
  #clock_container{
    padding-top:3px;
  }

`;
export const CommentContainer = styled(Row)`
width :96%;
 padding:15px;
`;
export const ProfileContainer = styled(Row)`
  width :80px;
  background-image : url(${props => props.src});
  background-repeat:no-repeat;
  background-position: top;
  background-size: contain;
  height:80px;
  border-radius :50%;
  border:1px solid #ccc;
`;
export const CommentTextConatainer = styled(Row)`
 
`;


