
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 3.5C8.32969 3.5 9 2.82969 9 2C9 1.17031 8.32969 0.5 7.5 0.5H4.5C2.01562 0.5 0 2.51562 0 5V17C0 19.4844 2.01562 21.5 4.5 21.5H7.5C8.32969 21.5 9 20.8297 9 20C9 19.1703 8.32969 18.5 7.5 18.5H4.5C3.67031 18.5 3 17.8297 3 17V5C3 4.17031 3.67031 3.5 4.5 3.5H7.5ZM23.6484 11.8156C23.8734 11.6047 24 11.3094 24 11C24 10.6906 23.8734 10.3953 23.6484 10.1844L16.8984 3.80937C16.5703 3.5 16.0922 3.41563 15.6797 3.59375C15.2672 3.77187 15 4.17969 15 4.625V8H9C8.17031 8 7.5 8.67031 7.5 9.5V12.5C7.5 13.3297 8.17031 14 9 14H15V17.375C15 17.825 15.2672 18.2281 15.6797 18.4062C16.0922 18.5844 16.5703 18.5 16.8984 18.1906L23.6484 11.8156Z" fill={pathFill}/>
      </svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;