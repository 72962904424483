import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';
import { toast } from "react-toastify";


const CheckoutForm2 = ({ productId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const handleSubmit = async (e) => {
    
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      // Send the payment method ID to your server for processing
      console.log(result.paymentMethod.id);
    }
  };

return (
  <form onSubmit={handleSubmit} id="subscription_form">
    <PaymentElement />
    <button id="subscription_btn" size={"large"} color={"primary"} sx={{ "marginTop": "2rem" }} type="submit" disabled={!stripe || !elements} onClick={handleSubmit}>
      Start Subscription
    </button>
    {/* Show error message to your customers */}
    {errorMessage && <div color={"error"} fontWeight={"bold"} sx={{ "paddingTop": "1rem" }}>{errorMessage}</div>}
  </form>
);
  };


export default function CheckoutForm({ productId }) {

  const stripePromise = loadStripe("pk_test_51NuV4jBvDGofGCnIfZqnmD63nypHvWKEchmfRlheoRnnIMpiqpUsrnv39irkyozHHg9lMLk07yVcDaSoWnr4qJKC00Dw9c23l5");

  return (
    <Elements stripe={stripePromise} mode="test">
      <CheckoutForm2 productId={productId} />
    </Elements>
  );
}
