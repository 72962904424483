import React from 'react';
import { useSelector } from 'react-redux';

function CameraIcon() {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="30" height="30" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="white" fill-opacity="0.8" />
            <path d="M18.9891 15.0375L18.5016 16.5H15C13.3453 16.5 12 17.8453 12 19.5V31.5C12 33.1547 13.3453 34.5 15 34.5H33C34.6547 34.5 36 33.1547 36 31.5V19.5C36 17.8453 34.6547 16.5 33 16.5H29.4984L29.0109 15.0375C28.7062 14.1188 27.8484 13.5 26.8781 13.5H21.1219C20.1516 13.5 19.2938 14.1188 18.9891 15.0375ZM24 30C21.5156 30 19.5 27.9844 19.5 25.5C19.5 23.0156 21.5156 21 24 21C26.4844 21 28.5 23.0156 28.5 25.5C28.5 27.9844 26.4844 30 24 30Z" fill="#3D54A8" />
        </svg>
    );
}

export default CameraIcon;
