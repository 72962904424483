import React from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"

const Category = () => {
    return(
        <MainLayout>
        </MainLayout>
    )
}

export default Category;