
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9933 9.98047C21.9933 10.6836 21.4203 11.2344 20.771 11.2344H19.5487L19.5755 17.4883C19.5755 17.5977 19.5678 17.6992 19.5564 17.8047V18.4375C19.5564 19.3008 18.8727 20 18.0285 20H17.4174C17.3754 20 17.3334 19.9648 17.2913 19.9961C17.2379 19.9648 17.1844 20 17.1309 20H14.9728C14.1287 20 13.445 19.3008 13.445 18.4375V15C13.445 14.3086 12.8988 13.75 12.2227 13.75H9.77818C9.10211 13.75 8.55591 14.3086 8.55591 15V18.4375C8.55591 19.3008 7.8722 20 7.02807 20H4.89291C4.83562 20 4.77832 19.9961 4.72103 19.9922C4.67519 19.9961 4.62936 20 4.58352 20H3.97239C3.12864 20 2.44455 19.3008 2.44455 18.4375V14.0625C2.44455 14.0273 2.44569 13.9883 2.44798 13.9531V11.2344H1.22418C0.535508 11.2344 0 10.6836 0 9.98047C0 9.62891 0.114741 9.31641 0.382342 9.04297L10.1754 0.313125C10.4428 0.0391406 10.7484 0 11.0157 0C11.2831 0 11.5887 0.0782812 11.8217 0.273984L21.5731 9.04297C21.8787 9.31641 22.0353 9.62891 21.9933 9.98047Z" fill={pathFill}/>
      </svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;