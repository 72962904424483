import React from 'react';
import InstructorView from "../../views/instructor";


const Instructor = () => {

    return (
        <>
            <InstructorView />
        </>
    )
}
export default Instructor;