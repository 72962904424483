import React from "react";
import {
  SubSystemWrapper,
  SubSystemContainer,
  SubSystemItemContainer,
  SubSystemItem,
  SubSystemMenusContainer,
  SubSystemMenusItem,
  IconContainer,
  ExitContainer,
  AppLogoContainer
} from "./style";
import ExitIcon from "../../../assets/icons/ExitIcon";
import OrdersIcon from "../../../assets/icons/OrdersIcon";
import AnnouncmentIcon from "../../../assets/icons/AnnouncmentIcon";
import AccountIcon from "../../../assets/icons/AccountIcon";
import DashboardIcon from "../../../assets/icons/DashboardIcon";
import FavoriteIcon from "../../../assets/icons/FavoriteIcon";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import CourseIcon from "../../../assets/icons/CourseIcon";
import ChevronIcon from ".././../../assets/icons/ChevronIcon";
import UserIcon from "../../../assets/icons/UserIcon";
import ChangePasswordIcon from "../../../assets/icons/ChangePasswordIcon";
import MasterIcon from "../../../assets/icons/MasterIcon";
import CategoriesIcon from "../../../assets/icons/CategoriesIcon";

import { useSelector, useDispatch } from "react-redux";
import LoginActions from "../../../redux/Login/Actions"
import { Grid, Box } from "@mui/material";
import MenuIcon from "../../../assets/icons/MenuIcon";
import logo from "../../../assets/images/dashboardlogo.png";
import avatar from "../../../assets/images/avatar.png";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
const { REACT_APP_API_URL } = process.env;

const SubSystemMenu = ({
  activeSystem,
  activeSubSystem,
  selectSubSystemHandler,
  activeSubSystemMenu,
  selected,
  toggleHandler,
  collapse,
  setCollapse,
  t
}) => {
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(LoginActions.logOut());
  }
  const appState = useSelector((state) => state.App);
  const { lang, theme } = appState;
  const loginState = useSelector((state) => state.Login);
  const { role } = loginState;
  const ProfileState = useSelector((state) => state.Profile);
  const { userInfo } = ProfileState;

  const SubSystemIcon = (id) => {
    const isActive = id === activeSubSystem.id;
    const pathFill = isActive === true ? theme.white : theme.white;
    switch (id) {
      case "01":
        return <DashboardIcon pathFill={pathFill} />;
      case "02":
        return <AccountIcon pathFill={pathFill} />;
      case "03":
        return <CourseIcon pathFill={pathFill} />;
      case "04":
        return <FavoriteIcon pathFill={pathFill} />;
      case "05":
        return <AnnouncmentIcon pathFill={pathFill} />;
      case "06":
        return <OrdersIcon pathFill={pathFill} />;
      case "07":
        return <CalendarIcon pathFill={pathFill} />;
      case "08":
        return <ChangePasswordIcon pathFill={pathFill} />;
      //////////////////////////////////////////
      case "11":
        return <MasterIcon pathFill={pathFill} />;
      case "12":
        return <UserIcon pathFill={pathFill} />;
      case "13":
        return <CourseIcon pathFill={pathFill} />;
      case "14":
        return <CategoriesIcon pathFill={pathFill} />;
      case "16":
        return <OrdersIcon pathFill={pathFill} />;
      case "18":
        return <FavoriteIcon pathFill={pathFill} />;
      default:
        break;
    }
  };
  return (
    <SubSystemWrapper collapse={collapse} src={logo}>
      {collapse === false ?
        <Grid container className="dashboard">
          <Grid id="logo-container" item xs={3} className="clickable">
            <Link to='/'><AppLogoContainer src={logo} height="25px" /></Link>
          </Grid>
          <Grid item xs={7}>{t("dashboard")}</Grid>
          <Grid item xs={2}><Box className="clickable" onClick={() => window.innerWidth > 600 ? setCollapse(!collapse) : setCollapse(collapse)}>
            <KeyboardArrowRightIcon /></Box></Grid>
        </Grid> :
        <Grid container>
          <Grid items xs={12} display="flex" justifyContent="center">
            <Box className="clickable" onClick={() => window.innerWidth > 600 ? setCollapse(!collapse) : setCollapse(collapse)}>
              <MenuIcon fill={theme.white} />
            </Box></Grid>
        </Grid>
      }

      <Grid container my={3} className="profile-container">
        <Grid id="logo-container" item xs={4}>
          <AppLogoContainer src={REACT_APP_API_URL + "/" + userInfo?.photo} height="50px" />
        </Grid>
        {collapse === false &&
          <Grid item xs={8} display="flex" alignItems="center" color="white">
            {lang === "fa" ? userInfo.fa_first_name + " " + userInfo.fa_last_name : userInfo.en_first_name + " " + userInfo.en_last_name}
          </Grid>}
      </Grid>
      {
        window.innerWidth <= 600 && <Link to='/'><AppLogoContainer src={logo} height="25px" /></Link>
      }
      <SubSystemContainer >
        {activeSystem?.subSystem.map((each) => {
          return (
            each.visible === true && role === each.role && (

              <SubSystemItemContainer title={each.title} key={each.id}>
                <SubSystemItem
                  lang={lang}
                  collapse={collapse}
                  to={each.menus.length > 0 ? "#" : each.path}
                  active={activeSubSystem.id === each.id && selected === each.id}
                  onClick={() => {

                    toggleHandler(each.id);
                    window.innerWidth > 600 ? setCollapse(!collapse) : setCollapse(collapse);
                    selectSubSystemHandler(each);
                  }}
                >
                  {SubSystemIcon(each.id)}
                  {collapse === false && <span>{lang === "fa" ? each.displayTitle : each.title}</span>}
                  <IconContainer className="o__subSystem__chevronIcon">
                    {each.hasMenus && <ChevronIcon />}
                  </IconContainer>
                </SubSystemItem>
              </SubSystemItemContainer>
            )
          );
        })}

      </SubSystemContainer>

      {/* {window.innerWidth <= 600 && <ExitContainer collapse={collapse} lang={lang}>
        <DashboardIcon pathFill={theme.white} />
        {collapse === false && <span>{t("home")}</span>}
      </ExitContainer>} */}

      <ExitContainer onClick={() => Logout()} collapse={collapse} lang={lang}>
        <ExitIcon pathFill={theme.white} />
        {collapse === false && <span>{t("exit")}</span>}
      </ExitContainer>


    </SubSystemWrapper>
  );
};

export default withTranslation()(SubSystemMenu);
