import React, { useState, useEffect } from 'react';
import { CalendarWrapprr } from "../styles"
import { Box, Pagination, Grid } from "@mui/material";
import ToggleableBox from "../../../shared/toggleBox";
import { withTranslation } from "react-i18next";
import { chunkArray, addOrRemove } from "../../../utils/index";
import Calendar from "./calendar"
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import PaymentActions from "../../../redux/Payment/Actions";
import { makeStyles } from "@mui/styles";
import { group } from "../../../utils/index";
import { toast } from 'react-toastify';
import {data1} from "./helpers"

const SelectTime = ({ t, data }) => {
    const lang = useSelector((state) => state.App.lang);
    const masterTimes = useSelector((state) => state.Payment.masterTimes);
    const meetingList = useSelector((state) => state.Payment.meetingList);

    const [selectedValues, setSelectedValues] = useState([]);
    const [calendar, setCalendar] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(data)
        const params = new URLSearchParams(window.location.search);
        dispatch(PaymentActions.getMasterAvailableTime({ course_id: parseInt(params.get("id") )}));
    }, []);

    useEffect(() => {
        if (masterTimes.length > 0) {
            makeCalendarData();
        }
    }, [masterTimes]);

    const makeCalendarData = () => {
        let result = group(masterTimes, "date");
        result = chunkArray(result, 6);
        setCalendar(result);
        setTotalPages(result.length);
    }

    const handlePageChange = (e, value) => {
        setCurrentPage(value);
    }

    const handleGroupChange = (groupIndex, value) => {
        let name="";
        name = "time"+ groupIndex + value.target.name;
        setSelectedValues({
            ...selectedValues,
            [name]: value.target.checked,
        });
        
        addToList(value.target.name, groupIndex);
    };
    const addToList = (item, groupIndex) => {
        let value = Number(item);

        if (meetingList.findIndex(each => each.id === value) !== -1) {
            dispatch(PaymentActions.removeFromMeetingList({ data: { "id": value } }));
        }
       
        else {
            let result = masterTimes?.filter(each => each.id === value);
            if (meetingList.length <= data.sessions - 1) {
                let metting = [];
                metting.push({ ...result[0], groupIndex });
                dispatch(PaymentActions.addToMeetingList({ data: metting[0] }));
            }
            else {
                let message = t("The number of selected sessions is more than allowed");
                toast.warning(message, {
                    position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
                });
            }
        }
    }

    const removeFromList = (item) => {
        let name="";
        name = "time"+ item.groupIndex + item.id;

        setSelectedValues({
            ...selectedValues,
            [name]: false,
        });
    }

    const useStyles = makeStyles((theme) => ({
        root: {

            '& .MuiPaginationItem-page.Mui-selected': { backgroundColor: '#3D54A8', color: "#fff" },
            '& .MuiPaginationItem-root svg': {
                transform: lang === 'fa' ? 'rotate(180deg)' : 'none',
            },
        },
    }));

    const classes = useStyles();

    return (
        <CalendarWrapprr>
            <Box className="choose-time">{t("Specify the date and time of the meetings")}</Box>
            <Box id="choosen-time">({t("number of selected sessions")} : {meetingList.length} /{data.sessions})</Box>
            <ToggleableBox title={t("my meetings")}>
                <Grid container>
                    {meetingList?.map((each, index) => {
                        return (
                            <Grid items xs={12} sm={12} md={6} p={1} key={index}>
                                <Grid xs={12} container className="meeting-container">
                                    <Grid items xs={3}>{each.week_day_name}</Grid>
                                    <Grid items xs={3}>{each.week_day} {each.month_name}</Grid>
                                    <Grid items xs={5} className="choose-time">{t("hour")} : ({each.start_time}-{each.end_time})</Grid>
                                    <Grid items xs={1}>
                                        <Box onClick={() => { removeFromList(each); dispatch(PaymentActions.removeFromMeetingList({ data: { "id": each.id } })) }}>
                                            <DeleteIcon />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </ToggleableBox>
            <ToggleableBox title={t("educational calendar")}>
                {calendar.map((each, index) => {
                    return (
                        currentPage === index + 1 &&
                        <Calendar
                            data={each}
                            selectedValues={selectedValues}
                            handleGroupChange={handleGroupChange}
                        />
                    )
                })}
                <Box display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        className={classes.root}
                        direction="rtl"
                    />
                </Box>

            </ToggleableBox>
        </CalendarWrapprr>
    )
}
export default withTranslation()(SelectTime);