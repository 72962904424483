import Avatar from '../../../../assets/icons/AccountIcon';
import LogoutIcon from '../../../../assets/icons/ExitIcon';

export const UserMenuConfig = [
  {
    id: 0,
    title: 'حساب کاربری',
    icon: Avatar,
  },
  {
    id: 1,
    title: 'خروج',
    icon: LogoutIcon,
  },
];
