import React, { useState } from 'react';
import LoginRegisterIntro from '../../views/LoginRegister/LoginRegisterIntro';
import LoginRegisterContent from '../../views/LoginRegister/LoginRegisterContent';
import { Grid } from "@mui/material";
import { ToastContainer } from 'react-toastify';

const LoginRegister = () => {
    const [mode, setMode] = useState('login');

    return (
        <Grid container height="100vh">
            <Grid item xl={8} lg={8} md={8} >
                <LoginRegisterIntro mode={mode} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}  height="100%">
                <LoginRegisterContent mode={mode} setMode={setMode} />
            </Grid>
          <ToastContainer />

        </Grid>
    )
}
export default LoginRegister;

