import styled from "styled-components";
import Col from "../../../../kit/Column";


export const BannerWrapper = styled(Col)`
   height: calc((100vw) * 0.5);
   background-image : url(${props => props.cover});
   background-repeat:no-repeat;
   background-position: top;
   background-size: cover;
   width: 89%;
   border-radius: 10px;
   @media screen and (min-width: 1700px) and (max-width :2000px) {
      width : 70%;
      background-size: contain;
      height: calc((100vw) * 0.38);

     }
  @media screen and (min-width: 2000px)  {
      width : 65%;
      background-size: contain;
      height: calc((100vw) * 0.38);
  }
`;

