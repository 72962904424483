import React, { useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { CalendarWrapprr, CalendarConatiner } from "../styles"
import { Grid, Box, FormControlLabel,  FormControl } from "@mui/material";

import { withTranslation } from "react-i18next";

const Calendar = ({ t, data, handleGroupChange, selectedValues }) => {

    return (
        <Grid container id="calendar">
            {data?.map((each, index) => {
                
                return (
                    <Grid item xs={12} sm={6} md={4} p={2} key={index}>
                        <FormControl component="fieldset">
                            <FormGroup
                             onChange={(e) => handleGroupChange(index, e)}
                              >
                                <CalendarConatiner>
                                    <Grid container p={2}>
                                        <Grid container item xs={12} id="date-container">
                                            <Grid item xs={6}>{each[0].date}</Grid>
                                            <Grid item xs={6} display="flex" justifyContent="end">{each[0].week_day_name}</Grid>
                                        </Grid>
                                        <Grid container item xs={12}>

                                            {each.map((item, ind) => {
                                                return (
                                                    <Grid item xs={6} p={1} key={ind}>

                                                        <Box className="time-container">
                                                            <FormControlLabel
                                                            value={item.id}
                                                                control={
                                                                    <Checkbox
                                                                        checked ={selectedValues["time"+index+item?.id] !== undefined ? selectedValues["time"+index+item?.id] : false}
                                                                        name={item.id}
                                                                    />}
                                                                label={item.start_time + "-" + item.end_time}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                </CalendarConatiner>

                            </FormGroup>
                        </FormControl>
                    </Grid>
                )
            })}
        </Grid >

    )
}
export default withTranslation()(Calendar);