import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const TitleWrapprr = styled(Row)`
    width:100%;
    padding:15px 0px;
    color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
    font-family: ${(props) => (props.theme.fontBold)} !important;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
`;
export const TitleContainer = styled(Row)`
    font-size: ${(props) => props.theme.fontSizeXl};
    justify-content : flex-start;
    padding:20px 10px;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: ${(props) => props.lang === "fa" ? "right" : "left"};
    background-size: contain;
    font-family: ${(props) => (props.theme.fontBold)} !important;

`;
export const MoreContainer = styled(Row)`
    font-size: ${(props) => props.theme.fontSizeMd};
    width:100%;
    justify-content : flex-end;
    #svg-container{
        transform:${(props) => props.lang === "fa" && "rotate(180deg)" };
    }
    svg{  
        width:35px;
        height:20px;
    }
    svg path {
        stroke : ${(props) => props.color ? props.color : props.theme.secondaryFontColor};

    }
    a{color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};}
`;
