import types from './Types';
import DelegateAction from '../ActionDelegator';

const DiscountActions = {
  setShowDiscountDatailModal: DelegateAction(types.SET_SHOW_DISCOUNT_DETAIL_MODAL),
  getDiscounts : DelegateAction(types.GET_DISCOUNTS_LIST),
  addDiscount : DelegateAction(types.ADD_DISCOUNT),
  deleteDiscount : DelegateAction(types.DELETE_DISCOUNT),
  updateDiscount : DelegateAction(types.UPDATE_DISCOUNT),
  getDiscountById : DelegateAction(types.GET_DISCOUNT_BY_ID),
  setShowDeleteDiscountModal : DelegateAction(types.SET_SHOW_DELETE_DISCOUNT_MODAL)
};

export default DiscountActions;
