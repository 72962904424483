export const prepareSelectData = (type, info, moreOption = "") => {

    let exportInformation = [];
  
    if (type === "categories") {
      for (let row of info) {
          exportInformation.push({
            value: row.id,
            label: row.name,
          });
      }
    } 
    else if (type === "category") {
        for (let row of info) {
          exportInformation.push({
            value: row.id,
            fa_name: row.fa_name,
            en_name: row.en_name,
            label : row.fa_name
          });
        }
      }
      else if (type === "master") {
        for (let row of info) {
          exportInformation.push({
            value: row.id,
            label: row.fa_first_name +" "+ row.fa_last_name,
          });
        }
      }
    
    return exportInformation;
  }

  export const chunkArray = (array, size) => {
    var results = [];
    while (array.length) {
        results.push(array.splice(0, size));
    }
    return results;
}
  export const addOrRemove = (arr, element) => {
        if (!arr.includes(element)) {
            arr.push(element);
        } else {
            arr.splice(arr.indexOf(element), 1);
        }
        return arr;
    }
  export  const group = (arr, key) => {
      return [...arr.reduce((acc, o) =>
          acc.set(o[key], (acc.get(o[key]) || []).concat(o))
          , new Map).values()];
  }

  export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }