import {
    all,
    takeEvery,
    put,
    fork,
  } from "redux-saga/effects";
  import types from "./Types";
  import Endpoints from "./Endpoints";
  import Api from "../../services/Api";
  
  
  export function* GetUserInfo() {
    yield takeEvery(types.GET_USER_INFO, function* ({ data }) {
      try {
        const response = yield Api.Get(Endpoints.GetUserInfo, true);
        if (response) {
          yield put({
            type: types.GET_USER_INFO_SUCCESS,
            data: response

          });
        } else
          yield put({
            type: types.GET_USER_INFO_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.GET_USER_INFO_FAIL,
        });
      }
    });
  }


  export function* UpdateUserInfo() {
    yield takeEvery(types.UPDATE_USER_INFO, function* ({ data }) {
      let body ={};
      if(data.lang === "fa"){
        body ={
          "fa_first_name": data.values.firstName,
           "fa_last_name": data.values.lastName,
           "email": data.values.email,
           "birthday": data.values.birthday,
           "time_zone": data.values.zone,
           "phone": data.values.phone,
           "gender": data.values.gender
       }
      }
      else{
        body ={
          "en_first_name": data.values.firstName,
           "en_last_name": data.values.lastName,
           "email": data.values.email,
           "birthday": data.values.birthday,
           "time_zone": data.values.zone,
           "phone": data.values.phone,
           "gender": data.values.gender
       }
      }
       
      try {
        const response = yield Api.Put(Endpoints.UpdateUserInfo ,body,true);
        if (response) {
          yield put({
            type: types.UPDATE_USER_INFO_SUCCESS,
            data: response,

          });
        } else
          yield put({
            type: types.UPDATE_USER_INFO_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.UPDATE_USER_INFO_FAIL,
        });
      }
    });
  }

  export function* ChangeUserPassword() {
    yield takeEvery(types.CHANGE_USER_PASSOWRD, function* ({ data }) {
      
      let body ={
        "new_password": data.values.newPassword,
        "old_password": data.values.currentPassword,
      }
      try {
        const response = yield Api.Put(Endpoints.ChangeUserPassword ,body,true);
        if (response) {
          yield put({
            type: types.CHANGE_USER_PASSOWRD_SUCCESS,
            data: response.data,

          });
        } else
          yield put({
            type: types.CHANGE_USER_PASSOWRD_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.CHANGE_USER_PASSOWRD_FAIL,
        });
      }
    });
  }

  export function* UpdateUserProfile() {
    yield takeEvery(types.UPDATE_USER_PROFILE, function* ({ data }) {
     
      try {
        const Data = new FormData();
        Data.append('file', data.file);
        const response = yield Api.Patch(Endpoints.UpdateUserProfile ,Data,true, "form");
        if (response) {
          yield put({
            type: types.UPDATE_USER_PROFILE_SUCCESS,
            data: response

          });
        } else
          yield put({
            type: types.UPDATE_USER_PROFILE_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.UPDATE_USER_PROFILE_FAIL,
        });
      }
    });
  }

  export function* GetNotifications() {
    yield takeEvery(types.GET_NOTIFICATIONS, function* ({ data }) {
      try {
        const response = yield Api.Get(Endpoints.GetNotifications, true);
        if (response) {
          yield put({
            type: types.GET_NOTIFICATIONS_SUCCESS,
            data: response

          });
        } else
          yield put({
            type: types.GET_NOTIFICATIONS_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.GET_NOTIFICATIONS_FAIL,
        });
      }
    });
  }
  
  export default function* ProfileSagas() {
    yield all(
      [
        fork(GetUserInfo),
        fork(UpdateUserInfo),
        fork(ChangeUserPassword),
        fork(UpdateUserProfile),
        fork(GetNotifications)
      ]);
  }
  