import styled from 'styled-components';
import Col from "../../kit/Column"

export const HomeWrapprr = styled(Col)`
background-color:  ${props => props.theme.baseBackGroundColor};

 width:100%;
`;
export const ContentWrapprr = styled(Col)`
 background-color:  ${props => props.theme.baseBackGroundColor};
 width:90%;
 padding:30px 0;
 @media screen and (min-width: 1700px) and (max-width :2000px) {
    width : 70%;
   }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
