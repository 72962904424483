import React, { useEffect, useState } from "react";
import { Modal, Box, Grid, MenuItem, Select } from "@mui/material"
import { withTranslation } from "react-i18next";
import { ModalConatiner } from "../../../../views/globalstyles";
import CloseIcon from "../../../../assets/icons/DeleteIcon";
import { Formik } from "formik";
import { FormInput } from "../../../../kit/Form";
import { StyledTextArea } from "../../../../kit/Form";
import FormController from "../../../../controllers/FormController";
import { VerifyValidationCourse } from "../../../../validation/Schemas/VerifyValidationCourse";
import { ActiveListOptions, durationListOptions, CourseTypeOptions } from "./helpers";

// Make sure to import default styles.
// This only needs to be done once; probably during bootstrapping process.
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import VirtualizedSelect from 'react-virtualized-select';
import { useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa";
const { REACT_APP_API_URL } = process.env;



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const MasterDetailModal = ({
    open,
    onClose,
    onConfirm,
    title,
    fields,
    setFields,
    t,
    mode
}) => {
    const stateCourse = useSelector((state) => state.Course);
    const { categoriesListOptions } = stateCourse;
    const mastersListOptions = useSelector((state) => state.Master.mastersListOptions);
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (fields.photo !== null) {
            setFile(URL.createObjectURL(fields.photo));
        }
    }, [fields]);

    useEffect(() => {
        if (mode === "edit" && fields.image && fields.photo === null) {
            setFile(REACT_APP_API_URL + "/" + fields.image);
        }
    }, [fields]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...fields,
            }}
            onSubmit={(values, actions) => {
                onConfirm(values, actions);
            }}
            validationSchema={VerifyValidationCourse}
        >
            {(formik) => (
                <Modal
                    open={open}
                    onClose={() => {setFile(null);onClose(formik)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <ModalConatiner lang={"fa"}>
                            <Grid container>
                                <Grid item container xs={12} id="modal_title">

                                    <Grid item xs={11} display="flex" justifyContent="start">
                                        {title}
                                    </Grid>
                                    <Grid item xs={1} display="flex" justifyContent="end"
                                        onClick={() => onClose(formik)} className="clickable">
                                        <CloseIcon />
                                    </Grid>
                                </Grid>

                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نام فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_title && formik.touched.fa_title ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_title}
                                                    id="fa_title"
                                                    name="fa_title"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_title:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام فارسی"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">نام انگلیسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_title && formik.touched.en_title ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_title}
                                                    id="en_title"
                                                    name="en_title"
                                                    onChange={(e) => {FormController.onChange(e, formik); setFields({...fields,en_title:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"نام  انگلیسی"}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">تعداد جلسات</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.sessions && formik.touched.sessions ? " warning-border" : "")
                                                    }
                                                    value={formik.values.sessions}
                                                    id="sessions"
                                                    name="sessions"
                                                    onChange={(e) => {FormController.onChange(e, formik); setFields({...fields,sessions:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"تعداد جلسات"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">انتخاب مدت زمان</Box>
                                                <VirtualizedSelect
                                                    value={formik.values.duration}
                                                    name="duration"
                                                    id="duration"
                                                    placeholder="انتخاب مدت زمان"
                                                    onChange={(selected) => {

                                                        if (selected !== null && selected !== undefined && selected !== 0) {
                                                            FormController.onSelect(selected, "duration", formik, null, true);
                                                            setFields({...fields,duration:selected.value})
                                                        }
                                                    }}
                                                    maxHeight={150}
                                                    options={durationListOptions}
                                                    className={
                                                        "custom_input" + (formik.errors.duration && formik.touched.duration ? " warning-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">انتخاب وضعیت</Box>
                                                <VirtualizedSelect
                                                    value={formik.values.status}
                                                    name="status"
                                                    id="status"
                                                    placeholder="انتخاب وضعیت"
                                                    onChange={(selected) => {

                                                        if (selected !== null && selected !== undefined && selected !== 0) {
                                                            FormController.onSelect(selected, "status", formik, null, true);
                                                            setFields({...fields,status:selected.value})
                                                        }
                                                    }}
                                                    maxHeight={150}
                                                    options={ActiveListOptions}
                                                    className={
                                                        "custom_input" + (formik.errors.status && formik.touched.status ? " warning-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">انتخاب استاد</Box>
                                                <VirtualizedSelect
                                                    value={formik.values && formik.values.master_id}
                                                    name="master_id"
                                                    id="master_id"
                                                    placeholder="انتخاب استاد"
                                                    onChange={(selected) => {
                                                        if (selected !== null && selected !== undefined && selected !== 0) {
                                                            FormController.onSelect(selected, "master_id", formik, null, true);
                                                            setFields({...fields,master_id:selected.value})
                                                        }
                                                    }}
                                                    maxHeight={250}
                                                    options={mastersListOptions}
                                                    className={
                                                        "custom_input" + (formik.errors.master_id && formik.touched.master_id ? " warning-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">تاریخ شروع</Box>
                                                <DatePicker
                                                    value={formik.values.start_date_fa}
                                                    calendar={persian}
                                                    locale={persian_fa}
                                                    className={
                                                        "input_file" + (formik.errors.start_date_fa && formik.touched.start_date_fa ? " warning-border" : "")
                                                    }
                                                    name="start_date_fa"
                                                    onChange={(e) => {FormController.onChangeDate(e, formik, "start_date_fa", null);setFields({...fields,start_date_fa:e.year + "/" + e.month.number + "/" + e.day})}}
                                                />
                                                {formik.errors.start_date_fa && formik.touched.start_date_fa ? (
                                                    <div className="text-alert">{formik.errors.start_date_fa}</div>
                                                ) : <div className="text-alert"></div>}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">تاریخ پایان</Box>
                                                <DatePicker
                                                    value={formik.values.end_date_fa}
                                                    calendar={persian}
                                                    locale={persian_fa}
                                                    className={
                                                        "input_file" + (formik.errors.end_date_fa && formik.touched.end_date_fa ? " warning-border" : "")
                                                    }
                                                    name="end_date_fa"
                                                    onChange={(e) => {FormController.onChangeDate(e, formik, "end_date_fa", null);setFields({...fields,end_date_fa:e.year + "/" + e.month.number + "/" + e.day})}}
                                                />
                                                {formik.errors.end_date_fa && formik.touched.end_date_fa ? (
                                                    <div className="text-alert">{formik.errors.end_date_fa}</div>
                                                ) : <div className="text-alert"></div>}
                                            </Box>
                                        </Grid>

                                       
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">قیمت</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.price && formik.touched.price ? " warning-border" : "")
                                                    }
                                                    value={formik.values.price}
                                                    id="price"
                                                    name="price"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,price:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"قیمت"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">واحد</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.price_unit && formik.touched.price_unit ? " warning-border" : "")
                                                    }
                                                    value={formik.values.price_unit}
                                                    id="price_unit"
                                                    name="price_unit"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,price_unit:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    // placeholder={"واحد"}
                                                    readOnly
                                                    style={{ direction: "ltr" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box mb={1} className="lable-form">قیمت با تخفیف</Box>
                                            <Box dispaly="flex">
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.new_price && formik.touched.new_price ? " warning-border" : "")
                                                    }
                                                    value={formik.values.new_price}
                                                    id="new_price"
                                                    name="new_price"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,new_price:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"قیمت با تخفیف"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">واحد</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.price_unit && formik.touched.price_unit ? " warning-border" : "")
                                                    }
                                                    value={formik.values.price_unit}
                                                    id="price_unit"
                                                    name="price_unit"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,price_unit:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    // placeholder={"واحد"}
                                                    readOnly
                                                    style={{ direction: "ltr" }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">لینک جلسه</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.meeting_link && formik.touched.meeting_link ? " warning-border" : "")
                                                    }
                                                    value={formik.values.meeting_link}
                                                    id="meeting_link"
                                                    name="meeting_link"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,meeting_link:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"لینک جلسه"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">انتخاب دسته بندی</Box>
                                                <VirtualizedSelect
                                                    value={formik.values.category_id}
                                                    name="category_id"
                                                    id="category_id"
                                                    placeholder="انتخاب دسته بندی"
                                                    onChange={(selected) => {

                                                        if (selected !== null && selected !== undefined) {
                                                            FormController.onSelect(selected, "category_id", formik, null, true);
                                                            setFields({...fields,category_id:selected.value})
                                                        }
                                                    }}
                                                    maxHeight={150}
                                                    options={categoriesListOptions}
                                                    className={
                                                        "custom_input" + (formik.errors.category_id && formik.touched.category_id ? " warning-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">انتخاب نوع</Box>
                                                <VirtualizedSelect
                                                    value={formik.values.course_type}
                                                    name="course_type"
                                                    id="course_type"
                                                    placeholder="انتخاب نوع"
                                                    onChange={(selected) => {

                                                        if (selected !== null && selected !== undefined && selected !== 0) {
                                                            FormController.onSelect(selected, "course_type", formik, null, true);
                                                            setFields({...fields,course_type:selected.value})
                                                        }
                                                    }}
                                                    maxHeight={150}
                                                    options={CourseTypeOptions}
                                                    className={
                                                        "custom_input" + (formik.errors.course_type && formik.touched.course_type ? " warning-border" : "")
                                                    }
                                                    backspaceToRemoveMessage=""
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={3} p={1}>
                                            <Box dispaly="flex">
                                                <Box mb={1} className="lable-form">ظرفیت کلاس</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.student_capacity && formik.touched.student_capacity ? " warning-border" : "")
                                                    }
                                                    value={formik.values.student_capacity}
                                                    id="student_capacity"
                                                    name="student_capacity"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,student_capacity:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"ظرفیت کلاس"}
                                                    readOnly
                                                />
                                            </Box>
                                        </Grid>
                                      
                                        <Grid items xs={7} p={1}>
                                            <Box mb={1} className="lable-form">انتخاب تصویر</Box>
                                            <Box display="flex">
                                             
                                                <FormInput
                                                    placeholder="انتخاب فایل"
                                                    className={
                                                        "login-form-control" + (formik.errors.image && formik.touched.image ? " warning-border" : "")
                                                    }
                                                    type="file"
                                                    name='image'
                                                    accept={".png, .jpg"}
                                                    onChange={(e) => {
                                                        FormController.onChangeFile(e, formik, null);
                                                        setFields({ ...fields, photo: e.target.files[0] })
                                                    }}
                                                />

                                                <img
                                                    style={{ width: "45px", paddingRight: "10px" }}
                                                    src={file}
                                                    alt="view" />

                                            </Box>
                                        </Grid>

                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">توضیح کوتاه فارسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_subtitle && formik.touched.fa_subtitle ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_subtitle}
                                                    id="fa_subtitle"
                                                    name="fa_subtitle"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_subtitle:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"توضیح کوتاه فارسی"}
                                                />
                                                {/* {formik.errors.fa_subtitle && formik.touched.fa_subtitle ? (
                                                    <div className="fa_subtitle-danger">{formik.errors.fa_subtitle}</div>
                                                ) : null} */}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">توضیح کوتاه انگلیسی</Box>
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_subtitle && formik.touched.en_subtitle ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_subtitle}
                                                    id="en_subtitle"
                                                    name="en_subtitle"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,en_subtitle:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"توضیح کوتاه انگلیسی"}
                                                />
                                                {/* {formik.errors.en_subtitle && formik.touched.en_subtitle ? (
                                                    <div className="en_subtitle-danger">{formik.errors.en_subtitle}</div>
                                                ) : null} */}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">توضیح فارسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.fa_description && formik.touched.fa_description ? " warning-border" : "")
                                                    }
                                                    value={formik.values.fa_description}
                                                    id="fa_description"
                                                    name="fa_description"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,fa_description:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"توضیح فارسی"}
                                                />
                                                {/* {formik.errors.fa_description && formik.touched.fa_description ? (
                                                    <div className="fa_description-danger">{formik.errors.fa_description}</div>
                                                ) : null} */}
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex" >
                                                <Box mb={1} className="lable-form">توضیح انگلیسی</Box>
                                                <StyledTextArea
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.en_description && formik.touched.en_description ? " warning-border" : "")
                                                    }
                                                    value={formik.values.en_description}
                                                    id="en_description"
                                                    name="en_description"
                                                    onChange={(e) => {FormController.onChange(e, formik) ;setFields({...fields,en_description:e.target.value})}}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"توضیح انگلیسی"}
                                                />
                                                {/* {formik.errors.en_description && formik.touched.en_description ? (
                                                    <div className="en_description-danger">{formik.errors.en_description}</div>
                                                ) : null} */}
                                            </Box>
                                        </Grid>





                                        <Grid items xs={12} p={1}>
                                            <Box dispaly="flex" >
                                                <button
                                                    color="primary"
                                                    size="lg"
                                                    onClick={formik.handleSubmit}
                                                    className="ml-1"
                                                >
                                                    <span className="color-white">{t("send")}</span>
                                                </button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>


                            </Grid>
                        </ModalConatiner>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default withTranslation()(MasterDetailModal);