import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"

const Psychology = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {psychologyList} = stateCourse;
  // alert("Psychology")
  return (
    psychologyList.length > 0 &&
    <ContentWrapprr>
      <Title title={t("psychology")}  hasMoreView={true} tabValue={psychologyList[0]?.category_id} data={psychologyList}/>
      <Carousel data={psychologyList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Psychology);
