import types from './Types';
import DelegateAction from '../ActionDelegator';

const MemberActions = {
  setShowMemberDatailModal: DelegateAction(types.SET_SHOW_MEMBER_MODAL),
  getMembers : DelegateAction(types.GET_MEMBERS_LIST),
  addMember : DelegateAction(types.ADD_MEMBER),
  deleteMember : DelegateAction(types.DELETE_MEMBER),
  updateMember : DelegateAction(types.UPDATE_MEMBER),
  getMemberById : DelegateAction(types.GET_MEMBER_BY_ID),

};

export default MemberActions;
