
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { ModalConatiner } from "../../views/globalstyles";
import { useSelector } from "react-redux";
import CloseIcon from "../../assets/icons/DeleteIcon";
import { Modal, Box, Rating, Grid } from "@mui/material";
import {
    AlertDescriptionContainer,
    ActionsContainer,
} from "./style";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};

const AlertModal = ({
    open,
    onClose,
    onConfirm,
    title,
    onCancel,
    onConfirmButtonText,
    onCancelButtonText,
    alertText,
    id
}) => {
    const lang = useSelector((state) => state.App.lang);

    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            BackdropProps={{ onClick: (e) => e.stopPropagation() }}
        >
            <Box sx={style}>
                <ModalConatiner lang={lang}>
                    <Grid container>
                        <Grid item container xs={12} id="modal_title">
                            <Grid item xs={11} display="flex" justifyContent="start">
                                {title}
                            </Grid>
                            <Grid item xs={1} onClick={() => onClose(false)} className="clickable">
                                <CloseIcon />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} >
                            <AlertDescriptionContainer>{alertText}</AlertDescriptionContainer>

                        </Grid>
                        <Grid item container xs={12} >
                            <ActionsContainer xl={22}>
                                <button className="o__confirmButton" onClick={() => onConfirm(id)}>
                                    {onConfirmButtonText}
                                </button>
                                <button className="o__cancelButton" onClick={() => onCancel(false)}>
                                    {onCancelButtonText}
                                </button>
                            </ActionsContainer>
                        </Grid>
                    </Grid>
                </ModalConatiner>
            </Box>
        </Modal>
    )
}
export default withTranslation()(AlertModal);