import React, { useEffect } from 'react';
import { Grid, Box ,Button } from "@mui/material";
import { TitleContainer ,AnnouncemnetContainer } from "../styles";
import ClockIcon from "../../../../../assets/icons/ClockIcon";
import { withTranslation } from "react-i18next";
import {useSelector } from "react-redux";
const Dashboard = ({t}) => {
    const ProfileState = useSelector((state) => state.Profile);
    const { notificationListLoading , notificationList , hasMore } = ProfileState;

  let data = [
        { id: 0 },
        { id: 1 },
    ]
    return (
        <>
            <TitleContainer>{t("recent notifications")}</TitleContainer>
            
                     {
                    notificationListLoading !== true ? notificationList.length > 0 ?
                        notificationList.map((each, index) => {
                    return (
                        <AnnouncemnetContainer key={index}>
                                <Grid container lg={12}>
                                    <Grid item xs={7} md={6} display="flex">
                                        <Box className="title_container">
                                          {t("title")}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5} md={6} display="flex" justifyContent="end">
                                        <Box>
                                            1400-05-10
                                        </Box>
                                        <Box px={1}><ClockIcon fill="#3b3b3b" /></Box>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="end">
                                      
                                    </Grid>
                                    <Grid item xs={12} display="flex">
                                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است...
                                    </Grid>
                                </Grid>
                        </AnnouncemnetContainer>
                    )
                })
                : <>{t("no data to dispaly")}</>
                : <div className="loader"></div>
        }
            {hasMore === true &&
                    <Button className="btn">{t("all items")}</Button>
                }
        </>
    )
}
export default withTranslation()(Dashboard);