import { ActionIconsContainer } from "../../../../shared/GeneralStyles";
import Row  from "../../../../kit/Row";
import TableActions from "../../../../shared/TableActions";
import StatementHandler from "../../../../utils/methods/StatementHandler";
import {Chip} from "@mui/material";
import {ImageContainerRow} from "../../globalStyle";

const { REACT_APP_API_URL } = process.env;
const MembersTableConfig = (UpdateMaster, DeleteMaster ,ToggleMaster) => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: "تصویر",
        displayTitle: "image",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return (
            <ImageContainerRow src={REACT_APP_API_URL + "/" + props.row.photo} />
          );
        },
      },
      {
        headerTitle: "نام و نام خانوادگی",
        displayTitle: "fa_first_name",
        size: 3,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.fa_first_name !== "" ? props.row.fa_first_name :"-"+" "+
              props.row.fa_last_name !== "" ? props.row.fa_last_name :"-"}
            </Row>
          );
        },
      },
    

      {
        headerTitle: "شماره موبایل",
        displayTitle: "phone",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.phone !=="" ? props.row.phone : "-"}
            </Row>
          );
        },
      },

      {
        headerTitle: "ایمیل",
        displayTitle: "email",
        size: 2,
        responsiveShow: false,
        element: (props) => {
          return (
            <Row>
              {props.row.email !=="" ? props.row.email : "-"}
            </Row>
          );
        },
      },

      {
        headerTitle: "نقش",
        displayTitle: "role",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (

            <Row>
              <Chip label={props.row.role === "" ? "-" : props.row.role.toUpperCase()} variant="outlined" color={"info"} />
            </Row>
          );
        },
      },
      {
        headerTitle: "منطقه زمانی",
        displayTitle: "time_zone",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.time_zone !=="" ? props.row.time_zone :"-" }
            </Row>
          );
        },
      },
      {
        headerTitle: "وضعیت",
        displayTitle: "status",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (

            <Row>
              <Chip label={props.row.status === "" ? "-" : props.row.status} variant="filled"
                    color={props.row.status === "confirm" ? "success" : "default"} />
            </Row>
          );
        },
      },
      
      {
        headerTitle: "عملیات",
        displayTitle: "id",
        size: 1,
        responsiveShow: false,
        element: (props) => {
          return (
            <ActionIconsContainer>
              <TableActions
                type="edit"
                onClick={() => {
                  UpdateMaster(props.row);
                }}
              />
              <TableActions
                type="delete"
                onClick={() => {
                  DeleteMaster(props.row);
                }}
              />
                <TableActions
                type="detail"
                onClick={() => {
                  ToggleMaster(props.row);
                }}
              />
            </ActionIconsContainer>
          );
        },
      },
    ],
  };
};
export default MembersTableConfig;
