import React from 'react';
import CloseIcon from "../../../../assets/icons/DeleteIcon"
import AvatarIcon from "../../../../assets/icons/AccountIcon";
import {
    ToggleWrapper,
    ImageContainer,
    InfoConatiner,
    InfoSection
} from "../../globalStyle";
import { Box, Grid } from "@mui/material";
const { REACT_APP_API_URL } = process.env;

const MemberDetailToggle = (props) => {
    const {
        isOpen,
        onToggle,
        hasLogo,
        selected
    } = props;

    return (
        <ToggleWrapper>
            <div className={`sidebar-menu${isOpen === true ? ' open' : ''}`}>
                <Box onClick={onToggle} px={2}><CloseIcon /></Box>
                <Grid display="flex" justifyContent="center" my={2} width="100%">
                    <ImageContainer src={REACT_APP_API_URL + "/" + selected?.photo} height="60px" />
                </Grid>
                <InfoConatiner>
                    <InfoSection>
                        <Box className='title'>نام و نام خانوادگی :</Box>
                        <Box>{selected?.fa_first_name} {selected?.fa_last_name}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'> نام و نام خانوادگی به انگلیسی :</Box>
                        <Box>{selected?.en_first_name} {selected?.en_last_name}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'> آدرس ایمیل :</Box>
                        <Box>{selected?.email !== "" ? selected?.email : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  شماره موبایل :</Box>
                        <Box>{selected?.phone !== "" ? selected?.phone : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  تاریخ تولد :</Box>
                        <Box>{selected?.birthday !== "" ? selected?.birthday : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  جنسیت :</Box>
                        <Box>{selected?.gender !== "" ? selected?.gender : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  تعداد دانشجو :</Box>
                        <Box>{selected?.student_count !== "" ? selected?.student_count : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  امتیاز :</Box>
                        <Box>{selected?.rate !== "" ? selected?.rate : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>   وضعیت :</Box>
                        <Box>{selected?.status !== "" ? selected?.status : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>   محل سکونت :</Box>
                        <Box>{selected?.location !== "" ? selected?.location : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>   منطقه زمانی :</Box>
                        <Box>{selected?.time_zone !== "" ? selected?.time_zone : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>   بیوگرافی فارسی :</Box>
                        <Box>{selected?.fa_biography !== "" ? selected?.fa_biography : "-"}</Box>
                    </InfoSection>
                    <InfoSection>
                        <Box className='title'>  بیوگرافی انگلیسی :</Box>
                        <Box>{selected?.en_biography !== "" ? selected?.en_biography : "-"}</Box>
                    </InfoSection>
                </InfoConatiner>
            </div>
        </ToggleWrapper>
    )
}

export default MemberDetailToggle;