import types from './Types';
import DelegateAction from '../ActionDelegator';

const CourseActions = {
  getCategories: DelegateAction(types.GET_CATEGORIES),
  getCourses: DelegateAction(types.GET_COURSES),
  getTimeZones: DelegateAction(types.GET_TIME_ZONES),
  getTabCourses : DelegateAction(types.GET_TAB_COURSES),
  getCourseItem : DelegateAction(types.GET_COURSES_ITEM)
};

export default CourseActions;
