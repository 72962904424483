import InstagramIcon from '../../assets/icons/InstagramIcon';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import TwitterIcon from '../../assets/icons/TwitterIcon';
import TelegramIcon from '../../assets/icons/TelegramIcon';
import LinkedinIcon from '../../assets/icons/LinkedinIcon';


const SocialConfig = [
    {
      id: 0,
      title:"instagram",
      body: InstagramIcon
    },
    {
      id: 1,
      title:"linkedin",
      body: LinkedinIcon
    },
    {
      id: 2,
      title: "telegram",
      body: TelegramIcon
    },
    {
      id: 3,
      title: "twitter",
      body: TwitterIcon
    },
    {
      id: 4,
      title: "FacebookIcon",
      body: FacebookIcon
    },

  
  ]
  
  export default SocialConfig