import types from './Types';
import RecordController from "../../controllers/RecordController";

const initState = {
  showMemberDetailModal: false,
  membersList: [],
  membersLoading: true,
  takeMember: 12,
  skipMember: 0,
  hasMore: false,
  countMember: 0,
  mode :"add"
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    case types.SET_SHOW_MEMBER_MODAL:
      return {
        ...state,
        showMemberDetailModal: action.data.visible,
        mode : action.data.mode,

      };
      case types.GET_MEMBERS_LIST:
        return {
          ...state,
          membersLoading: true,
  
        };
      case types.GET_MEMBERS_LIST_SUCCESS:
        let newMembersList = [...state.membersList];
        newMembersList = [...action.data.data];
        return {
          ...state,
          membersLoading: false,
          membersList: newMembersList,
          skipMember: state.skipMember + state.takeMember,
          countMember: action.data.count,
          hasMore: state.skipMember + state.takeMember < action.data.count ? true : false,
        };
    case types.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        membersList: RecordController.update(
          state.membersList,
          action.data
        ),
        showMemberDetailModal: false,
        mode: "add",
      };
    case types.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        membersList: RecordController.add(
          state.membersList,
          action.data
        ),
        showMemberDetailModal: false,
        mode: "add",
      };
    case types.DELETE_MEMBER_SUCCESS:
      let result = {id:action.data};
      return {
        ...state,
        membersList: RecordController.delete(
          state.membersList,
          result
        )
      };
    default:
      return state;
  }
}
