import keys from "./NameSpace"
class storage {
  set(name, data) {
    return localStorage.setItem(
      `${name}`,
      JSON.stringify(data)
    );
  }
  get(name) {
    return JSON.parse(localStorage.getItem(`${name}`));
  }
  remove(name) {
    return localStorage.removeItem(`${name}`);
  }
  removeAll(){
    return localStorage.clear();
  }
  logOut(){
      this.remove(keys.token);
      this.remove(keys.userInfo);
      this.remove(keys.isLoggedin);
      this.remove(keys.role)

  }
}
export default new storage();
