import types from './Types';
import RecordController from "../../controllers/RecordController";


const initState = {
  paymentStep: 0,
  meetingList: [],
  discountInfo: [],
  masterTimes: [],
  bookCourse: null
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.CHANGE_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: action.data,
      };
    case types.NEXT_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: state.paymentStep + 1,
      };
    case types.PREVIOUS_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: state.paymentStep - 1,
      };
    case types.ADD_TO_MEETING_LIST:
      return {
        ...state,
        meetingList: RecordController.add(
          state.meetingList,
          action.data.data
        )
      };
    case types.REMOVE_FROM_MEETING_LIST:
      return {
        ...state,
        meetingList: RecordController.delete(
          state.meetingList,
          action.data.data
        )
      };
    case types.VALIDATE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        discountInfo: action.data
      };
    case types.GET_MASTER_AVAILABLE_TIME_SUCCESS:
      return {
        ...state,
        masterTimes: action.data.data
      };
    case types.BOOK_COURSE_SUCCESS:
      return {
        ...state,
        bookCourse: action.data
      };
    default:
      return state;
  }
}
