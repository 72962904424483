import { string,email , number} from "../Rules";
import * as Yup from "yup";

export const VerifyValidationMaster = Yup.object().shape({

    fa_first_name: string("fa_first_name", true),
    fa_last_name: string("fa_last_name", true),
    en_first_name: string("en_first_name", true),
    en_last_name: string("en_last_name", true),
    email: email("email", true),
    phone: number("phone", true),


});
