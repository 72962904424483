import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import LanguageIcon from "../../../assets/icons/LanguageIcon";
import ClockIcon from "../../../assets/icons/ClockIcon";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AppActions from "../../../redux/App/Actions";
import { withTranslation } from "react-i18next";
import UserMenu from "./UserMenu";
import useOutsideClick from "../../../utils/methods/OutSideClickHandler";
import {ActionContainer} from "../styles";
import { ExpandMore } from '@mui/icons-material';

const Language = ({ color, t }) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const appState = useSelector((state) => state.App);
    const { lang, langtext } = appState;
    const LoginState = useSelector((state) => state.Login);
    const { isLoggedin } = LoginState;
    const ProfileState = useSelector((state) => state.Profile);
    const { userInfo } = ProfileState;
    const [timeZone, setTimeXone] = useState('');

    const subMenuRef = useRef();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const userMenuHandler = () => {
        setShowSubMenu(!showSubMenu);
    };
    useOutsideClick(subMenuRef, () => {
        setShowSubMenu(false);
    });

    const changeLanguage = (e) => {
        if (langtext === "فارسی") {
            i18n.changeLanguage("en");
            dispatch(AppActions.changeLang({ lang: "en", text: "English" }));
        }
        else {
            i18n.changeLanguage("fa");
            dispatch(AppActions.changeLang({ lang: "fa", text: "فارسی" }));
        }
    }

    useEffect(() => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeXone(userTimeZone);
        // alert(lang)
    }, [])
    return (
        <>
            <Grid container>
                <Grid item xs={5}>

                    {
                        isLoggedin === true ?
                            // <Link to={{ pathname: '/dashboard' }} >

                                <div ref={subMenuRef} className='d-flex justify-content-center align-items-center'>
                                    <div id="loginRegister-btn"  >
                                        <ActionContainer  onClick={userMenuHandler}>

                                            {lang === "fa" ? userInfo?.fa_first_name + "  " + userInfo?.fa_last_name :
                                                userInfo?.en_first_name + " " + userInfo?.en_last_name}
                                            <ExpandMore />
                                        </ActionContainer>
                                        
                                        <UserMenu setShowSubMenu={setShowSubMenu} visible={showSubMenu} />
                                    </div>
                                </div>
                            // </Link>
                            :
                            <Link to={{ pathname: '/login' }} ><button id="loginRegister-btn">
                                {t("login")} / {t("register")}
                            </button></Link>
                    }
                </Grid>
                <Grid item xs={5} display="flex" alignItems="center" justifyContent="center">
                    <Box className="svg-container"> <ClockIcon fill={color} /></Box>
                    <Box className="text-container">{timeZone}</Box>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
                    <Box onClick={() => changeLanguage()} id="change-lang">
                        <Box className="svg-container" ><LanguageIcon fill={color} /></Box>
                        <Box className="text-container">{langtext}</Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
export default withTranslation()(Language);