import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const CoursesWrapprr = styled(Col)`
    width:100%;
    margin-top:75px;
    background-color : #f7f7f7;

`;
export const CourseContainer = styled(Col)`
    padding: 20px 0;
    width:100%;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    #more_button{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: 1px solid ${(props) => props.theme.btnColor};
        width: 200px;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
     }

`;
export const FilterConatiner = styled(Row)`
    width:92%;
    background-color: ${(props) => props.theme.white};
    padding: 20px 3%;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow:0px 0px 3px 0px #b9b9b9;
    .filter_container{
        height:45px;
        width:100%;
        border-radius: 5px;
        justify-content :flex-end;
        display:flex;
    }
    #search-input{
        // width:40%;
        height:45px;
        border-radius: 8px;
        border: 2px solid #3D54A8;
        background: #FFF;

    }
    .MuiInputBase-root{
        width:100%;
        height: 100%;
        border-color :${(props) => props.theme.secondaryFontColor};
    }
    .MuiSelect-select{
        color: ${(props) => props.color ? props.color : props.theme.text};
        font-family: ${(props) => (props.theme.fontDefault)} !important;
    }
    .MuiFormControl-root{
        width:180px !important;
        height:100% !important;
    }
    .MuiInputLabel-root{
        font-family: ${(props) => (props.theme.fontDefault)} !important;

    }
    @media only screen and (max-width: 600px) {
        .MuiFormControl-root , #search-input{
            width:100% !important;
        }
     }
  
`;

//////////////////////////////////////////////
export const BannerContainer = styled(Row)`
    height: calc((100vw) * 0.53);
    background-image : url(${props => props.cover});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;    
  margin-bottom: -136px;
    #category_name{
        font-size: 50px;
        position: absolute;
        top: 12rem; 
        right: 5%;
        color: antiquewhite;
    }
     #category_text{
        fontSize: 15px;
         position: absolute;
         top: 19rem;
         right: 5%;
         color: antiquewhite;
         lineHeight: 32px;
         width: 30%;
    }
    @media screen and (max-width: 1000px) {
        #category_text{display : none;}
      }
    @media screen and (max-width: 600px) {
        #category_name{top: 8rem;font-size: 30px; }
      }
`;
export const BannerWrapper = styled(Col)`
  
    width: 80%;

    .MuiButtonBase-root{
        width :150px;
        color: ${(props) => props.color ? props.color : props.theme.text};
        font-family: ${(props) => (props.theme.fontBold)} !important;
        font-size: ${(props) => props.theme.fontSizeMd};
    }
    .Mui-selected{
        color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor} !important;

    }
    #tab_container{
        margin :30px 0;
    }
   
`;
