import React from 'react';
import { AboutInstructorWrapper, AboutInstructorContainer, TitleContainer, AboutContainer } from "../styles";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material"

const Instructor = ({ t,data, masterDetail }) => {
    const lang = useSelector((state) => state.App.lang);

    // const description = [
    //     { id: 0, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 1, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 3, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 2, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 0, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 1, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 3, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    //     { id: 2, text: "شروع به ساز تمبک در 7 سالکی نزد استاد اصفهانی (1377)" },
    // ]
    return (
        <AboutInstructorWrapper lang={lang}>
            <AboutInstructorContainer>
                <TitleContainer content="start">{t("about instructor")}</TitleContainer>
                <AboutContainer>
                    {/* {description.map((each, index) => {
                        return (
                            <div key={index} className="text-container">{each.text}</div>
                        )
                    })} */}
                    <div className="text-container">
                        {lang === "fa" ? masterDetail.fa_biography : masterDetail.en_biography}
                    </div>
                </AboutContainer>
            </AboutInstructorContainer>
        </AboutInstructorWrapper>
    )
}
export default withTranslation()(Instructor);