import React from 'react';
import { useSelector } from 'react-redux';

function Icon({fill}) {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <g clipPath="url(#clip0_9_693)">
      <path
        fill={fill}
        d="M7.25 3.75c0-.416.334-.75.75-.75s.75.334.75.75V7.6l2.666 1.775a.724.724 0 01.18 1.04.688.688 0 01-1.012.182l-3-2c-.209-.113-.334-.347-.334-.625V3.75zM8 0a8 8 0 018 8 8 8 0 01-8 8 8 8 0 01-8-8 8 8 0 018-8zM1.5 8c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5S11.59 1.5 8 1.5 1.5 4.41 1.5 8z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_9_693">
        <path fill={fill} d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
  );
}
Icon.defaultProps = {
  fill: "#fff",
};
export default Icon;
