import styled from "styled-components";

const Col = styled.div`
    display  : flex ;
    direction : rtl;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    `;

export default Col;
