import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const ContentWrapprr = styled(Col)`
  padding : 10px;
  .font-bold{
    font-family: ${(props) => (props.theme.fontBold)};
    font-size: ${(props) => props.theme.fontSizeMd};

  }
 *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
`;
export const ContentContainer = styled.div`
width:100%;
  min-height:300px;
  box-shadow: 0px 1px 3px 0px #888888;
  justify-content: flex-start;
  background-color:  ${(props) => props.theme.white};
  border-radius: 15px;
span , div{
font-family: ${(props) => (props.theme.fontDefault)};

}
`;

export const ImageBanner = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 350px;
    border-radius: 15px 15px 0 0;
`;
export const TitleContainer = styled(Row)`
    color: ${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeLg};
    font-family: ${(props) => (props.theme.fontDefault)};
    font-weight : bold;
    padding:15px 0px;
`;

export const SectionContainer = styled(Row)`
 border-top : 1px solid #F2F2F2;
 padding :10px;
 .MuiRating-root{
  display :flex;
  justify-content: center;
 }
`;
