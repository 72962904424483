import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const ContentWrapprr = styled(Col)`
  padding : 10px;
  .font-bold{
    font-family: ${(props) => (props.theme.fontBold)};
    font-size: ${(props) => props.theme.fontSizeMd};

  }
 *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
`;
export const ContentContainer = styled.div`
width:100%;
  min-height:300px;
  box-shadow: 0px 1px 3px 0px #888888;
  justify-content: flex-start;
  background-color:  ${(props) => props.theme.white};
  border-radius: 15px;
span , div{
font-family: ${(props) => (props.theme.fontDefault)};

}
`;

export const ImageBanner = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 150px;
    border-radius: 15px 15px 0 0;
`;
export const TitleContainer = styled(Row)`
    color: ${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeLg};
    font-family: ${(props) => (props.theme.fontDefault)};
    font-weight : bold;
  margin-top: 20px;
    padding:15px 0px;
`;
export const DescriptionConatiner = styled(Row)`
color: ${(props) => props.theme.text};
font-size: ${(props) => props.theme.fontSizeSm};
font-family: ${(props) => (props.theme.fontDefault)};
padding :10px;
text-align: justify;
`;
export const SectionContainer = styled(Row)`
 border-top : 1px solid #F2F2F2;
 padding :10px;
`;
export const BtnConatiner = styled(Row)`
padding :10px;
button{
  margin-top :10px;
  background-color: ${(props) => props.theme.white};
  border-radius: ${(props) => props.theme.borderRadius};
  border :2px solid ${(props) => props.theme.btnColor};;
  width: 100%;
  padding: 10px;
  color : ${(props) => props.theme.btnColor};
  cursor :pointer;
  font-weight : bold;
  font-size: ${(props) => props.theme.fontSizeLg};
  font-family: ${(props) => (props.theme.fontDefault)};

}
#btn:hover {
  color : ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.btnColor};

}

`;