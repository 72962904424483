import React from "react";
import { TitleWrapprr, TitleContainer, MoreContainer } from "./styles";
import { Grid, Box } from "@mui/material";
import { withTranslation } from "react-i18next";
import vector from "../../assets/images/vector.png";
import ArrowIcon from "../../assets/icons/arrowIcon";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";

const Title = ({ t, title, hasMoreView = true, color , tabValue ,data}) => {
  const lang = useSelector((state) => state.App.lang);
  return (
    <TitleWrapprr color={color} lang={lang}>
      <Grid container>
        <Grid item xs={6}>
          <TitleContainer src={vector} lang={lang}>{title}</TitleContainer>
        </Grid>
        {hasMoreView === true && (
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="end"
            alignItems="center"
            style={{"cursor":"pointer"}}
            onClick={(e)=>window.location.href='/courses?id='+tabValue}
          >
            
            {/*<Link to={{ pathname:  }} >*/}
            {/* <Link to='/courses' state={{id: tabValue,data}}> */}
              <MoreContainer color={color} lang={lang}>
                <Box>{t("more")}</Box>
                <Box id="svg-container" display="flex" justifyContent="center"><ArrowIcon /></Box>
              </MoreContainer>
            {/*</Link>*/}
          </Grid>
        )}
      </Grid>
    </TitleWrapprr>
  );
};
export default withTranslation()(Title);
