import React from "react";
import { withTranslation } from "react-i18next";
import {
   Grid,
   Box,
   Button,
   Table,
   TableRow,
   TableCell,
   TableBody,
   TableHead,
   TableContainer
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { TableWrapper } from "../../globalstyles";
import { InstructorAboutWrapprr, CourseDetailContainer, ImageProfileConianer, VideoContaoner } from "../styles";
import content from "../../../assets/images/content.png";
import CommentModal from "../../../shared/commentModal";
import CommentActions from "../../../redux/Comment/Actions";
import ArrowIcon from "../../../assets/icons/arrowIcon";
const { REACT_APP_API_URL } = process.env;

const InstructorAbout = ({ t, data }) => {
   const dispatch = useDispatch();
   const lang = useSelector((state) => state.App.lang);
   const showCommentModal = useSelector((state) => state.Comment.showCommentModal);
   const stateCourse = useSelector((state) => state.Course);
   const { courseItemList, courseItemListLoading } = stateCourse;

   const setShowCommentModalHandler = (visible) => {
      dispatch(CommentActions.setShowCommentModal({ visible }));
   }
   return (
      <InstructorAboutWrapprr lang={lang}>
         <Grid container>
            <Grid item md={4} sm={5} xs={12}>
               <Grid item xs={12}>
                  <Box>
                     <CourseDetailContainer>
                        <Grid container>
                           <Grid container xs={12} p={1}>
                              <Grid xs={6}>{t("Duration of each session")} :</Grid>
                              <Grid xs={6} display="flex" justifyContent="end">
                                 {lang === "fa" ? data.duration_text_fa : data.duration_text_en}
                              </Grid>
                           </Grid>
                           <Grid container xs={12} p={1} pb={3}>
                              <Grid xs={6}>{t("release date")} :</Grid>
                              <Grid xs={6} display="flex" justifyContent="end">
                                 {lang === "fa" ? data.start_date_fa : data.start_date_en}

                              </Grid>
                           </Grid>
                           <Grid container xs={12} p={1}>
                              <Button disabled>{t("registered")}</Button>
                           </Grid>
                        </Grid>
                     </CourseDetailContainer>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box>
                     <CourseDetailContainer src={content} backgroundColor="#e0e6ed" lang={lang}>
                        <Grid container>
                           <Grid container xs={12} p={1}>
                              <Box src={content} id="image_discount">
                                 {t("discount")}
                              </Box>
                           </Grid>
                           <Grid container xs={12} p={1}>
                              <Box display="flex" justifyContent="center" width="100%" xs={6}>{t("up to")}<Box px={1} color="red">50%</Box>  {t("discount")}</Box>
                           </Grid>
                           <Grid container xs={12} p={1} pb={3}>
                              <Box display="flex" justifyContent="center" width="100%" xs={6}>
                                 {t("more detail")}
                                 <Box id="arrow-container" display="flex" justifyContent="center"><ArrowIcon /></Box>
                              </Box>
                           </Grid>
                        </Grid>
                     </CourseDetailContainer>
                  </Box>
               </Grid>


            </Grid>
            <Grid item md={8} sm={7} xs={12}>
               <Grid item xs={12}>
                  <Box className="about-container" display="flex" flexDirection="column">
                     <Grid container>
                        <Grid item xs={6} className="title-container">
                           {lang === "fa" ? data?.fa_title : data?.en_title}
                        </Grid>
                        <Grid item xs={6} display="flex" justifyContent="end">{t("instructor")} :
                           {lang === "fa" ? data?.master.fa_first_name + " " + data?.master.fa_last_name : data?.master.en_first_name + " " + data?.master.en_last_name}
                        </Grid>
                        <Grid xs={12}>
                           <VideoContaoner src={content}></VideoContaoner>
                        </Grid>
                        <Grid xs={12}>
                           <TableWrapper lang={lang}>
                              <TableContainer >
                                 <Table >
                                    <TableHead>
                                       <TableRow>
                                          <TableCell align="right">{t("title")}</TableCell>
                                          <TableCell align="right">{t("date")}</TableCell>
                                          <TableCell align="right">{t("status")}</TableCell>
                                          <TableCell align="right"></TableCell>
                                       </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {courseItemList?.day_time?.map((row, ind) => (
                                          <TableRow key={ind}>
                                             <TableCell align="right">{row.title}</TableCell>
                                             <TableCell align="right">{row.date}</TableCell>
                                             <TableCell align="right">{row.status}</TableCell>
                                             <TableCell align="right" >
                                                <Box display="flex" id="actions_container">
                                                   {/* <Box id="comment_icon" onClick={() => setShowCommentModalHandler(true)}>
                                                      <Facebook />
                                                   </Box> */}

                                                   <button>
                                                      <a href={row.meeting_link} target="_blank">
                                                         {t("join the meeting")}
                                                      </a>
                                                   </button>

                                                </Box>
                                             </TableCell>
                                          </TableRow>
                                       ))}
                                    </TableBody>
                                 </Table>
                              </TableContainer>
                           </TableWrapper>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box className="about-container" display="flex">
                     <Grid container>
                        <Grid item xs={3} md={2}>
                           <ImageProfileConianer src={REACT_APP_API_URL + "/" + data?.master.photo} />
                        </Grid>
                        <Grid item xs={9} md={10}>
                           <Box id="instructor-container" pb={2}>{t("instructor")}</Box>
                           <Box className="title-container">
                              {lang === "fa" ? data?.master.fa_first_name + " " + data?.master.fa_last_name : data?.master.en_first_name + " " + data?.master.en_last_name}
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box className="about-container" display="flex" flexDirection="column">
                     <Box className="title-container">{t("about instructor")}</Box>
                     <Box py={1}>
                        {lang === "fa" ? data.master.fa_biography : data.master.en_biography}
                     </Box>
                  </Box>
               </Grid>
            </Grid>
         </Grid>
         <CommentModal open={showCommentModal} handleClose={setShowCommentModalHandler} />
      </InstructorAboutWrapprr>
   );
};
export default withTranslation()(InstructorAbout);