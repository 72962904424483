import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { InstructorAboutWrapprr, CourseDetailContainer, ImageProfileConianer } from "../styles";
import pic from "../../../assets/images/avatar.png";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
const { REACT_APP_API_URL } = process.env;

const InstructorAbout = ({ t, data }) => {
   const lang = useSelector((state) => state.App.lang);
   const isLoggedin = useSelector((state) => state.Login.isLoggedin);
   const showMessage  = () =>{
      let message= t("Please log in to your account");
     if(!isLoggedin){
          toast.warning(message, {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
         });
     }
  }
   return (
      <InstructorAboutWrapprr lang={lang}>
         <Grid container>
            <Grid item md={4} sm={5} xs={12}>
               <Box>
                  <CourseDetailContainer>
                     <Grid container>
                        <Grid container xs={12} p={1}>
                           <Grid xs={6}>{t("Duration of each session")} :</Grid>
                           <Grid xs={6} display="flex" justifyContent="end">
                              {lang === "fa" ? data?.duration_text_fa : data?.duration_text_en}
                           </Grid>
                        </Grid>
                        <Grid container xs={12} p={1} pb={3}>
                           <Grid xs={6}>{t("release date")} :</Grid>
                           <Grid xs={6} display="flex" justifyContent="end">
                              {lang === "fa" ? data?.start_date_fa : data?.start_date_en}
                           </Grid>
                        </Grid>

                        <Grid container xs={12} p={1} id="price-container">
                           <Grid xs={6}>{t("price")} :</Grid>
                           <Grid xs={6} display="flex" justifyContent="end">
                              <Box color="#777777" display="flex" alignItems="center">{data?.price_unit}</Box>
                              <Box
                                 style={{ fontSize: "18px" }}
                                 className="font-bold"
                                 color="#3D54A8"
                                 sx={{ pr: 1 }}>{data?.price}</Box>
                           </Grid>
                        </Grid>

                        <Grid container xs={12} p={1}>
                          <a href={isLoggedin === true ?'/courseRegister?id=' + data?.id : '/login?ref=/courseRegister?id='  + data?.id }>
                              <Button className="btn" onClick={() => {showMessage()}}>{t("register")}</Button>
                          </a>
                        </Grid>
                     </Grid>
                  </CourseDetailContainer>
               </Box>
            </Grid>
            <Grid item md={8} sm={7} xs={12}>
               <Grid item xs={12}>
                  <Box className="about-container" display="flex">
                     <Grid container>
                        <Grid item xs={3} md={2}>
                           <ImageProfileConianer src={REACT_APP_API_URL + "/" + data?.master?.photo} />
                        </Grid>
                        <Grid item xs={9} md={10}>
                           <Box id="instructor-container" pb={2}>{t("instructor")}</Box>
                           <Box className="title-container" style={{"cursor":"pointer"}} onClick={(e)=>window.location.href="/instructor?id=" + data?.master?.id}>
                              {lang === "fa" ? data?.master?.fa_first_name + " " + data?.master?.fa_last_name : data?.master.en_first_name + " " + data?.master.en_last_name}
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box className="about-container" display="flex" flexDirection="column">
                     <Box className="title-container">{t("about instructor")}</Box>

                     <Box py={1}>
                        {lang === "fa" ? data?.master?.fa_biography : data?.master?.en_biography}
                     </Box>

                  </Box>
               </Grid>
            </Grid>
         </Grid>
      </InstructorAboutWrapprr>
   );
};
export default withTranslation()(InstructorAbout);