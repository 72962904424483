import React from "react";

const CategoriesIcon = ({ pathFill, stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill={pathFill}
        stroke={stroke}
        strokeWidth="0.5"
        d="M7.894 1H4.828A3.832 3.832 0 001 4.827V6.17a.414.414 0 00.827 0V4.827c0-1.654 1.346-3 3-3h3.067c1.654 0 3 1.346 3 3V9.49c0 .775-.63 1.405-1.405 1.405H4.827a3.004 3.004 0 01-2.994-2.808.414.414 0 00-.825.053 3.833 3.833 0 003.82 3.582h4.661a2.234 2.234 0 002.232-2.232V4.827A3.832 3.832 0 007.894 1zM21.172 1h-3.066a3.832 3.832 0 00-3.828 3.827V9.49a2.234 2.234 0 002.232 2.232h4.662A3.832 3.832 0 0025 7.894V4.827A3.832 3.832 0 0021.172 1zm3 6.894c0 1.654-1.345 3-3 3H16.51c-.774 0-1.404-.63-1.404-1.405V4.827c0-1.654 1.346-3 3-3h3.066c1.655 0 3 1.346 3 3v3.067zM9.49 14.03H4.827A3.832 3.832 0 001 17.857v3.067a3.832 3.832 0 003.828 3.827h3.066a3.832 3.832 0 003.827-3.827v-4.662A2.234 2.234 0 009.49 14.03zm1.404 6.894c0 1.654-1.346 3-3 3H4.828c-1.655 0-3-1.346-3-3v-3.067c0-1.654 1.345-3 3-3H9.49c.774 0 1.404.63 1.404 1.405v4.662h0zM19.638 14.054c-.769 0-1.51.16-2.205.475a.414.414 0 00.342.753 4.515 4.515 0 016.372 4.108 4.515 4.515 0 01-4.51 4.51 4.515 4.515 0 01-4.509-4.51c0-1.151.434-2.248 1.222-3.087a.414.414 0 10-.603-.566 5.317 5.317 0 00-1.446 3.653 5.343 5.343 0 005.337 5.337 5.343 5.343 0 005.336-5.337 5.343 5.343 0 00-5.336-5.336z"
      ></path>
    </svg>
  );
}
CategoriesIcon.defaultProps = {
  pathFill: "#fff",
  stroke: "#fff"
}
export default CategoriesIcon;