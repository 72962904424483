import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import routePaths from "./nameSpace";
import Home from "../pages/home/index";
import ContactUs from "../pages/contactUs/index";
import AboutUs from "../pages/aboutUs/index";
import Courses from "../pages/courses/index";
import Course from "../pages/course/index";
import Login from "../pages/loginRegister/index";
import Instructor from "../pages/instructor"
import Learning from "../pages/learning/index";
import CourseRegister from "../pages/courseRegister";
import CompleteRegister from "../pages/completeRegister";
import ForgetPassword from "../pages/forgetPassword/index";

///////////////////dashboard member
import Dashboard from "../views/dashboard/member/dashboard";
import Favorites from "../views/dashboard/member/favorites";
import MyCourse from "../views/dashboard/member/course";
import Calendar from "../views/dashboard/member/calendar";
import Account from "../views/dashboard/member/account";
import Announcements from "../views/dashboard/member/announcements";
import Orders from "../views/dashboard/member/orders";
import ChangePassword from "../views/dashboard/member/changePassword";

import { MemeberPrivateRoute } from "./MemberPrivateRoute";
import { AdminPrivateRoute } from "./AdminPrivateRoute";

///////////////////dashboard admin
import Category from "../views/dashboard/admin/category";
import Master from "../views/dashboard/admin/master";
import Member from "../views/dashboard/admin/member";
import AdminCourse from "../views/dashboard/admin/course";
import Staff from "../views/dashboard/admin/staff";
import AdminOrders from "../views/dashboard/admin/orders";
import Discount from "../views/dashboard/admin/discount/index";

import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import Searches from "../pages/search";

const AppRoute = () => {
  const isLoggedin = useSelector((state) => state.Login.isLoggedin);
  const role = useSelector((state) => state.Login.role);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Switch>
        <Route
          exact
          path={routePaths.login}
          component={(props) =>
            isLoggedin && role === "member" ? ( <Redirect to={routePaths.dashboard} />) :
            isLoggedin && role === "admin" ?
              (<Redirect to={routePaths.masters} />) :
              (<Login {...props} />)
          }
        />

        <Route
          exact
          path="/"
          component={() => <Home />}
        />
        <Route
          path="/contact-us"
          exact
          component={() =>
            <ContactUs />
          } />
        <Route
          path="/about-us"
          component={() =>
            <AboutUs />
          }
        />
        <Route
          exact
          path="/login"
          component={() => <Login />}
        />
        <Route
          exact
          path="/register"
          element={<Login />}
        />
        <Route
          exact
          path="/forgetPassword"
          component={() => <ForgetPassword />}
        />

        <Route
          exact
          path="/search"
          component={() => <Searches />}
        />
        <Route
          exact
          path="/courses"
          component={() => <Courses />}
        />
        <Route
          exact
          path="/course/:id"
          component={() => <Course />}
        />
        <Route
          exact
          path="/instructor"
          component={() => <Instructor />}
        />
        <Route
          exact
          path="/learning/:id"
          component={() => <Learning />}
        />
        <Route
          exact
          path="/courseRegister"
          component={() => <CourseRegister />}
        />
        <Route
          exact
          path="/complete-register"
          component={() => <CompleteRegister />}
        />

        <MemeberPrivateRoute
          exact
          path={routePaths.dashboard}
          component={() => <Dashboard />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.favorites}
          component={() => <Favorites />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.announcements}
          component={() => <Announcements />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.account}
          component={() => <Account />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.my_courses}
          component={() => <MyCourse />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.calendar}
          component={() => <Calendar />}
        />
        <MemeberPrivateRoute
          exact
          path={routePaths.orders}
          component={() => <Orders />}
        />

        <MemeberPrivateRoute
          exact
          path={routePaths.change_password}
          component={() => <ChangePassword />}
        />




        <AdminPrivateRoute
          exact
          path={routePaths.categories}
          component={() => <Category />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.adminCourses}
          component={() => <AdminCourse />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.users}
          component={() => <Member />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.masters}
          component={() => <Master />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.staff}
          component={() => <Staff />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.adminOrders}
          component={() => <AdminOrders />}
        />
        <AdminPrivateRoute
          exact
          path={routePaths.discount}
          component={() => <Discount />}
        />
      </Switch>
    </BrowserRouter>
  )
};
export default AppRoute;
