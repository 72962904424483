import {
  all,
  takeEvery,
  put,
  fork,
  select

} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";

export function* GetCoursesList() {
  yield takeEvery(types.GET_PRIVATE_COURSES_LIST, function* ({ data }) {
    let response =[];
    try {
      if(data.type !== "filter"){
        response = yield Api.Get(Endpoints.GetCoursesList + `?offset=${data.offset}&limit=${data.limit}`, true);
      }
      else{
        response = yield Api.Get(Endpoints.GetCoursesList + `?q=${data.values.query}&offset=${data.offset}&limit=${data.limit}`, true);
      }
      
      if (response) {
        yield put({
          type: types.GET_PRIVATE_COURSES_LIST_SUCCESS,
          data: { data: response.data, count: response.total },

        });
      } else
        yield put({
          type: types.GET_PRIVATE_COURSES_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_PRIVATE_COURSES_LIST_FAIL,
      });
    }
  });
}

export function* AddCourse() {
  yield takeEvery(types.ADD_COURSE, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    const Data = new FormData();
    // Data.append("id" , data.fields.id);
    Data.append("category_id", data.fields.category_id);
    Data.append("course_type", data.fields.course_type);
    Data.append( "duration", data.fields.duration);
    Data.append( "en_description", data.fields.en_description);
    Data.append( "en_subtitle", data.fields.en_subtitle);
    Data.append( "en_title", data.fields.en_title);
    Data.append("fa_description", data.fields.fa_description);
    Data.append("fa_subtitle", data.fields.fa_subtitle);
    Data.append("fa_title", data.fields.fa_title);
    Data.append( "image", data.fields.photo);
    Data.append( "meeting_link", data.fields.meeting_link);
    Data.append( "master_id", data.fields.master_id);
    Data.append("new_price", data.fields.new_price);
    Data.append( "price", data.fields.price);
    Data.append( "sessions", data.fields.sessions);
    Data.append( "student_capacity", data.fields.student_capacity);
    Data.append( "price_unit", data.fields.price_unit);
    Data.append( "status",data.fields.status);
    Data.append( "start_date_fa", data.fields.start_date_fa);
    Data.append( "end_date_fa", data.fields.end_date_fa);
    
    try {
      const response = yield Api.Post(Endpoints.AddCourse,Data,true, "form");
      if (response) {
        yield put({
          type: types.ADD_COURSE_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.ADD_COURSE_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.ADD_COURSE_FAIL,
      });
    }
  });
}

export function* DeleteCourse() {
  yield takeEvery(types.DELETE_COURSE, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Delete(Endpoints.DeleteCourse + `/${data.course.id}`, true);
      if (response) {
        yield put({
          type: types.DELETE_COURSE_SUCCESS,
          data: data.course.id
        });
        data?.callBack();
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.DELETE_COURSE_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.DELETE_COURSE_FAIL,
      });
    }
  });
}

export function* UpdateCourse() {
  yield takeEvery(types.UPDATE_COURSE, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    const Data = new FormData();
    Data.append("id" , data.fields.id);
    Data.append("category_id", data.fields.category_id);
    Data.append("course_type", data.fields.course_type);
    Data.append( "duration", data.fields.duration);
    Data.append( "en_description", data.fields.en_description);
    Data.append( "en_subtitle", data.fields.en_subtitle);
    Data.append( "en_title", data.fields.en_title);
    Data.append("fa_description", data.fields.fa_description);
    Data.append("fa_subtitle", data.fields.fa_subtitle);
    Data.append("fa_title", data.fields.fa_title);
    Data.append( "image", data.fields.photo);
    Data.append( "meeting_link", data.fields.meeting_link);
    Data.append( "master_id", data.fields.master_id);
    Data.append("new_price", data.fields.new_price);
    Data.append( "price", data.fields.price);
    Data.append( "sessions", data.fields.sessions);
    Data.append( "student_capacity", data.fields.student_capacity);
    Data.append( "price_unit", data.fields.price_unit);
    Data.append( "status",data.fields.status);
    Data.append( "start_date_fa", data.fields.start_date_fa);
    Data.append( "end_date_fa", data.fields.end_date_fa);
    
    try {
      const response = yield Api.Put(Endpoints.UpdateCourse + `/${data.fields.id}`,Data,true, "form");
      if (response) {
        yield put({
          type: types.UPDATE_COURSE_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.UPDATE_COURSE_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.UPDATE_COURSE_FAIL,
      });
    }
  });
}

export function* GetCourseById() {
  yield takeEvery(types.GET_COURSE_BY_ID, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetCourseById + `/${data.id}`, false);
      if (response) {
        yield put({
          type: types.GET_COURSE_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_COURSE_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_COURSE_BY_ID_FAIL,
      });
    }
  });
}

export function* GetMyCourses() {
  yield takeEvery(types.GET_MY_COURSES_LIST, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetMyCourses + `?offset=${data.offset}&limit=${data.limit}`, true);
      if (response) {
        yield put({
          type: types.GET_MY_COURSES_LIST_SUCCESS,
          data: { data: response.data, count: response.total },

        });
      } else
        yield put({
          type: types.GET_MY_COURSES_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_MY_COURSES_LIST_FAIL,
      });
    }
  });
}
export function* GetCalendarList() {
  yield takeEvery(types.GET_CALENDAR_LIST, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetCalendarList, true);
      if (response) {
        yield put({
          type: types.GET_CALENDAR_LIST_SUCCESS,
          data: response,
        });
      } else
        yield put({
          type: types.GET_CALENDAR_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_CALENDAR_LIST_FAIL,
      });
    }
  });
}
export default function* PivateCoursesSagas() {
  yield all(
    [
      fork(GetCoursesList),
      fork(AddCourse),
      fork(DeleteCourse),
      fork(UpdateCourse),
      fork(GetCourseById),
      fork(GetMyCourses),
      fork(GetCalendarList)
    ]);
}
