import styled from "styled-components";
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";


export const BannerWrapper = styled(Col)`
   height: calc((100vw) * 0.46);
   background-color: #f7f7f7; 
   background-image : url(${props => props.cover});
   background-repeat:no-repeat;
   background-position: top;
   background-size: cover;
   width: 100vw;
   height: 70rem;
// transform: ${(props) => props.lang === "fa" ? "scaleX()" : "scaleX(-1)"}; 

button{
   background-color: ${(props) => props.theme.btnColor};
   border-radius: ${(props) => props.theme.borderRadius};
   border: none;
   width: 100%;
   padding: 15px;
   color : ${(props) => props.theme.white};
   cursor :pointer;
   font-family: ${(props) => (props.theme.fontDefault)};
}
input{
   height : 53px;
   border-radius: 5px 0 0 5px;
   font-family: ${(props) => (props.theme.fontDefault)};
   
}
.MuiInputBase-input{
   width:70px;
   background-color: ${(props) => props.theme.btnColor};
   color : ${(props) => props.theme.white};
   font-family: ${(props) => (props.theme.fontDefault)};
   border-radius: 0px  5px 5px 0;
   border :none;
}
#demo-simple-select{
   width :100%;
}
@media screen and (max-width: 768px) {
   //background-image : none;
   //height: fit-content;
   margin: 4rem;
}
`;

export const SearchContainer = styled(Col)`
   height : 200px;
   width :  600px;
   justify-content: flex-start;
   align-items: flex-start;
   position : absolute;
   top:25%;
   right:${(props) => props.lang === "fa" ? "7%" : "unset"};
   left : ${(props) => props.lang === "en" ? "7%" : "unset"};
   transform : translate (0 , -50%);
   @media screen and (max-width: 768px) {
      top:10%;
      width: 90%;
   }
   `;
export const TitleContainer = styled(Row)`
   color: ${(props) => props.theme.secondaryFontColor};
   font-size: 2.33rem ; //${(props) => props.theme.fontSizeLg};
   font-family: ${(props) => (props.theme.fontBold)};
   font-weight : bold;
   padding:15px 0px;
   font-weight: bold;
   padding: 30px 0px;
   //width: 92%;
`;
export const TextConatiner = styled(Row)`
   color: ${(props) => props.theme.thirdFontColor};
   //font-size: ${(props) => props.theme.fontSizeMd};
   //font-family: ${(props) => (props.theme.fontBold)};
   padding-bottom :30px;
   text-align: justify;
    font-size: 20px;
    line-height: 50px;
    max-width: 90%;
`;
export const FormContainer = styled(Row)`
   color: ${(props) => props.theme.thirdFontColor};
   font-size: ${(props) => props.theme.fontSizeSm};
   font-family: ${(props) => (props.theme.fontBold)};
   justify-content : flex-start;
   padding-left: 10px;
   
   @media screen and (max-width: 768px) {
    
      
   }
   .no-mobile{
      @media screen and (max-width: 768px) {
        display: none;
      
     }
   }
`;


