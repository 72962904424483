import React, { useEffect, useState } from 'react';
import { CoursesWrapprr } from "./styles";
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import Banner from "./components/banner"
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

const Courses = () => {
    const theme = useSelector((state) => state.App.theme);
    // const {location} = useHistory();
    // const { pathname } = useHistory();
    const [value, setValue] = useState(1);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");

    const params = new URLSearchParams(window.location.search);
    useEffect(() => {
        window.scrollTo(0, 0);
        // alert()
        // let selectedId = params.get("id")
        // if (selectedId === null || selectedId === undefined){
        //      selectedId = 1
        // }
        // location.state = {
        //     "id":selectedId
        // }
        // alert(params.get("id"))
        setValue(parseInt(params.get("id")));
    }, [params]);

    return (
        <CoursesWrapprr >
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor} />
            <Banner
                id={params.get("id")}
                value={value}
                setValue={setValue}
                search={search}
                setSearch={setSearch} 
                sort={sort}
                setSort={setSort}   />
            <Footer />
        </CoursesWrapprr>
    )
}
export default Courses;