import types from './Types';
import DelegateAction from '../ActionDelegator';

const MasterActions = {
  setShowMasterDatailModal: DelegateAction(types.SET_SHOW_MASTER_DETAIL_MODAL),
  getMasters : DelegateAction(types.GET_MASTERS_LIST),
  addMaster : DelegateAction(types.ADD_MASTER),
  deleteMaster : DelegateAction(types.DELETE_MASTER),
  updateMaster : DelegateAction(types.UPDATE_MASTER),
  getMasterById : DelegateAction(types.GET_MASTER_BY_ID),
  setShowDeleteMasterModal : DelegateAction(types.SET_SHOW_DELETE_MASTER_MODAL)
};

export default MasterActions;
