import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Discount = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {discountList} = stateCourse;

  return (
    discountList.length > 0 &&
    <ContentWrapprr>
      <Title title="تخفیف دوره ها" hasMoreView={true} tabValue={discountList[0]?.category_id}  data={discountList} color ="#21B076"/>
      <Carousel color ="#21B076"  data={discountList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Discount);
