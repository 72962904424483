import React, { useEffect, useState } from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"
import MasterActions from "../../../../redux/Master/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, CardContainer, AddContainer, FilterContainer } from "../../globalStyle";
import Table from "../../../../shared/Table";
import MastersTableConfig from "./MastersTableConfig";
import AlertModal from "../../../../shared/AlertModal";
import MasterDetailModal from "./MasterDetailModal";
import MasterDetailToggle from "./MasterDetailToggle";
import FormFilter from "../../FormFilter";
import { Grid } from "@mui/material"
const { REACT_APP_API_URL } = process.env;

const Master = () => {
  const dispatch = useDispatch();
  const masterState = useSelector((state) => state.Master);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const { mastersList, mastersLoading, takeMaster, skipMaster, countMaster, showMasterDetailModal, masterDetail, mode } = masterState;
  const [deleteState, setDeleteState] = useState({
    showAlert: false,
    master: null,
  });
  const defaultfields = {
    id: "",
    email: "",
    en_biography: "",
    en_first_name: "",
    en_last_name: "",
    phone: "",
    fa_biography: "",
    fa_first_name: "",
    fa_last_name: "",
    gender: "",
    birthday: "",
    photo: null,
    image: null,
    query:""
  };
  const [fields, setFields] = useState(defaultfields);
  // useEffect(() => {
  //   setFields({
  //     ...fields,
  //     ...masterDetail,
  //   });
  // }, [masterDetail]);
  /////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(MasterActions.getMasters({ offset: 0, limit: takeMaster }));
  }, [])

  ///////////////////////////////////////////detail
  const mastersDetailHandler = (result) => {

    setFields({
      ...fields,
      ...result,
      // ...masterDetail,
    });
    dispatch(
      MasterActions.setShowMasterDatailModal({
        visible: true,
        mode: "edit",
      })
    );
    // dispatch(MasterActions.getMasterById(result.id));
  };

  const addOrUpdateMasterHandler = (values, action, schedule) => {

    if (mode === "add") {
      dispatch(
        MasterActions.addMaster({
          fields: values,
          schedule
        })
      );
    } else if (mode === "edit") {
      dispatch(
        MasterActions.updateMaster({
          fields: values,
          schedule
        })
      );
    }
  };

  const closeMasterDetailsModal = (formik) => {
    formik.resetForm({});
    dispatch(
      MasterActions.setShowMasterDatailModal({
        visible: false,
        mode: "add",
      })
    );
    setFields({
      ...fields,
      ...defaultfields,
    });

    formik.resetForm({});
  };
  //////////////////////////////////////
  const pageHandler = (res) => {
    dispatch(
      MasterActions.getMasters({
        offset: res,
        limit: takeMaster,
      })
    );
  }
  //////////////////////////////////delete
  const deleteMaster = (master) => {
    setDeleteState({
      ...deleteState,
      showAlert: true,
      master,
    });
  }
  const onConfirmDeleteHandler = () => {
    let callBack = () => {
      setDeleteState({
        ...deleteState,
        showAlert: false,
        master: null,
      });
    };
    dispatch(
      MasterActions.deleteMaster({
        master: deleteState.master,
        callBack,
      })
    );
  };

  const onCancelDeleteHandler = () => {
    setDeleteState({
      ...deleteState,
      showAlert: false,
      master: null,
    });
  };
  ////////////////////////////////
  const toggleDetail = (result) => {
    setIsOpen(!isOpen);
    setSelected(result)
  }
  ////////////////////////////////filter
  const filterMaster = (values, action) => {
    dispatch(
      MasterActions.getMasters({ values, type: "filter", limit: 0, offset: "" })
    );
  }
  return (
    <MainLayout>
      <CardContainer>

        <FilterContainer>
          <Grid container >
            <Grid item xs={4}>
              <FormFilter fields={fields} onConfirm={filterMaster} />
            </Grid>
            <Grid item xs={8}>
              <AddContainer>
                <span onClick={() => {
                  dispatch(
                    MasterActions.setShowMasterDatailModal({
                      visible: true,
                      mode: "add",
                    })
                  );
                }}>+</span>
              </AddContainer>
            </Grid>
          </Grid>
        </FilterContainer>
        <TableWrapper height="calc(100% - 60px)">
          <Table
            tblConfig={MastersTableConfig(mastersDetailHandler, deleteMaster, toggleDetail)}
            tblData={mastersList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skipMaster}
            take={takeMaster}
            count={countMaster}
            loading={mastersLoading}
          />
        </TableWrapper>
      </CardContainer>
      <MasterDetailModal
        open={showMasterDetailModal}
        title={
          mode === "add" ? "اضافه کردن استاد " : "ویرایش استاد"
        }
        onClose={closeMasterDetailsModal}
        onConfirm={addOrUpdateMasterHandler}
        fields={fields}
        setFields={setFields}
        mode={mode}
      />
      <AlertModal
        open={deleteState.showAlert}
        onConfirm={onConfirmDeleteHandler}
        onClose={onCancelDeleteHandler}
        title={"حذف یک استاد"}
        onCancel={onCancelDeleteHandler}
        alertText={"آیا شما تمایل به حذف این استاد را دارید؟"}
        onConfirmButtonText={"بله"}
        onCancelButtonText={"خیر"}
      />
      <MasterDetailToggle
        isOpen={isOpen}
        onToggle={toggleDetail}
        hasLogo={true}
        selected={selected}
      />
    </MainLayout >
  )
}

export default Master;