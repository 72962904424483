import React, { useEffect } from 'react';
import CourseView from "../../views/course";


const Course = () => {

    useEffect(() => {

    }, [])

    return (
        <>
            <CourseView />
        </>
    )
}
export default Course;