import { axiosInstance } from "./axiosInstance";
import { apiConfig } from "./apiConfig";
const { REACT_APP_API_URL } = process.env;
class Api {

  Get = async (url, needAuth = true, type = "") => {
    let config = apiConfig(type, needAuth);
    return await axiosInstance({
      method: "get",
      url :`${REACT_APP_API_URL}/${url}`,
      ...config,
    });
  };
  Post = async (url, data, needAuth = true, type = "") => {
    let config = apiConfig(type, needAuth);
    
    return await axiosInstance({
      method: "post",
      url :`${REACT_APP_API_URL}/${url}`,
      data,
      ...config,
    });
  };
  Put = async (url, data, needAuth = true, type = "") => {
    let config = apiConfig(type, needAuth);
    return await axiosInstance({
      method: "put",
      url :`${REACT_APP_API_URL}/${url}`,
      data,
      ...config,
    });
  };
  Patch = async (url, data, needAuth = true, type = "") => {
    let config = apiConfig(type, needAuth);
    return await axiosInstance({
      method: "patch",
      url :`${REACT_APP_API_URL}/${url}`,
      data,
      ...config,
    });
  };
  Delete = async (url, data, needAuth = true, type = "") => {
    let config = apiConfig(type, needAuth);
    return await axiosInstance({
      method: "delete",
      url :`${REACT_APP_API_URL}/${url}`,
      data,
      ...config,
    });
  };
}

export default new Api();
