import React, { useEffect } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import {
    Grid,
    Box,
    Button
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { CourseWrapper ,ImageContainer } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import PrivateCourseActions from "../../../../redux/PrivateCourse/Actions";
import { Link } from "react-router-dom";
const { REACT_APP_API_URL } = process.env;

const Course = ({ t }) => {
    const lang = useSelector((state) => state.App.lang);
    const courseState = useSelector((state) => state.PrivateCourse);
    const { myCoursesListLoading, myCoursesList } = courseState;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(PrivateCourseActions.getMyCoursesList({ offset: 0, limit: 10 }));
    }, [])

    return (
        <MainLayout >
            <CourseWrapper lang={lang}>
                {myCoursesListLoading !== true ?
                    myCoursesList.length > 0 ?
                        <Grid container>
                            {myCoursesList?.map((each, index) => {
                                return (
                                    <Grid container item xs={12} key={index} >
                                        <Box className="course-container" p={3}>
                                            <Grid container >
                                                <Grid item xs={12} sm={3}>
                                                    <Box id="image-container">
                                                        <ImageContainer src={REACT_APP_API_URL + "/" + each?.course?.image}></ImageContainer>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container className="course-info">
                                                        <Grid item xs={6}>{t("course name")}:</Grid>
                                                        <Grid item xs={6} className="blue-font">
                                                            {lang === "fa" ? each?.course.fa_title : each?.course.en_title}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container className="course-info">
                                                        <Grid item xs={6}>{t("instructor name")}:</Grid>
                                                        <Grid item xs={6} className="blue-font">
                                                            {lang === "fa" ? each?.course.master?.fa_last_name : each?.course.master?.en_last_name}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container className="course-info">
                                                        <Grid item xs={6}>{t("The number of meetings held")}:</Grid>
                                                        <Grid item xs={6} className="blue-font">
                                                            {each?.course.duration}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Box className="btn-container">
                                                    <Link to={{ pathname: '/courses?id=' + each.course.id }} >
                                                        <Button>{t("Course page")}</Button>
                                                    </Link>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        : <>{t("no data to dispaly")}</>
                    : <div className="loader"></div>
                }
            </CourseWrapper>
        </MainLayout>
    )
}
export default withTranslation()(Course);