import styled from "styled-components";


export const AlertDescriptionContainer = styled.div`
  display:flex;
  font-size: ${(props) => props.theme.fontSizeSm};
  height: 30px;
  color: ${(props) => props.theme.textPrimary};
  justify-content: center;
  padding: 10px 0;
  width:100%;

`;
export const ActionsContainer = styled.div`
  display:flex;
  justify-content: space-between;
  padding:0px 15px;
  height: 50px;
  width:100%;
  box-sizing: border-box;
  button{
      width:150px;
  }
  .o__cancelButton {
    background-color: transparent;
     border: 1px solid ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.secondary};
  }
  .o__confirmButton{

  }
`;
