export default {

  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAIL: 'GET_CATEGORIES_FAIL',
  GET_CATEGORIES_EXCEPTION: 'GET_CATEGORIES_EXCEPTION',

  GET_COURSES: 'GET_COURSES',
  GET_COURSES_SUCCESS: 'GET_COURSES_SUCCESS',
  GET_COURSES_FAIL: 'GET_COURSES_FAIL',
  GET_COURSES_EXCEPTION: 'GET_COURSES_EXCEPTION',

  GET_TIME_ZONES: 'GET_TIME_ZONES',
  GET_TIME_ZONES_SUCCESS: 'GET_TIME_ZONES_SUCCESS',
  GET_TIME_ZONES_FAIL: 'GET_TIME_ZONES_FAIL',
  GET_TIME_ZONES_EXCEPTION: 'GET_TIME_ZONES_EXCEPTION',

  GET_TAB_COURSES: 'GET_TAB_COURSES',
  GET_TAB_COURSES_SUCCESS: 'GET_TAB_COURSES_SUCCESS',
  GET_TAB_COURSES_FAIL: 'GET_TAB_COURSES_FAIL',
  GET_TAB_COURSES_EXCEPTION: 'GET_TAB_COURSES_EXCEPTION',

  GET_COURSES_ITEM: 'GET_COURSES_ITEM',
  GET_COURSES_ITEM_SUCCESS: 'GET_COURSES_ITEM_SUCCESS',
  GET_COURSES_ITEM_FAIL: 'GET_COURSES_ITEM_FAIL',
  GET_COURSES_ITEM_EXCEPTION: 'GET_COURSES_ITEM_EXCEPTION',

};
