import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Box, Rating } from "@mui/material";
import {
    CourseDescriptionWrapper,
    CourseDescriptionContainer,
    CourseImageContainer,
    CourseTextContainer,
    TitleContainer
} from "../styles";
import pic from "../../../assets/images/content.png";
import { useSelector } from "react-redux";
const { REACT_APP_API_URL } = process.env;

const CourseDescription = ({ t ,data}) => {
    const lang = useSelector((state) => state.App.lang);

  
    return (
        <CourseDescriptionWrapper lang={lang}>
            <CourseDescriptionContainer lang={lang}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                          <CourseImageContainer src={REACT_APP_API_URL + "/" + data?.image} />
                    </Grid>
                    <Grid container  xs={12} sm={8} id="text-container" lang={lang}>
                        <Grid item xs={6}>
                        <Box id="title-container">
                                {lang === "fa" ? data?.fa_title : data?.en_title}
                                </Box>
                        </Grid>
                        <Grid item xs={6} display="flex" justifyContent="end">
                           <Box id="rating_container"> <Rating name="half-rating" defaultValue={data.rate} readOnly /></Box>
                        </Grid>
                        <Grid item xs={12}>
                            <CourseTextContainer>
                            {lang === "fa" ? data?.fa_description : data?.en_description}
                            </CourseTextContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </CourseDescriptionContainer>
        </CourseDescriptionWrapper>
    );
};
export default withTranslation()(CourseDescription);