import { string} from "../Rules";
import * as Yup from "yup";

export const VerifyValidationChangePassword = Yup.object().shape({


  currentPassword: string("currentPassword", true),
  newPassword: string("newPassword", true),
  repeatePassword: string("password", true),


});
