import React, { useEffect } from 'react';
import {LearningWrapprr} from "./styles";
import CourseDescription from "./components/courseDescription";
import RelatedCourse from "./components/relatedCourses";
import Comment from "./components/comment";
import InstructorAbout from "./components/instructorAbout";
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import CommentsActions from "../../redux/Comment/Actions";
import CourseActions from "../../redux/Course/Actions";

const Learning = () => {
    const theme = useSelector((state) => state.App.theme);
    const commentState = useSelector((state) => state.Comment);
    const {  skip ,take} = commentState;
    const dispatch = useDispatch();
    const {location} = useHistory();

    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(CommentsActions.getComments({course_id :location.state.id, offset:skip , limit:take}));
      dispatch(CourseActions.getCourses({
        related:true,
        recent:false,
        has_discount:false,
        courseType:"related",
        course_id :location.state.id
      }));
      dispatch(CourseActions.getCourseItem({course_id :location.state.id}));
    }, [location?.pathname]);



    return (
        <LearningWrapprr>
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor}/>
            <CourseDescription data={location?.state?.data}/>
            <InstructorAbout data={location?.state?.data}/>
            <Comment data={location?.state?.data}/>
            <RelatedCourse data={location?.state?.data}/>
            <Footer />
        </LearningWrapprr>
    )
}
export default Learning;