import React, { useEffect } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import {DashboardWrapper} from "./styles";
import {Grid ,Box} from "@mui/material";
import UpComingCourse from "./components/upComingCourses";
import RecentOrders from "./components/recentOrders";
import RecentAnnouncement from "./components/recentAnnouncement";
import {useDispatch , useSelector} from "react-redux"
import ProfileActions from "../../../../redux/Profile/Actions";
import PrivateCourseActions from "../../../../redux/PrivateCourse/Actions";

const Dashboard = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ProfileActions.getNotifications({}));
        dispatch(PrivateCourseActions.getMyCoursesList({ offset: 0, limit: 10 }));
    },[]);
  
    return (
     <MainLayout >
         <DashboardWrapper>
        <Grid container>
            <Grid xs={12} md={6} items p={1}>
                <Box className="card-container">
                    <UpComingCourse />
                </Box>
            </Grid>
            <Grid xs={12} md={6} items p={1}>
                 <Box className="card-container">
                    <RecentAnnouncement />
                </Box>
            </Grid>
            <Grid xs={12} items p={1}>
                 <Box className="card-container">
                    <RecentOrders />
                </Box>
            </Grid>
        </Grid>
        </DashboardWrapper>
    </MainLayout>
    )
}
export default Dashboard;