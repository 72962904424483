import React from 'react';
import {ContactBoxContainer} from "../styles"
import {Grid ,Box} from "@mui/material";
import { withTranslation } from "react-i18next";
import LocationIcon from "../../../assets/icons/LocationIcon";
import WhatsappIcon from "../../../assets/icons/WhatsappIcon";
import EmailIcon from "../../../assets/icons/EmailIcon";
const { 
    REACT_APP_COMPANY_ADDRESS,
    REACT_APP_COMPANY_TEL,
    REACT_APP_COMPANY_EMAIL
 } = process.env;


const ContactIntro = ({t}) => {

    return (
        <Grid container id="contact-intro-container">
        <Grid item xs={12} sm={4} md={4} lg={4} className="box-container">
            <ContactBoxContainer >
                <Box className="svg-container"><LocationIcon /></Box>
                <Box className="text-container">{t("address")}</Box>
                <Box className="text-container" display="flex" justifyContent="center">
                {REACT_APP_COMPANY_ADDRESS}
                </Box>
            </ContactBoxContainer>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} className="box-container">
            <ContactBoxContainer >
                <Box className="svg-container"><WhatsappIcon /></Box>
                <Box className="text-container">{t("whatsapp")}</Box>
                <Box className="text-container">{REACT_APP_COMPANY_TEL}</Box>
            </ContactBoxContainer>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4} className="box-container">
            <ContactBoxContainer >
                <Box className="svg-container"><EmailIcon /></Box>
                <Box className="text-container">{t("email")}</Box>
                <Box className="text-container">{REACT_APP_COMPANY_EMAIL}</Box>
            </ContactBoxContainer>
        </Grid>

    </Grid>
    )
}
export default withTranslation()(ContactIntro);