import React from 'react';
import {Box} from "@mui/material";

const CarouselBox  = ({box}) => {
    return (
        <Box
        display="flex"
        alignItems="center"
        justifyContent= "center"
        height={200}
        bgcolor="primary.main"
        >
            {box.content}
        </Box>
    )
}
export default CarouselBox;