import React, { Component } from "react";
import Content from "../content";
import ContentImage from "../../assets/images/content.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Arrow from '../../assets/icons/arrowIcon'
import {
  CarouselLayout
  , RowWrapper,
  SvgWrapper, ArrowL, ArrowR
} from './styles';

export default class Carousel extends Component {

  handleNext = (e) => {
    e.preventDefault();
    this.slider.slickNext();
  }

  handlePrev = (e) => {
    e.preventDefault();
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 1800,
      rtl: true,
      className: "center",
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 1360,
          settings: {
            slidesToShow: 4,
            centerPadding: "0px",
          }
        },
        {
          breakpoint: 1240,
          settings: {
            centerPadding: "0px",
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 921,
          settings: {
            centerPadding: "0px",
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            centerPadding: "0px",
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: "0px",
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  
    return (
      <CarouselLayout  color={this.props.color}>
        <RowWrapper>
          <div>
            <Slider {...settings} ref={(slider) => this.slider = slider}>
              {
               this.props.data && this.props.data?.map((item,index) => {
                  return (
                    <Content data={item} key={index}/>
                  )
                })
              }
            </Slider>
            <SvgWrapper color={this.props.color}>
              <ArrowR onClick={this.handleNext}><Arrow /></ArrowR>
              <ArrowL onClick={this.handlePrev}><Arrow /></ArrowL>
            </SvgWrapper>
          </div>
        </RowWrapper>

      </CarouselLayout>
    );
  }
};
