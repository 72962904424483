import React from 'react';
import { InstructorProfileWrapper, ProfileBoxContainer , TitleContainer ,ImageConatiner } from "../styles";
import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
const { REACT_APP_API_URL } = process.env;

const Instructor = ({ t ,data ,masterDetail}) => {
    const lang = useSelector((state) => state.App.lang);
    return (
        <InstructorProfileWrapper lang={lang}>
            <ImageConatiner src={REACT_APP_API_URL + "/" + masterDetail.photo}/>
            <TitleContainer color="#000" id="insructor_name">
            {lang === "fa" ? masterDetail.fa_first_name+" "+masterDetail.fa_last_name : masterDetail.en_first_name+" "+masterDetail.en_last_name}

            </TitleContainer>
            <TitleContainer >{t("music")}</TitleContainer>
            <Grid container>
                <Grid item xs={12} sm={6} md={3} lg={3} className="box-container">
                    <ProfileBoxContainer >
                        <Box className="text-container">{t("Number of active courses")}</Box>
                        <Box className="number-container">{masterDetail.active_course_count} {t("number")}</Box>
                    </ProfileBoxContainer>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className="box-container">
                    <ProfileBoxContainer >
                        <Box className="text-container">{t("students number")}</Box>
                        <Box className="number-container">{masterDetail.student_count} {t("persons")}</Box>
                    </ProfileBoxContainer>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className="box-container">
                    <ProfileBoxContainer >
                        <Box className="text-container">{t("average score")}</Box>
                        <Box className="number-container">{masterDetail.rate} {t("of")} 5</Box>
                    </ProfileBoxContainer>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} className="box-container">
                    <ProfileBoxContainer >
                        <Box className="text-container">{t("Duration of training")}</Box>
                        <Box className="number-container">{masterDetail.learning_sum_hours} {t("hour")}</Box>
                    </ProfileBoxContainer>

                </Grid>
            </Grid>
        </InstructorProfileWrapper>
    )
}
export default withTranslation()(Instructor);