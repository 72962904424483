import styled from 'styled-components';
import Col from "../../../../kit/Column";

export const CourseContainer = styled(Col)`
    padding: 20px 0;
    width:100%;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    #more_button{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: 1px solid ${(props) => props.theme.btnColor};
        width: 200px;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
     }

`;