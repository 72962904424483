import React from "react";

function Arrow() {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3203 53.3398L42.6536 32.0065L21.3203 10.6732" stroke="#3D54A8" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default Arrow;
