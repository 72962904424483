import React from "react";
import {SearchWrapprr} from "./styles";
import { FormInput } from "../../kit/Form";

const InputSearch = ({value, onChange , placeholder , name , id , className , width}) => {

    return (
        <SearchWrapprr width={width}>
            <FormInput type="text"  placeholder={placeholder} value={value}  onChange={onChange} className={className} name={name} id={id}/>
            <span class="search-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="20px" height="20px" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                </svg>
            </span>
        </SearchWrapprr>
    )
}
export default InputSearch;