export const daysListOptions = [
    { value: "sat", label: "شنبه" },
    { value: "sun", label: "یک شنبه" },
    { value: "mon", label: "دوشنبه" },
    { value: "tue", label: "سه شنبه" },
    { value: "wed", label: "چهار شنبه" },
    { value: "thu", label: "پنج شنبه" },
    { value: "fri", label: "جمعه" },
  
]
export const hoursListOptions = [
    { value: "00:00 - 01:00", label: "00:00 - 01:00" },
    { value: "01:00 - 02:00", label: "01:00 - 02:00" },
    { value: "02:00 - 03:00", label: "02:00 - 03:00" },
    { value: "03:00 - 04:00", label: "03:00 - 04:00" },
    { value: "04:00 - 05:00", label: "04:00 - 05:00" },
    { value: "05:00 - 06:00", label: "05:00 - 06:00" },
    { value: "07:00 - 08:00", label: "07:00 - 08:00" },
    { value: "08:00 - 09:00", label: "08:00 - 09:00" },
    { value: "09:00 - 10:00", label: "09:00 - 10:00" },
    { value: "10:00 - 11:00", label: "10:00 - 11:00" },
    { value: "11:00 - 12:00", label: "11:00 - 12:00" },
   
]

export const genderListOptions = [
    { value: "male", label: "مرد" },
    { value: "female", label: "زن" },
    { value: "other", label: "سایر" },
]