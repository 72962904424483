import React from "react";
import { Formik } from "formik";
import FormController from "../../../controllers/FormController";
import { LoginFormWrapper, TitleContainer, FormContainer, TextContainer,LogoContainer } from "./styles";
import { FormInput } from "../../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "../../../redux/Login/Actions"
import { withTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";
import {VerifyValidationRegister} from "../../../validation/Schemas/VerifyValidationRegister";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const RegisterForm = ({ setMode, t }) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.App.lang);
    const onUserRegister = (values, action) => {
        dispatch(LoginActions.register({ values, action }))
    }
    return (
        <LoginFormWrapper lang={lang}>
              <Link to='/'><LogoContainer src={Logo} /></Link>
            <TitleContainer>
                {t("register")}
            </TitleContainer>
            <FormContainer>
                <Formik
                    initialValues={{
                        password: "",
                        email: "",
                        firstName: "",
                        lastName: "",

                    }}
                    onSubmit={(values, actions) => {
                        onUserRegister(values, actions);
                    }}
                 validationSchema={VerifyValidationRegister}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid items xs={6} py={1}>
                                    <Box dispaly="flex" pl={1} flexDirection="column">
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.firstName && formik.touched.firstName ? " warning-border" : "")
                                            }
                                            value={formik.values.firstName}
                                            id="firstName"
                                            name="firstName"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("firstName")}
                                        />
                                          {/* {formik.errors.firstName && formik.touched.firstName ? (
                                            <div className="text-danger">{formik.errors.firstName}</div>
                                        ) : null} */}
                                    </Box>
                                </Grid>
                                <Grid items xs={6} py={1}>
                                    <Box dispaly="flex" pr={1}>
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.lastName && formik.touched.lastName ? " warning-border" : "")
                                            }
                                            value={formik.values.lastName}
                                            id="lastName"
                                            name="lastName"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("lastName")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid items xs={12} py={1}>
                                    <Box dispaly="flex" >
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                            }
                                            value={formik.values.email}
                                            id="email"
                                            name="email"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("email")}
                                        />
                                        {/* {formik.errors.email && formik.touched.email ? (
                                            <div className="text-danger">{formik.errors.email}</div>
                                        ) : null} */}
                                    </Box>
                                </Grid>
                                <Grid items xs={12} py={1}>
                                    <Box dispaly="flex" >
                                        <FormInput
                                            type="password"
                                            className={
                                                "login-form-control" + (formik.errors.password && formik.touched.password ? " warning-border" : "")
                                            }
                                            value={formik.values.password}
                                            id="password"
                                            name="password"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("password")}
                                        />
                                        {/* {formik.errors.password && formik.touched.password ? (
                                            <div className="text-danger">{formik.errors.password}</div>
                                        ) : null} */}
                                    </Box>
                                </Grid>
                                <Grid items xs={12} py={1}>
                                    <Box dispaly="flex" >
                                        <button
                                            color="primary"
                                            size="lg"
                                            onClick={formik.handleSubmit}
                                            className="ml-1"
                                        >
                                            <span className="color-white">{t("register")}</span>
                                        </button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </form>
                    )}
                </Formik>
            </FormContainer>
            <TextContainer >
                {t("Alraedy a member?")} <span id="login" onClick={() => { setMode("login") }}>{t("login")}</span>
            </TextContainer>
        </LoginFormWrapper>
    )
}
export default withTranslation()(RegisterForm)