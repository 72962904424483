import React from "react";
import { LoginRegisterFormWrapper } from "./styles";
import LoginForm from "../LoginForm";
import RegiserForm from "../RegisterForm";

const LoginRegisterContent = ({ mode, setMode }) => {
    return (
        <LoginRegisterFormWrapper mode={mode}>
            {mode === "login" ?
                <LoginForm setMode={setMode} /> :
                <RegiserForm setMode={setMode}/>}
        </LoginRegisterFormWrapper>
    )
}
export default LoginRegisterContent;