import * as Yup from "yup";
const requireMessage = "این فیلد الزامی است.";

export const email = (label, isRequire) => {
  if (isRequire) {
    return Yup.string().label(label).required(requireMessage);
  } else {
    return Yup.string().label(label);
  }
};


export const description = (label, isRequire = false) => {
  if (isRequire) {
    return Yup.string().label(label).max(1000).required(requireMessage);
  } else return Yup.string().label(label).max(1000);
};

export const string = (label, isRequire = false) => {
  if (isRequire) {
    return Yup.string()
      .label(label)
      .typeError(requireMessage)
      .required(requireMessage);
  } else {
    return Yup.string().label(label).nullable(true);
  }
};
export const number = (label, isRequire = false) => {
  if (isRequire) {
    return Yup.number()
      .label(label)
      .typeError(requireMessage)
      .required(requireMessage);
  } else {
    return Yup.string().label(label).nullable(true);
  }
};

export const file = (label, isRequire = false) => {
  if (isRequire) {
    return Yup.mixed()
      .label(label)
      .typeError(requireMessage)
      .required(requireMessage);
  } else {
    return Yup.mixed().label(label).nullable(true);
  }
};

