import types from './Types';
import RecordController from "../../controllers/RecordController";
import { prepareSelectData } from "../../utils";

const initState = {
  showMasterDetailModal: false,
  showDeleteMasterModal: false,
  mastersList: [],
  mastersListOptions: [],
  mastersLoading: true,
  takeMaster: 12,
  skipMaster: 0,
  hasMore: false,
  countMaster: 0,
  mode:"add",
  masterDetail :[]
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    case types.SET_SHOW_MASTER_DETAIL_MODAL:
      return {
        ...state,
        showMasterDetailModal: action.data.visible,
        mode : action.data.mode,
      };
    case types.SET_SHOW_DELETE_MASTER_MODAL:
      return {
        ...state,
        showDeleteMasterModal: action.data.visible,
      };
    case types.GET_MASTERS_LIST:
      return {
        ...state,
        mastersLoading: true,

      };
    case types.GET_MASTERS_LIST_SUCCESS:
      let newMastersList = [...state.mastersList];
      newMastersList = [...action.data.data];
      return {
        ...state,
        mastersLoading: false,
        mastersList: newMastersList,
        skipMaster: state.skipMaster + state.takeMaster,
        countMaster: action.data.count,
        hasMore: state.skipMaster + state.takeMaster < action.data.count ? true : false,
        mastersListOptions : prepareSelectData("master", action.data.data)
      };
    case types.UPDATE_MASTER_SUCCESS:
      return {
        ...state,
        mastersList: RecordController.update(
          state.mastersList,
          action.data
        ),
       showMasterDetailModal: false,
        mode :"add"
      };
    case types.ADD_MASTER_SUCCESS:
      return {
        ...state,
        mastersList: RecordController.add(
          state.mastersList,
          action.data
        ),
        showMasterDetailModal: false,
        mode :"add"
      };
    case types.DELETE_MASTER_SUCCESS:
      let result = { id: action.data };
      return {
        ...state,
        mastersList: RecordController.delete(
          state.mastersList,
          result
        )
      };
      case types.GET_MASTER_BY_ID_SUCCESS:
        return {
          ...state,
          masterDetail : action.data
        };
    default:
      return state;
  }
}
