import styled from "styled-components";
import Col from "../../../kit/Column";
import Row from "../../../kit/Row";
export const LoginFormWrapper = styled(Col)`
width :100%;
padding-right :400px;
  z-index: 199;
.field-container{
    padding:5px 0;
}
input : focus {
   background-color: inherit !important;
}
button{
   margin-top :10px;
   background-color: ${(props) => props.theme.btnColor};
   border-radius: ${(props) => props.theme.borderRadius};
   border: none;
   width: 100%;
   padding: 10px;
   color : ${(props) => props.theme.white};
   cursor :pointer;
   font-family: ${(props) => (props.theme.fontDefault)};

}
font-family: ${(props) => (props.theme.fontDefault)};
@media screen and (max-width: 900px) {
    width :80%;
    padding-right :0px
    }
*{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

`;
export const TitleContainer = styled(Row)`
    margin: 20px 0;
    font-size: ${(props) => props.theme.fontSizeXl};
    font-weight : bold;
  font-size: 24px;
    justify-content : flex-start;
    width: 100%;
    color :  ${(props) => props.theme.text};
`;
export const FormContainer = styled(Row)`
    width: 100%;
    form{
        width :100%;
        padding-top:20px;
    }
`;
export const BtnContainer = styled(Row)`
    margin :10px 0;
`;
export const TextContainer = styled(Row)`
    color :  ${(props) => props.theme.text};
    font-size : 13px; // ${(props) => props.theme.fontSizeSm};
    //justify-content : flex-start;
    cursor : pointer;
    text-align: right;
    #signUp{
      color : ${(props) => props.theme.secondaryFontColor};
    }
    #login{
    font-size : ${(props) => props.theme.fontSizeLg};
    }
    font-family: ${(props) => (props.theme.fontBold)};

`;

export const LogoContainer = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: contain;
    width: 200px;
    height: 200px;
    justify-content : flex-start;
`;