import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux"

const English = ({ t }) => {
  const stateCourse = useSelector((state) => state.Course);
  const { englishList } = stateCourse;
  return (
    englishList.length > 0 &&
    <ContentWrapprr>
      <Title title={t("english")} hasMoreView={true} tabValue={englishList[0]?.category_id} data={englishList} />
      <Carousel data={englishList} />
    </ContentWrapprr>
  );
};
export default withTranslation()(English);
