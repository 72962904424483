import React from 'react';
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import FormController from "../../../controllers/FormController";
import { FormWrapper, FormContainer } from "../styles";
import Row from "../../../kit/Row";
import { FormInput } from "../../../kit/Form";
import { StyledTextArea } from "../../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "../../../redux/Login/Actions"
import { Grid, Box } from "@mui/material";

const ContactForm = ({ t }) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.App.lang);
    const onUserRegister = (values, action) => {
        dispatch(LoginActions.register({ values, action }))
    }
    return (
        <FormWrapper lang={lang}>

            <FormContainer>
                <Formik
                    initialValues={{
                        email: "",
                        text: "",
                        firstName: "",
                        lastName: "",

                    }}
                    onSubmit={(values, actions) => {
                        onUserRegister(values, actions);
                    }}
                // validationSchema={}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid items xs={6} p={1}>
                                    <Box dispaly="flex">
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.firstName && formik.touched.firstName ? " warning-border" : "")
                                            }
                                            value={formik.values.firstName}
                                            id="firstName"
                                            name="firstName"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("firstName")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid items xs={6} p={1}>
                                    <Box dispaly="flex">
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.lastName && formik.touched.lastName ? " warning-border" : "")
                                            }
                                            value={formik.values.lastName}
                                            id="lastName"
                                            name="lastName"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("lastName")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid items xs={12} p={1}>
                                    <Box dispaly="flex" >
                                        <FormInput
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.email && formik.touched.email ? " warning-border" : "")
                                            }
                                            value={formik.values.email}
                                            id="email"
                                            name="email"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("email")}
                                        />
                                        {formik.errors.email && formik.touched.email ? (
                                            <div className="email-danger">{formik.errors.email}</div>
                                        ) : null}
                                    </Box>
                                </Grid>
                                <Grid items xs={12} p={1}>
                                    <Box dispaly="flex" >
                                        <StyledTextArea
                                            type="text"
                                            className={
                                                "login-form-control" + (formik.errors.text && formik.touched.text ? " warning-border" : "")
                                            }
                                            value={formik.values.text}
                                            id="text"
                                            name="text"
                                            onChange={(e) => FormController.onChange(e, formik)}
                                            onBlur={(e) => FormController.onBlur(e, formik)}
                                            placeholder={t("text")}
                                        />
                                        {formik.errors.text && formik.touched.text ? (
                                            <div className="text-danger">{formik.errors.text}</div>
                                        ) : null}
                                    </Box>
                                </Grid>
                                <Grid items xs={12} p={1}>
                                    <Box dispaly="flex" >
                                        <button
                                            color="primary"
                                            size="lg"
                                            onClick={formik.handleSubmit}
                                            className="ml-1"
                                        >
                                            <span className="color-white">{t("send")}</span>
                                        </button>
                                    </Box>
                                </Grid>
                            </Grid>



                        </form>
                    )}
                </Formik>
            </FormContainer>
        </FormWrapper>
    )
}
export default withTranslation()(ContactForm);