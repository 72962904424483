import React, {useEffect} from 'react';
import SelectTime from "./components/selectTime";
import CompleteInfo from "./components/completeInfo";
import Header from '../../shared/header';
import { useSelector, useDispatch } from "react-redux";
import { CoursesRegisterWrapprr, TabContainer, ActionsContainer, TabBodyContainer } from "./styles"
import Purchase from "./components/purchase";
import Payment from "./components/payment";
import { withTranslation } from "react-i18next";
import { Button, Stack ,Box} from "@mui/material";
import PaymentActions from "../../redux/Payment/Actions";
import Steppercustom from "../../shared/stepper"
import { CacheProvider } from '@emotion/react'
import { cacheRtl ,cacheLtr } from '../../createEmotionCache';
// import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CourseActions from "../../redux/Course/Actions";

const CourseRegister = ({ t, setTime }) => {
  const theme = useSelector((state) => state.App.theme);
  const lang = useSelector((state) => state.App.lang);
  const paymentStep = useSelector((state) => state.Payment.paymentStep);
  const meetingList = useSelector((state) => state.Payment.meetingList);
  const selectedCourseItem = useSelector((state) => state.Course.selectedCourseItem);
  const dispatch = useDispatch();

  useEffect(()=>{
      const params = new URLSearchParams(window.location.search);
      dispatch(CourseActions.getCourseItem({course_id :parseInt(params.get("id"))}));
      // dispatch(PaymentActions.getMasterAvailableTime().getCourseItem({course_id :parseInt(params.get("id"))}));
  },[])
  const PaymentStepTarget = (step) => {
    switch (step) {
      case 0:
        return <SelectTime data={selectedCourseItem}/>;
      case 1:
        return <CompleteInfo data={selectedCourseItem}/>;
      case 2:
        return <Payment data={selectedCourseItem}/>;
      case 3:
        return <Purchase data={selectedCourseItem}/>;
      default:
        return <SelectTime data={selectedCourseItem}/>;
        break;
    }
  };

  const OnConfirm =() => {
    if(paymentStep === 0){

      //
      //if(meetingList.length === selectedCourseItem.sessions){
      //}
      if (meetingList.length > 0){
        dispatch(PaymentActions.nextPaymentStep(paymentStep));
      } else{
        let message= t("Choose your sessions");
        toast.warning(message, {position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT});
      }
    }
    else if(paymentStep === 1){
      let times = [];
      meetingList.map(each => {
        times.push({date : each.date , start : each.start_time , end :each.end_time})
      })
      // setTime(times)
      dispatch(PaymentActions.nextPaymentStep(paymentStep));
    }
    else{
      dispatch(PaymentActions.nextPaymentStep(paymentStep));
    }
   
  }
  const bookCourse = () => {

  }

  return (
    <CacheProvider value={lang === "fa" ? cacheRtl : cacheLtr}>
    
      <CoursesRegisterWrapprr lang={lang}>
        <Header backgroundColor={theme.white} color={theme.secondaryFontColor} />
        <TabContainer>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Steppercustom activeStep={paymentStep} />
            </Stack>
        </TabContainer>
        <TabBodyContainer>
          { PaymentStepTarget(paymentStep) }
        </TabBodyContainer>
        <TabContainer>
          <ActionsContainer justify="end" lang={lang}>
            <Button className="btn disabled" onClick={() => {OnConfirm()}}>
              {t("confirm")}
            </Button>
            <Button className="btn disabled" id="return" onClick={() => { dispatch(PaymentActions.previousPaymentStep(paymentStep)) }} >
              {t("return")}
            </Button>
          </ActionsContainer>
        </TabContainer>
      </CoursesRegisterWrapprr>
    </CacheProvider>
  )
}
export default withTranslation()(CourseRegister);