import React, { useEffect } from 'react';
import { Grid, Box, Button } from "@mui/material";
import ClockIcon from "../../../../assets/icons/ClockIcon";
import MainLayout from "../../../../shared/dashboardMainLayout"
import { TitleContainer, ContenttWrapper } from "../../globalStyle";
import { AnnouncemnetContainer } from "./styles";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import ProfileActions from "../../../../redux/Profile/Actions";

const Dashboard = ({ t }) => {
    const lang = useSelector((state) => state.App.lang);
    const ProfileState = useSelector((state) => state.Profile);
    const { notificationListLoading , notificationList , hasMore } = ProfileState;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ProfileActions.getNotifications({}));
    }, []);

    const laodMore = () => {
        dispatch(ProfileActions.getNotifications({}));
      }

    return (
        <MainLayout>
            <ContenttWrapper>
                <TitleContainer>{t("recent notifications")}</TitleContainer>

                {
                    notificationListLoading !== true ? notificationList.length > 0 ?
                        notificationList.map((each, index) => {
                            return (
                                <AnnouncemnetContainer key={index}>
                                    <Grid container lg={12}>
                                        <Grid item xs={7} md={6} display="flex">
                                            <Box className="title_container">
                                                {t("title")}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5} md={6} display="flex" justifyContent="end">
                                            <Box>
                                                1400-05-10
                                            </Box>
                                            <Box px={1}><ClockIcon fill="#3b3b3b" /></Box>
                                        </Grid>
                                        <Grid item xs={12} display="flex" justifyContent="end">

                                        </Grid>
                                        <Grid item xs={12} display="flex" py={2}>
                                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است...
                                        </Grid>
                                    </Grid>
                                </AnnouncemnetContainer>
                            )
                        })
                        : <>{t("no data to dispaly")}</>
                        : <div className="loader"></div>
                }
                {hasMore === true &&
                    <Button className="btn" onClick={() => laodMore()}>{t("all items")}</Button>
                }
            </ContenttWrapper>
        </MainLayout>
    )
}
export default withTranslation()(Dashboard);