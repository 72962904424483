import { string,email , number ,file} from "../Rules";
import * as Yup from "yup";

export const VerifyValidationCourse = Yup.object().shape({

    fa_title: string("fa_title", true),
    en_title: string("en_title", true),
    fa_description: string("fa_description", true),
    en_description: string("en_description", true),
    status: string("status", true),
    fa_subtitle: string("fa_subtitle", true),
    en_subtitle: string("en_subtitle", true),
    duration: number("duration", true),
    price: number("price", true),
    student_capacity: number("student_capacity",true),
    sessions: number("sessions",true),
    start_date_fa: string("start_date_fa", true),
    end_date_fa: string("end_date_fa", true),
    price_unit: string("price_unit", true),
   // image: file("image", true),
    category_id: number("category_id", true),
    master_id: number("master_id", true),
    course_type: string("course_type", true),

});
