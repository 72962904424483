import { ActionIconsContainer } from "../../../../shared/GeneralStyles";
import Row  from "../../../../kit/Row";
import TableActions from "../../../../shared/TableActions";
import StatementHandler from "../../../../utils/methods/StatementHandler";
import {Chip} from "@mui/material";

const OrdersTableConfig = (UpdateMaster, DeleteMaster,ToggleMaster) => {
  return {
    showHeader: true,

    row: [
      {
        headerTitle: "ردیف",
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: "شناسه سفارش",
        displayTitle: "order_id",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return <Row>Order-242-1111</Row>;
        },
      },
      {
        headerTitle: "شناسه پرداخت",
        displayTitle: "payment_id",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return <Row>Pay-123-43434</Row>;
        },
      },
      // {
      //   headerTitle: "تاریخ خرید",
      //   displayTitle: "payment_date",
      //   size: 2,
      //   responsiveShow: true,
      //   element: (props) => {
      //     return <Row>{props.row.start_session_time}</Row>;
      //   },
      // },
      {
        headerTitle: "نوع کلاس",
        displayTitle: "class_type",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return  <Chip label={props.row.course.course_type === "peer_to_peer" ? "تک نفره": "چند نفره"} variant="outlined" color={"default"} />
        },
      },
      {
        headerTitle: "نام محصول",
        displayTitle: "fa_title",
        size: 3,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
            {props.row.course.fa_title }
          </Row>
          );
        },
      },
   
      {
        headerTitle: "ایمیل",
        displayTitle: "book_email",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.book_email !=="" ? props.row.book_email : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: "شرکت کننده",
        displayTitle: "book_name",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              { props.row.user.fa_first_name +" " + props.row.user.fa_last_name }
            </Row>
          );
        },
      },
      {
        headerTitle: "استاد",
        displayTitle: "master",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.master.fa_first_name  + " " + props.row.master.fa_last_name }
            </Row>
          );
        },
      },
      
      {
        headerTitle: "وضعیت",
        displayTitle: "status",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              <Chip label={props.row.status === "" ? "-" : (props.row.status === "book_status_payment_success" ? "Confirmed" :   props.row.status)} variant="filled"
                    color={props.row.status === "book_status_payment_success" ? "success" : "default"} />

            </Row>
          );
        },
      },

      {
        headerTitle: "عملیات",
        displayTitle: "id",
        size: 1,
        responsiveShow: false,
        element: (props) => {
          return (
            <ActionIconsContainer>
              <TableActions type="share" onClick={() => window.location.href = props.row.google_meet_url}/>
              <TableActions
                type="delete"
                onClick={() => {
                  DeleteMaster(props.row);
                }}
              />
              <TableActions
                type="detail"
                onClick={() => {
                  ToggleMaster(props.row);
                }}
              />
            </ActionIconsContainer>
          );
        },
      },
    
    ],
  };
};
export default OrdersTableConfig;
