import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"

const Music = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {musicList} = stateCourse;
  return (
    musicList.length>0 &&
    <ContentWrapprr>
      <Title title={t("music")} hasMoreView={true} tabValue={musicList[0]?.category_id} data={musicList}/>
      <Carousel data={musicList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Music);
