import {
  all,
  takeEvery,
  put,
  fork,
  select
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";

export function* GetDiscounts() {
  yield takeEvery(types.GET_DISCOUNTS_LIST, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetDiscounts + `?offset=${data.offset}&limit=${data.limit}`, true);
      if (response) {
        yield put({
          type: types.GET_DISCOUNTS_LIST_SUCCESS,
          data: { data: response.data, count: response.total },

        });
      } else
        yield put({
          type: types.GET_DISCOUNTS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_DISCOUNTS_LIST_FAIL,
      });
    }
  });
}

export function* AddDiscount() {
  yield takeEvery(types.ADD_DISCOUNT, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);
    
    let body = {
        code : data.fields.code,
        max_usage : Number(data.fields.max_usage),
        category_id : data.fields.category_id,
        course_id : data.fields.course_id,
        value : Number(data.fields.value),
        percent : Number(data.fields.percent),
    }


    try {
      const response = yield Api.Post(Endpoints.AddDiscount, body, true,);
      if (response) {
        yield put({
          type: types.ADD_DISCOUNT_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.ADD_DISCOUNT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.ADD_DISCOUNT_FAIL,
      });
    }
  });
}

export function* DeleteDiscount() {
  yield takeEvery(types.DELETE_DISCOUNT, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Delete(Endpoints.DeleteDiscount + `/${data.Discount.id}`, true);
      if (response) {
        yield put({
          type: types.DELETE_DISCOUNT_SUCCESS,
          data: data.Discount.id

        });
        data?.callBack();
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.DELETE_DISCOUNT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.DELETE_DISCOUNT_FAIL,
      });
    }
  });
}

export function* UpdateDiscount() {
  yield takeEvery(types.UPDATE_DISCOUNT, function* ({ data }) {

    let body = {
      code : data.fields.code,
      max_usage : Number(data.fields.max_usage),
      category_id : data.fields.category_id,
      course_id : data.fields.course_id,
      value : Number(data.fields.value),
      percent : Number(data.fields.percent),
  }

    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Put(Endpoints.UpdateDiscount + `/${data.fields.id}`, body, true);
      if (response) {
        yield put({
          type: types.UPDATE_DISCOUNT_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.UPDATE_DISCOUNT_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.UPDATE_DISCOUNT_FAIL,
      });
    }
  });
}

export function* GetDiscountById() {
  yield takeEvery(types.GET_DISCOUNT_BY_ID, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetDiscountById + `/${data.id}`, false);
      if (response) {
        yield put({
          type: types.GET_DISCOUNT_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_DISCOUNT_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_DISCOUNT_BY_ID_FAIL,
      });
    }
  });
}

export default function* DiscountsSagas() {
  yield all(
    [
      fork(GetDiscounts),
      fork(AddDiscount),
      fork(DeleteDiscount),
      fork(UpdateDiscount),
      fork(GetDiscountById)
    ]);
}
