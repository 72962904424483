import React, {  useEffect } from 'react';
import SearchesView from "../../views/searches";


const Searches = () => {
 
    useEffect(() => {
       
    }, [])
   
    return (  <SearchesView />  )
}
export default Searches;