import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const InstructorWrapper = styled(Col)`
background-color:  ${props => props.theme.baseBackGroundColor};
margin-top :70px;
 width:100%;
 border-top :1px solid #ccc;
 box-shadow:0px 1px 3px 1px #b9b9b9;
 padding-top:30px;
`;
export const InstructorCoursesWrapper = styled(Col)`
    padding: 50px 10px;
    width:90%;
    #title_container{
        color: ${(props) => props.color ? props.color : props.theme.text};
        font-family: ${(props) => (props.theme.fontBold)};
        font-size: ${(props) => props.theme.fontSizeLg};
        width :100%;
    padding : ${(props) => props.lang === "fa" ? "15px 20px 15px 0" : "15px 0 15px 20px"}; 

    }
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const AboutInstructorWrapper = styled(Row)`
    width:90%;
    background-color: #EEEFF8;
    border-radius : 10px;
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
    box-sizing: border-box;
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;
export const AboutInstructorContainer = styled(Col)`
    width:90%;
    box-sizing: border-box;
    padding :20px 0;
    @media screen and (min-width: 1700px) and (max-width :2000px) {
        width : 70%;
       }
    @media screen and (min-width: 2000px)  {
        width : 65%;
    }
`;

export const TitleContainer = styled(Row)`
    color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
    font-family: ${(props) => (props.theme.fontBold)};
    justify-content : ${(props) => props.content === "start" ? "flex-start" : "center"};
    width:100%;
    padding :15px 0;
    font-size: ${(props) => props.theme.fontSizeLg};

`;
export const AboutContainer = styled(Col)`
    color: ${(props) => props.color ? props.color : props.theme.text};
    font-family: ${(props) => (props.theme.fontBold)};
    align-items : flex-start;
    width:100%;

    .text-container{
        padding :6px 0;
    }
`;


export const InstructorProfileWrapper = styled(Col)`
width:90%;
padding : 30px;
.number-container {
    color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
    padding:10px 0;
    font-family: ${(props) => (props.theme.fontBold)};
    font-size: ${(props) => props.theme.fontSizeLg};
    direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
}
.text-container {
    color: ${(props) => props.color ? props.color : props.theme.text};
    padding:10px 0;
    font-family: ${(props) => (props.theme.fontBold)};
    font-size: ${(props) => props.theme.fontSizeMd};
}
#insructor_name{
    font-size: ${(props) => props.theme.fontSizeXl};

}
@media screen and (min-width: 1700px) and (max-width :2000px) {
    width : 70%;
   }
@media screen and (min-width: 2000px)  {
    width : 65%;
}

`;

export const ProfileBoxContainer = styled(Col)`
    margin:2%;
    height : 150px;
    border-radius : 10px;
    background-color: #EEEFF8;
`;
export const ImageConatiner = styled(Row)`
    height : 100px;
    width : 100px;
    border-radius : 50%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: ${(props) => props.lang === "fa" ? "right" : "left"};
    background-size: cover;
    border :1px solid #ccc;
  

`;

