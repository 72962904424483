import React from "react";
import { Formik } from "formik";
import FormController from "../../../controllers/FormController";
import { LoginFormWrapper, TitleContainer, FormContainer, BtnContainer, TextContainer ,LogoContainer } from "./styles";
import { FormInput } from "../../../kit/Form";
import { useDispatch, useSelector } from "react-redux";
import LoginActions from "../../../redux/Login/Actions";
import { useTranslation } from "react-i18next";
import { VerifyValidationLogin } from "../../../validation/Schemas/VerifyValidationLogin";
import Logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Grid } from "@mui/material"
const LoginForm = ({ setMode }) => {
    const { t } = useTranslation();
    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    const onUserLogin = (values, action) => {
        dispatch(LoginActions.login({ values, action }))
    }

    return (
        <LoginFormWrapper lang={lang}>
            <Link to='/'><LogoContainer src={Logo} /></Link>
            <TitleContainer>
                {t("login")}
            </TitleContainer>
            <FormContainer>
                <Formik
                    initialValues={{
                        password: "",
                        username: ""
                    }}
                    onSubmit={(values, actions) => {
                        onUserLogin(values, actions);
                    }}
                    validationSchema={VerifyValidationLogin}
                >
                    {(formik) => (
                        <form className="" onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid items xs={12} py={1}>
                                    <FormInput
                                        type="text"
                                        className={
                                            "login-form-control" + (formik.errors.username && formik.touched.username ? " warning-border" : "")
                                        }
                                        value={formik.values.username}
                                        id="username"
                                        name="username"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("email")}
                                    />
                                    {/* {formik.errors.username && formik.touched.username ? (
                                    <div className="text-danger">{formik.errors.username}</div>
                                ) : null} */}
                                </Grid>
                                <Grid items xs={12} py={1} >
                                    <FormInput
                                        type="password"
                                        className={
                                            "login-form-control" + (formik.errors.password && formik.touched.password ? " warning-border" : "")
                                        }
                                        value={formik.values.password}
                                        id="password"
                                        name="password"
                                        onChange={(e) => FormController.onChange(e, formik)}
                                        onBlur={(e) => FormController.onBlur(e, formik)}
                                        placeholder={t("password")}
                                    />
                                    {/* {formik.errors.password && formik.touched.password ? (
                                    <div className="text-danger">{formik.errors.password}</div>
                                ) : null} */}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"right"} justifyItems={"right"} >
                                <Grid item xs={12}   md={6} >
                                    <FormControlLabel control={<Checkbox defaultChecked />} label={t("remember me")} />
                                </Grid>
                                <Grid item xs={12} py={2} md={6} >
                                    <Link to="/forgetPassword">
                                        <TextContainer>
                                            {t("Forgot Password?")}
                                        </TextContainer>
                                    </Link>
                                </Grid>
                            </Grid>
                            <BtnContainer>
                                <button
                                    color="primary"
                                    size="lg"
                                    onClick={formik.handleSubmit}
                                    type="submit"
                                    style={{"font-size":"20px","margin":"10px 0px"}}
                                >
                                    <span className="color-white"> {t("login")}</span>
                                </button>
                            </BtnContainer>

                            <TextContainer >
                                {t("Don`t have an account?")} &nbsp;&nbsp;<span id="signUp" onClick={() => { setMode("register") }}>{t("Create one!")}</span>
                            </TextContainer>
                        </form>
                    )}
                </Formik>
            </FormContainer>
        </LoginFormWrapper>
    )
}
export default LoginForm