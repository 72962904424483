import { React } from "react";
import {
    BannerWrapper,
    SearchContainer,
    TitleContainer,
    TextConatiner,
    FormContainer,

} from './styles';
import { InputLabel, Select, MenuItem, Grid, Button, FormControl } from "@mui/material";
import faBanner from "../../../../assets/images/mainbanner.svg";
import enBanner from "../../../../assets/images/en-Banner.png";

import { FormInput } from "../../../../kit/Form";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";
import SerachInput from "../../../../shared/inputSearch"
const Banner = ({ t }) => {
    const lang = useSelector((state) => state.App.lang);
    const stateCourse = useSelector((state) => state.Course);
    const { categoriesListOptions } = stateCourse;
    const [q, setQ] = useState("all")
    const doSearch = (e) => {
        window.location.href = "/search?q=" + q
    }
    return (

        <BannerWrapper cover={lang === "fa" ? faBanner : enBanner} lang={lang}>
            <SearchContainer lang={lang}>
                <TitleContainer >
                    {t("select your requirement courses")}
                </TitleContainer>
                <TextConatiner>
                    {t("iranmeetup header text")}
                </TextConatiner>

                <Grid container>
                    <Grid item xs={9} >
                        <FormContainer >
                            <FormControl  style={{ "width": "10rem", "color": "white", "backgroundColor": "white" }}  >
                                <InputLabel  size={"normal"} color={"secondary"} style={{ "color": "white" }} id={"demo-simple-select-label"} >{t("category")}</InputLabel>
                                <Select style={{ "color": "white" }} labelId="demo-simple-select-label" fullWidth={true} id="demo-simple-select" >
                                    {categoriesListOptions && categoriesListOptions?.map((item, index) => {
                                        return (
                                            <MenuItem selected={true} value={item.value}>   {lang === "fa" ? item?.fa_name : item?.en_name} </MenuItem>
                                        )
                                    })}
                                    {/* 
                                    <MenuItem value={10}>{t("programming")} </MenuItem>
                                    <MenuItem value={20}>{t("english")} </MenuItem>
                                    <MenuItem value={30}>{t("law")} </MenuItem>
                                    <MenuItem value={10}>{t("sport")} </MenuItem>
                                    <MenuItem value={20}>{t("music")} </MenuItem>
                                    <MenuItem value={30}>{t("psychology")} </MenuItem> */}
                                </Select>
                            </FormControl>
                            {/* <FormInput id="search" name="search" onChange={(e) => setQ(e.target.value)} placeholder={t("search") + "..."} /> */}
                         <SerachInput id="search" onChange={(e) => setQ(e.target.value)}  placeholder={t("search") + "..."} width="100%" />

                        </FormContainer>
                    </Grid>
                    <Grid item xs={3}>
                        <Button onClick={doSearch}>{t("search")}</Button>
                    </Grid>
                </Grid>

            </SearchContainer>
        </BannerWrapper>
    )
}

export default withTranslation()(Banner);
