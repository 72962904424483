import types from './Types';
import RecordController from "../../controllers/RecordController";

const initState = {
  showCommentModal: false,
  commentsList: [],
  commentsLoading: true,
  takeComment: 3,
  skipComment: 0,
  hasMoreCourse: false,
  count: 0,
};

export default function Reducer(state = initState, action) {
  // alert(action.data)
  switch (action.type) {
    case types.SET_SHOW_COMMENT_MODAL:
      return {
        ...state,
        showCommentModal: action.data.visible,
      };
    case types.GET_COMMENTS_SUCCESS:
      return {
        ...state,
        commentsLoading: false,
        commentsList: [...state.commentsList, ...action.data.data] ,
        skipComment: state.skipComment + state.takeComment ,
        count: action.data.count,
        hasMore:  state.skipComment + state.takeComment < action.data.count ? true : false,

      };
      case types.ADD_COMMENT_SUCCESS:
        return {
          ...state,
          commentsList: RecordController.add(state.commentsList, action.data)
        };
        case types.DELETE_COMMENT_SUCCESS:
          let result = {id:action.data};
        return {
          ...state,
          commentsList: RecordController.delete(state.commentsList, result)
        };

    default:
      return state;
  }
}
