export default {
  SET_SHOW_DISCOUNT_DETAIL_MODAL: 'SET_SHOW_DISCOUNT_DETAIL_MODAL',
  SET_SHOW_DELETE_DISCOUNT_MODAL: 'SET_SHOW_DELETE_DISCOUNT_MODAL',


  GET_DISCOUNTS_LIST: 'GET_DISCOUNTS_LIST',
  GET_DISCOUNTS_LIST_SUCCESS: 'GET_DISCOUNTS_LIST_SUCCESS',
  GET_DISCOUNTS_LIST_FAIL: 'GET_DISCOUNTS_LIST_FAIL',
  GET_DISCOUNTS_LIST_EXCEPTION: 'GET_DISCOUNTS_LIST_EXCEPTION',

  ADD_DISCOUNT: 'ADD_DISCOUNT',
  ADD_DISCOUNT_SUCCESS: 'ADD_DISCOUNT_SUCCESS',
  ADD_DISCOUNT_FAIL: 'ADD_DISCOUNT_FAIL',
  ADD_DISCOUNT_EXCEPTION: 'ADD_DISCOUNT_EXCEPTION',

  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  DELETE_DISCOUNT_SUCCESS: 'DELETE_DISCOUNT_SUCCESS',
  DELETE_DISCOUNT_FAIL: 'DELETE_DISCOUNT_FAIL',
  DELETE_DISCOUNT_EXCEPTION: 'DELETE_DISCOUNT_EXCEPTION',

  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  UPDATE_DISCOUNT_SUCCESS: 'UPDATE_DISCOUNT_SUCCESS',
  UPDATE_DISCOUNT_FAIL: 'UPDATE_DISCOUNT_FAIL',
  UPDATE_DISCOUNT_EXCEPTION: 'UPDATE_DISCOUNT_EXCEPTION',

  GET_DISCOUNT_BY_ID: 'GET_DISCOUNT_BY_ID',
  GET_DISCOUNT_BY_ID_SUCCESS: 'GET_DISCOUNT_BY_ID_SUCCESS',
  GET_DISCOUNT_BY_ID_FAIL: 'GET_DISCOUNT_BY_ID_FAIL',
  GET_DISCOUNT_BY_ID_EXCEPTION: 'GET_DISCOUNT_BY_ID_EXCEPTION',
 
};
