import React from 'react';
import { ContactUsWrapper, ContactImageContainer } from "./styles"
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import ContactIntro from "./components/contactIntro";
import ContactForm from "./components/contactForm"
import { Grid, Box } from "@mui/material";
import contact from "../../assets/images/contact.png"
import {  useSelector } from "react-redux";

const Contact = () => {
    const lang = useSelector((state) => state.App.lang);
    const theme = useSelector((state) => state.App.theme);

    return (
        <ContactUsWrapper lang={lang}>
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor}/>
            <Grid container id="contact-container" >
                <Grid item md={6}>
                    <ContactImageContainer src={contact} lang={lang}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} id="form-container">
                    <ContactForm />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <ContactIntro />
                </Grid>
            </Grid>
            <Footer />
        </ContactUsWrapper>
    )
}
export default Contact;