import {
    all,
    takeEvery,
    put,
    fork,
    select
  } from "redux-saga/effects";
  import types from "./Types";
  import Endpoints from "./Endpoints";
  import Api from "../../services/Api";
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";
  
  
  export function* ValidateDiscountCode() {
    yield takeEvery(types.VALIDATE_DISCOUNT_CODE, function* ({ data }) {
      const lang = yield select((state) => state.App.lang);

      try {
        const response = yield Api.Get(Endpoints.ValidateDiscountCode+`/${data.course_id}/${data.code}`, true);
        if (response) {

          toast.success(i18n.t("Done successfully"), {
            position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
          });
          yield put({
            type: types.VALIDATE_DISCOUNT_CODE_SUCCESS,
            data: {data:response},
          });
        } else
          toast.error(i18n.t("Operation failed"), {
            position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
          });
          yield put({
            type: types.VALIDATE_DISCOUNT_CODE_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        let errMsg = lang === "fa" ? e.response.data.error_text_fa : e.response.data.error_text_en ;
        if (errMsg){

          toast.error(errMsg, {
            position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
          });
        }else{

          toast.error(i18n.t("کد تخفیف صحیح نمیباشد"), {
            position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
          });
        }
        
        yield put({
          type: types.VALIDATE_DISCOUNT_CODE_FAIL,
        });
      }
    });
  }


  export function* GetMasterAvailableTime() {
    yield takeEvery(types.GET_MASTER_AVAILABLE_TIME, function* ({ data }) {
      try {
        const response = yield Api.Get(Endpoints.GetMasterAvailableTime+`/${data.course_id}`, true);
        if (response) {
          yield put({
            type: types.GET_MASTER_AVAILABLE_TIME_SUCCESS,
            data: {data:response.data},

          });
        } else
          yield put({
            type: types.GET_MASTER_AVAILABLE_TIME_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.GET_MASTER_AVAILABLE_TIME_FAIL,
        });
      }
    });
  }

  export function* BookCourse() {
    yield takeEvery(types.BOOK_COURSE, function* ({ data }) {
      
      let body ={
          "book_email": data.email,
          "book_name": data.name,
          "course_id": data.course_id,
          "times": data.times,
      }
      try {
        const response = yield Api.Post(Endpoints.BookCourse ,body,true);
        if (response) {
          yield put({
            type: types.BOOK_COURSE_SUCCESS,
            data: response.data,

          });
          if(data.paymentStep){
             yield put({
                type: types.NEXT_PAYMENT_STEP,
                data: data.paymentStep,

            });
          }
        } else
          yield put({
            type: types.BOOK_COURSE_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.BOOK_COURSE_FAIL,
        });
      }
    });
  }

  export function* ProcessPayment() {
    yield takeEvery(types.PROCESS_PAYMENT, function* ({ data }) {
      
      let body ={
   
      }
      try {
        const response = yield Api.Post(Endpoints.ProcessPayment ,body,true);
        if (response) {
          yield put({
            type: types.PROCESS_PAYMENT_SUCCESS,
            data: response.data

          });
        } else
          yield put({
            type: types.PROCESS_PAYMENT_EXCEPTION,
            data: {
              exception: response.status,
            },
          });
      } catch (e) {
        yield put({
          type: types.PROCESS_PAYMENT_FAIL,
        });
      }
    });
  }
  
  export default function* PaymentsSagas() {
    yield all(
      [
        fork(ValidateDiscountCode),
        fork(GetMasterAvailableTime),
        fork(BookCourse),
        fork(ProcessPayment)
      ]);
  }
  