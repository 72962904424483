import React from "react";
import Title from "../../../shared/title"
import { ContentWrapprr } from "../styles"
import Carousel from "../../../shared/carousel";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RelatedCourses = ({ t }) => {
  const lang = useSelector((state) => state.App.lang);
  const stateCourse = useSelector((state) => state.Course);
  const { relatedList, relatedLoading } = stateCourse;
  return (
    <ContentWrapprr backgroundColor="transparent" lang={lang}>
      {
        relatedLoading !== true ?
          relatedList.length > 0 ?
            <>
              <Title title={t("related courses")} hasMoreView={true} />
              <Carousel data={relatedList} />
            </>
            : <>{t("no data to dispaly")}</>
          : <div className="loader"></div>
      }

    </ContentWrapprr>
  );
};
export default withTranslation()(RelatedCourses);
