import { string,email} from "../Rules";
import * as Yup from "yup";

export const VerifyValidationRegister = Yup.object().shape({
  
 firstName: string("firstName", true),
  email: email("email", true),
  password: string("password", true),

});
