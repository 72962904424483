import types from './Types';
import DelegateAction from '../ActionDelegator';

const AppActions = {
  sideBarIsShow: DelegateAction(types.SIDEBAR_ISSHOW),
  moreIconIsShow: DelegateAction(types.MORE_iCON_IS_SHOW),
  responsiveSettingMenuShow: DelegateAction(types.RESPONSIVE_SETTING_MENU_SHOW),
  userMenu: DelegateAction(types.USER_MENU),
  changeLang : DelegateAction(types.CHANGE_LANGUAGE),

};

export default AppActions;
