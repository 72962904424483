import styled from "styled-components";
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const CarouselLayout = styled(Row)`
    width: 100%;
    min-height: 500px;
    *:focus {
    outline: 0;
    outline: none;
  }
  .slick-dots li{
    width: 10px;
    height: 10px;
    border-radius:10px;
    background-color: #eceef6;

  }
  .slick-dots .slick-active{
    width: 25px;
    height: 10px;
    background-color: ${(props) => props.color ? props.color : props.theme.secondaryFontColor};
  }
  .slick-dots li button:before {
    font-size: 0px;
  }
`;

export const RowWrapper= styled(Row)`
width:100%;
   flex-wrap: wrap;
   justify-content : space-between;
   > div{
        width: 100%; 
    }
    .slick-slider{
        padding-bottom: 30px;
    }
    .slick-prev, .slick-next{
        top: auto;
        z-index: -10;
        bottom: 0px;
    }
    .slick-prev {
        left: 47%;
    }
     .slick-next {
        right: 47%;
    }
    .slick-list{
       direction:ltr;
    }
`;

export const SvgWrapper=styled(Col)`
    position : relative;
    width:100%;
    text-align: center;
    margin: 0 auto;
    flex-direction:row;
    svg{
        width:50px;
        height :50px;
    }
    svg path {
        stroke : ${(props) => props.color ? props.color : props.theme.secondaryFontColor};

    }
`;
export const ArrowR=styled.div`
    width:35px;
    height:25px;
    position: absolute;
    top: -272px;
    right: -35px;
  cursor: pointer;
`;
export const ArrowL=styled.div`
    width:35px;
    height:25px;
    transform:rotate(180deg);
    position: absolute;
    top: -250px;
    left: -35px;
  cursor: pointer;
`;
