import React, { useEffect ,useState } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import { ContenttWrapper } from "../../globalStyle";
import PersonalInfo from "./components/personalInfo";
import { useDispatch, useSelector } from "react-redux";
import ProfileActions from "../../../../redux/Profile/Actions";

const Accounts = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.Profile.userInfo);
    const [timeZoneList ,setTimeZoneList] = useState([]);
    useEffect(() => {
        dispatch(ProfileActions.getUserInfo({}));
        renderData();
        
    }, []);
    const renderData = () => {
        fetch('timeZone.json')
            .then((r) => r.json())
            .then((data) => {
            
                let res = data;
                let items = [];

                for (let i = 0; i < res.length; i++) {
                    
                    for(let j=0 ; j <res[i].zones.length ; j++){
                        
                        items.push(res[i].zones[j]);
                    }
                }
            setTimeZoneList(items)
            });
    }

    return (
        <MainLayout >
            <ContenttWrapper>
                <PersonalInfo userInfo={userInfo} timeZoneList={timeZoneList}/>
            </ContenttWrapper>
        </MainLayout>
    )
}
export default Accounts;