
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5 4.5C16.5 6.98438 14.4844 9 12 9C9.51562 9 7.5 6.98438 7.5 4.5C7.5 2.01562 9.51562 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM11.25 11.625V24L8.98125 22.8656C8.00156 22.3781 6.94219 22.0688 5.85 21.9609L1.35 21.5109C0.585938 21.4312 0 20.7891 0 20.0156V10.5C0 9.67031 0.670312 9 1.5 9H2.92031C5.90156 9 8.80781 9.91875 11.25 11.625ZM12.75 24V11.625C15.1922 9.91875 18.0984 9 21.0797 9H22.5C23.3297 9 24 9.67031 24 10.5V20.0156C24 20.7844 23.4141 21.4312 22.65 21.5062L18.15 21.9562C17.0625 22.0641 15.9984 22.3734 15.0187 22.8609L12.75 24Z" fill={pathFill}/>
      </svg>
      
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;