import React from "react";

const DetailsIcon = ({ pathFill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill={pathFill}
        d="M8.889 1.111v5H6.11V8.89h-5V1.11H8.89zm0-1.111H1.11C.5 0 0 .5 0 1.111V8.89C0 9.5.5 10 1.111 10h5.556L10 6.667V1.11C10 .5 9.5 0 8.889 0zM5 6.111H2.222V5H5v1.111zM7.778 3.89H2.222V2.778h5.556v1.11z"
      ></path>
    </svg>
  );
};
DetailsIcon.defaultProps = {
  pathFill: "#3D54A8",
};
export default DetailsIcon;
