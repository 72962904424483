import styled from "styled-components";
import Col from "../../../../kit/Column";
import Row from "../../../../kit/Row";
export const FormWrapper = styled(Col)`
width :100%;
font-family: ${(props) => (props.theme.fontDefault)};
*{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

input : focus {
   background-color: inherit !important;
}
input{
    min-height:35px !important;
    height:35px;
}
.MuiInputBase-root {
    width: 100%;
    height: 35px;
}

.MuiSelect-select{
    color: ${(props) => props.color ? props.color : props.theme.text};
    font-family: ${(props) => (props.theme.fontDefault)} !important;
}
`;

export const FormContainer = styled(Row)`
    width: 100%;
    form{
        width :100%;
        padding-top:20px;
    }
`;
export const BtnContainer = styled(Row)`
    margin :10px 0;
    .btn{
        margin-top :10px;
        background-color: ${(props) => props.theme.btnColor};
        border-radius: ${(props) => props.theme.borderRadius};
        border: none;
        width: 100%;
        padding: 10px;
        color : ${(props) => props.theme.white};
        cursor :pointer;
        font-family: ${(props) => (props.theme.fontDefault)};
     }
     .btn:hover{
        background-color: ${(props) => props.theme.btnColor};
     }
`;

export const ImageContainer = styled.div`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: contain;
    width: 80px;
    height: 80px;
    @media screen and (max-width: 900px) {
       display:none !important;
    }
`;