import React from "react";
import {
  FootertWrapprr,
  FooterContainer,
  LogoContainer,
  TextContainer,
  MapContainer,
  LastFooter,
  SocialContainer,
} from "./styles";
import { Grid, Box } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import map from "../../assets/images/map.png"
import SocialConfig from "./config";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";


const Footer = ({ t }) => {
  const lang = useSelector((state) => state.App.lang);
  const stateCourse = useSelector((state) => state.Course);
  const { categoriesListOptions } = stateCourse;
 
  return (
    <FootertWrapprr lang={lang}>
      <FooterContainer>
        <Grid container style={{"marginBottom":"1.3rem"}} >
          <Grid item xs={6} sm={3} style={{"line-height":"2.2rem", "font-size":"1.1rem"}}>
            <Box style={{"fontWeight":"800"}}>{t("quick access")}</Box>
            <ul >
              <li style={{"cursor":"pointer"}} onClick={(e)=>window.location.href= '/'}>{t("home")}</li>
              <li style={{"cursor":"pointer"}} onClick={(e)=>window.location.href= '/about-us'}>{t("Terms and Conditions")}</li>
              <li style={{"cursor":"pointer"}} onClick={(e)=>window.location.href= '/contact-us'}>{t("contact us")}</li>
              <li style={{"cursor":"pointer"}} onClick={(e)=>window.location.href= '/about-us'}>{t("about")}</li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={3}  style={{"line-height":"2.2rem", "font-size":"1.1rem"}}>
            <Box  style={{"fontWeight":"800"}}>{t("courses")}</Box>
            <ul >
              {categoriesListOptions && categoriesListOptions?.map((item, index) => {
                return (
                  <li  style={{"cursor":"pointer"}}  key={index} onClick={(e)=>window.location.href= '/courses?id=' + item?.value}>
                    {lang === "fa" ? item?.fa_name : item?.en_name}
                  </li>
                )
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LogoContainer src={Logo} />
            <TextContainer>
              {t("Iran Meetup, an online platform offering various courses and disciplines for Persian speakers and Iranians abroad")}
            </TextContainer>
            <SocialContainer>
              {
                SocialConfig.map((each, index) => {
                  let Body = each.body;
                  return (
                    <Box className="socail" key={index}>
                      <Body />
                    </Box>
                  )
                })
              }
            </SocialContainer>
          </Grid>
        </Grid>
        <Grid container >
          <Grid xs={12} sm={6}>
            <a href={"https://www.google.com/maps/place/@35.7583719,51.3994681,19z/"} target={"_blank"}>
              <MapContainer src={map}></MapContainer>
            </a>
          </Grid>
          <Grid container xs={12} sm={6} style={{"marginTop":"1rem"}}>
            <Grid item xs={12}>
              <TextContainer>
                {process.env.REACT_APP_COMPANY_ADDRESS}
              </TextContainer>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextContainer>
              {t("phone")} : {process.env.REACT_APP_COMPANY_TEL}
              </TextContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextContainer>
              {t("email")} :{process.env.REACT_APP_COMPANY_EMAIL}
              </TextContainer>

            </Grid>
          </Grid>
        </Grid>
      </FooterContainer>
      <LastFooter>
        <p style={{"fontSize":"15px", "paddingTop":"1rem", "fontWeight":"400", "lineHeight":"36px"}}>
          {t("All material and intellectual rights of this website for")}
          <Link >
            <a target="_blank" href={"/"}> {t("iranmeetup")}، </a>
          </Link>
          {t("It is reserved")}
        </p>
      </LastFooter>
    </FootertWrapprr>
  );
};

export default withTranslation()(Footer);
