import React from 'react';
import { useSelector } from 'react-redux';

function LanguageIcon({fill}) {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_9_689)">
        <path
          fill={fill}
          d="M15.714 5.714c-.114-.4-.285-.8-.457-1.143A8.118 8.118 0 008.971.057L8.686 0H7.429l-.343.057C4.343.343 2 2.057.8 4.571a6.81 6.81 0 00-.457 1.143 7.79 7.79 0 000 4.572c.114.4.286.8.457 1.143a8.118 8.118 0 006.286 4.514l.343.057H8.686l.343-.057a8.118 8.118 0 006.285-4.514c.172-.343.343-.743.457-1.143.4-1.543.4-3.086-.057-4.572zM14 4.571h-2.971a11.62 11.62 0 00-1.372-3.257A6.94 6.94 0 0114 4.571zM10.343 8c0 .743-.057 1.543-.229 2.286H5.943a12.297 12.297 0 010-4.572h4.171c.172.743.229 1.543.229 2.286zm-.457 3.429a9.678 9.678 0 01-1.715 3.428H8a9.678 9.678 0 01-1.714-3.428h3.6zM6.229 4.57a9.679 9.679 0 011.714-3.428h.171A9.679 9.679 0 019.83 4.57h-3.6zm.228-3.257a11.621 11.621 0 00-1.371 3.257H2.114a6.94 6.94 0 014.343-3.257zM1.2 8c0-.8.114-1.543.4-2.286h3.257a12.298 12.298 0 000 4.572H1.6C1.314 9.543 1.2 8.8 1.2 8zm.914 3.429h2.972a11.622 11.622 0 001.371 3.257 6.94 6.94 0 01-4.343-3.257zm7.543 3.257a11.622 11.622 0 001.372-3.257H14a6.94 6.94 0 01-4.343 3.257zm1.657-4.4a15.386 15.386 0 000-4.572h3.2a6.965 6.965 0 010 4.572h-3.2z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_9_689">
          <path fill={fill} d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
LanguageIcon.defaultProps = {
  fill: "#fff",
};
export default LanguageIcon;
