import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"

const Sport = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {sportList} = stateCourse;
  return (
    sportList.length > 0 &&
    <ContentWrapprr>
      <Title title={t("sport")} hasMoreView={true} tabValue={sportList[0]?.category_id} data={sportList}/>
      <Carousel data={sportList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(Sport);
