import types from './Types';
import RecordController from "../../controllers/RecordController";
import { prepareSelectData } from "../../utils";

const initState = {
  showDsicountDetailModal: false,
  showDeleteDiscountModal: false,
  dicountsList: [],
  dicountsListOptions: [],
  dicountsLoading: true,
  takeDiscount: 12,
  skipDiscount: 0,
  hasMore: false,
  countDiscount: 0,
  mode:"add",
  DiscountDetail :[]
};

export default function Reducer(state = initState, action) {
  switch (action.type) {
    case types.SET_SHOW_DISCOUNT_DETAIL_MODAL:
      return {
        ...state,
        showDsicountDetailModal: action.data.visible,
        mode : action.data.mode,
      };
    case types.SET_SHOW_DELETE_DISCOUNT_MODAL:
      return {
        ...state,
        showDeleteDiscountModal: action.data.visible,
      };
    case types.GET_DISCOUNTS_LIST:
      return {
        ...state,
        dicountsLoading: true,

      };
    case types.GET_DISCOUNTS_LIST_SUCCESS:
      let newdicountsList = [...state.dicountsList];
      newdicountsList = [...action.data.data];
      return {
        ...state,
        dicountsLoading: false,
        dicountsList: newdicountsList,
        skipDiscount: state.skipDiscount + state.takeDicount,
        countDiscount: action.data.count,
        hasMore: state.skipDiscount + state.takeDicount < action.data.count ? true : false,
        dicountsListOptions : prepareSelectData("Dicount", action.data.data)
      };
    case types.UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        dicountsList: RecordController.update(
          state.dicountsList,
          action.data
        ),
       showDsicountDetailModal: false,
        mode :"add"
      };
    case types.ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        dicountsList: RecordController.add(
          state.dicountsList,
          action.data
        ),
        showDsicountDetailModal: false,
        mode :"add"
      };
    case types.DELETE_DISCOUNT_SUCCESS:
      let result = { id: action.data };
      return {
        ...state,
        dicountsList: RecordController.delete(
          state.dicountsList,
          result
        )
      };
      case types.GET_DISCOUNT_BY_ID_SUCCESS:
        return {
          ...state,
          DiscountDetail : action.data
        };
    default:
      return state;
  }
}
