export default {

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_EXCEPTION: 'LOGIN_EXCEPTION',

  REGISTER: 'REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  REGISTER_EXCEPTION: 'REGISTER_EXCEPTION',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',

  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
  RESET_PASSWORD_EXCEPTION: 'RESET_PASSWORD_EXCEPTION',

  FORGET_PASSWORD: 'FORGET_PASSWORD',
  FORGET_PASSWORD_SUCCESS: 'FORGET_PASSWORD_SUCCESS',
  FORGET_PASSWORD_FAIL: 'FORGET_PASSWORD_FAIL',
  FORGET_PASSWORD_EXCEPTION: 'FORGET_PASSWORD_EXCEPTION',

  ACTIVATE_AN_ACCOUNT: 'ACTIVATE_AN_ACCOUNT',
  ACTIVATE_AN_ACCOUNT_SUCCESS: 'ACTIVATE_AN_ACCOUNT_SUCCESS',
  ACTIVATE_AN_ACCOUNT_FAIL: 'ACTIVATE_AN_ACCOUNT_FAIL',
  ACTIVATE_AN_ACCOUNT_EXCEPTION: 'ACTIVATE_AN_ACCOUNT_EXCEPTION',
};
