export default {
  SET_SHOW_COURSE_MODAL: 'SET_SHOW_COURSE_MODAL',

  GET_PRIVATE_COURSES_LIST: 'GET_PRIVATE_COURSES_LIST',
  GET_PRIVATE_COURSES_LIST_SUCCESS: 'GET_PRIVATE_COURSES_LIST_SUCCESS',
  GET_PRIVATE_COURSES_LIST_FAIL: 'GET_PRIVATE_COURSES_LIST_FAIL',
  GET_PRIVATE_COURSES_LIST_EXCEPTION: 'GET_PRIVATE_COURSES_LIST_EXCEPTION',

  ADD_COURSE: 'ADD_COURSE',
  ADD_COURSE_SUCCESS: 'ADD_COURSE_SUCCESS',
  ADD_COURSE_FAIL: 'ADD_COURSE_FAIL',
  ADD_COURSE_EXCEPTION: 'ADD_COURSE_EXCEPTION',

  DELETE_COURSE: 'DELETE_COURSE',
  DELETE_COURSE_SUCCESS: 'DELETE_COURSE_SUCCESS',
  DELETE_COURSE_FAIL: 'DELETE_COURSE_FAIL',
  DELETE_COURSE_EXCEPTION: 'DELETE_COURSE_EXCEPTION',

  UPDATE_COURSE: 'UPDATE_COURSE',
  UPDATE_COURSE_SUCCESS: 'UPDATE_COURSE_SUCCESS',
  UPDATE_COURSE_FAIL: 'UPDATE_COURSE_FAIL',
  UPDATE_COURSE_EXCEPTION: 'UPDATE_COURSE_EXCEPTION',

  GET_COURSE_BY_ID: 'GET_COURSE_BY_ID',
  GET_COURSE_BY_ID_SUCCESS: 'GET_COURSE_BY_ID_SUCCESS',
  GET_COURSE_BY_ID_FAIL: 'GET_COURSE_BY_ID_FAIL',
  GET_COURSE_BY_ID_EXCEPTION: 'GET_COURSE_BY_ID_EXCEPTION',

  GET_MY_COURSES_LIST: 'GET_MY_COURSES_LIST',
  GET_MY_COURSES_LIST_SUCCESS: 'GET_MY_COURSES_LIST_SUCCESS',
  GET_MY_COURSES_LIST_FAIL: 'GET_MY_COURSES_LIST_FAIL',
  GET_MY_COURSES_LIST_EXCEPTION: 'GET_MY_COURSES_LIST_EXCEPTION',

  GET_CALENDAR_LIST: 'GET_CALENDAR_LIST',
  GET_CALENDAR_LIST_SUCCESS: 'GET_CALENDAR_LIST_SUCCESS',
  GET_CALENDAR_LIST_FAIL: 'GET_CALENDAR_LIST_FAIL',
  GET_CALENDAR_LIST_EXCEPTION: 'GET_CALENDAR_LIST_EXCEPTION',
 
};
