
import React from "react";

function Icon({pathFill}) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.2778 3.67182V1.86748C17.2778 1.39326 16.9 1 16.4444 1C15.9889 1 15.6111 1.39326 15.6111 1.86748V3.60243H8.38889V1.86748C8.38889 1.39326 8.01111 1 7.55556 1C7.1 1 6.72222 1.39326 6.72222 1.86748V3.67182C3.72222 3.96098 2.26667 5.82316 2.04445 8.58752C2.02223 8.92295 2.28889 9.20054 2.6 9.20054H21.4C21.7222 9.20054 21.9889 8.91138 21.9556 8.58752C21.7333 5.82316 20.2778 3.96098 17.2778 3.67182Z" fill={pathFill}/>
    <path d="M20.8889 10.9355C21.5 10.9355 22 11.456 22 12.0922V19.2171C22 22.687 20.3333 25.0002 16.4444 25.0002H7.55556C3.66667 25.0002 2 22.687 2 19.2171V12.0922C2 11.456 2.5 10.9355 3.11111 10.9355H20.8889Z" fill={pathFill}/>
    <path d="M8.11108 16.9057C7.82219 16.9057 7.5333 16.7785 7.32219 16.5703C7.12219 16.3505 6.99997 16.0498 6.99997 15.7491C6.99997 15.4484 7.12219 15.1477 7.32219 14.9279C7.6333 14.6041 8.12219 14.4999 8.5333 14.685C8.67775 14.7428 8.79997 14.8238 8.89997 14.9279C9.09997 15.1477 9.22219 15.4484 9.22219 15.7491C9.22219 16.0498 9.09997 16.3505 8.89997 16.5703C8.68886 16.7785 8.39997 16.9057 8.11108 16.9057Z" fill="#3D54A8"/>
    <path d="M11.9998 16.9057C11.7109 16.9057 11.422 16.7785 11.2109 16.5703C11.0109 16.3505 10.8887 16.0498 10.8887 15.7491C10.8887 15.4484 11.0109 15.1477 11.2109 14.9279C11.3109 14.8238 11.4331 14.7428 11.5776 14.685C11.9887 14.4999 12.4776 14.6041 12.7887 14.9279C12.9887 15.1477 13.1109 15.4484 13.1109 15.7491C13.1109 16.0498 12.9887 16.3505 12.7887 16.5703C12.7331 16.6166 12.6776 16.6628 12.622 16.7091C12.5553 16.7554 12.4887 16.7901 12.422 16.8132C12.3553 16.8479 12.2887 16.8711 12.222 16.8826C12.1442 16.8942 12.0776 16.9057 11.9998 16.9057Z" fill="#3D54A8"/>
    <path d="M15.8885 16.9031C15.5996 16.9031 15.3107 16.7759 15.0996 16.5677C14.8996 16.3479 14.7773 16.0472 14.7773 15.7465C14.7773 15.4458 14.8996 15.1451 15.0996 14.9253C15.2107 14.8212 15.3218 14.7402 15.4662 14.6824C15.6662 14.5898 15.8885 14.5667 16.1107 14.613C16.1773 14.6245 16.244 14.6477 16.3107 14.6824C16.3773 14.7055 16.444 14.7402 16.5107 14.7865C16.5662 14.8328 16.6218 14.879 16.6773 14.9253C16.8773 15.1451 16.9996 15.4458 16.9996 15.7465C16.9996 16.0472 16.8773 16.3479 16.6773 16.5677C16.6218 16.614 16.5662 16.6602 16.5107 16.7065C16.444 16.7527 16.3773 16.7875 16.3107 16.8106C16.244 16.8453 16.1773 16.8684 16.1107 16.88C16.0329 16.8916 15.9551 16.9031 15.8885 16.9031Z" fill="#3D54A8"/>
    <path d="M8.11108 20.952C7.96664 20.952 7.82219 20.9173 7.68886 20.8594C7.54441 20.8016 7.4333 20.7206 7.32219 20.6165C7.12219 20.3968 6.99997 20.096 6.99997 19.7953C6.99997 19.4946 7.12219 19.1939 7.32219 18.9741C7.4333 18.87 7.54441 18.789 7.68886 18.7312C7.88886 18.6387 8.11108 18.6155 8.3333 18.6618C8.39997 18.6734 8.46664 18.6965 8.5333 18.7312C8.59997 18.7543 8.66664 18.7891 8.7333 18.8353C8.78886 18.8816 8.84441 18.9279 8.89997 18.9741C9.09997 19.1939 9.22219 19.4946 9.22219 19.7953C9.22219 20.096 9.09997 20.3968 8.89997 20.6165C8.84441 20.6628 8.78886 20.7206 8.7333 20.7553C8.66664 20.8016 8.59997 20.8363 8.5333 20.8594C8.46664 20.8941 8.39997 20.9173 8.3333 20.9288C8.25553 20.9404 8.18886 20.952 8.11108 20.952Z" fill="#3D54A8"/>
    <path d="M11.9998 20.9511C11.7109 20.9511 11.422 20.8239 11.2109 20.6157C11.0109 20.396 10.8887 20.0952 10.8887 19.7945C10.8887 19.4938 11.0109 19.1931 11.2109 18.9733C11.622 18.5454 12.3776 18.5454 12.7887 18.9733C12.9887 19.1931 13.1109 19.4938 13.1109 19.7945C13.1109 20.0952 12.9887 20.396 12.7887 20.6157C12.5776 20.8239 12.2887 20.9511 11.9998 20.9511Z" fill="#3D54A8"/>
    <path d="M15.8885 20.9511C15.5996 20.9511 15.3107 20.8239 15.0996 20.6157C14.8996 20.396 14.7773 20.0952 14.7773 19.7945C14.7773 19.4938 14.8996 19.1931 15.0996 18.9733C15.5107 18.5454 16.2662 18.5454 16.6773 18.9733C16.8773 19.1931 16.9996 19.4938 16.9996 19.7945C16.9996 20.0952 16.8773 20.396 16.6773 20.6157C16.4662 20.8239 16.1773 20.9511 15.8885 20.9511Z" fill="#3D54A8"/>
    </svg>
    
  );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;