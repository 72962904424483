import React from 'react';
import { useSelector } from 'react-redux';

function EmailIcon() {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50.75 7C53.6484 7 56 9.35047 56 12.25C56 13.9016 55.2234 15.4547 53.9 16.45L30.1 34.3C28.8531 35.2297 27.1469 35.2297 25.9 34.3L2.1 16.45C0.777984 15.4547 0 13.9016 0 12.25C0 9.35047 2.35047 7 5.25 7H50.75ZM23.8 37.1C26.2938 38.9703 29.7062 38.9703 32.2 37.1L56 19.25V42C56 45.8609 52.8609 49 49 49H7C3.13359 49 0 45.8609 0 42V19.25L23.8 37.1Z" fill="white"/>
        </svg>
    );
}

export default EmailIcon;
