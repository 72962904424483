import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";

export const TitleContainer = styled(Row)`
    width:100%;
    justify-content:start;
    margin: 8px 0;
    font-size: ${(props) => props.theme.fontSizeMd};
    font-family: ${(props) => (props.theme.fontBold)};
    color: ${(props) => props.theme.secondaryFontColor};
`;
export const CardContainer = styled(Col)`
    width:90%;
    justify-content:end;
    height : 100%;
    align-items: center;
    padding: 10px 0px;
    box-sizing: border-box;
`;
export const AddContainer = styled(Row)`
    width:100%;
    justify-content:end;
    height:60px;
    span {
        display:flex;
        justify-content:center;
        align-items: center;
        width :40px;
        height:40px;
        font-size: ${(props) => props.theme.fontSizeLg};
        color: ${(props) => props.theme.white};
        background-color :${(props) => props.theme.secondaryFontColor};
        border-radius : 50%;
        cursor : pointer;
    }
`;
export const ContenttWrapper = styled(Col)`
    width:90%;
    padding :20px 0;
    // button{
    //     margin-top :5px;
    //     border-radius: ${(props) => props.theme.borderRadius};
    //     border: 1px solid ${(props) => props.theme.secondaryFontColor};
    //     width: 120px;
    //     padding: 5px;
    //     color : ${(props) => props.theme.secondaryFontColor};
    //     cursor :pointer;
    //     font-family: ${(props) => (props.theme.fontDefault)};
    //  }
    .MuiTabs-root{
        direction:ltr;
    }
    .blue-font{
        color : ${(props) => props.theme.secondaryFontColor};
    }
    #order-table tbody tr:nth-child(odd) {
        background-color: #fff;
      }
    #collapse-row td{
        padding:0 !important;
        border-radius: 10px;
    }
  
`;
export const ImageContainer = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  width: ${props => props.height ? props.height  :"30px"};
  height: ${props => props.height ? props.height  :"30px"};
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${props => props.src});
  background-size : contain;
  background-repeat :  no-repeat;
  background-position: center;
  border-radius: 10px;
`;
export const ImageContainerRow = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  border-radius: 10%;
  width: ${props => props.height ? props.height  :"50px"};
  height: ${props => props.height ? props.height  :"50px"};
  background: url(${props => props.src});
  background-size : cover;
  background-repeat :  no-repeat;
  background-position: center;
  box-shadow:0px 1px 3px 1px #b9b9b9;
  //border-radius: 10px;
`;

export const TableWrapper = styled(Col)`
width: 100%;
height: ${(props) => props.height || "auto"};
box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
table {
    border-collapse:separate; 
    border-spacing: 0 1em;
  }
table td{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.text};
    border:none;
    background-color : #f1f2f3;
}
table th{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.secondaryFontColor};
    border:none;
    background-color : #e0e6ed;
}
tr td:first-child , tr th:first-child{
    border-top-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"}; 
    border-bottom-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
   }
tr td:last-child , tr th:last-child { 
    border-top-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};  
}

button{
    height: 100%;
    background-color: ${(props) => props.theme.white};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};;
    width: 100%;
    color : ${(props) => props.theme.secondaryFontColor};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
`;
///////////////////////////////////////toggle
export const ToggleWrapper = styled.div`
.sidebar-menu {
  padding-top: 10px;
  overflow-y: scroll;
  width: 350px;
  height: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: -350px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
  background : ${(props) => (props.theme.white)};
  box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
  &.open {
    -webkit-transform: translateX(350px);
    transform: translateX(350px);
  }
  li{
    color : ${(props) => (props.theme.textPrimary)};
  }
  .menu {
    > li {
      > a {
        padding: 15px 20px;
        color: #fff;
      }
    }
  }
}
}
`;


export const InfoConatiner = styled.div`

    display: flex;
    justify-content: center;
    flex-direction :column;
    padding: 0 20px;
    display: flex;
    align-items: flex-start;

`;
export const InfoSection = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  width : 100%;
  padding:10px 0;
  justify-content: flex-start;
  span {
    color : ${(props) => (props.theme.textPrimary)};
    padding:0 5px;
  }
  .title{
    color : ${(props) => (props.theme.textSecondary)};
    padding:0;
  }
  &:last-child{
    border-bottom:none;
  }

`;
///////////////////////////////////////filter
export const FilterContainer = styled(Row)`
   width : 100%;
   padding-bottom :0;
   box-sizing : border-box;
   .filter-box{
      width : 100%;
      justify-content: flex-start;
   }
   .btn-filter{
      font-family: ${(props) => (props.theme.fontDefault)};
      padding:5px;
      background-color : ${props => props.theme.primary};
      color:${props => props.theme.white}; 
      width : 100px;
      border:none;
      height :42px;
      cursor : pointer;
    }
    form{
      width : 100%;
    }
`;
export const FormRow = styled(Col)`
   width : 100%;
   padding-bottom :0;
   box-sizing : border-box;
   .text-alert{
      color : red;
      font-size: 10px;
      width: 100%;
      height :20px;
   }
`;


