export default {
    ///////////////////////member
    login: "/login",
    home:"/",
    dashboard : "/dashboard",
    account :"/account",
    my_courses :"/my_courses",
    favorites :"/favorites",
    announcements :"/announcements",
    calendar :"/calendar",
    orders :"/orders",
    change_password :"/change_password",

    ///////////////////////admin
    masters :"/masters",
    categories :"/categories",
    users :"/users",
    adminCourses :"/adminCourses",
    staff : "/staff",
    adminOrders : "/adminOrders",
    discount : "/discounts"


};