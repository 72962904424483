export const data = [
    {
        id: 0, day: "شنبه", date: "مرداد",
        time:
            [
                { id: 111, hour: "10-12" },
                { id: 11, hour: "10-12" },
                { id: 21, hour: "10-12" },
                { id: 31, hour: "10-12" },
                { id: 41, hour: "10-12" },
                { id: 51, hour: "10-12" },
            ]
    },
    {
        id: 1, day: "یک شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 2, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 3, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 4, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 5, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 0, day: "شنبه", date: "مرداد",
        time:
            [
                { id: 0, hour: "10-12" },
                { id: 1, hour: "10-12" },
                { id: 2, hour: "10-12" },
                { id: 3, hour: "10-12" },
                { id: 4, hour: "10-12" },
                { id: 5, hour: "10-12" },
            ]
    },
    {
        id: 1, day: "یک شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 2, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 3, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 4, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 5, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 0, day: "شنبه", date: "مرداد",
        time:
            [
                { id: 0, hour: "10-12" },
                { id: 1, hour: "10-12" },
                { id: 2, hour: "10-12" },
                { id: 3, hour: "10-12" },
                { id: 4, hour: "10-12" },
                { id: 5, hour: "10-12" },
            ]
    },
    {
        id: 1, day: "یک شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 2, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 3, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
    {
        id: 4, day: "شنبه", date: "مرداد",
        time:
        [
            { id: 0, hour: "10-12" },
            { id: 1, hour: "10-12" },
            { id: 2, hour: "10-12" },
            { id: 3, hour: "10-12" },
            { id: 4, hour: "10-12" },
            { id: 5, hour: "10-12" },
        ]
    },
 
]