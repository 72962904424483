import React from 'react';
import { useSelector } from 'react-redux';

function InstagramIcon() {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M55.2381 0H4.7619C2.13198 0 0 2.23858 0 5V55C0 57.7614 2.13198 60 4.7619 60H55.2381C57.868 60 60 57.7614 60 55V5C60 2.23858 57.868 0 55.2381 0Z" fill="#3D54A8"/>
    <path d="M37.337 14H22.6607C20.3645 14.003 18.1632 14.9166 16.5396 16.5404C14.916 18.1642 14.0027 20.3656 14 22.6619L14 37.3381C14.0027 39.6344 14.916 41.8358 16.5396 43.4596C18.1632 45.0834 20.3645 45.997 22.6607 46H37.337C39.6334 45.9973 41.835 45.0838 43.4588 43.46C45.0827 41.8362 45.9961 39.6346 45.9989 37.3381V22.6619C45.9961 20.3654 45.0827 18.1638 43.4588 16.54C41.835 14.9162 39.6334 14.0027 37.337 14V14ZM29.9994 38.7449C28.2684 38.7458 26.576 38.2333 25.1363 37.2721C23.6966 36.3109 22.5744 34.9443 21.9116 33.3452C21.2488 31.7461 21.0752 29.9863 21.4128 28.2885C21.7503 26.5907 22.5839 25.0311 23.808 23.8072C25.0321 22.5832 26.5918 21.7499 28.2896 21.4125C29.9875 21.0752 31.7472 21.249 33.3463 21.912C34.9453 22.575 36.3118 23.6975 37.2727 25.1373C38.2337 26.577 38.7461 28.2695 38.7449 30.0006C38.741 32.3187 37.8183 34.5408 36.179 36.1798C34.5397 37.8189 32.3176 38.7413 29.9994 38.7449ZM38.958 23.3137C38.4466 23.3137 37.9467 23.1621 37.5215 22.878C37.0963 22.5938 36.7649 22.19 36.5692 21.7176C36.3735 21.2451 36.3223 20.7252 36.4221 20.2237C36.5218 19.7221 36.7681 19.2614 37.1297 18.8998C37.4913 18.5382 37.952 18.2919 38.4536 18.1922C38.9551 18.0924 39.475 18.1436 39.9475 18.3393C40.4199 18.535 40.8238 18.8664 41.1079 19.2916C41.392 19.7168 41.5436 20.2167 41.5436 20.7281C41.543 21.4137 41.2704 22.071 40.7856 22.5557C40.3009 23.0405 39.6436 23.3131 38.958 23.3137Z" fill="#FCFCFC"/>
    <path d="M29.9134 23.625C28.6688 23.6241 27.452 23.9923 26.4167 24.6831C25.3815 25.3739 24.5743 26.3562 24.0974 27.5057C23.6205 28.6553 23.4953 29.9205 23.7375 31.1412C23.9798 32.362 24.5786 33.4835 25.4584 34.3639C26.3381 35.2442 27.4591 35.8439 28.6797 36.087C29.9003 36.3302 31.1656 36.2058 32.3155 35.7298C33.4654 35.2537 34.4483 34.4473 35.1398 33.4125C35.8313 32.3778 36.2004 31.1612 36.2004 29.9166C36.198 28.2495 35.535 26.6513 34.3566 25.4721C33.1782 24.2928 31.5805 23.6286 29.9134 23.625Z" fill="#FCFCFC"/>
    <path d="M38.958 20.0195C38.8178 20.0198 38.6808 20.0615 38.5643 20.1396C38.4479 20.2177 38.3572 20.3285 38.3037 20.4581C38.2502 20.5877 38.2363 20.7302 38.2638 20.8677C38.2913 21.0052 38.3589 21.1314 38.4581 21.2305C38.5573 21.3295 38.6837 21.397 38.8212 21.4242C38.9587 21.4515 39.1012 21.4374 39.2307 21.3837C39.3603 21.33 39.471 21.2391 39.5488 21.1225C39.6267 21.0059 39.6682 20.8689 39.6682 20.7287C39.6679 20.5405 39.593 20.3601 39.4598 20.2272C39.3266 20.0942 39.1462 20.0195 38.958 20.0195Z" fill="#FCFCFC"/>
    </svg>
  );
}

export default InstagramIcon;
