import React, { useEffect } from 'react';
import LearningView from "../../views/learning";


const Course = () => {

    useEffect(() => {

    }, [])

    return (
        <>
            <LearningView />
        </>
    )
}
export default Course;