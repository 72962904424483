import types from "./Types";
import { prepareSelectData } from "../../utils";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
let categories = Storage.get(Keys.categories)  ? Storage.get(Keys.categories) : [];
const initState = {
  categoriesList: [],
  categoriesListOptions: categories,
  

  programmingList: [],
  programmingLoading: true,

  sportList: [],
  sportLoading: true,

  musicList: [],
  musicLoading: true,

  englishList: [],
  englishLoading: true,

  lawList: [],
  lawLoading: true,

  psychologyList: [],
  psychologyLoading: true,

  relatedList: [],
  relatedLoading: true,

  recentList: [],
  recentLoading: true,

  discountList: [],
  discountLoading: true,

  masterCourseList: [],
  masterCourseLoading: true,

  courseList: [],
  courseLoading: true,
  take: 8,
  skip: 0,
  hasMoreCourse: false,
  count: 0,
  category_id: null,

  selectedCourseItem : [],

  courseItemList : [],
  courseItemListLoading : true
};
const GetContentTypesStateKeys = (type) => {

  switch (type) {

    case 'programming':
      return {
        dataKey: "programmingList",
        loadingKey: "programmingLoading",
      };
    case 'English':
      return {
        dataKey: "englishList",
        loadingKey: "englishLoading",
      };
    case 'sport':
      return {
        dataKey: "sportList",
        loadingKey: 'sportLoading',
      };
    case 'music':
      return {
        dataKey: "musicList",
        loadingKey: 'musicLoading',
      };
    case 'law':
      return {
        dataKey: "lawList",
        loadingKey: 'lawLoading',
      };
    case 'psychology':
      return {
        dataKey: "psychologyList",
        loadingKey: 'psychologyLoading',
      };
    case 'related':
      return {
        dataKey: "relatedList",
        loadingKey: 'relatedLoading',

      };
    case 'recent':
      return {
        dataKey: "recentList",
        loadingKey: 'recentLoading',

      };
    case 'discount':
      return {
        dataKey: "discountList",
        loadingKey: 'discountLoading',
      };
    case 'master':
      return {
        dataKey: "masterCourseList",
        loadingKey: 'masterCourseLoading',
      };
    default:
      return {
        dataKey: "relatedList",
        loadingKey: "relatedLoading",
      };
  }
}

export default function Reducer(state = initState, action) {

  switch (action.type) {

    case types.GET_CATEGORIES_SUCCESS:
      let result = prepareSelectData("category", action.data);
      Storage.set(Keys.categories, result);
      return {
        ...state,
        categoriesList: action.data,
        categoriesListOptions: result
      };
    case types.GET_COURSES:
      let contentTypeInfo1 = GetContentTypesStateKeys(action.data.courseType);
      return {
        ...state,
        [contentTypeInfo1.loadingKey]: true,
        [contentTypeInfo1.dataKey]: [],
      };
    case types.GET_COURSES_SUCCESS:
      let contentTypeInfo = GetContentTypesStateKeys(action.data.courseType);
      return {
        ...state,
        [contentTypeInfo.loadingKey]: false,
        [contentTypeInfo.dataKey]: action.data.data,
      };
    case types.GET_TAB_COURSES:
      if(action.data.mode !== "loadMore"){
        return{
          ...state,
          courseLoading: true,
          courseList :[]
        }
      }
      else{
        return{
          ...state
        }
      }
      
    case types.GET_TAB_COURSES_SUCCESS:
      return {
        ...state,
        courseList: action.data.category_id !== state.category_id ?
          action.data.data : action.data.mode === "loadMore" ?
            [...state.courseList, ...action.data.data] : action.data.data,
        category_id: action.data.category_id,
        courseLoading: false,
        skip: action.data.category_id !== state.category_id ? 0 :
          action.data.mode === "loadMore" ?
            state.skip + state.take : 0,
        count: action.data.count,
        hasMoreCourse: action.data.category_id !== state.category_id ?
          0 + state.take < action.data.count ? true : false
          :
          state.skip + state.take < action.data.count ? true : false,

      };
      case types.GET_COURSES_ITEM_SUCCESS:
        return {
          ...state,
          selectedCourseItem : action.data,
          courseItemList : action.data,
          courseItemListLoading : false
        };
    default:
      return state;
  }
}
