import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


export const FootertWrapprr = styled(Col)`
    position: relative;
    left: 0px;
    bottom: 0px;
  border-top : 4px solid ${(props) => props.theme.secondaryFontColor};
  font-family: ${(props) => (props.theme.fontBold)};
  width :100%;
  min-height :500px;
  padding :20px 20px;
  li a , li{
    color: ${(props) => props.theme.secondaryFontColor};
    font-weight : bold;
  }
  @media screen and (max-width: 600px) {
  min-height :700px;
    
  }
  *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
`;
export const FooterContainer = styled(Col)`
  width :90%;
  @media screen and (min-width: 1700px) and (max-width :2000px) {
    width : 70%;
   }
@media screen and (min-width: 2000px)  {
    width : 65%;
}
`;
export const SocialContainer = styled(Row)`
  justify-content: flex-start;
  width :90%;
  .socail {
    padding:5px;
  }
  svg{
    width: 45px;
    height: 45px;
    margin-top:15px;
  }
 
`;
export const LogoContainer = styled(Row)`
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: contain;
    width: 80px;
    height: 80px;
    justify-content : flex-start;
`;
export const TextContainer = styled(Row)`
    color: ${(props) => props.theme.secondaryFontColor};
    font-size: ${(props) => props.theme.fontSizeMd};
    font-weight : bold;
    justify-content : flex-start;
    font-family: ${(props) => (props.theme.fontDefault)};
    padding:7px 0;
`;
export const MapContainer = styled(Row)`
    width :90%;
    height :120px;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: right;
    background-size: cover;
    box-shadow:1px 5px 8px #9e9e9e;
    margin-bottom: 5rem;
    border-radius: 12px;
    @media screen and (max-width: 600px) {
      width :100%;
    margin-bottom: 0px;
      }
`;
export const LastFooter = styled(Row)`
  height: 55px;
  line-height: 50px;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  color: ${(props) => props.theme.text};
  border-top : 2px solid ${(props) => props.theme.secondaryFontColor};
  font-size: ${(props) => props.theme.fontSizeXs};
  z-index: 6;
  p{width: 90%;}
  a {
    text-decoration: none;
    font-family: ${(props) => props.theme.fontBold};
  }
`;
