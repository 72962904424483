import React, { useEffect } from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer
} from "@mui/material";
import { TableWrapper } from "../../../../globalstyles";
import { withTranslation } from "react-i18next";
import { TitleContainer } from "../styles";
import { useSelector } from "react-redux";

const UpComingCourses = ({ t }) => {
    const lang = useSelector((state) => state.App.lang);
    const courseState = useSelector((state) => state.PrivateCourse);
    const { myCoursesList ,myCoursesListLoading } = courseState;


    return (
        <TableWrapper lang={lang}>
            <TitleContainer>{t("upComing courses")}</TitleContainer>
            <TableContainer >
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t("title")}</TableCell>
                            <TableCell align="center">{t("date")}</TableCell>
                            <TableCell align="center">{t("hour")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            myCoursesListLoading !== true ? myCoursesList.length > 0 ?
                                myCoursesList.map((row, ind) => {
                                    return(
                                    <TableRow key={ind}>
                                        <TableCell align="center">{lang === "fa" ? row.course.fa_title : row.course.en_title}</TableCell>
                                        <TableCell align="center">{lang === "fa" ? row.course.start_date_fa : row.course.start_date_en}</TableCell>
                                        <TableCell align="center">10-12</TableCell>
                                    </TableRow>)
                                })
                                : <>{t("no data to dispaly")}</>
                                : <div className="loader"></div>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </TableWrapper>
    )
}
export default withTranslation()(UpComingCourses);