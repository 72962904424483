import types from './Types';
import DelegateAction from '../ActionDelegator';

const CommentActions = {
  setShowCommentModal: DelegateAction(types.SET_SHOW_COMMENT_MODAL),
  getComments : DelegateAction(types.GET_COMMENTS),
  addComment : DelegateAction(types.ADD_COMMENT),
  deleteComment : DelegateAction(types.DELETE_COMMENT),


};

export default CommentActions;
