import React from 'react';
import { useSelector } from 'react-redux';

function WhatsappIcon() {
    const theme = useSelector((state) => state.App.theme);

    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.4411 31.436C27.0087 35.8656 21.8735 40.1048 19.8435 38.0748C16.9399 35.1712 15.1479 32.64 8.74153 37.7892C2.33793 42.9356 7.25753 46.3684 10.0715 49.1796C13.3195 52.4276 25.4267 49.3532 37.3939 37.3888C49.3583 25.4216 52.4243 13.3144 49.1735 10.0664C46.3595 7.24957 42.9435 2.33277 37.7971 8.73637C32.6479 15.14 35.1763 16.932 38.0855 19.8384C40.1071 21.8684 35.8707 27.0036 31.4411 31.436Z" fill="white"/>
        </svg>
        
    );
}

export default WhatsappIcon;
