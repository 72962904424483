export default {
  
  GET_USER_INFO: 'GET_USER_INFO',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAIL: 'GET_USER_INFO_FAIL',
  GET_USER_INFO_EXCEPTION: 'GET_USER_INFO_EXCEPTION',

  CHANGE_USER_PASSOWRD: 'CHANGE_USER_PASSOWRD',
  CHANGE_USER_PASSOWRD_SUCCESS: 'CHANGE_USER_PASSOWRD_SUCCESS',
  CHANGE_USER_PASSOWRD_FAIL: 'CHANGE_USER_PASSOWRD_FAIL',
  CHANGE_USER_PASSOWRD_EXCEPTION: 'CHANGE_USER_PASSOWRD_EXCEPTION',

  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_FAIL: 'UPDATE_USER_INFO_FAIL',
  UPDATE_USER_INFO_EXCEPTION: 'UPDATE_USER_INFO_EXCEPTION',

  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAIL: 'UPDATE_USER_PROFILE_FAIL',
  UPDATE_USER_PROFILE_EXCEPTION: 'UPDATE_USER_PROFILE_EXCEPTION',

  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',
  GET_NOTIFICATIONS_EXCEPTION: 'GET_NOTIFICATIONS_EXCEPTION',
 
};
