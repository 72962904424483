import styled from 'styled-components';

const positionGenerator = (placement) => {
  switch (placement) {
    case 'top':
      return 'left: -20px; top: 100%;';
      break;
    case 'bottom':
      return 'left: 0px; top: 100%;';
      break;
    case 'right':
      return 'left: -20px; top: 100%;';
      break;
    case 'left':
      return 'left: -20px; top: 100%;';
      break;
    default:
      return 'left: -20px; top: 100%;';
      break;
  }
};

export const StyledSubMenuWrapper = styled.div`
  box-shadow:0px 4px 16px 0px rgba(0,0,0,0.10);
  display: ${(props) => (props.visible === false ? 'none' : 'flex')};
  position: absolute;
  left: 55px;
  top: 60px;
  ${(props) => positionGenerator(props.placement)};
  width: ${(props) => (props.width ? props.width : '150px')};
  min-height: ${(props) => (props.height ? props.height : '80px')};
  align-items: flex-start;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  justify-content: center;
  max-height: 300px;
  ::-webkit-scrollbar {
    overflow: hidden;
    width: 0.063rem;
  }
  .square {
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: 20px;
    top: -6px;
    z-index: 20;
    transform: rotate(45deg);
    border-top: solid 1px #fff;
    border-left: solid 1px #fff;
    display: none;
  }
`;
