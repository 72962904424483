import React from "react";
import { useDispatch , useSelector } from "react-redux";
import StyledSubMenu from "../../../StyledSubMenu/index";
import { UserMenuConfig } from "./UserMenuConfig";
import {
  MenuListContainer,
  MenuItem,
  MenuListIcon,
  MenuListText,
} from "./style";

import AppActions from "../../../../redux/App/Actions";
import LoginActions from "../../../../redux/Login/Actions";
import { useHistory } from "react-router-dom";

const UserMenu = (props) => {
  const { visible, setShowSubMenu } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const LoginState = useSelector((state) => state.Login);
  const { role } = LoginState;

  const userMenuActions = (id) => {
    setShowSubMenu(false);
    switch (id) {
      case 0:
        handleLogin();
        break;
      case 1:
        logoutHandler();
        break;
      default:
        break;
    }
    dispatch(AppActions.userMenu(false));
  };

  const handleLogin = () =>{
    if(role === "admin"){
      history.push("/masters");
    }
    else{
      history.push("/dashboard");
    }

  }

  const logoutHandler = () => {
    dispatch(
      LoginActions.logOut({})
    )
  };

  return (
    <>
      <StyledSubMenu visible={visible} placement="bottom">
        <MenuListContainer>
          {UserMenuConfig.map((item) => {
            let Icon = item.icon;
            return (
              <MenuItem key={item.id} onClick={() => {userMenuActions(item.id);}}>
                <MenuListIcon>
                  <Icon pathFill="#3D54A8"/>
                </MenuListIcon>
                <MenuListText>{item.title}</MenuListText>
              </MenuItem>
            );
          })}
        </MenuListContainer>
      </StyledSubMenu>
    </>
  );
};
export default UserMenu;
