import React, { useEffect, useState } from 'react';
import { CoursesWrapprr, CourseContainer, SectionContainer, FilterConatiner } from "./styles";
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useSelector, useDispatch } from "react-redux";
import Content from '../../shared/content';
import { Grid, Button } from "@mui/material";
import CourseActions from '../../redux/Course/Actions';
import { withTranslation } from "react-i18next";
import { TitleContainer } from '../../shared/content/styles';
import Master from '../../shared/master';
import pic from "../../assets/images/avatar.png";
import { FormInput } from "../../kit/Form";
import MasterActions from '../../redux/Master/Actions';
import SerachInput from "../../shared/inputSearch";

const SearchesView = ({ t }) => {
    const dispatch = useDispatch();
    const CourseState = useSelector((state) => state.Course);
    const { hasMoreCourse, courseList, courseLoading, skip, take } = CourseState;
    const masterState = useSelector((state) => state.Master);
    const { mastersList, mastersLoading, takeMaster, skipMaster, countMaster, hasMore } = masterState;

    const lang = useSelector((state) => state.App.lang);
    const theme = useSelector((state) => state.App.theme);
    const params = new URLSearchParams(window.location.search);
    const [value, setValue] = useState("");

    useEffect(() => {
        
        setValue(params.get("q"));
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: "",
            offset: skip,
            limit: take,
            query: params.get("q"),
            sort: "",
            oriented: ""
        }));
        dispatch(MasterActions.getMasters({
            offset: skipMaster,
            limit: takeMaster,
            values:{
                query: params.get("q"),
            },
            type: "filter"
        }));
    }, []);

    const showMoreCourse = () => {
        dispatch(CourseActions.getTabCourses({
            related: false,
            recent: false,
            has_discount: false,
            category_id: "",
            offset: skip + take,
            limit: take,
            mode: "loadMore",
            query: value,
            sort: "",
            oriented: ""
        }))
    }
    const showMoreMaster = () => {
        dispatch(MasterActions.getMasters({
            offset: skipMaster,
            limit: takeMaster,
            values:{
                query: value,
            },
            type: "filter"
        }));
    }
    const handleChange = (e) => {
        let result = e.target.value;
        setValue(result);
        if (result.length > 3 || result.length === 0) {
            dispatch(CourseActions.getTabCourses({
                related: false,
                recent: false,
                has_discount: false,
                query: result,
                category_id: "",
                limit: 12,
                sort: "",
                oriented: "",
                offset: 0
            }));
            dispatch(MasterActions.getMasters({
                offset: 0,
                limit: 8,
                values:{
                    query: result,
                },
                type: "filter"
            }));
        }
    }

    return (
        <CoursesWrapprr lang={lang}>
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor} />
            <CourseContainer>
                <FilterConatiner>
                    <Grid container display="flex" justifyContent="center">
                        <Grid item xs={12} py={1} display="flex" justifyContent="center">
                                <SerachInput   onChange={handleChange} value={value}  placeholder={t("search")}/>
                        </Grid>
                    </Grid>
                </FilterConatiner>

                {courseLoading !== true ?
                    courseList.length > 0 ? <>
                        <TitleContainer>{t("courses")}</TitleContainer>
                        <SectionContainer lang={lang}>

                            <Grid container>
                                {courseList?.map((each, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Content data={each} key={index} />
                                        </Grid>
                                    )
                                })}
                            </Grid>

                        </SectionContainer>
                    </> : <></> : <div className="loader"></div>}
                {hasMoreCourse === true &&
                    <Button className="more_button" onClick={() => showMoreCourse()}>{t("more")}</Button>
                }

                {
                    mastersLoading !== true ?
                        mastersList.length > 0 ?
                            <>
                                <TitleContainer>{t("instructors")}</TitleContainer>
                                <SectionContainer>
                                    <hr/>
                                    <Grid container>
                                        {mastersList?.map((each, index) => {
                                            return (
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Master data={each} key={index} />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                </SectionContainer>
                            </> : <></> : <div className="loader"></div>}
                {
                    hasMore === true &&
                    <Grid p={1}><Button className="more_button" onClick={() => showMoreMaster()}>{t("more")}</Button></Grid>
                }
            </CourseContainer>
            <Footer />
        </CoursesWrapprr >
    )
}
export default withTranslation()(SearchesView);