import React, { useEffect } from 'react';
import {CourseWrapprr} from "./styles";
import CourseDescription from "./components/courseDescription";
import RelatedCourse from "./components/relatedCourses";
import Comment from "./components/comment";
import InstructorAbout from "./components/instructorAbout";
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useSelector,useDispatch } from "react-redux";
import { useHistory , useParams } from 'react-router-dom';
import CommentsActions from "../../redux/Comment/Actions";
import CourseActions from "../../redux/Course/Actions";
import PrivateCourseActions from "../../redux/PrivateCourse/Actions";

const Course = () => {
    const theme = useSelector((state) => state.App.theme);
    const courseDetail = useSelector((state) => state.PrivateCourse.courseDetail);
    const commentState = useSelector((state) => state.Comment);
    const {  skipComment ,takeComment} = commentState;
    const {location} = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        // alert("->" + location.state.id)
      window.scrollTo(0, 0);
      dispatch(PrivateCourseActions.getCourseById({id:id}));
      dispatch(CommentsActions.getComments({course_id :id, offset:0 , limit:takeComment}));
      dispatch(CourseActions.getCourses({
        related:true,
        recent:false,
        has_discount:false,
        courseType:"related",
        course_id :id
      }))
    }, [location?.pathname]);

    return (
        <CourseWrapprr>
            <Header backgroundColor={theme.white} color={theme.secondaryFontColor}/>
            <CourseDescription data={courseDetail}/>
            <InstructorAbout data={courseDetail}/>
            <Comment data={courseDetail}/>
            <RelatedCourse data={courseDetail}/>
            <Footer />
        </CourseWrapprr>
    )
}
export default Course;