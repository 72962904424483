export default {
  SET_SHOW_MASTER_DETAIL_MODAL: 'SET_SHOW_MASTER_DETAIL_MODAL',
  SET_SHOW_DELETE_MASTER_MODAL: 'SET_SHOW_DELETE_MASTER_MODAL',


  GET_MASTERS_LIST: 'GET_MASTERS_LIST',
  GET_MASTERS_LIST_SUCCESS: 'GET_MASTERS_LIST_SUCCESS',
  GET_MASTERS_LIST_FAIL: 'GET_MASTERS_LIST_FAIL',
  GET_MASTERS_LIST_EXCEPTION: 'GET_MASTERS_LIST_EXCEPTION',

  ADD_MASTER: 'ADD_MASTER',
  ADD_MASTER_SUCCESS: 'ADD_MASTER_SUCCESS',
  ADD_MASTER_FAIL: 'ADD_MASTER_FAIL',
  ADD_MASTER_EXCEPTION: 'ADD_MASTER_EXCEPTION',

  DELETE_MASTER: 'DELETE_MASTER',
  DELETE_MASTER_SUCCESS: 'DELETE_MASTER_SUCCESS',
  DELETE_MASTER_FAIL: 'DELETE_MASTER_FAIL',
  DELETE_MASTER_EXCEPTION: 'DELETE_MASTER_EXCEPTION',

  UPDATE_MASTER: 'UPDATE_MASTER',
  UPDATE_MASTER_SUCCESS: 'UPDATE_MASTER_SUCCESS',
  UPDATE_MASTER_FAIL: 'UPDATE_MASTER_FAIL',
  UPDATE_MASTER_EXCEPTION: 'UPDATE_MASTER_EXCEPTION',

  GET_MASTER_BY_ID: 'GET_MASTER_BY_ID',
  GET_MASTER_BY_ID_SUCCESS: 'GET_MASTER_BY_ID_SUCCESS',
  GET_MASTER_BY_ID_FAIL: 'GET_MASTER_BY_ID_FAIL',
  GET_MASTER_BY_ID_EXCEPTION: 'GET_MASTER_BY_ID_EXCEPTION',
 
};
