import types from './Types';
import DelegateAction from '../ActionDelegator';

const PaymentActions = {
  nextPaymentStep : DelegateAction (types.NEXT_PAYMENT_STEP),
  previousPaymentStep : DelegateAction (types.PREVIOUS_PAYMENT_STEP),
  addToMeetingList : DelegateAction (types.ADD_TO_MEETING_LIST),
  removeFromMeetingList : DelegateAction (types.REMOVE_FROM_MEETING_LIST),
  validateDiscountCode: DelegateAction (types.VALIDATE_DISCOUNT_CODE),
  getMasterAvailableTime : DelegateAction (types.GET_MASTER_AVAILABLE_TIME),
  bookCourse : DelegateAction (types.BOOK_COURSE),
  processPayment : DelegateAction (types.PROCESS_PAYMENT)
};

export default PaymentActions;
