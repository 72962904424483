import { all } from 'redux-saga/effects';
 import CourseSagas from './Course/Sagas';
 import LoginSagas from './Login/Sagas';
 import CommentSagas from './Comment/Sagas';
 import PaymentSagas from './Payment/Sagas';
import ProfileSagas from "./Profile/Sagas";
import MemberSagas from "./Member/Sagas";
import MasterSagas from "./Master/Sagas";
import PrivateCourseSagas from "./PrivateCourse/Sagas";
import DiscountSagas from "./Discount/Sagas";



export default function* sagas(getState) {
  yield all([
     LoginSagas(),
     CourseSagas(),
     CommentSagas(),
     PaymentSagas(),
     ProfileSagas(),
     MemberSagas(),
     MasterSagas(),
     PrivateCourseSagas(),
     DiscountSagas()
  ]);
}
