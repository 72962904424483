import styled from 'styled-components';
import Row from "../../kit/Row";


export const SearchWrapprr = styled(Row)`
position: relative;
width : ${(props) => props.width ? props.width : "300px"}; 
input {
  width:  ${(props) => props.width ? props.width : "100%"};  

}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}
`;