import React, { useEffect , useState } from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"
import DiscountActions from "../../../../redux/Discount/Actions";
import { useDispatch , useSelector } from "react-redux";
import {TableWrapper , CardContainer ,AddContainer} from "../../globalStyle";
import DiscountsTableConfig from "./DiscountsTableConfig";
import Table from "../../../../shared/Table";
import AlertModal from "../../../../shared/AlertModal";
import DiscountDetailModal from "./DiscountDetailModal";
import {SmallRow} from "../../../../kit/Row";

const Discount = () => {
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = useState({
    showAlert: false,
    Discount: null,
  });
  const defaultfields = {
    id:"",
    code: "",
    max_usage: "",
    category_id : 0,
    course_id : 0,
    value : "",
    percent : "",
  };
  const [fields, setFields] = useState(defaultfields);
  const DiscountState = useSelector((state) => state.Discount);
  const { dicountsList, dicountsLoading, takeDiscount, skipDiscount, countDiscount, mode ,showDsicountDetailModal  } = DiscountState;
  useEffect(() => {
    dispatch(DiscountActions.getDiscounts({ offset: 0, limit: takeDiscount ,is_staff :true }));
  }, []);
 ///////////////////////////////////////////detail
    console.log(dicountsList)
 const discountsDetailHandler = (result) => {
    
  setFields({
    ...fields,
    ...result,
  });
  dispatch(
    DiscountActions.setShowDiscountDatailModal({
      visible: true,
      mode: "edit",
    })
  );
 
};

const addOrUpdateDiscountHandler = (values, action) => {
  if (mode === "add") {
    dispatch(
      DiscountActions.addDiscount({
        fields: values,
        is_staff :true
      })
    );
  } else if (mode === "edit") {
    dispatch(
      DiscountActions.updateDiscount({
        fields: values,
        is_staff :true
      })
    );
  }
};

const closeDiscountDetailsModal = (formik) => {
  formik.resetForm({});
  dispatch(
    DiscountActions.setShowDiscountDatailModal({
      visible: false,
      mode: "add",
    })
  );
  setFields({
    ...fields,
    ...defaultfields,
  });

  formik.resetForm({});
};
//////////////////////////////////////
const pageHandler = (res) => {
  dispatch(
    DiscountActions.getDiscounts({
      offset: res,
      limit: takeDiscount,
      is_staff :true
    })
  );
}
////////////////////////////////////delete
  const deleteDiscount = (Discount) => {
    setDeleteState({
      ...deleteState,
      showAlert: true,
      Discount,
    });
  }
  const onConfirmDeleteHandler = () => {
    let callBack = () => {
      setDeleteState({
        ...deleteState,
        showAlert: false,
        Discount: null,
      });
    };
    dispatch(
      DiscountActions.deleteDiscount({
        Discount: deleteState.Discount,
        is_staff :true,
        callBack,
      })
    );
  };

  const onCancelDeleteHandler = () => {
    setDeleteState({
      ...deleteState,
      showAlert: false,
      Discount: null,
    });
  };

  return (
    <MainLayout>
      <CardContainer>
      <AddContainer>
          <span onClick={() => {
            dispatch(
              DiscountActions.setShowDiscountDatailModal({
                visible: true,
                mode: "add",
              })
            );
          }}>+</span>
        </AddContainer>
        <TableWrapper height="calc(100% - 70px)">
          <Table
            tblConfig={DiscountsTableConfig(discountsDetailHandler, deleteDiscount)}
            tblData={dicountsList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skipDiscount}
            take={takeDiscount}
            count={countDiscount}
            loading={dicountsLoading}
          />
        </TableWrapper>
      </CardContainer>
      <DiscountDetailModal
        open={showDsicountDetailModal}
        title={
          mode === "add" ? "اضافه کردن تخفیف " : "ویرایش تخفیف"
        }
        onClose={closeDiscountDetailsModal}
        onConfirm={addOrUpdateDiscountHandler}
        fields={fields}
        setFields={setFields}
      />
      <AlertModal
         open={deleteState.showAlert}
         onConfirm={onConfirmDeleteHandler}
         onClose={onCancelDeleteHandler}
         title={"حذف یک تخفیف"}
         onCancel={onCancelDeleteHandler}
        alertText={"آیا شما تمایل به حذف این تخفیف را دارید؟"}
        onConfirmButtonText={"بله"}
        onCancelButtonText={"خیر"}
      />
    </MainLayout>

  )
}

export default Discount;