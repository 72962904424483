import styled from 'styled-components';
import  Col from "../../../kit/Column";
import  Row from "../../../kit/Row";


export const ContentWrapper = styled(Col)`
  height: 100%;
  background-color: ${(props) => props.theme.baseBackGroundColor};
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    width: 100%;
  } ;
`;
export const BtnContainer = styled(Row)`
    margin :10px 0;
    button{
      margin-top :10px;
      background-color: ${(props) => props.theme.btnColor};
      border-radius: ${(props) => props.theme.borderRadius};
      border: none;
      width: 100%;
      padding: 10px;
      color : ${(props) => props.theme.white};
      cursor :pointer;
      font-family: ${(props) => (props.theme.fontDefault)};
      width :200px;
   }
`;


