import {
  all,
  takeEvery,
  put,
  fork,
  select

} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";

export function* GetMembers() {
  yield takeEvery(types.GET_MEMBERS_LIST, function* ({ data }) {
    try {
      let response =[];
      if(data.type === "filter"){
         response = yield Api.Get(Endpoints.GetMembers + `?offset=${data.offset}&limit=${data.limit}&is_staff=${data.is_staff}&q=${data.values.query}`, true);
      }
      else{
        response = yield Api.Get(Endpoints.GetMembers + `?offset=${data.offset}&limit=${data.limit}&is_staff=${data.is_staff}`, true);
      }
   
      if (response) {
        yield put({
          type: types.GET_MEMBERS_LIST_SUCCESS,
          data: { data: response.data, count: response.total },

        });
      } else
        yield put({
          type: types.GET_MEMBERS_LIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_MEMBERS_LIST_FAIL,
      });
    }
  });
}

export function* AddMember() {
  yield takeEvery(types.ADD_MEMBER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    const Data = new FormData();
    Data.append('email', data.fields.email);
    Data.append('phone', data.fields.phone);
    Data.append('en_biography', data.fields.en_biography);
    Data.append('en_first_name', data.fields.en_first_name);
    Data.append('en_last_name', data.fields.en_last_name);
    Data.append('fa_biography', data.fields.fa_biography);
    Data.append('fa_first_name', data.fields.fa_first_name);
    Data.append('fa_last_name', data.fields.fa_last_name);
    Data.append('gender', data.fields.gender);
    Data.append('birthday', data.fields.birthday);
    Data.append('password', data.fields.ramz);

    try {
      const response = yield Api.Post(Endpoints.AddMember+ `?is_staff=${data.is_staff}`, Data, true ,"form");
      if (response) {
        yield put({
          type: types.ADD_MEMBER_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.ADD_MEMBER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.ADD_MEMBER_FAIL,
      });
    }
  });
}

export function* DeleteMember() {
  yield takeEvery(types.DELETE_MEMBER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    try {
      const response = yield Api.Delete(Endpoints.DeleteMember + `/${data.member.id}?is_staff=${data.is_staff}`, true);
      if (response) {
        yield put({
          type: types.DELETE_MEMBER_SUCCESS,
          data: data.member.id
        });
        data?.callBack();
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.DELETE_MEMBER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.DELETE_MEMBER_FAIL,
      });
    }
  });
}

export function* UpdateMember() {
  yield takeEvery(types.UPDATE_MEMBER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    const Data = new FormData();
    Data.append('email', data.fields.email);
    Data.append('phone', data.fields.phone);
    Data.append('en_biography', data.fields.en_biography);
    Data.append('en_first_name', data.fields.en_first_name);
    Data.append('en_last_name', data.fields.en_last_name);
    Data.append('fa_biography', data.fields.fa_biography);
    Data.append('fa_first_name', data.fields.fa_first_name);
    Data.append('fa_last_name', data.fields.fa_last_name);
    Data.append('gender', data.fields.gender);
    Data.append('birthday', data.fields.birthday);
    Data.append('password', data.fields.ramz);
    try {
      const response = yield Api.Put(Endpoints.UpdateMember + `/${data.fields.id}?is_staff=${data.is_staff}`, Data, true ,"form");
      if (response) {
        yield put({
          type: types.UPDATE_MEMBER_SUCCESS,
          data: response
        });
        toast.success(i18n.t("Done successfully"), {
          position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
        });

      } else
        yield put({
          type: types.UPDATE_MEMBER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.UPDATE_MEMBER_FAIL,
      });
    }
  });
}

export function* GetMemberById() {
  yield takeEvery(types.GET_MEMBER_BY_ID, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetMemberById + `/${data.id}`, true);
      if (response) {
        yield put({
          type: types.GET_MEMBER_BY_ID_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_MEMBER_BY_ID_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_MEMBER_BY_ID_FAIL,
      });
    }
  });
}

export default function* MembersSagas() {
  yield all(
    [
      fork(GetMembers),
      fork(AddMember),
      fork(DeleteMember),
      fork(UpdateMember),
      fork(GetMemberById)
    ]);
}
