export const modulesList = [
  {
    id: "0",
    title: "mainSystem",
    displayTitle: "داشبورد",
    visible: true,
    basePath: "/",
    hasSubSystem: true,
    subSystem: [
      {
        id: "01",
        title: "dashboard",
        displayTitle: "پیشخوان",
        path: "/dashboard",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "02",
        title: "account",
        displayTitle: "حساب کاربری",
        path: "/account",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "03",
        title: "my-courses",
        displayTitle: "دوره های من",
        path: "/my_courses",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "04",
        title: "favorites",
        displayTitle: "علاقه مندی ها",
        path: "/favorites",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "05",
        title: "announcements",
        displayTitle: "اعلانات",
        path: "/announcements",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "06",
        title: "orders",
        displayTitle: "سفارشات",
        path: "/orders",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "07",
        title: "calendar",
        displayTitle: "تقویم",
        path: "/calendar",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      {
        id: "08",
        title: "change_password",
        displayTitle: "تغییر رمز عبور",
        path: "/change_password",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "member"
      },
      ////////////////////////////////////////////admin
      {
        id: "11",
        title: "masters",
        displayTitle: "استاد",
        path: "/masters",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        id: "12",
        title: "users",
        displayTitle: "کاربران",
        path: "/users",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        id: "13",
        title: "adminCourses",
        displayTitle: "درس",
        path: "/adminCourses",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        id: "14",
        title: "staff",
        displayTitle: "کارکنان",
        path: "/staff",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        id: "15",
        title: "categories",
        displayTitle: "دسته بندی",
        path: "/categories",
        visible: false,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        id: "16",
        title: "adminOrders",
        displayTitle: "سفارشات",
        path: "/adminOrders",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
      {
        title: "discounts",
        id: "18",
        path: "/discounts",
        displayTitle: "تخفیفات",
        visible: true,
        hasMenus: false,
        menus: [],
        role : "admin"
      },
    ],
  },
];
