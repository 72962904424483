
import React, { useState, Fragment, useEffect } from 'react';
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TableContainer,
    Collapse,
    Grid,
    IconButton
} from "@mui/material";
import { TableWrapper } from "../../../globalstyles";
import { withTranslation } from "react-i18next";
import { TitleContainer, ContenttWrapper } from "../../globalStyle";
import MainLayout from "../../../../shared/dashboardMainLayout";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector, useDispatch } from "react-redux";
import PrivateCourseActions from "../../../../redux/PrivateCourse/Actions";

const Orders = ({ t }) => {
    const [collapse, setCollapse] = useState(null);
    const lang = useSelector((state) => state.App.lang);
    const dispatch = useDispatch();
    const courseState = useSelector((state) => state.PrivateCourse);
    const { myCoursesListLoading, myCoursesList } = courseState;

    useEffect(() => {
        dispatch(PrivateCourseActions.getMyCoursesList({ offset: 0, limit: 10 }));
    }, [])

    const collapseOrders = (rowIndex) => {
        if (collapse === rowIndex) {
            setCollapse(null);
        }
        else {
            setCollapse(rowIndex)
        }
    }

    const detail = (row) => {

        const list = [
            { id: 0, title: lang === "fa" ? "شماره سفارش:" : "Order Number :", amount_fa: "123456" , amount_en: "123456" },
            { id: 0, title: lang === "fa" ? "تعداد جلسه:" : "Number of sessions :", amount_fa: row.course.sessions , amount_en: row.course.sessions },
            { id: 0, title: lang === "fa" ? "جمع کل مبلغ:" : "Total amount :", amount_fa: "200" , amount_en: "200"  },
            { id: 0, title: lang === "fa" ? "عنوان دوره:" : "Course title :", amount_fa: row.course.fa_title , amount_en: row.course.en_title },
            { id: 0, title: lang === "fa" ? "تاریخ شروع:" : "Start Date :", amount_fa: row.course.start_date_fa , amount_en: row.course.start_date_en  },
            { id: 0, title: lang === "fa" ? "مبلغ تخفیف:" : "Discount amount :", amount_fa: row.discount , color: "red" , amount_en: row.discount },
            { id: 0, title: lang === "fa" ? "نام استاد:" : "Master :", amount_fa:  row.course.master.fa_last_name , amount_en: row.course.master.en_last_name },
            { id: 0, title: lang === "fa" ? "وضعیت:" : "Status :", amount_fa: row.status, color: "green" , amount_en: row.status},
            { id: 0, title: lang === "fa" ? "مبلغ نهایی:" : "Final amount :", amount_fa: row.sum_price , amount_en: row.sum_price },

        ]
        return list;
    }
    return (
        <MainLayout>
            <ContenttWrapper >
                <TableWrapper id="order-table" lang={lang} >
                    <TitleContainer>{t("recent orders")}</TitleContainer>
                    <TableContainer >
                        <Table >
                            <TableHead>
                                <TableRow>

                                    <TableCell align="center">{t("price")}</TableCell>
                                    <TableCell align="center">{t("start date")}</TableCell>
                                    <TableCell align="center">{t("Number of sessions")}</TableCell>
                                    <TableCell align="center">{t("course title")}</TableCell>
                                    <TableCell align="center">{t("Number of orders")}</TableCell>

                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {myCoursesList?.map((row, ind) => (
                                    <Fragment>
                                        <TableRow key={ind}>

                                            <TableCell align="center">{row.course.price}</TableCell>
                                            <TableCell align="center">{lang === "fa" ? row.course.start_date_fa : row.course.start_date_en}</TableCell>
                                            <TableCell align="center">{row.course.sessions}</TableCell>
                                            <TableCell align="center">{lang === "fa" ? row.course.fa_title : row.course.en_title}</TableCell>
                                            <TableCell align="center">{row.course.price}</TableCell>
                                            <TableCell
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => collapseOrders(ind)}
                                            >

                                                {collapse === ind ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}

                                            </TableCell>

                                        </TableRow>
                                        <TableRow id="collapse-row">
                                            <TableCell
                                                className="py-0"
                                                colSpan={6}
                                            >
                                                <Collapse
                                                    in={collapse === ind}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Grid container p={2}>
                                                        {detail(row).map((each, index) => {
                                                            return (
                                                                <Grid container items xs={6} md={4} py={1}>
                                                                    <Grid xs={6}
                                                                        items className="blue-font"
                                                                        display="flex"
                                                                        justifyContent="start">
                                                                        {each.title}</Grid>
                                                                    <Grid xs={6}
                                                                        items
                                                                        color={each?.color}
                                                                        display="flex"
                                                                        justifyContent="start">{lang === "fa" ? each.amount_fa : each.amount_en}</Grid>
                                                                </Grid>
                                                            )
                                                        })}

                                                    </Grid>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TableWrapper>
            </ContenttWrapper >
        </MainLayout >
    )
}
export default withTranslation()(Orders);