import styled from "styled-components";
import Row from "../../../kit/Row";
import Col from "../../../kit/Column";
import { Link } from "react-router-dom";
import { globalFlexCenter } from "../../GeneralStyles";
export const SubSystemWrapper = styled(Col)`
  align-items: center;
  background-color: ${(props) => props.theme.primary};
  justify-content: flex-start;
  width: ${(props) =>props.collapse === false ? "180px" : "50px"};
  height: calc(100% - 16px);
  padding-top: 15px;
  position: relative;
  border-radius: ${(props) => props.theme.primaryBorderRadius};
  .o__subSystemTitle {
    color: ${(props) => props.theme.primary};
    border-bottom: ${(props) => props.theme.borderPrimary};
    width: 230px;
    justify-content: center;
    height: 40px;
  }
  .dashboard{
    border-bottom: 1px solid #afafaf73;
    color:${(props) => props.theme.white};
  }
  .clickable{
    cursor:pointer;
  }
  @media screen and (max-width: 600px) {
     width: 50px;
    }
`;
export const AppLogoContainer = styled(Col)`
  justify-content: center;
  box-sizing: border-box;
  width: ${props => props.height ? props.height  :"30px"};
  height: ${props => props.height ? props.height  :"30px"};
  background-image: url(${props => props.src});
  background-size : contain;
  background-repeat :  no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 1px solid white;
`;

export const SubSystemContainer = styled(Col)`
  margin-top:15px;
  justify-content: flex-start;
  height: calc(100% - 75px);
  width :100%;
`;

export const SubSystemItemContainer = styled(Row)`
  height: auto;
  width: 100%;
  display: ${(props) => (props.title === "profile" && "none")};
 
`;
export const SubSystemItem = styled(Link)`
  padding:8px 0px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-right: ${(props) =>props.collapse === false && props.lang === "fa" ? "10px" : "0px"};
  padding-left: ${(props) =>props.collapse === false && props.lang === "en" ? "10px" : "0px"};

  justify-content: ${(props) =>props.collapse === false ? "flex-start" : "center"} ;
  // padding-right :10px;
  // justify-content:flex-start;
  position: relative;
  color: ${(props) => (props.active ? "#fff" : props.theme.white)};
  background-color :${(props) => (props.active ? props.theme.primaryLight : props.theme.primary)};
  border-radius: 10px;
  font-size : ${(props) => (props.theme.fontSizeSm)};
  font-family: ${(props) => (props.theme.fontBold)};
  cursor: pointer;
  span {
    margin-right: ${(props) =>props.lang === "fa" ? "20px" : "0px"};
    margin-left: ${(props) =>props.lang === "en" ? "20px" : "0px"};

  }
  .o__subSystem__chevronIcon {
    ${globalFlexCenter};
    transform: ${(props) => props.active && "rotate(180deg)"};
    position: absolute;
    left: 0px;
    padding: 0px 10px;
    svg {
      width: 100%;
      path {
        // fill: ${(props) => (props.active ? props.theme.textSecondary : "#ccc")};
        stroke: ${(props) => (props.active ? props.theme.textSecondary : "#ccc")};
        g: ${(props) => (props.active ? props.theme.textSecondary : "#ccc")};

      }
    }
  }
  @media screen and (max-width: 600px) {
    padding-right: 0px;
    padding-left: 0px;
    justify-content: center;
   }
`;
export const SubSystemMenusContainer = styled(Col)`
  margin: 0px 5px 0px 10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
export const SubSystemMenusItem = styled(Link)`
  margin: 5px 0px 5px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: ${(props) => props.active && props.theme.fontBold};
  font-weight: ${(props) => props.active ? props.theme.fontSizeMd : props.theme.fontSizeSm};
  color: ${(props) => (props.active ? "#fff" : "#CCCCCC")};
  padding-right:30px;
`;
export const TitleContainer = styled.div`
color : ${props => props.theme.textSecondary};
font-size : ${props => props.theme.fontSizeLg};
font-family: ${(props) => props.theme.fontBold};
padding :5px 0px;
`;
export const ExitContainer = styled.div`
display: flex;
align-items: center;
width: ${(props) =>props.collapse === false ? "150px" : "30px"};
cursor :pointer;
color : ${props => props.theme.white};
font-size : ${props => props.theme.fontSizeSm};
    padding: 5px 20px;
    margin-top: 30px;
    margin-bottom: 10px;
    span{
      margin-right: ${(props) =>props.lang === "fa" ? "20px" : "0px"};
      margin-left: ${(props) =>props.lang === "en" ? "20px" : "0px"};
    }
`;
export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: ${(props) => props.size || "15px"};
  height: ${(props) => props.size || "15px"};
  svg {
    width: ${(props) => props.size || "15px"};
    height: ${(props) => props.size || "15px"};
  }
`;

