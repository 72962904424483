import React from 'react';
import { useSelector } from 'react-redux';

function FacebookIcon() {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="none"
    viewBox="0 0 60 60"
  >
    <path
      fill="#3D54A8"
      d="M55.238 0H4.762C2.132 0 0 2.239 0 5v50c0 2.761 2.132 5 4.762 5h50.476C57.868 60 60 57.761 60 55V5c0-2.761-2.132-5-4.762-5z"
    ></path>
    <path
      fill="#FCFCFC"
      d="M46 30a16 16 0 10-16 16c.091 0 .187 0 .281-.006v-12.45h-3.438v-4.007h3.438v-2.95c0-3.419 2.088-5.284 5.138-5.284 1.029-.005 2.057.047 3.08.156v3.574h-2.1c-1.655 0-1.98.788-1.98 1.944v2.55h3.969l-.52 4.008H34.42V45.38A16.005 16.005 0 0046 30z"
    ></path>
  </svg>
  );
}

export default FacebookIcon;
