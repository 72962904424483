import React from 'react';
import { useSelector } from 'react-redux';

function TwitterIcon() {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="#3D54A8"
        d="M55.238 0H4.762C2.132 0 0 2.239 0 5v50c0 2.761 2.132 5 4.762 5h50.476C57.868 60 60 57.761 60 55V5c0-2.761-2.132-5-4.762-5z"
      ></path>
      <path
        fill="#FCFCFC"
        d="M46 20.08a13.68 13.68 0 01-3.78 1.036 6.524 6.524 0 002.886-3.63 13.112 13.112 0 01-4.162 1.588 6.56 6.56 0 00-11.346 4.49 6.755 6.755 0 00.152 1.496A18.569 18.569 0 0116.226 18.2a6.562 6.562 0 002.018 8.772 6.478 6.478 0 01-2.965-.808v.071a6.59 6.59 0 005.258 6.447c-.562.148-1.14.22-1.72.216a5.8 5.8 0 01-1.243-.12 6.622 6.622 0 006.13 4.57 13.18 13.18 0 01-8.13 2.798A12.294 12.294 0 0114 40.058c3 1.933 6.496 2.956 10.065 2.944 12.072 0 18.672-10 18.672-18.668 0-.29-.01-.57-.025-.847A13.086 13.086 0 0046 20.08z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
