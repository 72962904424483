import styled from "styled-components";
import Col from "../../kit/Column";
import Row from "../../kit/Row"
export const FullPageWrapper = styled(Col)`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.backgroundColor || "#3D54A8"};
 *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

`;

export const FullPageContainer = styled(Row)`
  height: 95vh;
  width: 97vw;
  background-color: transparent;
  justify-content: space-between;
`;
export const ContentViewWrapper = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100%);
  height: 100%;
`;

export const BodyContainer = styled(Row)`
width: ${(props) =>props.collapse === false ? "calc(100% - 190px)" : "calc(100% - 70px)"};
height: calc(100%);
background-color: ${(props) => props.theme.white};
border-radius: ${(props) => props.theme.primaryBorderRadius};
@media screen and (max-width: 600px) {
  width: calc(100% - 70px);
 }
`
export const ContentViewContainer = styled(Row)`
  align-items: flex-start;
  width: ${(props) => props.hasIndustry !== true ? "calc(100%)" : " calc(80%)"};
  background-color: ${(props) => props.theme.white};
  height: 100%;
  border-radius: ${(props) => props.theme.primaryBorderRadius};
  overflow-y: auto;
  @media only screen and (max-height: 695px) {
    overflow-y: auto;
    height: ${(props) => props.needAutoHeight === true && "auto"};
    max-height: calc(100% - 5px);
  }
`;
