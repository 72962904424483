import React from "react";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import DetailsIcon from "../../assets/icons/DetailsIcon";
import EditIcon from "../../assets/icons/EditIcon";
import ShareIcon from "../../assets/icons/ShareIcon";
import { ActionIconsItem } from "../GeneralStyles";

const TableAction = ({ onClick, name, type }) => {
  const Icon = (type) => {
    switch (type) {
      case "delete":
        return <DeleteIcon />;
      case "edit":
        return <EditIcon />;
      case "share":
        return <ShareIcon />;
      case "detail":
        return <DetailsIcon />;
      default:
        break;
    }
  };
  return (

    <ActionIconsItem onClick={onClick}>{Icon(type)}</ActionIconsItem>

  );
};

export default TableAction;
