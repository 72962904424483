import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row"


export const ContactUsWrapper = styled(Col)`
    width:100%;
    margin-top:75px;
    background-color : #f7f7f7;
    border-top :1px solid #ccc;
    box-shadow:0px 1px 3px 1px #b9b9b9;
    #contact-container{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top :40px;
        direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};
    }
    #contact-intro-container {
        width: 80%;
        margin :30px 0;

    }
    #form-container{
        display: flex;
        padding-left: ${(props) => props.lang === "fa" ? "11%" : "20px"};
        padding-right: ${(props) => props.lang === "fa" ? "20px" : "11%"};
    }
    @media screen and (max-width: 900px) {
        #form-container{
            display: flex;
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
        }  
    }
`;
export const ContactBoxContainer = styled(Col)`
    margin:2%;
    height : 220px;
    border-radius : 10px;
    padding: 0 20px;
    background-color: ${props => props.theme.thirdFontColor};
    .text-container{
        padding:10px 0;
        color: ${props => props.theme.white};
        text-align: justify;
    }
    .svg-container{
        padding:10px 0;
       svg{
        width: 40px;
        height: 40px;
       } 
    }
`;

///////////////////////////////////////
export const FormWrapper = styled(Col)`
width :100%;
.field-container{
    padding:5px 0;
}

input  : focus {
   background-color: inherit !important;
}
textarea{
    background-color: white;
    padding:0 10px;
   border-radius: ${(props) => props.theme.borderRadius};
}
input , textarea{
    box-sizing: border-box;
}
button{
   margin-top :10px;
   background-color: ${(props) => props.theme.btnColor};
   border-radius: ${(props) => props.theme.borderRadius};
   border: none;
   width: 100%;
   padding: 10px;
   color : ${(props) => props.theme.white};
   cursor :pointer;
   font-family: ${(props) => (props.theme.fontDefault)};
}
font-family: ${(props) => (props.theme.fontDefault)};
@media screen and (max-width: 900px) {
    width :80%;
    padding-right :0px
    }
    *{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 

`;

export const FormContainer = styled(Row)`
    width: 100%;
    form{
        width :100%;
        padding-top:20px;
    }
`;
export const ContactImageContainer = styled(Row)`
    width:100%;
    background-image : url(${props => props.src});
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
    height :400px;
    transform: ${(props) => props.lang === "fa" ? "scaleX()" : "scaleX(-1)"}; 
  
`;