import React, { useEffect } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import ChangePassword from "./components/changePassword";
import { ContenttWrapper } from "../../globalStyle";
const Accounts = () => {

    return (
        <MainLayout >
            <ContenttWrapper>
                <ChangePassword />
            </ContenttWrapper>
        </MainLayout>
    )
}
export default Accounts;