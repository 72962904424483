import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Tab,
    Tabs,
    Typography
} from "@mui/material"
import { TabWrapper } from "./styles"
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box id="tab-content-container">
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const TabHandler = (props) => {
    const { TabConfig, id, handleChange, tabValue,search , setSearch,sort,setSort } = props
    
    useEffect(() => {
        // alert(id)
    }, []);

    return (
        <TabWrapper >

            <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                indicatorColor={""}
                // variant={isMobile ? "scrollable" : 'standard' }
                variant={"scrollable"}
                scrollButtons={"auto"}
                allowScrollButtonsMobile
            >

                {TabConfig.map((each, index) => {
                    // console.log(each.id)
                    return (
                        <Tab label={each.title}  value={each.id} {...a11yProps(each.id)} />
                    );
                })}


            </Tabs>

            {TabConfig.map((each, index) => {
                let Body = each.body;
                // console.log(tabValue)
                return (
                    <TabPanel value={tabValue} index={each.id} component="div" style={{ width: "100%" }}>
                        <Body
                            id={id}
                            xxx={id}
                            search={search}
                            setSearch={setSearch}
                             sort={sort}
                             setSort={setSort} 
                            />
                    </TabPanel>
                );
            })}
        </TabWrapper>
    );
};

export default TabHandler;