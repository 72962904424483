import React, { useEffect } from "react";
import RegisterCompleteImage from '../../views/registerCompelete/RegisterCompleteImage';
import RegisterCompleteContent from '../../views/registerCompelete/RegisterCompleteContent';
import { Grid } from "@mui/material";
import { ToastContainer } from 'react-toastify';

const CompleteRegister =() => {
  
    return(
        <Grid container height="100vh">
        <Grid item xl={8} lg={8} md={8} >
            <RegisterCompleteImage />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}  height="100%">
            <RegisterCompleteContent/>
        </Grid>
      <ToastContainer />

    </Grid>

    )
}
export default CompleteRegister;