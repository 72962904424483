import types from './Types';
import DelegateAction from '../ActionDelegator';

const ProfileActions = {
  getUserInfo : DelegateAction (types.GET_USER_INFO),
  changeUserPassword : DelegateAction (types.CHANGE_USER_PASSOWRD),
  updateUserProfile : DelegateAction (types.UPDATE_USER_PROFILE),
  updateUserInfo : DelegateAction (types.UPDATE_USER_INFO),
  getNotifications: DelegateAction (types.GET_NOTIFICATIONS),
};

export default ProfileActions;
