import React, { useEffect } from "react";
import { Modal, Box, Grid ,Button } from "@mui/material"
import { withTranslation } from "react-i18next";
import { ModalConatiner } from "../../../../views/globalstyles";
import CloseIcon from "../../../../assets/icons/DeleteIcon";
import { Formik } from "formik";
import { FormInput } from "../../../../kit/Form";
import FormController from "../../../../controllers/FormController";
import VirtualizedSelect from 'react-virtualized-select';
import { useSelector } from "react-redux";
import { generateRandomString } from "../../../../utils";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
};
const genderListOptions = [
    { value: "male", label: "مرد" },
    { value: "female", label: "زن" },
    { value: "other", label: "سایر" },
]

const MemberDetailModal = ({
    open,
    onClose,
    onConfirm,
    title,
    fields,
    setFields,
    t
}) => {
    const stateCourse = useSelector((state) => state.Course);
    const { categoriesListOptions } = stateCourse;
    useEffect(() => {

    }, [fields]);

 
    const generateCode = (formik ,name) => {
        const randomString = generateRandomString(8);
        console.log(randomString);
        formik.setFieldValue(name, randomString);

    }
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                ...fields,
            }}
            onSubmit={(values, actions) => {
                onConfirm(values, actions);
            }}
        >
            {(formik) => (
                <Modal
                    open={open}
                    onClose={() => onClose(formik)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableAutoFocus={true}
                    BackdropProps={{ onClick: (e) => e.stopPropagation() }}
                >
                    <Box sx={style}>
                        <ModalConatiner lang={"fa"}>
                            <Grid container>
                                <Grid item container xs={12} id="modal_title">

                                    <Grid item xs={11} display="flex" justifyContent="start">
                                        {title}
                                    </Grid>
                                    <Grid item xs={1} display="flex" justifyContent="end"
                                        onClick={() => onClose(formik)} className="clickable">
                                        <CloseIcon />
                                    </Grid>
                                </Grid>

                                <form className="" onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid items xs={6} p={1}>
                                        <Box mb={1} className="lable-form">کد تخفیف</Box>
                                            <Box dispaly="flex">
                                                <FormInput
                                                    type="text"
                                                    className={
                                                        "login-form-control" + (formik.errors.code && formik.touched.code ? " warning-border" : "")
                                                    }
                                                    value={formik.values.code}
                                                    id="code"
                                                    name="code"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"کد تخفیف"}
                                                    readOnly
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form" style={{height:"18px"}}></Box>
                                                <Button style={{marginTop:"0px"}} onClick={() => {generateCode(formik , "code")}} id="generate-discount"> ایجاد کد تخفیف</Button>
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">تعداد</Box>
                                                <FormInput
                                                    type="number"
                                                    min={0}
                                                    className={
                                                        "login-form-control" + (formik.errors.max_usage && formik.touched.max_usage ? " warning-border" : "")
                                                    }
                                                    value={formik.values.max_usage}
                                                    id="max_usage"
                                                    name="max_usage"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"تعداد استفاده"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} p={1}>
                                        <Box mb={1} className="lable-form">دسته بندی</Box>
                                            <VirtualizedSelect
                                                value={formik.values.category_id}
                                                name="category_id"
                                                id="category_id"
                                                placeholder="انتخاب دسته بندی"
                                                onChange={(selected) => {

                                                    FormController.onSelect(selected, "category_id", formik, null, true);
                                                }}
                                                maxHeight={150}
                                                options={categoriesListOptions}
                                                className={
                                                    "custom_input" + (formik.errors.category_id && formik.touched.category_id ? " warning-border" : "")
                                                }
                                                backspaceToRemoveMessage=""
                                            />
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">مقدار</Box>
                                                <FormInput
                                                    type="number"
                                                    min={0}
                                                    className={
                                                        "login-form-control" + (formik.errors.value && formik.touched.value ? " warning-border" : "")
                                                    }
                                                    value={formik.values.value}
                                                    id="value"
                                                    name="value"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"مقدار"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid items xs={6} p={1}>
                                            <Box dispaly="flex">
                                            <Box mb={1} className="lable-form">درصد</Box>
                                                <FormInput
                                                    type="number"
                                                    min={0}
                                                    max={100}
                                                    className={
                                                        "login-form-control" + (formik.errors.percent && formik.touched.percent ? " warning-border" : "")
                                                    }
                                                    value={formik.values.percent}
                                                    id="percent"
                                                    name="percent"
                                                    onChange={(e) => FormController.onChange(e, formik)}
                                                    onBlur={(e) => FormController.onBlur(e, formik)}
                                                    placeholder={"درصد"}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid px={1} color="red"> 
                                                    لطفا تنها یکی از فیلدهای مقدار یا درصد را وارد کنید
                                        </Grid>
                                        <Grid items xs={12} p={1}>
                                            <Box dispaly="flex" >
                                                <button
                                                    color="primary"
                                                    size="lg"
                                                    onClick={formik.handleSubmit}
                                                    className="ml-1"
                                                >
                                                    <span className="color-white">{t("send")}</span>
                                                </button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </ModalConatiner>
                    </Box>
                </Modal>
            )}
        </Formik>
    )
}
export default withTranslation()(MemberDetailModal);