
export const theme = {
    primary: "#3D54A8",
    primaryLight : "#5065B1",
    secondary: "#125C69",
    text: "#393A3C",
    textLight: "#f8f9fc",
    lightGary: "#dddddd",
    baseFontColor: "#393A3C",
    secondaryFontColor : "#3D54A8",
    thirdFontColor : "#21B076",
    lightFontColor : "#999999",
    primaryColor:"#393A3C",
    white: "#fff",

    baseBackGroundColor : "#fff",
    inputBorder :"#CFD8DE",
    btnColor:"#3D54A8",
    secondaryBtnColor: "#21B076",
    borderRadius:"5px",
    primaryBorderRadius : "10px",
    fontDefault: "Dana",
    fontMedium: "DanaMedium",
    fontBold: "DanaBold",
    fontBlack: "DanaBlack",
    fontLight: "DanaLight",
    
    fontSizeMd: "1rem",
    fontSizeLg: "1.25rem",
    fontSizeXl: "1.5rem",
    fontSizeXXl: "2.125rem",
    fontSizeSm: "0.875rem",
    fontSizeXs: "0.75rem",
    fontSizeXXs: "0.625rem",
  };
  