import React from 'react';
import { Stepper, Step, StepLabel, StepIcon } from '@mui/material';
import { makeStyles } from "@mui/styles"
import Step1Icon from '../../assets/icons/LanguageIcon';
import Step2Icon from '../../assets/icons/CommentIcon';
import Step3Icon from '../../assets/icons/LinkedinIcon';
import Step4Icon from '../../assets/icons/FacebookIcon';
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";





const CustomStepper = ({ activeStep, t }) => {
  const lang = useSelector((state) => state.App.lang);

  const useStyles = makeStyles((theme) => ({
    root: {
    
      '& .MuiStepIcon-root': {
        color: '#ccc',
        '&$active': {
          color: '#4CAF50', // Green color for active step
        },
        '&$completed': {
          color: '#4CAF50', // Green color for completed step
        },
      },
      '& .MuiStepIcon-text': {
        fill: '#000', // Color for the SVG icon
      },
      [theme.direction === "rtl" && theme.breakpoints.up('sm')]: {
        flexDirection: 'row-reverse',
        '& .MuiStepLabel-label': {
          textAlign: 'right'
        },
      },
    },

    active: {},
    completed: {},
  }));

  const CustomStepIcon = (value) => {
    // const classes = useStyles();
    // const { active, completed } = props;

    const icons = {
      1: <Step1Icon />,
      2: <Step2Icon />,
      3: <Step3Icon />,
      4: <Step4Icon />,
    };

    return (
      <StepIcon
      // classes={{
      //   root: classes.root,
      //   active: classes.active,
      //   completed: classes.completed,
      // }}

      >
        {icons[value]}
      </StepIcon>
    );
  };

  const steps = [
    t("Choose the day and time"),
    t("completion of information"),
    t("payment"),
    t("purchase")
  ];

  return (
    <Stepper activeStep={activeStep}   alternativeLabel >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
          // StepIconComponent={CustomStepIcon(index+1)}
          >{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default withTranslation()(CustomStepper);