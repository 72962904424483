import axios from "axios";
import storage from "../../storage";
import Keys from "../../storage/NameSpace";
// import { toast } from 'react-toastify';

export let inRefresh = false;
let requestList = [];
let baseurl = "";

export const axiosInstance = axios.create({
  baseurl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  
  (response) => {

    if (response.status !== 200) {
  
      // toast.error(response.data.message, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
      return response.data.status;
    } else {
      return response.data;
    }
  },
  (error) => {
    const { config } = error;
    const originalRequest = config;
    if (error.response && error.response.status === 401) {
       window.location.replace("/");
      // storage.removeAll()
      storage.logOut()
      if (!inRefresh) {
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers["Authorization"] =
            "Bearer " + storage.get(Keys.token);
          resolve(axiosInstance(originalRequest));
        });
      });
      return retryOrigReq;
    }
    else if (error.response && error.response.status === 403) {
    return error.response.data
   }
     else {
      return Promise.reject(error);
    }
  }
);

const subscribeTokenRefresh = (request) => {
  requestList.push(request);
};
