
import React from "react";
import { CommentWrapper, CommentContainer, ProfileContainer, CommentTextConatainer } from "./styles";
import { Rating, Grid, Box } from "@mui/material";
import Profile from "../../assets/images/avatar.png"
import { useSelector, useDispatch } from "react-redux";
import ClockIcon from "../../assets/icons/ClockIcon"
import { withTranslation } from "react-i18next";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import CommentActions from '../../redux/Comment/Actions';
const { REACT_APP_API_URL } = process.env;


const Comment = ({ t, data }) => {
    const lang = useSelector((state) => state.App.lang);
    const ProfileState = useSelector((state) => state.Profile);
    const { userInfo } = ProfileState;
    const dispatch = useDispatch();
    const deleteComment = (id) => {
        dispatch(CommentActions.deleteComment({ comment_id: id }))
    }
    return (
        <CommentWrapper lang={lang}>
            <CommentContainer>
                <Grid container>
                    <Grid item xs={3} sm={2} lg={1}>
                        <ProfileContainer src={REACT_APP_API_URL + "/" + userInfo?.photo}  />
                    </Grid>
                    <Grid container item xs={9} sm={10} lg={11}>
                        <Grid item xs={7} md={6} display="flex">
                            <Box id="title_container">
                                {lang === "fa" ? data.fa_name : data.en_name}
                            </Box>
                            <Box id="rate_container"><Rating name="half-rating" defaultValue={data.rate} readOnly /></Box>
                        </Grid>
                        <Grid item xs={5} md={6} display="flex" justifyContent="end">
                            <Box>
                                {lang === "fa" ? data.fa_date : data.en_date}
                            </Box>
                            <Box id="clock_container"><ClockIcon fill="#3b3b3b" /></Box>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="end">
                            {data.is_own_by_me === true && <Box onClick={() =>deleteComment(data.id)}><DeleteIcon /></Box>}
                        </Grid>
                        <Grid item xs={12} display="flex">
                            <CommentTextConatainer>
                                {data.title}
                            </CommentTextConatainer>
                        </Grid>
                    </Grid>
                </Grid>
            </CommentContainer>
        </CommentWrapper>
    )
}
export default withTranslation()(Comment);