import React, { useEffect, useState } from "react";
import {
    BannerWrapper,
    BannerContainer
} from '../../styles';
import psychologyCourse from "../../../../assets/images/psychologyCourse.png";
import sportCourse from "../../../../assets/images/sportCourse.png";
import lawCourse from "../../../../assets/images/lawCourse.png";
import englishCourse from "../../../../assets/images/englishCourse.png";
import programmingCourse from "../../../../assets/images/programmingCourse.png";
import musicCourse from "../../../../assets/images/musicCourse.png";
import { withTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import TabHandler from "../../../../shared/tabHandler";
import Course from "../course/course";


const Banner = ({ t, id, value, setValue, search, setSearch, sort, setSort }) => {
    const stateCourse = useSelector((state) => state.Course);
    const { categoriesListOptions } = stateCourse;
    const lang = useSelector((state) => state.App.lang);
    const [TabConfig, setTabConfig] = useState([]);

    const params = new URLSearchParams(window.location.search);
    // const TabConfig = [
    //     { id: 1, title: t("programming"), body: Course },
    //     { id: 4, title: t("sport"), body: Course },
    //     { id: 2, title: t("english"), body: Course },
    //     { id: 3, title: t("law"), body: Course },
    //     { id: 5, title: t("music"), body: Course },
    //     { id: 6, title: t("psychology"), body: Course },
    // ];
    const [categoryName, setCategoryName] = useState("")
    useEffect(() => {
        let data = [];
        // console.log(categoriesListOptions)
            // alert(categoriesListOptions)
        categoriesListOptions.map((each) => {
            const title = lang === "fa" ? each?.fa_name : each?.en_name;
            // console.log(id)
            if (each.value === Number(params.get("id"))){
                setCategoryName(title)
            }
            // if(each.value === 6){
            //     console.log(Course)
            // }
            return (
                data.push({
                    id: each.value,
                    title: title,
                    body: Course
                })
            )
        })
        setTabConfig(data);
        setValue(parseInt(params.get("id")))
    }, [lang, setCategoryName])


    const handleChange = (e, newValue) => {
        // alert(newValue)
        // console.log(TabConfig)
        window.location.href='/courses?id='+newValue
        // setValue(newValue);
        //     // onClick={(e)=>}
        // for (const tabConfigKey in TabConfig) {
        //     if (TabConfig[tabConfigKey].id === newValue) {
        //         setCategoryName(TabConfig[tabConfigKey].title)
        //     }
        // }
    };

    const handleBackground = (value) => {

        switch (value) {
            case 1:
                return programmingCourse;
            case 2:
                return englishCourse;
            case 3:
                return lawCourse;
            case 4:
                return sportCourse;
            case 5:
                return musicCourse;
            case 6:
                return psychologyCourse;
            default:
                return programmingCourse;
        }
    }
    return (
        <BannerWrapper>
            <BannerContainer  >
                <img src={handleBackground(value)} style={{"width":"100%", "height":"92vh", "backgroundSize":"cover","position":"absolute", "top":"5.5rem"}} />
                <Typography id="category_name" component={"h4"}  >
                    {categoryName}
                </Typography>
                <Typography id="category_text" style={{"lineHeight":"30px", "textAlign":"justify"}} component={"h4"}>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                </Typography>
            </BannerContainer>
            <Box sx={{ width: '100%' }} display="flex" justifyContent="center" id="tab_container">

                <TabHandler
                    TabConfig={TabConfig}
                    id={value}
                    handleChange={handleChange}
                    tabValue={value}
                    search={search}
                    setSearch={setSearch}
                    sort={sort}
                    setSort={setSort}
                />
            </Box>
        </BannerWrapper>
    )
}

export default withTranslation()(Banner);
