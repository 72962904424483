import React, { useState } from 'react';
import LoginRegisterIntro from '../../views/LoginRegister/LoginRegisterIntro/index';
import ForgetPasswordContent from '../../views/forgetPassword';
import { Grid } from "@mui/material";


const ForgetPassword = () => {
  
    return (
        <Grid container height="100vh">
            <Grid item xl={8} lg={8} md={8} >
               <LoginRegisterIntro  mode={"login"} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}  height="100%">
                <ForgetPasswordContent />
            </Grid>
        </Grid>
    )
}
export default ForgetPassword;

