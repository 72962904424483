export default {
  SET_SHOW_MEMBER_MODAL: 'SET_SHOW_MEMBER_MODAL',

  GET_MEMBERS_LIST: 'GET_MEMBERS_LIST',
  GET_MEMBERS_LIST_SUCCESS: 'GET_MEMBERS_LIST_SUCCESS',
  GET_MEMBERS_LIST_FAIL: 'GET_MEMBERS_LIST_FAIL',
  GET_MEMBERS_LIST_EXCEPTION: 'GET_MEMBERS_LIST_EXCEPTION',

  ADD_MEMBER: 'ADD_MEMBER',
  ADD_MEMBER_SUCCESS: 'ADD_MEMBER_SUCCESS',
  ADD_MEMBER_FAIL: 'ADD_MEMBER_FAIL',
  ADD_MEMBER_EXCEPTION: 'ADD_MEMBER_EXCEPTION',

  DELETE_MEMBER: 'DELETE_MEMBER',
  DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_FAIL: 'DELETE_MEMBER_FAIL',
  DELETE_MEMBER_EXCEPTION: 'DELETE_MEMBER_EXCEPTION',

  UPDATE_MEMBER: 'UPDATE_MEMBER',
  UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
  UPDATE_MEMBER_FAIL: 'UPDATE_MEMBER_FAIL',
  UPDATE_MEMBER_EXCEPTION: 'UPDATE_MEMBER_EXCEPTION',

  GET_MEMBER_BY_ID: 'GET_MEMBER_BY_ID',
  GET_MEMBER_BY_ID_SUCCESS: 'GET_MEMBER_BY_ID_SUCCESS',
  GET_MEMBER_BY_ID_FAIL: 'GET_MEMBER_BY_ID_FAIL',
  GET_MEMBER_BY_ID_EXCEPTION: 'GET_MEMBER_BY_ID_EXCEPTION',
 
};
