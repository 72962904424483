import types from './Types';
import RecordController from "../../controllers/RecordController";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
let localUserInfo = Storage.get(Keys.userInfo)
  ? Storage.get(Keys.userInfo)
  : {};

const initState = {
  userInfo: {
    ...localUserInfo,
  },
  notificationList: [],
  notificationListLoading :true,
  hasMore : false,
};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.GET_USER_INFO_SUCCESS:
      Storage.set(Keys.userInfo, action.data);
      return {
        ...state,
        userInfo: action.data,
      };
      case types.UPDATE_USER_INFO_SUCCESS:
      Storage.set(Keys.userInfo, action.data);
      return {
        ...state,
        userInfo: action.data,
      };
      case types.UPDATE_USER_PROFILE_SUCCESS:
        Storage.set(Keys.userInfo, action.data);
        return {
          ...state,
          userInfo: action.data,
        };
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: action.data,
        notificationListLoading :false,
      };
    default:
      return state;
  }
}
