import {
  all,
  takeEvery,
  put,
  fork,
  takeLatest,
  select
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Storage from "../../storage";
import keys from "../../storage/NameSpace";
import Api from "../../services/Api";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import i18n from "../../helpers/i18n";
import profileTypes from "../Profile/Types";

export function* login() {

  const params = new URLSearchParams(window.location.search);
  yield takeLatest(types.LOGIN, function* ({ data }) {
      const lang = yield select((state) => state.App.lang);
    try {
      let body = {
        email: data.values.username,
        password: data.values.password,
      };
      const response = yield Api.Post(Endpoints.Login, body, false);
      
      data.action.resetForm();
      data.action.setSubmitting(false);
      yield put({
        type: types.LOGIN_SUCCESS,
        data : response
      });
      yield put({
        type: profileTypes.GET_USER_INFO,
        data : response
      });

      
      toast.success(i18n.t("logged in successfully"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
        // if (params && params.get("ref")){
        //   window.location.href = params.get("ref")
        // }
    } catch (e) {
      
     let errMsg = e.response.data.message;
      toast.error(i18n.t("The username and password are incorrect"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
      data.action.resetForm();
      yield put({
        type: types.LOGIN_FAIL,
        data: {
          exception: errMsg,
        },
      });
    
    }
  });
}

export function* Register() {

  yield takeLatest(types.REGISTER, function* ({ data }) {
    const lang = yield select((state) => state.App.lang);

    try {
      let body = {
        email: data.values.email,
        password: data.values.password,
        name: data.values.firstName,
        lastName :data.values.lastName,
      };
      const response = yield Api.Post(Endpoints.Register, body, false);
      data.action.resetForm();
      data.action.setSubmitting(false);
      yield put({
        type: types.REGISTER_SUCCESS,
        data : response
      });
      toast.success(i18n.t("Done successfully"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });


    } catch (e) {
     let errMsg = e.response.data.message;
      toast.error(i18n.t("Operation failed"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
      data.action.resetForm();
      yield put({
        type: types.REGISTER_FAIL,
        data: {
          exception: errMsg,
        },
      });
    
    }
  });
}

export function* ActivateAnAccount() {

  yield takeEvery(types.ACTIVATE_AN_ACCOUNT, function* ({ data }) {
    const lang = yield select((state) => state.App);

    try {
      let body = {
        email: data.email,
        activation: data.activation,
      };
      const response = yield Api.Post(Endpoints.ActivateAnAccount, body, false);
      yield put({
        type: types.ACTIVATE_AN_ACCOUNT_SUCCESS,
        data : response
      });
      toast.success(i18n.t("Done successfully"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });


    } catch (e) {
      let msg= e.response.data.status;
      toast.error(msg, {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
      yield put({
        type: types.ACTIVATE_AN_ACCOUNT_FAIL,
        data: {
          exception: msg,
        },
      });
    }
  });
}

export function* ForgetPassword() {

  yield takeEvery(types.FORGET_PASSWORD, function* ({ data }) {
    const lang = yield select((state) => state.App);
    try {
      let body = {
        email: data.values.email,
      };
      const response = yield Api.Post(Endpoints.ForgetPassword, body, false);
      yield put({
        type: types.FORGET_PASSWORD_SUCCESS,
        data : response
      });
      toast.success(i18n.t("The recovery link has been sent to your email"), {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
    } catch (e) {
      let msg= e.response.data.status;
      toast.error(msg, {
        position: lang === "fa" ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.BOTTOM_LEFT,
      });
      yield put({
        type: types.FORGET_PASSWORD_FAIL,
        data: {
          exception: msg,
        },
      });
    }
  });
}


export function* Logout() {
  yield takeEvery(types.LOGOUT, function* ({ data }) {
    try {
      const response = yield Api.Post(Endpoints.Logout, {});
      yield put({
        type: types.LOGOUT_SUCCESS,
      });
    } catch (e) {
      yield put({
        type: types.LOGOUT_FAIL,
      });
    }
  });
}

export default function* LoginSagas() {
  yield all(
    [
      fork(login),
      // fork(Logout),
      fork(Register),
      fork(ActivateAnAccount),
      fork(ForgetPassword)
    ]);
}
