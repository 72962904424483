import styled from 'styled-components';
import  Col from "../../../kit/Column";

export const LoginRegisterFormWrapper = styled(Col)`
  height: 100%;
  background-color: ${(props) => props.theme.baseBackGroundColor};
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    width: 100%;
  } ;
`;


