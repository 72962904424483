import Row from "../kit/Row"
import styled from 'styled-components';
import Col from "../kit/Column";


export const TableWrapper = styled(Col)`
width: 100%;
table {
    border-collapse:separate; 
    border-spacing: 0 1em;
  }
table td{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.text};
    border:none;
    background-color : #f1f2f3;
}
table th{
    font-family: ${(props) => (props.theme.fontDefault)};
    color: ${(props) => props.theme.secondaryFontColor};
    border:none;
    background-color : #e0e6ed;
}
tr td:first-child , tr th:first-child{
    border-top-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"}; 
    border-bottom-left-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
   }
tr td:last-child , tr th:last-child { 
    border-top-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-bottom-left-radius: ${(props) => props.lang === "fa" ? "10px" : "0"};
    border-top-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};
    border-bottom-right-radius: ${(props) => props.lang === "en" ? "10px" : "0"};  
}

button{
    height: 100%;
    background-color: ${(props) => props.theme.white};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};;
    width: 100%;
    color : ${(props) => props.theme.secondaryFontColor};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
 #actions_container{
    height:38px;
    display :flex;
    align-items:center;
    justify-content:center;
 }
 #comment_icon{
     padding: 0 3px;
     margin: 0 3px;
     display :flex;
     height: 100%;
     justify-content: center;
     align-items: center;
      background-color: ${(props) => props.theme.thirdFontColor};
      border-radius: ${(props) => props.theme.borderRadius};
     svg{width :25px; height:20px;}
 }

`;

export const ModalConatiner = styled(Col)`

*{direction: ${(props) => props.lang === "fa" ? "rtl" : "ltr"};} 
button{
    margin-top :10px;
    background-color: ${(props) => props.theme.secondaryFontColor};
    border-radius: ${(props) => props.theme.borderRadius};
    border: 1px solid ${(props) => props.theme.secondaryFontColor};
    width: 100%;
    padding: 10px;
    color : ${(props) => props.theme.white};
    cursor :pointer;
    font-family: ${(props) => (props.theme.fontDefault)};
 }
 #rating_container{
    height: 30px;
    border-radius: 5px;
    padding: 15px;
    border: 1px solid #ccc;
}
.MuiRating-root{
    font-size: ${(props) => props.theme.fontSizeXXl};

}
.MuiRating-icon {
    color :#ffc107;
}
#modal_title{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
`;