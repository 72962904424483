import React from "react";
import Title from "../../../../shared/title"
import { ContentWrapprr } from "../../styles"
import Carousel from "../../../../shared/carousel";
import {withTranslation} from "react-i18next";
import {useSelector} from "react-redux"
const LastCourse = ({t}) => {
  const stateCourse = useSelector((state) => state.Course);
  const {recentList} = stateCourse;

  return (
    recentList.length > 0&&
    <ContentWrapprr>
      <Title title={t("recent courses")} hasMoreView={false} />
      <Carousel data={recentList}/>
    </ContentWrapprr>
  );
};
export default withTranslation()(LastCourse);
