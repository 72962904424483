import { React } from "react";
import {
    IntroductionWrapper,
    BoxTitle,
    IntroductionItem,
    ImgContainer,
} from './styles';
import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
const { REACT_APP_API_URL } = process.env;

const Introduction = () => {
    const stateCourse = useSelector((state) => state.Course);
    const {categoriesList} = stateCourse;
    const lang = useSelector((state) => state.App.lang);
    const doClick = (e) =>{

    }
    return (
        <IntroductionWrapper>
            <Grid container>
                {categoriesList && categoriesList.slice(1).reverse()?.map((item,index) => {
                    return (
                        <Grid item xs={6} sm={4} md={2} lg={2} key={index}>
                            <IntroductionItem >
                                <div className="ItemContainer" style={{"cursor":"pointer"}}
                                     onClick={(e)=>window.location.href = '/courses?id=' + item?.id}>
                                     {/*<Link to={{ pathname:  }} >*/}
                                        {/*<Link to={'/courses/' + item?.id}>*/}
                                         <ImgContainer src={REACT_APP_API_URL+"/"+item?.icon} />
                                        <BoxTitle>
                                             {lang === "fa" ? item?.fa_name : item?.en_name}
                                        </BoxTitle>
                                    {/*</Link>*/}

                                </div>

                            </IntroductionItem>
                        </Grid>

                    )
                })}
            </Grid>
        </IntroductionWrapper>
    )
}

export default Introduction;
