import React,{useState} from "react";
import { Modal, Box, Rating, Grid } from "@mui/material"
import { withTranslation } from "react-i18next";
import { StyledTextArea } from "../../kit/Form";
import { ModalConatiner } from "../../views/globalstyles";
import { useSelector } from "react-redux";
import CloseIcon from "../../assets/icons/DeleteIcon";

import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//   reversedRating: {
//     direction: 'rtl',
//     '& .MuiRating-icon': {
//       transform: 'scaleX(-1)', // Flip the stars horizontally
//     },
//   },
// });
// const useStyles = makeStyles({
//   reversedRating: {
//     display: 'flex',
//     flexDirection: 'row-reverse', // Reverse the row direction
//   },
// });
// const useStyles = makeStyles({
//   customRating: {
//     display: 'flex',
//     flexDirection: 'row-reverse',
//     '& .MuiRating-icon': {
//       transform: 'scaleX(-1)',
//       transition: 'transform 0.3s ease-in-out',
//     },
//     '& .MuiRating-iconEmpty': {
//       transform: 'scaleX(-1)',
//     },
//     '& .MuiRating-iconFilled': {
//       transform: 'scaleX(-1)',
//     },
//   },
// });
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 2,
};

const CommentModal = ({
  open,
  handleClose,
  onConfirm,
  t
}) => {
  // const classes = useStyles();
  const lang = useSelector((state) => state.App.lang);
  const [values, setValues] = useState({rate: 0, text: ""})
  const onChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setValues({...values, [name] : value})
  }
  return (
    <Modal
      open={open}
      onClose={(e) => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus={true}
      BackdropProps={{ onClick: (e) => e.stopPropagation() }} >
      <Box sx={style}>
        <ModalConatiner lang={lang}>
          <Grid container>
            <Grid item container xs={12} id="modal_title">
              <Grid item xs={11} display="flex" justifyContent="start">
                {t("register a comment")}
              </Grid>
              <Grid item xs={1} onClick={() => handleClose(false)} className="clickable">
                <CloseIcon />
              </Grid>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="center">
              <Box id="rating_container">
                <Rating
                  name="rate"
                  // className={classes.customRating}
                  value={values.rate}
                  onChange={(e) => onChangeHandler(e)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box >
                {t("comment")}
              </Box>
              <Box>
                <StyledTextArea
                  placeholder={t("write your comment")}
                  value={values.text}
                  name="text"
                  onChange={(e) => onChangeHandler(e)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <button onClick={() => onConfirm({ title: values.text, rate: Number(values.rate) })}>
                {t("register a comment")}
              </button>
            </Grid>
          </Grid>
        </ModalConatiner>
      </Box>
    </Modal>
  )
}
export default withTranslation()(CommentModal);