import React ,{useEffect} from 'react';
import InstructorProfile from './components/instructorProfile';
import AboutInstructor from './components/aboutInstructor';
import InstructorCourse from './components/instructorCourse';
import {InstructorWrapper} from "./styles";
import Header from '../../shared/header';
import Footer from '../../shared/footer';
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import CourseActions from "../../redux/Course/Actions";
import MasterActions from "../../redux/Master/Actions";

const Instructor = () => {
    // const  pathname  = useHistory();
    // const {location} = useHistory();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(MasterActions.getMasterById({id : params.get("id")}));
      dispatch(CourseActions.getCourses({
        related:false,
        recent:false,
        has_discount:false,
        courseType:"master",
        master_id :params.get("id")
      }))
    }, []);
    const theme = useSelector((state) => state.App.theme);
    const masterDetail = useSelector((state) => state.Master.masterDetail);
    // console.log("--->", masterDetail)
    return (
        <InstructorWrapper>
           <Header backgroundColor={theme.white} color={theme.secondaryFontColor}/>
           <InstructorProfile     masterDetail={masterDetail}/>
           <AboutInstructor  masterDetail={masterDetail}/>
           <InstructorCourse  />
           <Footer />
        </InstructorWrapper>
    )
}
export default Instructor;