
import React, { useEffect, useState } from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"
import PrivateCourseActions from "../../../../redux/PrivateCourse/Actions";
import { useDispatch, useSelector } from "react-redux";
import { TableWrapper, AddContainer, CardContainer, FilterContainer } from "../../globalStyle";
import Table from "../../../../shared/Table";
import CoursesTableConfig from "./CoursesTableConfig"
import AlertModal from "../../../../shared/AlertModal";
import CourseDetailModal from "./CourseDetailModal";
import CourseToggleDetail from "./CourseToggleDetail";
import MasterActions from "../../../../redux/Master/Actions";
import FormFilter from "../../FormFilter";
import {Grid} from "@mui/material"

const Course = () => {
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = useState({
    showAlert: false,
    course: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const defaultfields = {
    id: "",
    student_capacity: "1",
    status: "",
    en_title: "",
    duration: "",
    sessions: "",
    price: "",
    fa_title: "",
    fa_description: "",
    en_description: "",
    fa_subtitle: "",
    en_subtitle: "",
    start_date_fa: "",
    end_date_fa: "",
    new_price :"",
    course_type :"",
    master_id:"",
    image:null,
    photo:null,
    price_unit:"$ CAD",
    category_id:"",
    meeting_link:"",
    query :""
  };
  const [fields, setFields] = useState(defaultfields);

  const courseState = useSelector((state) => state.PrivateCourse);
  const { privateCoursesList, coursesLoading, take, skip, count, mode, showCourseDetailModal } = courseState;
  useEffect(() => {
    dispatch(PrivateCourseActions.getCoursesList({ offset: 0, limit: take }));
    dispatch(MasterActions.getMasters({ offset: 0, limit: 1000 }));

  }, []);

  ///////////////////////////////////////////detail
  const coursesDetailHandler = (result) => {

    setFields({
      ...fields,
      ...result,
      // ...masterDetail,
    });
    dispatch(
      PrivateCourseActions.setShowCourseDatailModal({
        visible: true,
        mode: "edit",
      })
    );
    // dispatch(PrivateCourseActions.getMasterById(result.id));
  };

  const addOrUpdateCourseHandler = (values, action) => {
    if (mode === "add") {
      dispatch(
        PrivateCourseActions.addCourse({
          fields: values,
        })
      );
    } else if (mode === "edit") {
      dispatch(
        PrivateCourseActions.updateCourse({
          fields: values,
        })
      );
    }
  };

  const closeCourseDetailsModal = (formik) => {
    formik.resetForm({});
    dispatch(
      PrivateCourseActions.setShowCourseDatailModal({
        visible: false,
        mode: "add",
      })
    );
    setFields({
      ...fields,
      ...defaultfields,
    });

    formik.resetForm({});
  };
  //////////////////////////////////////
  const pageHandler = (res) => {
    dispatch(
      PrivateCourseActions.getCoursesList({
        offset: res,
        limit: take,
      })
    );
  }
  ///////////////////////////////////////////////delete
  const deleteCourse = (course) => {
    setDeleteState({
      ...deleteState,
      showAlert: true,
      course,
    });
  }
  const onConfirmDeleteHandler = () => {
    let callBack = () => {
      setDeleteState({
        ...deleteState,
        showAlert: false,
        course: null,
      });
    };
    dispatch(
      PrivateCourseActions.deleteCourse({
        course: deleteState.course,
        callBack,
      })
    );
  };

  const onCancelDeleteHandler = () => {
    setDeleteState({
      ...deleteState,
      showAlert: false,
      course: null,
    });
  };
      ////////////////////////////////
      const toggleDetail = (result) => {
        setIsOpen(!isOpen);
        setSelected(result)
      }
      ////////////////////////////////filter
  const filterCourse = (values, action) => {
    dispatch(
      PrivateCourseActions.getCoursesList({values , type :"filter",limit :0 ,offset:""})
    );
  }
  return (
    <MainLayout>
      <CardContainer>
      <FilterContainer>
        <Grid container >
            <Grid item xs={4}>
              <FormFilter fields={fields} onConfirm={filterCourse} />
            </Grid>
            <Grid item xs={8}>
            <AddContainer>
              <span onClick={() => {
              dispatch(
                PrivateCourseActions.setShowCourseDatailModal({
                  visible: true,
                  mode: "add",
                })
                );
              }}>+</span>
            </AddContainer>
              </Grid>
        </Grid>
        </FilterContainer>
        <TableWrapper height="calc(100% - 70px)">
          <Table
            tblConfig={CoursesTableConfig(coursesDetailHandler, deleteCourse ,toggleDetail)}
            tblData={privateCoursesList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skip}
            take={take}
            count={count}
            loading={coursesLoading}
          />
        </TableWrapper>
      </CardContainer>
      <CourseDetailModal
        open={showCourseDetailModal}
        title={
          mode === "add" ? "اضافه کردن درس " : "ویرایش درس"
        }
        onClose={closeCourseDetailsModal}
        onConfirm={addOrUpdateCourseHandler}
        fields={fields}
        setFields={setFields}
        mode={mode}
      />
      <AlertModal
        open={deleteState.showAlert}
        onConfirm={onConfirmDeleteHandler}
        onClose={onCancelDeleteHandler}
        title={"حذف یک درس"}
        onCancel={onCancelDeleteHandler}
        alertText={"آیا شما تمایل به حذف این درس را دارید؟"}
        onConfirmButtonText={"بله"}
        onCancelButtonText={"خیر"}
      />
       <CourseToggleDetail
          isOpen={isOpen}
          onToggle={toggleDetail}
          hasLogo={true}
          selected={selected}
      />
    </MainLayout>
  )
}

export default Course;