import React, { useState, useEffect } from 'react';
import { HomeWrapprr } from "./styles";
import CarouselBox from './box';
import Introduction from './components/introduction';
import Banner from './components/banner';
import Header from '../../shared/header';
import Music from './components/music';
import English from './components/english';
import Psychology from './components/psychology';
import Law from './components/law';
import Sport from './components/sport';
import Footer from '../../shared/footer';
import Programming from './components/programming';
import Discount from "./components/discount";
import Slogen from "./components/slogen"
import LastCourse from './components/lastCourse';
import { useSelector } from "react-redux";

const Home = () => {
    const theme = useSelector((state) => state.App.theme);
   
    return (
        <HomeWrapprr >
            <Header  color={theme.white}/>
            <Banner />
            <Introduction />
            <LastCourse />
            <Slogen />
            <Music />
            <English />
            <Psychology />
            <Sport />
            <Programming />
            <Law />
            <Discount />
            <Footer />
        </HomeWrapprr>
    )
}
export default Home;