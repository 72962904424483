
import React from "react";

function Icon({pathFill}) {
    return (
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_39_2551)">
      <path d="M0 2.25V22.8609C0 23.4891 0.510937 24 1.13906 24C1.37344 24 1.60312 23.9297 1.79531 23.7938L9 18.75L16.2047 23.7938C16.3969 23.9297 16.6266 24 16.8609 24C17.4891 24 18 23.4891 18 22.8609V2.25C18 1.00781 16.9922 0 15.75 0H2.25C1.00781 0 0 1.00781 0 2.25Z" fill={pathFill}/>
      </g>
      <defs>
      <clipPath id="clip0_39_2551">
      <rect width="18" height="24" fill={pathFill}/>
      </clipPath>
      </defs>
      </svg>
      );
}
Icon.defaultProps = {
  pathFill: "#3019A3",
};
export default Icon;