import React from 'react';
import { useSelector } from 'react-redux';

function TelegramIcon() {
  const theme = useSelector((state) => state.App.theme);

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="none"
    viewBox="0 0 60 60"
  >
    <path
      fill="#3D54A8"
      d="M55.238 0H4.762C2.132 0 0 2.239 0 5v50c0 2.761 2.132 5 4.762 5h50.476C57.868 60 60 57.761 60 55V5c0-2.761-2.132-5-4.762-5z"
    ></path>
    <path
      fill="#FCFCFC"
      d="M26.557 34.576l-.53 7.445a1.852 1.852 0 001.479-.716l3.55-3.39 7.359 5.39c1.35.751 2.3.355 2.665-1.242l4.829-22.635c.428-1.995-.721-2.775-2.033-2.286L15.486 28.01c-1.937.752-1.909 1.832-.33 2.324l7.258 2.254L39.271 22.04c.793-.526 1.514-.234.922.29L26.557 34.576z"
    ></path>
  </svg>
  );
}

export default TelegramIcon;
