import React, { useEffect,useState } from 'react';
import MainLayout from "../../../../shared/dashboardMainLayout"
import { withTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import TabHandler from "../../../../shared/tabHandler";
import Course from "./course";
import { ContenttWrapper } from "../../globalStyle";


const Favorites = ({ t}) => {
    const stateCourse = useSelector((state) => state.Course);
    const { categoriesListOptions } = stateCourse;
    const lang = useSelector((state) => state.App.lang);
    const [TabConfig, setTabConfig] = useState([]);
    const [value, setValue] = useState(1);

    useEffect(() => {
        let data = [];
        categoriesListOptions.map((each) => {
            return (
                data.push({
                    id: each.value,
                    title: lang === "fa" ? each?.fa_name : each?.en_name,
                    body: Course
                })
            )
        })
        setTabConfig(data);
    }, [lang])

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

  
    return (
        <MainLayout>
            <ContenttWrapper>
            <Box sx={{ width: '100%' }} display="flex" justifyContent="center" id="tab_container">

                <TabHandler
                    TabConfig={TabConfig}
                    id={value}
                    handleChange={handleChange}
                    tabValue={value}
                />
            </Box>
            </ContenttWrapper>
        </MainLayout>
    )
}

export default withTranslation()(Favorites);
