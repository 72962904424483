import React, { useEffect , useState } from "react";
import MainLayout from "../../../../shared/dashboardMainLayout"
import MemberActions from "../../../../redux/Member/Actions";
import { useDispatch , useSelector } from "react-redux";
import {TableWrapper , CardContainer ,AddContainer, FilterContainer} from "../../globalStyle";
import MembersTableConfig from "./MembersTableConfig";
import Table from "../../../../shared/Table";
import AlertModal from "../../../../shared/AlertModal";
import MemberDetailModal from "./MemberDetailModal";
import MemberToggleDetail from "./MemberToggleDetail";
import FormFilter from "../../FormFilter";
import {Grid} from "@mui/material";
const Member = () => {
  const dispatch = useDispatch();
  const [deleteState, setDeleteState] = useState({
    showAlert: false,
    member: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const defaultfields = {
    id:"",
    email: "",
    en_biography: "",
    en_first_name: "",
    en_last_name: "",
    en_middle_name: "",
    fa_biography: "",
    fa_first_name: "",
    fa_last_name: "",
    fa_middle_name: "",
    gender: "",
    birthday:"",
    ramz:"",
  };
  const [fields, setFields] = useState(defaultfields);
  const memberState = useSelector((state) => state.Member);
  const { membersList, membersLoading, take, skip, count, mode ,showMemberDetailModal  } = memberState;
  useEffect(() => {
    dispatch(MemberActions.getMembers({ offset: 0, limit: take ,is_staff :true }));
  }, []);

 ///////////////////////////////////////////detail
 const membersDetailHandler = (result) => {
    
  setFields({
    ...fields,
    ...result,
  });
  dispatch(
    MemberActions.setShowMemberDatailModal({
      visible: true,
      mode: "edit",
    })
  );
 
};

const addOrUpdateMemberHandler = (values, action) => {
  if (mode === "add") {
    dispatch(
      MemberActions.addMember({
        fields: values,
        is_staff :true
      })
    );
  } else if (mode === "edit") {
    dispatch(
      MemberActions.updateMember({
        fields: values,
        is_staff :true
      })
    );
  }
};

const closeMemberDetailsModal = (formik) => {
  formik.resetForm({});
  dispatch(
    MemberActions.setShowMemberDatailModal({
      visible: false,
      mode: "add",
    })
  );
  setFields({
    ...fields,
    ...defaultfields,
  });

  formik.resetForm({});
};
//////////////////////////////////////
const pageHandler = (res) => {
  dispatch(
    MemberActions.getMembers({
      offset: res,
      limit: take,
      is_staff :true
    })
  );
}
////////////////////////////////////delete
  const deleteMember = (member) => {
    setDeleteState({
      ...deleteState,
      showAlert: true,
      member,
    });
  }
  const onConfirmDeleteHandler = () => {
    let callBack = () => {
      setDeleteState({
        ...deleteState,
        showAlert: false,
        member: null,
      });
    };
    dispatch(
      MemberActions.deleteMember({
        member: deleteState.member,
        is_staff :true,
        callBack,
      })
    );
  };

  const onCancelDeleteHandler = () => {
    setDeleteState({
      ...deleteState,
      showAlert: false,
      member: null,
    });
  };
    ////////////////////////////////
    const toggleDetail = (result) => {
      setIsOpen(!isOpen);
      setSelected(result)
    }
////////////////////////////////filter
const filterMember = (values, action) => {
  dispatch(
    MemberActions.getMembers({ values, type: "filter", limit: 0, offset: "",is_staff: true })
  );
}
return (
  <MainLayout>
    <CardContainer>
      <FilterContainer>
        <Grid container >
          <Grid item xs={4}>
            <FormFilter fields={fields} onConfirm={filterMember} />
          </Grid>
          <Grid item xs={8}>
            <AddContainer>
              <span onClick={() => {
                dispatch(
                  MemberActions.setShowMemberDatailModal({
                    visible: true,
                    mode: "add",
                  })
                );
              }}>+</span>
            </AddContainer>
          </Grid>
        </Grid>
      </FilterContainer>
        <TableWrapper height="calc(100% - 70px)">
          <Table
            tblConfig={MembersTableConfig(membersDetailHandler, deleteMember ,toggleDetail)}
            tblData={membersList}
            showPaging={true}
            pageHandler={(res) => {
              pageHandler(res);
            }}
            skip={skip}
            take={take}
            count={count}
            loading={membersLoading}
          />
        </TableWrapper>
      </CardContainer>
      <MemberDetailModal
        open={showMemberDetailModal}
        title={
          mode === "add" ? "اضافه کردن پرسنل " : "ویرایش پرسنل"
        }
        onClose={closeMemberDetailsModal}
        onConfirm={addOrUpdateMemberHandler}
        fields={fields}
        setFields={setFields}
      />
      <AlertModal
         open={deleteState.showAlert}
         onConfirm={onConfirmDeleteHandler}
         onClose={onCancelDeleteHandler}
         title={"حذف یک پرسنل"}
         onCancel={onCancelDeleteHandler}
        alertText={"آیا شما تمایل به حذف این پرسنل را دارید؟"}
        onConfirmButtonText={"بله"}
        onCancelButtonText={"خیر"}
      />
      <MemberToggleDetail
          isOpen={isOpen}
          onToggle={toggleDetail}
          hasLogo={true}
          selected={selected}
      />
    </MainLayout>

  )
}

export default Member;