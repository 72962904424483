import styled from 'styled-components';
import Col from "../../kit/Column";
import Row from "../../kit/Row";


// export const TabWrapper = styled(Col)`
// width:100%;


// .MuiTab-root{
//    width: 130px;
// }

// @media only screen and (max-width: 768px) {
//   .MuiTab-root{
//      width: unset;
//   }
 
// }
// `;

export const TabWrapper = styled(Col)`
  width:100%;
.MuiTabs-root{
  width:100%;

 }
 .Mui-selected {
    border-bottom : 2px solid ${(props) => (props.theme.primary)};
 }

 .MuiTab-root{
    font-family: ${(props) => (props.theme.fontDefault)};
    width: 150px;
 }

 .MuiTabs-scroller{
    padding: 10px;
    border-radius : ${(props) => (props.theme.primaryBorderRadius)};
    display: flex;
    justify-content: center;
   //  border: 1px solid #ccc;
 }
 .MuiTabScrollButton-root{
   display : none;
 }

   #tab-content-container{
      padding :40px;
   }


@media only screen and (max-width: 768px) {
   .MuiTab-root{
      width: unset;
   }
   .MuiTabs-scrollButtons{
      display : none
   }
   #tab-content-container{
      padding :10px;
    }
    .MuiTabs-scroller{
      justify-content: normal;
    }
}
 
`;