import React from 'react';
import CourseRegisterView from "../../views/courseRegister";


const CourseRegister = () => {

    return (
        <>
            <CourseRegisterView />
        </>
    )
}
export default CourseRegister;