import React from "react";

  const PersonIcon =({pathFill}) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={pathFill}
        d="M9.75 6a3 3 0 10-6 0 3 3 0 106 0zm1.5 1.5V9h2.25v2.25H15V9h2.25V7.5H15V5.25h-1.5V7.5h-2.25zm-10.5 6V15h12v-1.5c0-1.995-3.998-3-6-3-2.003 0-6 1.005-6 3z"
      ></path>
    </svg>
  );
}
PersonIcon.defaultProps = {
    pathFill: "#3019A3",
  };

export default PersonIcon;